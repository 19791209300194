import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import Switch from '@mui/material/Switch';
import {
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import { async } from "rxjs/internal/scheduler/async";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "14px",
    marginTop: "30px"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding: "1.8%",

  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    padding: "0.8%",
    marginTop: "1%"
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const Payment = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [data, setData] = useState({
    apikey: '',
    paysalt: '',
    status:''
  })


  // const onChangeData = (event) => {
  //   setData({ ...data, [event.target.name]: event.target.value });
  // };
  const onChangeData = (event) => {
    if (event.target.name === 'status') {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
     
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };


  console.log("data***",data)
  
  // useEffect(() => {
   
  //       setData({
  //           paykey: respo?.paykey || '', 
  //           paysalt: respo?.paysalt || ''
  //         });

  // }, []);

  const userId = JSON.parse(localStorage.getItem("USER"));

  const [addLoadingStatus, setAddLoadingStatus] = useState(false)

//   const handleSubmit = async (event) => {
//     setAddLoadingStatus(true)
//     const formData = new FormData();
//     formData.append("id", userId.id);
//     formData.append("apikey", data.apikey);
//     formData.append("baseUrl", data.baseUrl);
//     formData.append("senderid", data.senderid);
//     if (
//       data.apikey == "" ||
//      data.baseUrl == "" ||
//      data.senderid == "" 
//    ) {
//     SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
//   } else {
//     try {
//       const response = await Axios({
//         method: "get",
//         url: `${process.env.REACT_APP_API2_HOST}/get_payment_settings`,
//         // data: formData,
//         // headers: { "Content-Type": "application/json" },
//       });
//       if(response){
//       if (response.data.message.toString() == "Updated Successfully!") {
//         NotificationManager.success(response.data.message);
//         setAddLoadingStatus(false)
//       } else {
//         NotificationManager.error(response.data.message);
//         setAddLoadingStatus(false)
//       }
//     }
//     else{
//       NotificationManager.error(response.data.message);
//       setAddLoadingStatus(false)
//     }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// };

// useEffect(() => {
//     if (respo) {
//       setData({
//         paykey: respo.paykey || '',
//         paysalt: respo.paysalt || ''
//       });
//     }
//   }, [respo]);


//  const handleSubmit=async()=>{
//   try{
//     const formData = new FormData();
//     formData.append("id",id);
//     formData.append("paykey",data.paykey);
//     formData.append("paysalt", data.paysalt);
//     formData.append("status",data.status)

//     const response = await Axios({
//         method: "put",
//         url: `${process.env.REACT_APP_API2_HOST}/add_payment_settings?id=${id}`,
//         // url:`http://192.168.1.42:8080/add_payment_settings?id=6347b1284028b200c6af7b51`,
//         data: formData,
//         headers: { "Content-Type": "application/json" },
//       });
  

//   }catch(error){
//     console.log(error)
//   }
//  }


let id="6347b1284028b200c6af7b51"
const handleSubmit = async () => {
  try {

    const formData = new FormData();
    formData.append("id", id);
    formData.append("paykey", data.paykey);
    formData.append("paysalt", data.paysalt);
    formData.append("status", data?.status);
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    if (
      data.paykey == "" ||
     data.paysalt == "" ||
     data.paysalt == ""
   ) {
    SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
  } else {
    const response = await Axios.post(
      `${process.env.REACT_APP_API2_HOST}/add_payment_settings?id=6347b1284028b200c6af7b51`,
      formData,
      config
    );

    if(response){
      if (response.data.message.toString() == "Updated Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false)
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false)
      }
  }

}
    


  } catch (error) {
    console.log(error);
  }
};

const getResponse=async()=>{
    try {
        const response = await Axios({
          method: "get",
          url: `${process.env.REACT_APP_API2_HOST}/get_payment_settings?id=6347b1284028b200c6af7b51`,
        });
         console.log("response_1",response?.data?.payment_settings) 
         setData(response?.data?.payment_settings)

          console.log("response_2",response) 
      } catch (error) {
        console.log(error);
      }
 }


 console.log("statussss",data?.status)


    useEffect(()=>{
        getResponse()
    },[])
  
  return (
    <>
    <Header title="Payment Gateway"/>
        <Typography variant="h6" className={classes.prescriptionTitle}>
        Pay Salt
        </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            // name="senderid"
            name="paysalt" 
            value={data.paysalt}
            field="title"
            placeholder="pay salt"
            className={classes.style_2}
            onChange={onChangeData}
          />
        </Grid>

        <Typography variant="h6" className={classes.prescriptionTitle}>
            Pay Key
        </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            // name="apikey"
            name="paykey"
            // value={data.apikey}
            value={data.paykey}
           
            field="title"
            placeholder="Api Key"
            onChange={onChangeData}
            className={classes.style_2}
          />
        </Grid>
        <Grid>
        <Switch
         name="status"
        
        checked={data.status}
        onChange={onChangeData}
        inputProps={{ 'aria-label': 'controlled' }}
    />
    
        </Grid>
        
        <Box mb={3} style={{ marginTop: "2%",padding: "1%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.mediumBtnContainer}
            onClick={handleSubmit}
          >
            {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
          </Button>
        </Box>

    </>
  );
};

export default Payment;
