import React, { useRef } from "react";
import Header from "../../components/layout/header";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
} from "../../utils/app-util";
import Api from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import { Button, makeStyles, Grid, Box } from "@material-ui/core";

import { Form, Text } from "informed";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import { TextInput, InputNumber } from "sixsprints-react-ui";
import Routes from "../../routes/routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  container: {
    padding: theme.spacing(8),
    marginLeft: theme.spacing(12),
    width: "95%",
  },
}));

const NewChatPackage = ({ showHeader = true }) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const formApi = useRef();
  let history = useHistory();

  const handleSubmit = (val) => {
    Api.post("/chat-package", val).subscribe(
      (resp) => {
        history.push(Routes.CHAT_PACKAGE);
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CREATE_SUCCESS);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      {showHeader && <Header showBackBtn title="New Package" />}
      <Form apiRef={formApi} onSubmit={handleSubmit}>
        <Grid
          container
          className={clsx(classes.container, globalClass.roundWhiteBg)}
        >
          <Grid
            container
            item
            alignItems="stretch"
            xs={12}
            sm={6}
            md={4}
            style={{
              padding: "10px",
            }}
          >
            <Text
              required
              variant="outlined"
              type="text"
              field="packageName"
              placeholder="Package Name"
              validateOnBlur
              validateOnChange
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
              padding: "10px",
            }}
          >
            <Text
              required
              variant="outlined"
              field="totalQuestions"
              placeholder="Total Question"
              type="number"
              validateOnBlur
              validateOnChange
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
              padding: "10px",
            }}
          >
            <Text
              required
              variant="outlined"
              field="totalDays"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              placeholder="Total Days"
              validateOnBlur
              validateOnChange
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
              padding: "10px",
            }}
          >
            <Text
              required
              variant="outlined"
              field="amount"
              type="number"
              placeholder="Amount"
              validateOnBlur
              validateOnChange
            />
          </Grid>
        </Grid>
        <Box m={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Form>
    </div>
  );
};

export default NewChatPackage;
