import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { asField } from "informed";
import Api from "../../utils/api";
import Bro from "brototype";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import { useEffect } from "react";
import { debounce } from "lodash"

const { ValueContainer, Placeholder } = components;

const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const AsyncDrpdwn = ({
  fieldState,
  fieldApi,
  baseUrl,
  searchColumns,
  valueKey,
  allDoctors,
  doctorValue,
  ...props
}) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props;
  const [optionsList, setOptionsListDoctor] = useState([]);

  const user = JSON.parse(localStorage.getItem("USER"))

        useEffect(() => {
          if (baseUrl === "doctor" && user.roleName === "DOCTOR_ADMIN") {
            handleChange(user)
          }
          else {
            handleChange()
          }
        }, [])


  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      onChange(null);
      setValue(null);
      return;
    }

  
      setValue(selectedOption);
      onChange && onChange(selectedOption);
    
  };

  const handleBlur = (e) => {
    setTouched(true);
    onBlur && onBlur(e);
  };

  const loadOptions = debounce((inputValue, callback) => {
    const dto = {
      page: 0,
      size: 20,

      filterModel: {
        _: {
          filterType: "text",
          type: "",
          filter: inputValue,
          fields: searchColumns || [],
        },

        custom: {
          filterType: "exact",
          filter: `doctorPatients::slug::${user.slug}`
        },
      },
      sortModel: [],
    };

    const dtop = {
      page: 0,
      size: 20,

      filterModel: {
        _: {
          filterType: "text",
          type: "",
          filter: inputValue,
          fields: searchColumns || [],
        },
      },
      // custom: {
      //   filterType: "exact",
      //   filter: `doctorPatients::slug::$ }`
      // },

      sortModel: [],
    };

    
      if(baseUrl === "doctor"){
        Api.post(`doctor/search`, dtop).subscribe(
          
          (resp) => {
            const { content } = Bro(resp).iCanHaz("data.data") || {};
            
            callback(content || []);
            setOptionsListDoctor(content || []);
          },
          (err) => {
            DEFAULT_ERROR_CALLBACK(err);
          }
          ) 
        }

    if(baseUrl === "patient"){
      Api.post(`patient/search`, dto).subscribe(
        (resp) => {
          const { content } = Bro(resp).iCanHaz("data.data") || {};
          callback(content || []);
          setOptionsListDoctor(content || []);
        },
        (err) => {
          DEFAULT_ERROR_CALLBACK(err);
        }
      );
    }
  },1000)
      
  const styles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const getValue = !value && value !== 0 ? null : typeof value === "string" && value.length ? optionsList.find((option) => option[valueKey] === value) : value;

  return (
    <AsyncSelect
      {...rest}
      ref={forwardedRef}
      value={getValue}
      onChange={handleChange}
      onBlur={handleBlur}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      styles={
        {
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          singleValue: (base) => ({
            ...base,
            fontSize: "10px",
          }),
          ...(props.useMaterialStyle && {
            container: (base) => ({
              ...base,
              marginTop: "5px",
              width: "150px",
            }),
            control: (base, state) => ({
              ...base,
              border: "none",
              borderRadius: 0,
              background: "none",
              boxShadow: "none",
              "&::after": {
                content: "''",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                borderBottom: state.isFocused
                  ? "2px solid #2784FF"
                  : "1px solid rgba(0, 0, 0, 0.42)",
              },
              "&:hover": {
                "&::after": {
                  borderBottom: state.isFocused
                    ? "2px solid #2784FF"
                    : "2px solid rgba(0, 0, 0, 0.87)",
                },
              },
            }),
            option: (base) => ({ ...base, fontSize: "10px" }),
          }),
          ...(props.isFloatLabel
            ? {
              valueContainer: (provided, state) => ({
                ...provided,
                overflow: "visible",
                paddingLeft: 0,
              }),
              placeholder: (provided, state) => {
                return {
                  ...provided,
                  position: "absolute",
                  top:
                    state.isFocused ||
                      state.hasValue ||
                      state.selectProps.inputValue
                      ? -8
                      : "50%",
                  transition: "top 0.1s, font-size 0.1s",
                  fontSize: "10px",
                  transform:
                    (state.isFocused ||
                      state.hasValue ||
                      state.selectProps.inputValue) &&
                    "translate(0, 1.5px) scale(0.75)",
                  transformOrigin: "top left",
                };
              },
            }
            : {
              placeholder: (provided, state) => ({
                ...provided,
                fontSize: "10px",
                display:
                  state.isFocused ||
                    state.isSelected ||
                    state.selectProps.inputValue
                    ? "none"
                    : "block",
              }),
            }),
          ...props.styles,
        } || styles
      }
      components={{
        ValueContainer: NewValueContainer,
        ...(props.dropdownIndicator === false && {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }),
      }}
    />
  );
};

export default asField(AsyncDrpdwn);
