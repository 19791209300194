import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useState } from "react";

export const MultiSelectWithCheckbox = ({
  labelText,
  optionsList = [],
  idKey = 'id',
  valueKey = 'value',
  defaultSelected = [],
  renderSelectedValue = (selected) => selected.join(', '),
  onSelectionChange,
  minWidth = 220,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  const handleOnChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelectionChange && onSelectionChange(value);
  };

  return (
    <div className="multi-select-with-checkbox-wrapper">
      <FormControl sx={{ m: 1, minWidth: minWidth  }}>
        <InputLabel id="multiple-checkbox-label">{labelText}</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id={`${labelText}-multiple-checkbox`}
          multiple
          value={selectedValue}
          onChange={handleOnChange}
          input={<OutlinedInput label={labelText} />}
          renderValue={renderSelectedValue}
        >
          {optionsList.map((option, index) => (
            <MenuItem key={option[idKey] ?? index} value={option[valueKey]}>
              <Checkbox
                checked={selectedValue.indexOf(option[valueKey]) > -1}
              />
              <ListItemText primary={option[valueKey]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
