import React from "react";
import { Route, Redirect } from "react-router-dom";
import Routes from "./routes";
import AuthService from "../utils/services/auth-service";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const redirectPath = rest.redirectPath || Routes.LOGIN;
  return (
    <Route
      {...rest}
      render={props => {
        if (AuthService.isAuthenticated) {
          return <Component {...props} />;
        } else {
          // Store the current URL before redirecting
          const currentPath = `${props.location.pathname}${props.location.search}`;
          localStorage.setItem('intendedUrl', currentPath);
          return (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: props.location, authError: true }
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
