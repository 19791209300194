import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Paper,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import { TextInput } from "sixsprints-react-ui";
import AuthService from "../../utils/services/auth-service";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { AppointmentsStatusState, AppointmentsState } from "../../states";
import Header from "../../components/layout/header";
import Api2 from "../../utils/api2";
import Loader from "../../components/misc/loader";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { Form } from "informed";
import useGlobalStyles from "../../utils/global-style";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50vh",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  modalStyle_1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "85%",
    background: "white",
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  queue: {
    width: "40px",
    textAlign: "center",
  },
  container: {
    margin: "20px auto",
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "10%",
  },

  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "4%",
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: "0.7rem",
  },
  calledCellStyle: {
    backgroundColor: "#faebd7 ",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "15px",
    marginTop: "20px",
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
}));

const ReferralList = ({
  pageSize,
  rows,
  count,
  className,
  onDateTimeClicked,
  onViewPrescriptionClicked,
  onAddDiscountClicked,
  onPrintReceiptClicked,
  onViewEMRClicked,
  onPrintLetterHeadClicked,
  handleOpenPrescription,
  onNotifyClicked,
  summary,
}) => {
  const globalClass = useGlobalStyles();
  const [status, setStatus] = useState({});
  const appointmentStatus = useRecoilValue(AppointmentsStatusState);
  const user = AuthService.getUser();
  const appointmentList = useRecoilValue(AppointmentsState);
  const [content, setContent] = useState(null);
  const [addModelOpen, setaddModelOpen] = React.useState(false);
  const [AddLoad, setAddLoader] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const token = AuthService.getToken();

  const addhandleOpen = () => setaddModelOpen(true);
  const addhandleClose = () => setaddModelOpen(false);

  const [uniqueData, setUniqueData] = useState(null);

  const [updateModelOpen, setupdateModelOpen] = useState(false);
  const updatehandleOpen = (item) => (
    setupdateModelOpen(true), setUniqueData(item)
  );
  const updatehandleClose = () => setupdateModelOpen(false);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (item) => (
    setOpen(true), setContent(item.referredPatients)
  );
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [allData, setAllDoctors] = useState(null);
  const [field, setField] = useState({
    name: "",
    mobileNumber: "",
    email: "",
  });

  const handleOnchange = async (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    Api2.get(
      `${process.env.REACT_APP_API2_HOST}get_referring_doctors?referredTo=${user.slug}`
    ).subscribe((resp) => {
      if (resp) {
        setAllDoctors(resp?.data.data);
      }
    });
  }, [apiStatus]);

  const handleSubmit = async (type) => {
    if (type === "Add") {
      setAddLoader(true);
      const formData = new FormData();
      formData.append("name", field.name);
      formData.append("mobileNumber", field.mobileNumber);
      formData.append("email", field.email);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}add_referring_doctor`,
          data: formData,
          headers: {
            "Content-Type": "application/json",
            "X-AUTH-TOKEN": token,
          },
        });
        if (response) {
          if (response.data.message === "Added Successfully!") {
            NotificationManager.success(response.data.message);
            setAddLoader(false);
            setApiStatus(!apiStatus);
            setaddModelOpen(false);
          } else {
            NotificationManager.success(response.data.message);
            setAddLoader(false);
          }
        }
      } catch (error) {
        DEFAULT_ERROR_CALLBACK(error);
      }
    }
    if (type === "update") {
      setAddLoader(true);
      const formData = new FormData();
      formData.append("name", field.name);
      formData.append("mobileNumber", field.mobileNumber);
      formData.append("email", field.email);
      formData.append("id", uniqueData?._id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}update_referring_doctor`,
          data: formData,
          headers: {
            "Content-Type": "application/json",
            "X-AUTH-TOKEN": token,
          },
        });
        if (response) {
          if (response.data.message === "Updated Successfully!") {
            NotificationManager.success(response.data.message);
            setAddLoader(false);
            setApiStatus(!apiStatus);
            setupdateModelOpen(false);
          } else {
            NotificationManager.success(response.data.message);
            setAddLoader(false);
          }
        }
      } catch (error) {
        DEFAULT_ERROR_CALLBACK(error);
      }
    }
  };

  useEffect(() => {
    setField({
      name: uniqueData?.name,
      mobileNumber: uniqueData?.mobileNumber,
      email: uniqueData?.email,
    });
  }, [uniqueData]);

  return (
    <>
      <Header title="Refferal Doctor" />

      <TableContainer
        component={Paper}
        className={className ? className : classes.container}
      >
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={addhandleOpen}
          >
            ADD
          </Button>
        </Box>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" className={classes.textStyle}>
                Name
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Mobile Number
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Email Id
              </TableCell>
              <TableCell
                component="th"
                className={classes.textStyle}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Typography align="center">No data available</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ) : (
              allData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item) => {
                  return (
                    <>
                      <TableRow style={{ padding: "5%" }}>
                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography>{item.name}</Typography>
                        </TableCell>

                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography> {item.mobileNumber}</Typography>
                        </TableCell>

                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography> {item.email}</Typography>
                        </TableCell>

                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={{ marginBottom: "3px" }}
                            onClick={() => handleOpen(item)}
                          >
                            Refers Patient
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<EditIcon />}
                            onClick={() => updatehandleOpen(item)}
                          >
                            Update
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
            )}
          </TableBody>
          {/* <TablePagination
            rowsPerPageOptions={[1, 10, 25, 50, 100]}
            count={allData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
        </Table>
      </TableContainer>

      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <CloseIcon
              onClick={handleClose}
              style={{ float: "right", marginBottom: "7px", cursor: "pointer" }}
            />
            <Typography variant="h5" className={classes.prescriptionTitle}>
              Refferal Patients
            </Typography>

            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead className={classes.striky}>
                  <TableRow className={classes.striky}>
                    <TableCell component="th" className={classes.textStyle}>
                      Id
                    </TableCell>
                    <TableCell component="th" className={classes.textStyle}>
                      Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content &&
                    content?.map((row) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              className={classes.cellTextStyle}
                              style={{ padding: "2%" }}
                            >
                              <Typography>{row.slug}</Typography>
                            </TableCell>

                            <TableCell
                              className={classes.cellTextStyle}
                              style={{ padding: "2%" }}
                            >
                              <Typography> {row.name}</Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      }

      {addModelOpen && (
        <Modal
          open={addModelOpen}
          onClose={addhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle_1} style={{ background: "white" }}>
            <Typography variant="h4" component="h2">
              Add Refferal Doctor
            </Typography>
            <CloseIcon
              onClick={addhandleClose}
              style={{ float: "right", marginBottom: "7px", cursor: "pointer" }}
            />

            <Form className={classes.form}>
              <Box className={classes.staffDetailsForm}>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Doctor Name
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="name"
                    type="text"
                    label="Doctor Name"
                    validateOnBlur
                    validateOnChange
                    name="name"
                    value={field.name}
                    onChange={handleOnchange}
                  />
                </Box>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Email Id
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="email"
                    type="text"
                    label="Email Id"
                    validateOnBlur
                    validateOnChange
                    name="email"
                    value={field.email}
                    onChange={handleOnchange}
                  />
                </Box>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Mobile Number
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="mobileNumber"
                    type="number"
                    label="Mobile Number"
                    validateOnBlur
                    validateOnChange
                    name="mobileNumber"
                    value={field.mobileNumber}
                    onChange={handleOnchange}
                  />
                </Box>
              </Box>
              <Box mx=".4rem" my={12} className={classes.submitBtn}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => handleSubmit("Add")}
                >
                  {AddLoad ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Form>
          </Box>
        </Modal>
      )}
      {updateModelOpen && (
        <Modal
          open={updateModelOpen}
          onClose={updatehandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle_1} style={{ background: "white" }}>
            <Typography variant="h4" component="h2">
              Update Refferal Doctor
            </Typography>
            <CloseIcon
              onClick={updatehandleClose}
              style={{ float: "right", marginBottom: "7px", cursor: "pointer" }}
            />

            <Form className={classes.form}>
              <Box className={classes.staffDetailsForm}>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Doctor Name
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="name"
                    type="text"
                    label="Doctor Name"
                    validateOnBlur
                    validateOnChange
                    value={field?.name}
                    name="name"
                    onChange={handleOnchange}
                  />
                </Box>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Email Id
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="email"
                    type="text"
                    label="Email Id"
                    validateOnBlur
                    validateOnChange
                    value={field?.email}
                    name="email"
                    onChange={handleOnchange}
                  />
                </Box>
                <Box mb="0.5rem">
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Mobile Number
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field="mobileNumber"
                    type="number"
                    label="Mobile Number"
                    validateOnBlur
                    validateOnChange
                    value={field?.mobileNumber}
                    name="mobileNumber"
                    onChange={handleOnchange}
                  />
                </Box>
              </Box>
              <Box mx=".4rem" my={12} className={classes.submitBtn}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => handleSubmit("update")}
                >
                  {AddLoad ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Form>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ReferralList;
