import React, { useState, useEffect, useCallback, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import useGlobalStyles from "../../utils/global-style";
import { debounce } from "lodash"
import {
  Box, TextField, List,
  ListItem,
  ListItemText,
  CircularProgress,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { TextInput } from "sixsprints-react-ui";
import AuthService from "../../utils/services/auth-service";
import { TextAreaInput } from "sixsprints-react-ui";
import Api from "../../utils/api";
import Bro from "brototype";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import Api2 from "../../utils/api2";
import {TextAreaCustom,TextInputCustom} from "./TextAreaCustom";



const useStyles = makeStyles((theme) => ({
  fSize: {
    fontSize: "10px",
  },
}));



const ReferalDoctor = ({
  referringOtherEmail,
  setReferringOtherEmail,
  referringDoc,
  referringDocNo,
  referringDocEmail,
  setReferringDoc,
  setReferringDocNo,
  setReferringDocEmail,
}) => {


  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const tenant = AuthService.getTenant() ?? {};
  const [referalDocList, setReferalDocList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(false);
  let searchSubscription = null;

  const editData = new Object({ referalDocList })

  const [selectedDoctor, setSelectedDoctor] = useState({ name: "" });

  const referalDocMobile = useMemo(() => {
    return referringDocNo ? referringDocNo : ""
  }, [referringDocNo])

  const referalDocEmail = useMemo(() => {
    return referringDocEmail ? referringDocEmail : ""
  }, [referringDocEmail])

  const referalDocOtherEmail = useMemo(() => {
    return referringOtherEmail ? referringOtherEmail : []
  }, [referringOtherEmail])

  // useEffect(() => {
  //   Axios.get(
  //     `${process.env.REACT_APP_API2_HOST}referral-doctors?size=1000&page=0&tenant=${tenant.id}`
  //   ).then((resp) => {
  //     const { content } = resp.data.data;
  //     setReferalDocList(content);
  //   });
  // }, [tenant.id]);

  const user = JSON.parse(localStorage.getItem('USER'));

  useEffect(() => {

    if (user.roleName === "DOCTOR_ADMIN" || user.roleName === "ADMIN") {
      Axios.get(`${process.env.REACT_APP_API2_HOST}get_referring_doctors?referredTo=${user.slug}`).then(
        (resp) => {
          if (resp) {
            setReferalDocList(resp.data.data);
          }
        }
      );
    }
    else {
      Axios.get(`${process.env.REACT_APP_API2_HOST}get_referring_doctors`).then(
        (resp) => {
          if (resp) {
            setReferalDocList(resp.data.data);
          }
        }
      );
    }
  }, [tenant.id]);





  // useEffect(() => {
  //   setSelectedDoctor((prevState) => {
  //     return { ...prevState, name: referringDoc  };
  //   });
  //   setSelectedDoctor((prevState) => {
  //     return { ...prevState, name: referalDocMobile };
  //   });
  // }, [referringDoc,referalDocMobile]);

  // const handleChange = useCallback(
  //   (_, doctor) => {

  //     if (doctor) {

  //       setSelectedDoctor(doctor);
  //       const { name, mobileNumber, email } = doctor;
  //       name && setReferringDoc(name);
  //       mobileNumber && setReferringDocNo(mobileNumber);
  //       email && setReferringDocEmail(email);
  //     } else {
  //       setReferringDoc("");
  //       setReferringDocNo("");
  //       setReferringDocEmail("");
  //       setReferringOtherEmail(null);
  //     }
  //   },
  //   [
  //     setReferringDoc,
  //     setReferringDocEmail,
  //     setReferringDocNo,
  //     setReferringOtherEmail,
  //   ]
  // );

  // const handelChangeInput = (e) => {
  //   setReferalName(e.target.value)
  // }

  const handleChangeFields = useCallback((value, handle) => {
    if (handle === setReferringOtherEmail) {
      value ? handle(value) : handle(null);
    } else {
      handle(value);
    }
  }, [setReferringOtherEmail]);


  const onMedSelected = useCallback((e, value) => {

    // handleChange();
    setSearchList([]);
    if (value) {
      setReferringDoc(value);
      setSelectedDoctor(value);
      const { name, mobileNumber, email } = value;
      name && setReferringDoc(name);
      mobileNumber && setReferringDocNo(mobileNumber);
      email && setReferringDocEmail(email);
    } else {
      setReferringDoc("");
      setReferringDocNo("");
      setReferringDocEmail("");
      setReferringOtherEmail(null);
    }
  }, [setReferringDoc,
    setReferringDocEmail,
    setReferringDocNo,
    setReferringOtherEmail]);



  const handleOnChange = debounce((e, index) => {
    let val = e.target.value;
    if (val === "") {
      setSearchList([]);
      return;
    }
    setLoading(true);

    if (searchSubscription) {
      searchSubscription.unsubscribe();
    }

    searchSubscription = Api2.get(`${process.env.REACT_APP_API2_HOST}get_referring_doctors?referredTo=${user.slug}&name=${name}`).subscribe(
      (resp) => {
        const { content } = Bro(resp).iCanHaz("data.data") || {};
        setSearchList(resp.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        DEFAULT_ERROR_CALLBACK(err);
      }
    );
  }, 1000);


  const handleChangeName = (e) => {
    handleOnChange(e);
    setReferringDoc(e.target.value);
    setName(e.target.value);
  };

    return (
    <Box display="flex" gridGap={24} flexWrap="wrap">
      <Box mr={2} style={{ minWidth: "200px" }}>

        <Box component="th">
          {/* <TextAreaInput
            onChange={handleChangeName}
            style={{ padding: "10px", marginTop: "10px" }}
            className={globalClass.roundWhiteBg}
            placeholder="Name"
            value={referringDoc}
            field="name"
          /> */}
          <TextAreaCustom
            onChange={handleChangeName}
            style={{ padding: "10px", marginTop: "10px" }}
            className={globalClass.roundWhiteBg}
            placeholder="Name"
            value={referringDoc}
            field="name"
          />
          {searchList?.length > 0 && (
            <Paper
              // ref={wrapperRef}
              className={globalClass.listStyle}
              elevation={2}
              style={{ position: "absolute", zIndex: 999 }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px",
                  }}
                >
                  <CircularProgress disableShrink size={14} />
                </div>
              ) : (
                <List dense>
                  {searchList?.map((value, listIndex) => (
                    <ListItem
                      key={listIndex}
                      button
                      onClick={(e) => onMedSelected(e, value)}
                    >
                      <ListItemText
                        disableTypography
                        className={classes.fSize}
                        primary={`${value.name} `}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          )}
        </Box>


        {/* <Autocomplete
        style={{ width: 200 , display: 'none' }}
          id="refering-doc-list"
          className={globalClass.roundWhiteBg}
          options={referalDocList}
          getOptionLabel={(doctor) => doctor.name}
          value={selectedDoctor}
          onChange={handleChange}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Doctor Name"
                variant="outlined"
                onChange={handelChangeInput}
                style={{ display: "flex", alignItems: "center", marginTop: 16 }}
              />
            );
          }}
        /> */}
      </Box>

      <Box mr={2} style={{ minWidth: "200px" }}>
        {/* <TextInput
          className={globalClass.roundWhiteBg}
          placeholder="Mobile Number"
          field="referringDoctorMobile"
          autoComplete="off"
          value={referalDocMobile}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringDocNo)
          }
          validateOnBlur
          validateOnChange
        /> */}
        <TextInputCustom
          className={globalClass.roundWhiteBg}
          placeholder="Mobile Number"
          field="referringDoctorMobile"
          autoComplete="off"
          value={referalDocMobile}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringDocNo)
          }
          validateOnBlur
          validateOnChange
        />
      </Box>
     
      <Box mr={2} style={{ minWidth: "200px" }}>
        {/* <TextInput
          className={globalClass.roundWhiteBg}
          placeholder="Email"
          field="referringDocEmail"
          autoComplete="off"
          value={referalDocEmail}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringDocEmail)
          }
          validateOnBlur
          validateOnChange
        /> */}
        <TextInputCustom
          className={globalClass.roundWhiteBg}
          placeholder="Email"
          field="referringDocEmail"
          autoComplete="off"
          value={referalDocEmail}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringDocEmail)
          
          }
          validateOnBlur
          validateOnChange
          
        />
      </Box>

      <Box style={{ minWidth: "400px" }}>
        {/* <TextInput
          className={globalClass.roundWhiteBg}
          style={{ width: "100%" }}
          placeholder="Other Emails separated by comma"
          field="otherEmails"
          autoComplete="off"
          value={referalDocOtherEmail}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringOtherEmail)
          }
          validateOnBlur
          validateOnChange
        /> */}
        <TextInputCustom
          className={globalClass.roundWhiteBg}
          style={{ width: "100%" }}
          placeholder="Other Emails separated by comma"
          field="otherEmails"
          autoComplete="off"
          value={referalDocOtherEmail}
          onChange={(e) =>
            handleChangeFields(e.target.value, setReferringOtherEmail)
          }
          validateOnBlur
          validateOnChange
        />
      </Box>
    </Box>
  );
};
export default ReferalDoctor;
