import React, { useState, useRef, useEffect, useCallback } from "react";
import Header from "../../components/layout/header";
import { useLocation, useParams } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Paper,
  Divider,
  Button,
  fade,
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import ChatBubble from "./chatBubble";
import SendIcon from "@material-ui/icons/SendOutlined";
import AttachIcon from "@material-ui/icons/AttachFile";
import ChatSidebar from "./sidebar";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import LoopOutlinedIcon from "@material-ui/icons/LoopOutlined";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";

import ChatHeader from "./chat-header";
import useSocket from "./socket";
import { Form, TextArea } from "informed";
import AuthService from "../../utils/services/auth-service";
import Api from "../../utils/api";
import {
  getFormattedDate,
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
} from "../../utils/app-util";
import moment from "moment";
import AppConstants from "../../utils/app-constants";
import DataService from "../../utils/services/data-service";
import { useHistory } from "react-router-dom";
import Loader from "../../components/misc/loader";
import SidebarContent from "./sidebar-content";
import FlatList from "flatlist-react";
import { ChatsState, TempChatUsersState } from "../../states";
import { useRecoilState, useSetRecoilState } from "recoil";
import { NotificationManager } from "react-notifications";
import Axios from "axios";
import BlockIcon from "@material-ui/icons/Block";
import Routes from "../../routes/routes";
import TreatmentSummery from "./treatmentSummery";
import { ListItem } from "../../components/sixsprint-react-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "scroll",
  },
  userList: {
    flexGrow: 1,
    margin: theme.spacing(0, 3),
    padding: theme.spacing(5),
    overflowY: "auto",
    height: "85vh",
  },
  chatContainer: {
    height: "90vh",
    position: "relative",
  },
  chatHeader: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    margin: theme.spacing(0, 1),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.chatBg,
  },
  chatBox: {
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderBottomLeftRadius: "14px",
    borderBottomRightRadius: "14px",
    padding: theme.spacing(6),
    height: "66vh",
    width: "100%",
    overflowY: "auto",
  },
  containerBox: {
    flexGrow: 1,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0, 2),
  },
  containerInput: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(0, 2),
    height: "6vh",
  },
  attachIcon: {
    height: "6vh",
  },
  search: {
    position: "relative",
    borderRadius: 25,
    padding: theme.spacing(5, 5, 5, 10),
    border: "1.5px solid #ddd",
    margin: theme.spacing(5),
    backgroundColor: fade(theme.palette.common.white, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.1),
    },
    width: "auto",
  },
  searchBar: {
    width: "94%",
    padding: "12px 6px",
    borderRadius: 12,
    marginTop: 12,
    border: "1px solid gray",
    outline: "none",
  },
  input: {
    fontSize: 14,
    fontFamily: "Comfortaa",
    border: "hidden",
    "&:focus": {
      outline: "none",
    },
    backgroundColor: "transparent",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  text: {
    textAlign: "center",
  },

  adjustContainer: {
    padding: theme.spacing(0, 4, 0, 4),
  },

  adjustPadding: {
    padding: theme.spacing(0, 0, 0, 8),
  },

  textInput: {
    width: "100%",
    fontSize: "0.85rem",
  },

  defaultStyle: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    backgroundColor: "#FFFFFF",
  },

  activeStyle: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    backgroundColor: "#F3F7FD",
  },

  loadMoreBtn: {
    position: "absolute",
    top: "80px",
    color: "white",
    display: "flex",
    alignSelf: "center",
  },
  newChat: {
    paddingTop: 12,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
}));

const ItemLoader = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    style={{ marginTop: 20 }}
  >
    <CircularProgress size={25} color={"primary"} />
    <Typography style={{ marginTop: 8 }}>Please wait...</Typography>
  </Box>
);

const Chat = () => {
  const messagesEndRef = useRef();
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [activePatient, setActivePatient] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showList, setShowList] = useState(true);
  const [showChatBox, setShowChatBox] = useState(true);
  const user = AuthService.getUser();
  const globalClass = useGlobalStyles();
  const formApi = useRef();
  let history = useHistory();
  const socket = useSocket({});
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [question, setQuestions] = useState(0);
  const [tempUsers, setTempUsers] = useRecoilState(TempChatUsersState);
  const [hasMore, setHasMore] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const uniqueChatSession = location?.state?.data?.data;
  const { chatUser, index } = (location && location.state?.data?.data) || {};

  const [visibility, setVisibility] = useState(false);
  let chatBoxContainer = document.getElementById("chatBox");
  const setChats = useSetRecoilState(ChatsState);
  const [newChatUsers, setNewChatUsers] = useState([]);
  const [searchedKey, setSearchedKey] = useState("");
  const [chatPackages, setChatPackages] = useState([]);
  const [newChatUserSlug, setNewChatUserSlug] = useState(null);
  const chatBoxRef = useRef(null);
  const [searchChat, setSearchChat] = useState(null);
  const [isscrolldown, setIsScrollDown] = useState(true);

  const [blockPatientstatus, setBlockPatientstatus] = useState(null);

  const [opensChat, setOpensChat] = React.useState(false);
  const handleOpensChat = () => setOpensChat(true);
  const handleClosesChat = () => setOpensChat(false);

  const toggleSideTab = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isscrolldown) {
      if (chatBoxRef.current) {
        const scrollHeight = chatBoxRef?.current?.scrollHeight;
        const height = chatBoxRef?.current?.clientHeight;
        const maxScrollTop = scrollHeight - height;
        chatBoxRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      }
    } else {
      setIsScrollDown(true);
    }
  }, [chatBoxRef, socket.messageHistory]);

  const onResolvedBtnClicked = () => {
    Api.put(
      `chat-session/mark-resolve?sessionSlug=${activePatient.slug}`
    ).subscribe(
      (resp) => {
        setQuestions(resp.data.data.totalQuestions);
        socket.setCurrentPage(0);
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
        refreshChatList();
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const onUnResolvedBtnClicked = () => {
    Api.put(
      `chat-session/mark-unresolve?sessionSlug=${activePatient.slug}`
    ).subscribe(
      (resp) => {
        setQuestions(resp.data.data.totalQuestions);
        socket.setCurrentPage(0);
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const updateCurrentUser = (updatedUsers) => {
    let updatedPatient = updatedUsers.filter(
      (item) => item.slug === activePatient.slug
    );
    setActivePatient(updatedPatient[0]);
  };

  useEffect(() => {
    if (
      messagesEndRef.current &&
      socket.messageHistory.length > 0 &&
      socket.isScrollDown
    ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [socket.messageHistory, socket.isScrollDown]);

  const sortByUnreadMessages = (a, b) => {
    return b.hasResolvedMessages - a.hasResolvedMessages;
  };

  useEffect(() => {
    onListItemClicked(chatUser, index);
  }, []);

  const fetchChatSessions = () => {
    setLoading(true);
    const chatSessions = DataService.getChatSessions(
      `/chat-session/my?page=${currentPage}&size=20`
    ).subscribe(
      (resp) => {
        const sortedList = resp?.content?.sort(sortByUnreadMessages);
        const chatSessionMap = new Map();
        const updatedChatSessionsList = [...tempUsers, ...sortedList];
        updatedChatSessionsList.map((chatSession) => {
          if (!chatSessionMap.has(chatSession?.slug)) {
            chatSessionMap.set(chatSession?.slug, chatSession);
          }
        });

        setUsers(Array.from(chatSessionMap.values()));
        setTempUsers(Array.from(chatSessionMap.values()));

        if (currentPage + 1 >= resp?.totalPages) {
          setCurrentPage(currentPage + 1);
          setHasMore(false);
          setLoading(false);
        }
        if (activePatient) {
          updateCurrentUser(resp.content);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
      }
    );
    return () => chatSessions.unsubscribe();
  };

  const refreshChatList = () => {
    setIsRefreshing(true);
    setSearchedKey("");
    setNewChatUsers([]);
    const refreshList = DataService.getChatSessions(
      `/chat-session/my?page=0&size=20`
    ).subscribe((resp) => {
      let nepostion = 0;
      let check = 0;
      const sortedList = resp?.content?.sort(sortByUnreadMessages);
      if (uniqueChatSession) {
        sortedList.map((item, i) => {
          if (check === 0) {
            if (item.slug === uniqueChatSession?.slug) {
              nepostion = i;
              check = 1;
            }
          }
        });
        if (check === 0) {
          sortedList.push(uniqueChatSession);
          nepostion = sortedList?.length - 1;
        }
        setPatientSlug(uniqueChatSession.patientSlug);
      }

      setTempUsers(sortedList);
      setCurrentPage(1);
      setIsRefreshing(false);
      if (uniqueChatSession) {
        setTimeout(() => {
          onListItemClicked(uniqueChatSession, nepostion);
        }, 2000);
      }
      SHOW_SUCCESS_NOTIFICATION("Patient List refreshed successfully");
    });
    return () => refreshList.unsubscribe();
  };

  useEffect(() => {
    setTimeout(() => {
      refreshChatList();
    }, 2000);
  }, []);

  let custom;

  if (user.roleName === "DOCTOR_ADMIN" || user.roleName === "DOCTOR") {
    custom = {
      filterType: "exact",
      filter: `doctorPatients::slug::${user.slug}`,
    };
  } else {
    custom = {};
  }

  const handleSearch = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchedKey(value);
      const filteredPatientSlug = [];
      const filteredList = users?.filter(({ patient }) => {
        const ifNameMatches = (patient?.name || "")
          .toLowerCase()
          .includes(value);
        const ifMobileMatches = (patient?.mobileNumber || "").includes(value);
        filteredPatientSlug.push(patient?.slug);
        return ifNameMatches || ifMobileMatches;
      });

      if (filteredList?.length) {
        setTempUsers(filteredList);
      } else {
        setTempUsers([]);
        setLoading(false);
      }

      if (!value) {
        setNewChatUsers([]);
      } else {
        const payload = {
          page: 0,
          size: 20,
          filterModel: {
            _: {
              filterType: "text",
              type: "",
              filter: value,
            },
            custom,
          },
          sortModel: [],
        };
        Api.post(`patient/search`, payload).subscribe((resp) => {
          const newUsers = resp.data.data.content;
          const pendingNewUsers = newUsers.filter(
            (user) => !filteredPatientSlug.includes(user.slug)
          );
          setNewChatUsers(pendingNewUsers);
        });
      }
    },
    [users]
  );

  const uploadImage = (evt) => {
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    formData.append("files", fileArray[0]);
    DataService.uploadImage(formData).subscribe(
      (resp) => {
        socket.sendMessage({ imgUrl: resp?.imageId });
        setChats((prev) => {
          const data = JSON.parse(JSON.stringify(prev));
          if (typeof selectedIndex === "string") {
            const foundChat = data[selectedIndex];
            if (foundChat) {
              foundChat.hasResolvedMessages = false;
            }
          }
          return [...data];
        });

        setTempUsers((prev) => {
          const data = JSON.parse(JSON.stringify(prev));
          if (typeof selectedIndex === "string") {
            const foundChat = data[selectedIndex];
            if (foundChat) {
              foundChat.hasResolvedMessages = false;
            }
          }
          const sortedData = [...data].sort(sortByUnreadMessages);
          return [...sortedData];
        });
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleMoreMessages = () => {
    if (socket.currentPage <= socket.totalPages) {
      socket.loadMoreMessages(activePatient.slug);
      setIsScrollDown(false);
    }
  };

  const handleSubmit = (val) => {
    if (!val || !val.msg || !val.msg.trim()) {
      return;
    }
    socket.sendMessage(val);
    setChats((prev) => {
      const data = JSON.parse(JSON.stringify(prev));
      if (typeof selectedIndex === "string") {
        const foundChat = data[selectedIndex];
        if (foundChat) {
          foundChat.hasResolvedMessages = false;
        }
      }
      return [...data];
    });
    setTempUsers((prev) => {
      const data = JSON.parse(JSON.stringify(prev));
      if (typeof selectedIndex === "string") {
        const foundChat = data[selectedIndex];
        if (foundChat) {
          foundChat.hasResolvedMessages = false;
        }
      }
      const sortedData = [...data].sort(sortByUnreadMessages);
      return [...sortedData];
    });

    formApi.current.reset();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        handleSubmit(formApi.current.getState().values);
      }
    }
  };

  const onListItemClicked = (val, index) => {
    setSelectedIndex(index);
    setActivePatient(val);
    setQuestions(val?.totalQuestions);
    if (isMobile) {
      setShowList(false);
      setShowChatBox(true);
    }
    if (socket.isConnected && activePatient?.slug !== val?.slug) {
      socket.unsubscribe(activePatient?.slug);
    }
    if (
      !socket?.isConnected ||
      (socket?.isConnected && activePatient?.slug !== val?.slug)
    ) {
      socket.setMessageHistory([]);
      socket.setCurrentSessionSlug(val?.slug);
      socket.startSession(val?.slug);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: false,
  });

  const handleChatAction = (val) => {
    history.push({
      pathname: Routes.CHATIMAGE,
      state: val,
    });
  };

  const onBackIconClicked = () => {
    setShowList(true);
    setShowChatBox(false);
  };

  React.useEffect(() => {
    if (chatBoxContainer) {
      chatBoxContainer.addEventListener(
        "scroll",
        function (e) {
          socket.isConnected &&
          socket.isMsgLoaded &&
          chatBoxContainer.scrollTop < 500
            ? setVisibility(true)
            : setVisibility(false);
        },
        false
      );
    }
  });

  useEffect(() => {
    const payload = {
      filterModel: {},
      page: 0,
      size: 200,
      sortModel: [],
    };
    Api.post("chat-package/search", payload).subscribe((resp) => {
      setChatPackages(resp.data.data.content);
    });
    fetchChatSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newChatUserSlug) {
      if (socket.isConnected) {
        socket.unsubscribe(newChatUserSlug);
      } else {
        socket.setMessageHistory([]);
        socket.setCurrentSessionSlug(newChatUserSlug);
        socket.startSession(newChatUserSlug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatUserSlug]);

  const [patientSlug, setPatientSlug] = useState("");

  const getSessionSlug = useCallback(
    (patientSlug) => {
      Axios.get(
        `${process.env.REACT_APP_API2_HOST}chat-sessions?patientSlug=${patientSlug}`
      )
        .then((resp) => {
          const response = resp.data.data;
          setNewChatUserSlug(response.slug);
          setActivePatient(response);
          setQuestions(response.totalQuestions);
        })
        .catch((err) => {
          DEFAULT_ERROR_CALLBACK(err);
        });
    },
    [patientSlug]
  );

  const uniqueSelectedChatSession = () => {
    setNewChatUserSlug(uniqueChatSession?.slug);
    setActivePatient(uniqueChatSession);
    setQuestions(uniqueChatSession?.totalQuestions);

    if (isMobile) {
      setShowList(false);
      setShowChatBox(true);
    }
    if (socket.isConnected && activePatient?.slug !== uniqueChatSession?.slug) {
      socket.unsubscribe(activePatient?.slug);
    }
    if (
      !socket?.isConnected ||
      (socket?.isConnected && activePatient?.slug !== uniqueChatSession?.slug)
    ) {
      socket.setMessageHistory([]);
      socket.setCurrentSessionSlug(uniqueChatSession?.slug);
      socket.startSession(uniqueChatSession?.slug);
    }
  };

  useEffect(() => {
    if (uniqueChatSession) {
      uniqueSelectedChatSession();
    }
  }, []);

  const handleStartNewChat = useCallback(
    (patient) => {
      const freeChatPackage = chatPackages.find(
        (chatPackage) => chatPackage.amount === 0
      );
      if (freeChatPackage) {
        const payload = {
          patientSlug: patient.slug,
          chatPackageSlug: freeChatPackage.slug,
        };
        Api.post(
          `/order/initiate/chat-package/doctor?patientSlug=${patient.slug}&chatPackageSlug=${freeChatPackage.slug}`,
          payload
        ).subscribe(
          (resp) => {
            const response = resp.data.data;

            getSessionSlug(response.patientSlug);
            uniqueSelectedChatSession(response.patientSlug);
          },
          (err) => {
            DEFAULT_ERROR_CALLBACK(err);
          }
        );
      } else {
        NotificationManager.error("Please Create a Free chat Package");
      }
    },
    [chatPackages]
  );

  useEffect(() => {
    DataService.getChatSessions(`/chat-session/my?size=20`).subscribe(
      (resp) => {
        resp.content.map((item) => {
          if (item.patientSlug === patientSlug) {
            setQuestions(item.totalQuestions);
          }
        });
      }
    );
  }, [socket.messageHistory]);

  const TreatmentClick = () => {
    window.open(`/#!` + Routes.TREATMENTSUMMERY);
  };

  return (
    <div className={`${classes.root} ${globalClass.scrollBar}`}>
      <Header
        title="Chat"
        toggleChatSidebar={isMobile && activePatient && toggleSideTab}
        menu={true}
      />

      <Grid container className={classes.adjustContainer}>
        {showList && (
          <Grid
            container
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className="scroll-bar-wrap"
            style={{ overflow: "scroll" }}
          >
            <Paper
              className={clsx(
                classes.userList,
                globalClass.roundWhiteBg,
                globalClass.srollBar
              )}
              style={{ overflow: "scroll" }}
            >
              <Box
                alignItems="center"
                style={{
                  margin: 5,
                  marginBottom: 12,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={!isRefreshing && <LoopOutlinedIcon />}
                  onClick={refreshChatList}
                  fullWidth
                >
                  {isRefreshing ? (
                    <CircularProgress size={"0.75rem"} color={"white"} />
                  ) : (
                    <Typography style={{ fontSize: "0.7rem" }}>
                      Refresh User List
                    </Typography>
                  )}
                </Button>
                <input
                  value={searchedKey}
                  type="text"
                  className={classes.searchBar}
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </Box>
              <Typography>Chat History</Typography>

              <Box style={{ height: "75%", overflow: "auto" }}>
                <FlatList
                  list={tempUsers}
                  renderItem={(item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedIndex === index
                            ? classes.activeStyle
                            : classes.defaultStyle
                        }
                      >
                        <Box>
                          {item.blocked === true ? (
                            <BlockIcon style={{ color: "red" }} />
                          ) : null}
                        </Box>
                        <ListItem
                          handleClick={() => (
                            onListItemClicked(item, index),
                            setPatientSlug(item?.patient?.slug)
                          )}
                          name={`${
                            item.patient?.name ||
                            item.patient?.mobileNumber ||
                            ""
                          }`}
                          text={item.latestMessagePreview}
                          time={item?.dateModified ?? ""}
                          nameVariant="subtitle1"
                          unseenMsgStatus={item.hasResolvedMessages}
                          status={item.status === "ACTIVE"}
                          image={<BlockIcon />}
                        />
                        <Divider />
                      </div>
                    );
                  }}
                  renderWhenEmpty={() =>
                    loading ? (
                      <ItemLoader />
                    ) : (
                      <Typography className={classes.text}>
                        No Chat History found !
                      </Typography>
                    )
                  } // let user know if initial data is loading or there is no data to show
                  hasMoreItems={hasMore}
                  loadMoreItems={fetchChatSessions}
                  paginationLoadingIndicator={<ItemLoader />}
                  paginationLoadingIndicatorPosition="center"
                />

                {newChatUsers.length ? (
                  <>
                    <Typography className={classes.newChat}>
                      New Chat
                    </Typography>
                    <FlatList
                      list={newChatUsers}
                      renderItem={(item, index) => (
                        <div
                          key={index}
                          className={
                            selectedIndex === index
                              ? classes.activeStyle
                              : classes.defaultStyle
                          }
                        >
                          <ListItem
                            handleClick={() => handleStartNewChat(item)}
                            name={`${item.name || item.mobileNumber}`}
                            text={`${item.uhid || item.slug}`}
                            nameVariant="subtitle1"
                          />
                          <Divider />
                        </div>
                      )}
                      renderWhenEmpty={() =>
                        loading ? (
                          <ItemLoader />
                        ) : (
                          tempUsers.length && (
                            <Typography>Sorry! No Data Found</Typography>
                          )
                        )
                      } // let user know if initial data is loading or there is no data to show
                      hasMoreItems={hasMore}
                      loadMoreItems={fetchChatSessions}
                      paginationLoadingIndicator={<ItemLoader />}
                      paginationLoadingIndicatorPosition="center"
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Paper>
          </Grid>
        )}
        {showChatBox && (
          <Grid
            container
            item
            xs={false}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            justify="center"
            className={clsx(classes.chatContainer)}
          >
            {activePatient ? (
              <>
                <ChatHeader
                  onBackIconClicked={isMobile && onBackIconClicked}
                  itemStyle={classes.chatHeader}
                  name={activePatient && activePatient.patient?.name}
                  date="Remaining"
                  time={`${question} Question(s)`}
                  patientSlug={patientSlug}
                  docSlug={user.slug}
                  handleOpensChat={handleOpensChat}
                  setSearchChat={setSearchChat}
                  setBlockPatientstatus={setBlockPatientstatus}
                />
                {visibility && socket.currentPage < socket.totalPages && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.button} ${classes.loadMoreBtn}`}
                    startIcon={<ArrowUpwardIcon />}
                    onClick={handleMoreMessages}
                  >
                    Load More Messages
                  </Button>
                )}
                <Paper
                  id="chatBox"
                  ref={chatBoxRef}
                  className={clsx(
                    classes.chatBox,
                    globalClass.whiteBg,
                    globalClass.srollBar
                  )}
                >
                  {socket.isMsgLoaded ? (
                    socket.messageHistory.map((item, index) => {
                      return (
                        <ChatBubble
                          left={user.slug !== item.fromUserSlug}
                          user={activePatient && activePatient?.patient?.name}
                          key={index}
                          handleClick={handleChatAction}
                          img={
                            item?.attachment?.content
                              ? item?.attachment?.content
                              : item?.attachment?.contenttype &&
                                ` data:${item?.attachment?.contenttype};base64,${item?.attachment?.data?.data}`
                          }
                          contenttype={item?.attachment?.contenttype}
                          messageType={item.type}
                          actions={item.actions}
                          text={item?.text}
                          time={item.dateCreated.slice(0, -2)}
                          hideActions
                          textVariant={"body1"}
                        />
                      );
                    })
                  ) : (
                    <Loader height="100%" />
                  )}
                  <div ref={messagesEndRef} />
                </Paper>

                <Form
                  style={{ width: "100%" }}
                  apiRef={formApi}
                  onSubmit={handleSubmit}
                  onKeyPress={handleKeyPress}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center" 
                  >
                    <Box
                      display="flex"
                      flexGrow="1"
                      justifyContent="center"
                      alignItems="center"
                      className={clsx(
                        classes.containerInput,
                        globalClass.roundWhiteBg
                      )}
                    >
                      <TextArea
                        style={{
                          border: "none",
                          borderRadius: "10px",
                          padding: "10px"
                        }}
                        field="msg"
                        placeholder="Type here"
                        className={clsx(
                          classes.adjustPadding,
                          classes.textInput
                        )}
                        onFocus={() => {
                          socket.setIsScrollDown(true);
                        }}
                      />

                      <IconButton type="submit" color="primary">
                        <SendIcon fontSize="large" />
                      </IconButton>
                    </Box>

                    <Box
                      mx={2}
                      display="flex"
                      className={clsx(
                        classes.attachIcon,
                        globalClass.roundWhiteBg
                      )}
                      alignItems="center"
                    >
                      <input
                        accept="image/*"
                        className={globalClass.fileInput}
                        id="contained-button-file"
                        type="file"
                        multiple
                        onChange={uploadImage}
                      />
                      <label htmlFor="contained-button-file">
                        <IconButton color="primary" component="span">
                          <AttachIcon fontSize="large" />
                        </IconButton>
                      </label>
                    </Box>
                  </Box>
                </Form>
              </>
            ) : (
              <Paper
                className={clsx(classes.containerBox, globalClass.roundWhiteBg)}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  height={"85vh"}
                  alignItems="center"
                >
                  <Typography variant="h5">
                    {"Click on a user to start a chat"}
                  </Typography>
                </Box>
              </Paper>
            )}
          </Grid>
        )}
        {!isMobile && (
          <Grid
            container
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className="scroll-bar-wrap"
          >
            <SidebarContent
              data={activePatient}
              quesValue={question}
              onResolvedBtnClicked={onResolvedBtnClicked}
              onUnResolvedBtnClicked={onUnResolvedBtnClicked}
            />
          </Grid>
        )}
      </Grid>

      <ChatSidebar
        onResolvedBtnClicked={onResolvedBtnClicked}
        data={activePatient}
        quesValue={question}
        open={open}
        toggleSideTab={toggleSideTab}
      />

      {opensChat && (
        <Modal
          open={opensChat}
          onClose={handleClosesChat}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className={classes.modalStyle}
            style={{ overflow: "scroll", height: "80%" }}
          >
            {socket.isMsgLoaded ? (
              socket.messageHistory
                .filter((search) => {
                  return search?.text === ""
                    ? null
                    : search?.text
                        ?.toLowerCase()
                        .includes(searchChat?.toLowerCase());
                })
                ?.map((item, index) => {
                  return (
                    <ChatBubble
                      left={user.slug !== item.fromUserSlug}
                      user={activePatient && activePatient?.patient?.name}
                      key={index}
                      handleClick={handleChatAction}
                      img={
                        item?.attachment?.content
                          ? item?.attachment?.content
                          : item?.attachment?.contenttype &&
                            (` data:${item?.attachment?.contenttype};base64,${item?.attachment?.data?.data}` ||
                              item?.attachmentUrl)
                      }
                      messageType={item.type}
                      actions={item.actions}
                      text={item?.text}
                      time={item.dateCreated}
                      hideActions
                      textVariant={"body1"}
                    />
                  );
                })
            ) : (
              <Loader height="100%" />
            )}
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Chat;