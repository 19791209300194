import moment from "moment";

export const getTimeFromStartAndEnd = (startTime, endTime, interval, period) => {
    const start = moment(startTime, 'hh:mm')
    const end = moment(endTime, 'hh:mm')

    let periodsInADay = moment.duration(end.diff(start)).as(period)

    let isNegative = false
    if (periodsInADay < 0) {
        periodsInADay = moment.duration(start.diff(end)).as(period)
        isNegative = true
    }
    const timeLabels = [];
    for (let i = 0; i <= periodsInADay; i += interval) {
        start.add(i === 0 ? 0 : interval, period);
        timeLabels.push(start.format('HH:mm'));
        if (isNegative && endTime === start.format('HH:mm')) {
            break
        }
    }
  
    return timeLabels;
};

const DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const getDayFromStartAndEnd = (start, end) => {
    if (start === end) return [start]
    let startDay = DAYS.indexOf(start)
    let endDay = DAYS.indexOf(end)
    
    let dayInBetween = [];
    if(startDay > endDay) {
        dayInBetween = [
            ...DAYS.slice(startDay),
            ...DAYS.slice(0, endDay+1)
        ]
    } else {
        dayInBetween = [ ...DAYS.slice(startDay, endDay+1) ]
    }
    return dayInBetween
};