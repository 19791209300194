import {atom} from 'recoil'

export const ChatsState = atom({
    key: "chats",
    default: []
})

export const TempChatUsersState = atom({
    key: "temp-chart-users",
    default: []
})