import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export const CustomDialogBox = ({
  open,
  dialogTitle,
  handleDialogOnClose,
  children,
  showCloseButton = false,
  actionBtnText = "Confirm",
  dialogActions,
  onConfirm,
  showDialogActions = true,
  dialogContentInlineStyle,
}) => {
  const classes = useStyles();
  return (
    <div className="custom-dialog-box-wrapper">
      <Dialog open={open} onClose={handleDialogOnClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h5">{dialogTitle}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogOnClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={dialogContentInlineStyle}>{children}</DialogContent>
        {showDialogActions && (
          <DialogActions sx={{padding: "10px 24px 24px"}}>
            {dialogActions ? (
              dialogActions
            ) : (
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#445b79",
                      "&:hover": {
                        backgroundColor: "#9aa7bc",
                      },
                      borderRadius: "10px"
                    }}
                    onClick={onConfirm}
                  >
                    {actionBtnText}
                  </Button>
                </Grid>
                {showCloseButton && (
                  <Grid item>
                    <Button onClick={handleDialogOnClose}>Close</Button>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
