import React, { useState } from "react";
import Header from "../../components/layout/header";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Api from "../../utils/api";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import PatientForm from "./patient-form";

const NewPatient = () => {
  let history = useHistory();

  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [sex, setSex] = useState("");
  const [address, setAddress] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [addLoadingStatus, setAddLoadingStatus] = useState(false);

  const handleSubmit = (val) => {
    setAddLoadingStatus(true);

    if (name === "") {
      SHOW_ERROR_NOTIFICATION("Name is required");
      setAddLoadingStatus(false);
    } else if (mobileNumber === "") {
      SHOW_ERROR_NOTIFICATION("Mobile Number is required");
      setAddLoadingStatus(false);
    } else if (mobileNumber.length !== 10) {
      SHOW_ERROR_NOTIFICATION("Enter a 10 digit valid number");
      setAddLoadingStatus(false);
    } else if (sex === "") {
      SHOW_ERROR_NOTIFICATION("Sex is required");
      setAddLoadingStatus(false);
    } else {
      val.roleName = "patient";
      val.mobileNumber = mobileNumber;
      val.countryCode = countryCode;
      val.name = name;
      val.email = email;
      val.dob = dob | "";
      val.gender = sex;
      val.address = address | "";
      Api.post("/patient", val).subscribe(
        (resp) => {
          if (resp.data.status === true) {
            SHOW_SUCCESS_NOTIFICATION("Create Successfully");
            history.push(Routes.PATIENT);
            setAddLoadingStatus(false);
          } else {
            SHOW_ERROR_NOTIFICATION(resp.data.message);
            setAddLoadingStatus(false);
          }
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
          setAddLoadingStatus(false);
        }
      );
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Header showBackBtn title="New Patient" />
      <Box m={10}>
        <PatientForm
          handleSubmit={handleSubmit}
          setName={setName}
          setEmail={setEmail}
          setDob={setDob}
          setSex={setSex}
          setAddress={setAddress}
          setCountryCode={setCountryCode}
          setMobileNumber={setMobileNumber}
          setIsInvalidPhone={setIsInvalidPhone}
          addLoadingStatus={addLoadingStatus}
        />
      </Box>
    </div>
  );
};

export default NewPatient;
