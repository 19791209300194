import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import CallIcon from "@material-ui/icons/CallOutlined";
import AuthService from "../../utils/services/auth-service";
import Copyright from "./copyright";


const useStyles = makeStyles((theme) => ({
  grow: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  pt: {
    marginTop: "2rem",
  },
  p: {
    padding: theme.spacing(1, 0),
  },
  m1: {
    margin: theme.spacing(0, 1),
  },
  address: {
    width: 300,
  },
}));

const Footer = ({ title }) => {
  const classes = useStyles();
  const tenant = AuthService.getTenant();

  return (
    
    <div className={classes.pt}>
    
      {tenant && (
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <PlaceIcon fontSize="small" />
              <Typography variant="caption">
                {tenant.contactInfo.addressLine1},
              </Typography>
              <Typography variant="caption">
                {tenant.contactInfo.addressLine2}
              </Typography>
              <Typography variant="caption">
                {" " + tenant.contactInfo.city}, {tenant.contactInfo.state}
                {", "}
                {tenant.contactInfo.country} - {tenant.contactInfo.pincode}
              </Typography>
            </Box>

            <Box my={1}>
              <Box display="flex" alignItems="center">
                <EmailIcon fontSize="small" />
                <Typography className={classes.m1} variant="caption">
                  <a href={`mailto:${tenant.contactInfo.email}`}>
                    {tenant.contactInfo.email}
                  </a>
                </Typography>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <CallIcon fontSize="small" />
                <Typography className={classes.m1} variant="caption">
                  <a href={`tel:${tenant.contactInfo.contactNumber}`}>
                    {tenant.contactInfo.contactNumber}
                  </a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
      

      <div className={classes.grow}>
        <Copyright
          className={classes.p}
          title="ProClinic"
          color="textSecondary"
          url="https://proclinic.in"
        />
      </div>
    </div>
  );
};

export default Footer;
