import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Form } from "informed";
import { SelectInput, TextInput } from "sixsprints-react-ui";
import useGlobalStyles from "../../utils/global-style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";

import { Box, IconButton, Button } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";

import Header from "../../components/layout/header";
import Api from "../../utils/api";
import Loader from "../../components/misc/loader";
import AppConstants from "../../utils/app-constants";
import { validateEmail } from "../../utils/app-util";
import { WebsiteAPI } from "../../apis/WebsiteAPI";

const useStyles = makeStyles({
  sectionContainer: {
    minHeight: "100vh",
  },
  staffDetailsForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "24px",
  },
  dropdownFields: {
    display: "flex",
    position: "relative",
  },
  iconButton: {
    position: "absolute",
    right: "5px",
    top: "25%",
  },
  fields: {
    width: "180px",
  },
});

const NewStaff = () => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [addLoadingStatus, setAddLoadingStatus] = useState(false);
  const [allSpeciality, setAllSpeciality] = useState([]);
  const history = useHistory();
  const roleName = useMemo(
    () => [
      "ADMIN",
      "DOCTOR_ADMIN",
      "DOCTOR",
      "NURSE",
      "ASSISTANT",
      "RECEPTIONIST",
      "QUEUE",
    ],
    []
  );

  const handleClickEyeBtn = useCallback(() => {
    setPasswordVisible((prevState) => !prevState);
  }, []);

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    username: "",
    roleName: "",
    speciality: "",
  });

  // ----- side effects -----
  useEffect(() => {
    WebsiteAPI.getAllSpeciality().then((response) => {
      setAllSpeciality(response);
    })
  }, [])

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSave = async (event) => {
    setAddLoadingStatus(true);
    const req = {
      name: data.name,
      email: data.email,
      password: data.password,
      roleName: data.roleName,
      mobileNumber: `${countryCode + mobileNumber}`,
      ...(data?.speciality && { speciality: data?.speciality }),
    };

    if (
      data.name &&
      data.email &&
      mobileNumber &&
      data.roleName &&
      data.password
    ) {
      // Check if speciality is required based on the roleName
      const isSpecialityRequired =
        data.roleName === "DOCTOR" || data.roleName === "DOCTOR_ADMIN";

      if (isSpecialityRequired && !data.speciality) {
        NotificationManager.error(
          "Speciality is required for the selected role."
        );
        setAddLoadingStatus(false);
      } else {
        const isValidEmailId = validateEmail(data.email);

        if (!isValidEmailId) {
          NotificationManager.error(AppConstants.MSG.INVALID_EMAIL);
          setAddLoadingStatus(false);
        } else if (mobileNumber.length !== 10) {
          NotificationManager.error(AppConstants.MSG.INVALID_MOBILE_NUMBER);
          setAddLoadingStatus(false);
        } else {
          Api.post(`doctor`, req).subscribe(
            (resp) => {
              if (resp.data.status === true) {
                NotificationManager.success("Added Successfully");
                setAddLoadingStatus(false);
                setTimeout(() => {
                  history.goBack();
                }, 2000);
              } else {
                NotificationManager.error(resp.data.message);
                setAddLoadingStatus(false);
              }
            },
            (err) => {
              DEFAULT_ERROR_CALLBACK(err);
              setAddLoadingStatus(false);
            }
          );
        }
      }
    } else {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      setAddLoadingStatus(false);
    }
  };

  const handleChangePhoneNumber = useCallback((value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setIsInvalidPhone(false);
  }, []);

  return (
    <div className={classes.sectionContainer}>
      <Header showBackBtn title="New Staff" />
      <Box m={10}>
        <Form onSubmit={handleSave}>
          <Box className={classes.staffDetailsForm}>
            <Box mb="0.5rem">
              <TextInput
                className={`${globalClass.roundWhiteBg} ${classes.fields}`}
                field="name"
                name="name"
                type="text"
                label="Staff Name"
                onChange={onChangeData}
              />
            </Box>

            <Box mb="0.5rem">
              <TextInput
                className={`${globalClass.roundWhiteBg} ${classes.fields}`}
                field="email"
                type="text"
                name="email"
                label="Staff Email"
                onChange={onChangeData}
              />
            </Box>
            <Box mb="0.5rem">
              <PhoneInput
                country={"in"}
                className={`${globalClass.roundWhiteBg} ${classes.fields}`}
                enableSearch
                inputClass="login-page__phone-input"
                label="Staff Number"
                field="mobileNumber"
                countryCodeEditable={false}
                placeholder="Enter Mobile"
                onChange={handleChangePhoneNumber}
                validateOnBlur
                validateOnChange
              />
            </Box>
            <Box className={classes.dropdownFields}>
              <Box mb="0.5rem">
                <TextInput
                  className={`${globalClass.roundWhiteBg} ${classes.fields}`}
                  field="password"
                  type={passwordVisible ? "text" : "password"}
                  label="Password"
                  name="password"
                  onChange={onChangeData}
                />
              </Box>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className={classes.iconButton}
                onClick={handleClickEyeBtn}
              >
                {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Box>
            <Box mb="0.5rem">
              <SelectInput
                className={globalClass.roundWhiteBg}
                options={roleName}
                field="roleName"
                placeholder="Role"
                label="Role Name"
                name="roleName"
                onChange={onChangeData}
              />
            </Box>
            {(data?.roleName === "DOCTOR" || data?.roleName === "DOCTOR_ADMIN") && (
              <Box mb="0.5rem">
                <SelectInput
                  className={globalClass.roundWhiteBg}
                  options={allSpeciality}
                  field="speciality"
                  placeholder="Speciality"
                  label="Speciality"
                  name="speciality"
                  onChange={onChangeData}
                  labelKey="name"
                  valueKey="_id"
                />
              </Box>
            )}
          </Box>
          <Box mx=".4rem" my={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.mediumBtnContainer}
            >
              {addLoadingStatus ? (
                <Loader width="200px" height="20px" color="white" />
              ) : (
                "SAVE"
              )}
            </Button>
          </Box>
        </Form>
      </Box>
    </div>
  );
};

export default NewStaff;
