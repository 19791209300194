import React from "react";
import GenericGrid from "../../components/grid/generic-grid";
import ReferalDoctorsColumn from "./referal-doctors-column";
import useGlobalStyles from "../../utils/global-style";
import ReferralList from "./referalDoctorList";

const ReferalDoctor = () => {
  const globalClasses = useGlobalStyles();



  

  return (
    <div className={globalClasses.addSpacing}>
      {/* <GenericGrid
        hideToggleEditMode={false}
        baseUrl="referral-doctors"
        csvName="referral-doctors"
        //addText={"Add Doctor"}
        hideAddNew={true}
        hideOnUpload={true}
        hideOnCopy={true}
        hideExport={true}
        hideGlobalSearch={true}
        hideOnResetPassword={true}
        columnDefGenerator={ReferalDoctorsColumn}
        title="Referal Doctors"
      /> */}


        <ReferralList/>
    </div>
  );
};

export default ReferalDoctor;
