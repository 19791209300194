import React, { useState, forwardRef } from "react";
import { asField } from "informed";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useRef } from "react";

const CustomCalendarComponent = forwardRef(
  ({ ipDate, onClick, dateFormat, handleIpChange }, ref) => {
    return (
      <div>
        <input
          onClick={onClick}
          value={ipDate}
          placeholder={dateFormat}
          onChange={(e) => handleIpChange(e.target.value)}
          type="input"
        />
      </div>
    );
  }
);

const CustomDatePicker = asField(({ fieldState, fieldApi, ...props }) => {
  const {
    required,
    onChange,
    onBlur,
    onFocus,
    containerClassName,
    labelClass,
    placeholder,
    minDate,
    className,
    maxLength,
    format,
    hideLabel,
    ...rest
  } = props;

  const { error, value, touched } = fieldState;
  const { setValue } = fieldApi;

  let dateFormat = "DD/MM/YYYY";
  const shouldParse = !!value && ("" + value).indexOf("/") === -1;
  const dispValue = shouldParse
    ? moment(value).format(dateFormat)
    : value || "";

  const [dpDate, setDpDate] = useState();
  const [ipDate, setIpDate] = useState(moment().format(dateFormat));

  const _calendar = useRef();

  const handleDPChange = (val) => {
    setDpDate(val);
    setValue(moment(val, dateFormat).toDate().getTime());
    setIpDate(moment(val).format(dateFormat));
  };

  const handleIpChange = (val) => {
    let d = moment(val, dateFormat);

    if (d.isValid()) {
      setDpDate(d.toDate());
      setValue(d.toDate().getTime());
      _calendar.current.setOpen(true);
    } else {
      setDpDate(null);
      _calendar.current.setOpen(false);
    }

    setIpDate(val);
  };

  return (
    <div className={containerClassName}>
      {!hideLabel && (
        <div className={labelClass}>
          <label>
            {props.placeholder}{" "}
            {required ? <span className="required">*</span> : ""}
          </label>
        </div>
      )}

      <div>
        <DatePicker
          {...rest}
          ref={_calendar}
          value={dispValue}
          selected={dpDate}
          customInput={
            <CustomCalendarComponent
              ipDate={ipDate}
              dateFormat={dateFormat}
              handleIpChange={(val) => handleIpChange(val)}
            />
          }
          className={className}
          placeholderText={placeholder}
          minDate={minDate}
          onChange={(value) => handleDPChange(value)}
        />
      </div>
      {touched && error && <span className="no-fill-warning">{error}</span>}
    </div>
  );
});

export default CustomDatePicker;
