import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"
import DataService from '../../utils/services/data-service'
import { Typography, Box, Grid, makeStyles } from '@material-ui/core'
import Api from '../../utils/api'
import clsx from 'clsx'
import useGlobalStyles from '../../utils/global-style'
import Paper from "@mui/material/Paper"
import { DEFAULT_ERROR_CALLBACK } from '../../utils/app-util'
import { getFormattedDate } from '../../utils/app-util'
import Dialog from '@mui/material/Dialog'
import Slider from 'react-slick'
import Header from '../../components/layout/header'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Loader from '../../components/misc/loader'
import Api2 from '../../utils/api2'
import CustomActionButton from '../../components/common/CustomActionButton'
import { useHistory } from "react-router-dom";
import Routes from '../../routes/routes'

const NewReport = () => {
    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        queue: {
            width: '40px',
            textAlign: 'center',
        },
        presBox: {
            height: "20vh",
            overflowY: "scroll",
        },
        container: {
            margin: '20px auto',
            width: '96%',
            padding: theme.spacing(8),
            borderRadius: theme.spacing(0, 6, 6, 6),
        },
        textStyle: {
            color: theme.palette.common.black,
            fontWeight: 'bold',
            opacity: '0.8',
            fontSize: '0.7rem',
            width: '10%',
        },
        textMoreStyle: {
            color: theme.palette.common.black,
            fontWeight: 'bold',
            opacity: '0.8',
            fontSize: '0.7rem',
            width: '4%',
        },
        cellTextStyle: {
            color: theme.palette.common.black,
            fontSize: '0.7rem',
        },
        calledCellStyle: {
            backgroundColor: '#faebd7',
        },

        header_styling: {
            // margin: "0px",
            width: '1%',

            // gap: "0"

        },


        body_styling: {
            width: '1%',
            // margin: "0px",
            // gap: "0"



        },



        icon: {
            margin: theme.spacing(0, 4),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            opacity: '0.8',
            padding: theme.spacing(4),
            '&:hover': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                opacity: '0.6',
            },
            '&:disabled': {
                border: '1px solid rgba(0, 0, 0, 0.26)',
            },





        },

    }));
    const globalClass = useGlobalStyles();
    const classes = useStyles();

    const [openReport, setOpenReport] = useState(false);

    const handleCloseReport = useCallback(() => {
        setOpenReport(false);
    }, []);

    const handleImageClick = (val) => {
        let data = val;
        let w = window.open("about:blank");
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const slug = searchParams.get('slug');
    console.log("test_slug", slug)


    const [patientReports, setPatientReports] = useState([]);
    const [isloading, setisloading] = useState(false)
    const [patientdetails,setPatientDetails]=useState({})

    useEffect(() => {
        setisloading(true);
        const subscriptions = [];
    
        const fetchReports = DataService.fetchList("report/search", 0, 100, {
            patientSlug: {
                values: [slug],
                filterType: "set",
            },
        }).subscribe({
            next: (resp) => {
                console.log("test_reports", resp);
                const reports = [...resp.content];
                const newestReports = reports.reverse();
                setPatientReports(newestReports);
                setisloading(false);
            },
            error: (error) => {
                console.error("Error fetching reports:", error);
                setisloading(false);
            }
        });
    
        subscriptions.push(fetchReports);
    
        const fetchProfile = Api2.get(`/getprofile?id=${slug}`).subscribe({
            next: (response) => {
                console.log("test_responsepatient", response);
                setPatientDetails(response.data.data);
            },
            error: (error) => {
                console.error("Error fetching patient profile:", error);
            }
        });
    
        subscriptions.push(fetchProfile);
    
        return () => {
            subscriptions.forEach(subscription => subscription.unsubscribe());
        };
    }, [slug]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    const [selectedReport, setSelectedReport] = useState([]);
    const [selectSingleImage, setSelectSingleImage] = useState(null)
    const [reportType, setReportType] = useState(null);

    const handleSelectedReport = (report) => {
        setSelectedReport(report);
        Api.get(`report/${report.slug}`).subscribe(
            (resp) => {
                console.log("test_reportss", resp)
                setSelectSingleImage(resp.data.data)
                setOpenReport(true);
                if (resp.data.data?.images) {
                    let fileType = null;
                    fileType = resp.data.data.images.map((data) => data.contenttype);
                    setReportType(fileType);
                }
            },
            (error) => {
                DEFAULT_ERROR_CALLBACK(error);
            }
        );
    };

    const handleAddReport = () => {
        history.push(`${Routes.REPORT}?patientSlug=${slug}&appointmentSlug=${''}`)
    }

    return (
        <div>
            <Header showBackBtn title="Report" />
            <div className="second_part d-flex justify-content-between align-items-center ps-2">
                <div className="patient_name">
                    {/* <h5>INVOICE TO</h5> */}
                    <p>
                        {" "}
                        <b>{patientdetails?.name} </b> <br />
                        <small style={{ fontWeight: "500" }}>
                            UHID: &nbsp;{patientdetails?.uhid}
                        </small>
                    </p>
                </div>
                <div>
                    <CustomActionButton buttonTitle='Add Report' onClick={handleAddReport}/>
                </div>
            </div>

            <Grid container item style={{ display: "flex", justifyContent: "center" }} >
                {
                    isloading ? (<Loader />) : (
                        <Paper className={classes.containerBox} sx={{ padding: "20px" }} style={{width:"100%"}}>
                            {/* <MiniHeader title="Report(s)" /> */}
                            {patientReports.length > 0 ? (
                                <Box className={clsx(classes.presBox, globalClass.srollBar)} style={{ height: "400px", paddingLeft: "20px" }}>
                                    {patientReports.map((data) => (
                                        <div
                                            className={classes.reportCard}
                                            key={data.id}
                                            onClick={() => handleSelectedReport(data)}
                                        >
                                            <h6 className={classes.title} style={{cursor: "pointer", display: "inline-block"}}>
                                                {`${data.reportName ?? ""} / (${getFormattedDate(data.reportDate, "Do MMMM YYYY") ??
                                                    ""
                                                    })`}
                                            </h6>
                                        </div>
                                    ))}
                                </Box>
                            ) : (
                                <div className={classes.noReport}>
                                    <Typography variant="h6">No Reports Found</Typography>
                                </div>
                            )}
                        </Paper>
                    )
                }
            </Grid>
            <Dialog
                className={classes.dialogBackground}
                fullScreen
                open={openReport}
                onClose={handleCloseReport}
            >
                <div className={classes.closeContainer}>
                    <div className={classes.reportClose} onClick={handleCloseReport}>
                        <p className={classes.closeHeading} style={{ marginTop: "14px" }}>Close</p>
                    </div>
                    <div className={classes.reportTitle}>
                        <span>
                            {`${selectSingleImage?.reportName ?? ""} / ${getFormattedDate(
                                selectSingleImage?.reportDate,
                                "Do MMMM YYYY"
                            ) ?? ""
                                }/ 
                  Value: ${selectSingleImage?.value ?? ""}`}
                        </span>
                    </div>
                </div>
                {selectSingleImage?.images ? (
                    selectSingleImage?.images?.length === 1 ? (
                        selectSingleImage?.images[0].contenttype === "application/pdf" ? (
                            <iframe
                                title="Report picture"
                                src={selectSingleImage.images[0].content}
                                alt="Report"
                                className={classes.reportImage}
                                onClick={() =>
                                    handleImageClick(selectSingleImage.images[0].content)
                                }
                                style={{ cursor: "pointer", width: "100%", height: "90vh" }}
                            />
                        ) : (
                            <img
                                title="Report picture"
                                src={selectSingleImage.images[0].content}
                                alt="Report"
                                className={classes.reportImage}
                                onClick={() =>
                                    handleImageClick(selectSingleImage.images[0].content)
                                }
                                style={{ cursor: "pointer" }}
                            />
                        )
                    ) : (
                        <div style={{}} className={classes.multipleImageContainer}>
                            <Slider {...settings}>
                                {selectSingleImage.images.map((im, index) => {
                                    return (
                                        <div key={index} style={{ width: "100%", height: "90vh" }}>
                                            {im?.contenttype === "application/pdf" ? (
                                                <iframe
                                                    title="Report picture"
                                                    src={im.content}
                                                    alt="Report"
                                                    className={classes.reportImage}
                                                    onClick={() => handleImageClick(im?.content)}
                                                    style={{ cursor: "pointer", width: "100%", height: "90vh" }}
                                                />
                                            ) : (
                                                <img
                                                    title="Report picture"
                                                    src={im?.content}
                                                    alt="Report"
                                                    className={classes.reportMultipleImage}
                                                    onClick={() => handleImageClick(im?.content)}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    )
                ) : (
                    <div className={classes.noReportImage}>
                        Report image is not available.
                    </div>
                )}
            </Dialog>
        </div>
    )
}

export default NewReport
