import { firstValueFrom } from "rxjs";
import Api from "../utils/api";

export const AccessLevelAPI = {
  getAllAccessLevels: async function () {
    try {
      const response = await firstValueFrom(Api.get("access-level"));
      return response.data;
    } catch (error) {
      console.error("Error fetching doctors:", error);
      // TODO: check if needed to throw the error
      throw error;
    }
  },

  setSharedAccessLevels: async function (
    targetDoctorSlug,
    accessLevelToBeSharedList
  ) {
    try {
      const response = await firstValueFrom(
        Api.post(`/share-access`, {
          targetDoctorSlug,
          accessLevels: accessLevelToBeSharedList,
        })
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      console.error("Error sharing access levels:", error);
      throw error;
    }
  },

  updateSharedAccessLevels: async function (
    targetDoctorSlug,
    accessLevelToBeSharedList
  ) {
    try {
      const response = await firstValueFrom(
        Api.put(`/share-access`, {
          targetDoctorSlug,
          accessLevels: accessLevelToBeSharedList,
        })
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      console.error("Error updating access levels:", error);
      throw error;
    }
  },

  deleteShareAccessForADoctor: async function (targetDoctorSlug) {
    try {
      const response = await firstValueFrom(
        Api.delete(`/share-access?targetDoctorSlug=${targetDoctorSlug}`)
      );
    } catch (error) {
      console.error("Error deleting access levels:", error);
      throw error;
    }
  },
};
