import React, { useState, useEffect, createContext } from 'react'

import { useLocation } from "react-router-dom"
import Api from '../../utils/api';

import { TablePagination, Tab, Typography, Box, makeStyles, Grid, TextField, IconButton, Tooltip } from "@material-ui/core"

import moment from 'moment';
import { tr } from 'date-fns/locale';
import { AddCircleOutline } from "@material-ui/icons";
import Routes from '../../routes/routes';
import VisibilitySharp from '@material-ui/icons/VisibilitySharp';
import medicine from "../../assets/medicine.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { Form } from 'informed';
import Loader from '../../components/misc/loader';

const useStyles = makeStyles((theme) => ({
    tab: {
        fontSize: "0.9rem",
        textTransform: "none"
    },
    icon: {
        margin: theme.spacing(0, 4),
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        opacity: '0.8',
        padding: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            opacity: '0.6',
        },
        '&:disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26)',
        },





    },
    icon_1: {
        margin: theme.spacing(0, 4),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        opacity: '0.8',
        padding: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.white,
            opacity: '0.6',
        },
        '&:disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26)',
        },





    },
}));












const Folder = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id');


    const [patientStatus, setPatientStatus] = useState()
    const [provisionalDiagnosis, setProvisionalDiagnosis] = useState()
    const [plan, setPlan] = useState()
    const [roomNumber, setRoomNumber] = useState()
    const [doctor, setDoctor] = useState()
    const [patientName, setPatientName] = useState()
    const [doctorName, setDoctorName] = useState()
    const [patientUhid, setPatientUhid] = useState()
    const [admitHistory, setAdmitHistory] = useState([])
    const [page, setPage] = useState(0);
    const [profilePic, setprofilePic] = useState("");
    const [loader, setLoader] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [waitResponse, setWaitResponse] = useState(false);

    const classes = useStyles();


    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [warningOpen, setWarningOpen] = useState(false)

    const handleScroll = (e) => {

        if (!hasMore) {

            return;
        }

        if (!waitResponse) {
            const { scrollTop, clientHeight, scrollHeight } = e.target;

            console.log("test_scroll", Math.floor(scrollTop), clientHeight, scrollHeight)
            if (Math.floor(scrollTop) + clientHeight + 2 >= scrollHeight) {


                setPage(page + 1);
            }
        }
    };

    const onmedicineIconclicked = () => {
        setWarningOpen(true)
    }
    const handleClosee = () => {
        setWarningOpen(false)
    };
    const [isLoading, setIsloading] = useState(false)
    const [medicineslist, setMedicationList] = useState([])
    console.log('test_medicines', medicineslist)
    useEffect(() => {
        setIsloading(true)
        Api.get(`admit/id/${id}`).subscribe((resp) => {
            console.log("test_admitdetails", resp);
            let admitdata = resp.data.data
            setDoctorName(admitdata?.doctor?.name)
            setPatientName(admitdata?.patient?.name)
            setPatientUhid(admitdata?.patient?.uhid)
            setRoomNumber(admitdata?.roomNumber)
            setProvisionalDiagnosis(admitdata?.prov)
            setPlan(admitdata?.plan)
            setPatientStatus(admitdata?.status)
            setDoctor(admitdata?.doctor)
            setMedicationList(admitdata?.medications)



        })
        setIsloading(false)

    }, [])

    useEffect(() => {
       
        Api.post('admit/history/search', {
            "size": 20,
            "page": 0,
            "filterModel": {
                "admitId": {
                    "type": "equals",
                    "value": id
                }
            },
            "sortModel": [
                {
                    "colId": "createdAt",
                    "sort": "DESC"
                }
            ]
        }).subscribe((resp) => {
            if (resp) {
                setAdmitHistory(resp?.data?.data?.content)
               

               
            }

        })
        setPage(0);
    }, [id])


    useEffect(() => {
        if (!hasMore) {
            setLoader(false);
            return;
        }

        setLoader(true);

        setWaitResponse(true);
        Api.post('admit/history/search', {
            "size": 20,
            "page": 0,
            "filterModel": {
                "admitId": {
                    "type": "equals",
                    "value": id
                },
                "prescription": {
                    "type": "custom",
                    "value": "exists"
                },
                "billing": {
                    "type": "custom",
                    "value": "exists"
                },
            },
            "sortModel": [
                {
                    "colId": "createdAt",
                    "sort": "DESC"
                }
            ]
        }).subscribe((resp) => {
            if (resp) {
                if (resp?.data?.data?.content === 0) {
                    setHasMore(false);
                    setLoader(false);

                    return;
                }

                console.log('test_response', resp?.data?.data?.content)
                setAdmitHistory([...admitHistory, ...resp?.data?.data?.content])
                setLoader(false);
                setWaitResponse(false);
            }

        })

    }, [page])










    const [rotate, setRotate] = useState(false);
    const [addtoggle, setaddtoggle] = useState(false);
    const addAppointmentItemstoggle = () => {
        setaddtoggle(!addtoggle)
        setRotate(!rotate);
        window.open(`#!` + Routes.PRESCRIPTION + `?id=${id}`, '_blank');
    }

    const oneyebuttonClicked = () => {
        window.open(`#!` + Routes.FILELIST + `?id=${id}`, '_blank');
    }





    return (
        <>
            {
                isLoading ? (<Loader />) : (
                    <>
                        <Box
                            flexGrow="1"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb="1.5rem"
                        >
                            <div style={{ fontSize: '17px', padding: "22px 57px", fontFamily: "Comfortaa", fontWeight: "bold" }}>File</div>
                            <div>
                                <Tooltip title='View Logs'>
                                    <span>
                                        <IconButton onClick={oneyebuttonClicked}
                                            className={classes.icon}
                                        >
                                            <VisibilitySharp style={{ fontSize: "15px" }} />

                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title='Medicine'>
                                    <span>
                                        <IconButton
                                            className={classes.icon_1}
                                            onClick={onmedicineIconclicked}

                                        >
                                            {/* <Terrain style={{ fontSize: "15px" }} /> */}
                                            <img src={medicine} style={{
                                                fontSize: "15px", height: "1em", width: "1em",


                                            }} alt="" />
                                        </IconButton>
                                        {/* <img src={PassedMotion} style={{ fontSize: "15px" }} alt="" /> */}
                                    </span>
                                </Tooltip>

                            </div>
                        </Box>
                        <div className="second_part justify-content-between  align-items-center ps-2">
                            <div className="patient_name  align-items-center ps-2 row">

                                <p className='col-md-2'>
                                    {" "}
                                    <b>Patient Name </b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{patientName}
                                    </small>
                                </p>
                                <p className='col-md-1'>
                                    {" "}
                                    <b>Room</b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{roomNumber}
                                    </small>
                                </p>
                                <p className='col-md-2'>
                                    {" "}
                                    <b>Doctor</b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{doctorName}
                                    </small>
                                </p>
                                <p className='col-md-2'>
                                    {" "}
                                    <b>Number/UHID</b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{patientUhid}
                                    </small>
                                </p>
                                <p className='col-md-2'>
                                    {" "}
                                    <b>Diagnosis</b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{provisionalDiagnosis}
                                    </small>
                                </p>
                                <p className='col-md-1'>
                                    {" "}
                                    <b>Plan</b> <br />
                                    <small style={{ fontWeight: "500" }}>
                                        &nbsp;{plan}
                                    </small>
                                </p>
                                <p className='col-md-1'>
                                    {" "}
                                    <b>Status</b> <br />
                                    <small style={{ fontWeight: "500", paddingRight: "15px" }}>
                                        &nbsp;{patientStatus}
                                    </small>
                                </p>
                            </div>


                        </div>
                    </>
                )
            }

            <div>
                <div style={{ height: "700px", overflow: "auto", fontSize: "10px", backgroundColor: "white", padding: "5px" }} onScroll={handleScroll}>
                    {
                        admitHistory?.map((item, index) => (
                            <>
                                {
                                    item.historyType == "prescription" && (
                                        <>
                                        <Typography variant='h6'>{item?.prescription?.templateTypeName}</Typography>
                                            <div dangerouslySetInnerHTML={{ __html: item?.prescriptionHtml }} />
                                            <span>{item.createdByName}</span><br />
                                            <span>Date :{moment(item.createdAt).format("DD-MM-YYYY")}  , Time :{moment(item.createdAt).format(" HH:mm:ss")}</span>
                                            <hr />
                                        </>
                                    )
                                }
                                {
                                    item.historyType == "billing" && (
                                        <>
                                            <div>
                                                <p style={{fontWeight:"bold",margin:'10px'}}>Bill</p>
                                                <span>Cost:{item?.billing?.price.toFixed(2)}&nbsp;|&nbsp;Quantity:{item?.billing?.number}&nbsp;|&nbsp;Discount:{
                                                    item?.billing?.discountValue > 0 ? (<>
                                                        {item?.billing?.discountType == 'percentage' ? (<>
                                                            {item?.billing?.discount} ({item?.billing?.discountValue}%)

                                                        </>) : (<>
                                                            {item?.billing?.discountValue.toFixed(2)}
                                                        </>)}
                                                    </>) : ("0")
                                                }&nbsp;|&nbsp;Subtotal:(
                                                    {item?.billing?.type == 'credit' ? (<>
                                                        +
                                                    </>) : (<>
                                                        -
                                                    </>)}
                                                    ) {item?.billing?.totalAmount.toFixed(2)}</span>
                                            </div>
                                            <hr />
                                        </>
                                    )
                                }
                            </>
                        ))
                    }

                </div>
            </div>

            {/* new section */}

            <div>
                {/* <h5 style={{ "fontWeight": "bold", "textAlign": "center" }}>Patients Summary</h5> */}
                <div style={{ height: "700px", overflow: "auto", fontSize: "10px", backgroundColor: "white", padding: "5px" }}>

                    <div style={{ margin: "2px", bottom: " 40px", position: "fixed", right: "10px" }}>
                        {/* <AddCircleIcon onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease", }} /> */}
                        <AddCircleOutline onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease" }} />
                    </div>

                    {/* medicines list */}
                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={warningOpen}
                        onClose={handleClosee}
                    >
                        <DialogContent>
                            <DialogContentText>
                            </DialogContentText>
                            <Form>

                                <div style={{ padding: "0px 0px" }}>
                                    <Box
                                        sx={{
                                            width: 1200,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                        }}
                                    >
                                        <Typography variant="h4" className={classes.prescriptionTitle}>
                                            Medicines <span style={{ float: "right" }}><Button onClick={handleClosee} style={{ fontSize: "18px", marginTop: "-10px;" }}> X </Button></span>
                                        </Typography>
                                        <Box sx={{ overflow: 'hidden', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr', gap: '9px' }}>
                                            {/* <TextField style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 20px #00000008", borderRadius: 14, marginTop: 5, marginRight: 10, }} id="outlined-basic" label="Activity name" variant="outlined" /> */}

                                            <ul style={{ marginTop: "30px" }}>
                                                {
                                                    medicineslist.map((item, index) => (
                                                        <li>
                                                            <b>{item.name + " " + "-"}</b><span style={{ marginLeft: "10px" }}>{item.dose}</span><span style={{ marginLeft: "7px", marginRight: '15px' }}>{item.frequency}</span>x<span >{item.num + " " + item.interval}</span><span style={{ marginLeft: "7px" }}>{item.remarks}</span>
                                                        </li>
                                                    )

                                                    )
                                                }
                                            </ul>

                                            {/* <button style={{ borderRadius: "15px", backgroundColor: "black", color: "white", padding: "7px 15px", margin: "4px" }}> Add</button> */}
                                        </Box>





                                        {/* <Box sx={{ display:"flex" ,justifyContent: 'center', gap: '10px', width: "400px", position: "relative", left: "800px",textAlign:"center" }}> */}

                                    </Box>
                                </div>

                            </Form>

                        </DialogContent>
                    </Dialog>






                </div>
            </div>
        </>
    )
}

export default Folder
