import React, { useState, useEffect, useRef } from 'react';
import { asField } from 'informed';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 205
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  }
}));

export const SelectInput = asField((props) => {
  const {
    fieldState,
    fieldApi,
    onChange,
    options,
    className,
    label,
    containerClassName,
    labelKey,
    valueKey,
    ...rest
  } = props;

  const { error, value = "" } = fieldState;
  const { setValue } = fieldApi;

  const classes = useStyles();
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div className={containerClassName}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel style={{ marginTop: "8%" }} ref={inputLabel} id="select-label">
          {label}
        </InputLabel>
        <Select
          labelId="select-label"
          className={`MuiFormControl-marginNormal ${className}`}
          labelWidth={labelWidth}
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            setValue(val);
            if (onChange) {
              onChange(e, val);
            }
          }}
          {...rest}
        >
          {(options || []).map((op, idx) => (
            <MenuItem key={idx} value={op[valueKey] || op}>
              {op[labelKey] || op}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText variant="standard">{error}</FormHelperText>
      </FormControl>
    </div>
  );
});