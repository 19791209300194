import React, { useState } from "react";
import Api2 from "../../../utils/api2";
import { useHistory } from "react-router-dom";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Box,
  makeStyles
} from "@material-ui/core";
import moment from "moment";
import { useEffect } from "react";
import Loader from "../../../components/misc/loader";
import Routes from "../../../routes/routes";

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(10),
    width: "95%",
    maxWidth: 1500,
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  event: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "1rem",
  },
  headerCell: {
    padding: "1rem",
    width: (window.innerWidth - 240) / 8,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  eventText: {
    fontSize: "1rem",
  },
  cell: {
    padding: "0 !important",
    width: (window.innerWidth - 240) / 8,
    minHeight: "80px",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  calenderData: {
    color: "white !important",
  }
}));

const TIME_INTERVAL = 10;
const Calendar = ({ events, toggleSideTab, className }) => {
  const history = useHistory();
  const [page, setPage] = useState(0)
  const [apiStatus, setApiStatus] = useState(false)
  const [apiStatus1, setApiStatus1] = useState(true)

  const [loader, setLoader] = useState(false)

  const [caldata, setcaldata] = useState([]);

  const handlePage = () => {
    setApiStatus(true)
    setPage((prev) => prev + 1)
  }

  var stat = 0;

  useEffect(() => {
    if (stat === 0) {
      handlePage();
      stat = 1
    }
    else {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      ) {
        handlePage();
      }
      else {
      }
    }
  }, [])

  const [calenderData, setCalendarData] = useState([]);

  const getDays = calenderData?.map((item) => {
    return (item.days)
  })

  const getDaysName = getDays?.map((item) => {
    return (item)
  })

  const dayName = getDaysName?.map((item) => {
    return (item)
  })

  const weekName = dayName && dayName[0]?.map((item) => {
    return item;
  })

  const classes = useStyles();
  const currentMonth = moment().format("MMMM");


  const firstEvent = (e) => {
    var bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;
    if (bottom) {
      let pg = page + 1;
      setPage(pg);
      getData();
    }
  }

  const [failure, setfailure] = useState()


  const getData = () => {
    setLoader(true)
    Api2.get(`get_week_schedule?page=${page}`).subscribe(
      (resp) => {
        if (resp) {
          setLoader(false)
          const data = resp?.data.data
          const arr1 = caldata;
          data.map((item) => (
            arr1.push(item)
          ))
          setcaldata(arr1);
          setCalendarData(arr1)
          setApiStatus1(false)
          setApiStatus(false)
        }
        if (resp.data.data.message === "exception.unexpected") {
          setfailure();
        }
      })
  }


  useEffect(() => {
    getData();
  }, [failure])

  // const handleAddAppointment = (item) => {
  //   history.push({
  //     pathname: Routes.NEW_APPOINTMENT,
  //     state: item,
  //   })
  // }

  const handleOpenPrescription = (item) => {
    history.push(`${Routes.PRESCRIPTION}?slug=${item.appointmentSlug}`)
  }


  const onUnavailibilityClick = () => {
    history.push(`${Routes.PROFILE}`)
  }

  return (
    <>

      {
        apiStatus1 ? <Loader />
          :
          <Box
            component={Paper}
            className={className ? className : classes.container}
          >
            <Box py="2rem" display="flex" justifyContent="center">
              <Typography variant="h5">
                <strong>{currentMonth}</strong>
              </Typography>
            </Box>

            <TableContainer style={{ height: "400px" }} onScroll={firstEvent}>
              <Table stickyHeader aria-label="calendar">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography variant="h6">Time</Typography>
                    </TableCell>
                    {weekName?.map((item, index) => {
                      return (
                        <TableCell
                          className={classes.headerCell}
                          align="center"
                          key={index}
                        >
                          <Typography variant="h5">
                            <strong>{item.dateString.slice(-2)}</strong>
                          </Typography>
                          <Typography variant="h5">{item.day_of_week}</Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: "400px" }}>

                  {
                    dayName?.map((item) => {
                      return (
                        <TableRow >
                          {
                            item?.slice(0, 1)?.map((item) => {
                              return (
                                <TableCell style={{ width: "200px", border: "1px solid white" }} align="center">
                                  <Typography variant="h6" >
                                    {item.startTimeOnly + "-" + item.endTimeOnly}
                                  </Typography>
                                </TableCell>
                              )
                            })
                          }
                          {
                            item?.map((item) => {

                              if (item.status === "un-available") {
                                return (
                                  <TableCell onClick={onUnavailibilityClick} className={classes.calenderData} style={{ width: "200px", backgroundColor: "orange", border: "1px solid white", cursor: "pointer" }} align="center">
                                    <Typography variant="h6" color="white !important">
                                      {item.status}
                                    </Typography>
                                  </TableCell>
                                )
                              }

                              if (item.status === "open") {
                                return (
                                  <TableCell className={classes.calenderData} style={{ width: "200px", backgroundColor: "#1f8d60", border: "1px solid white" }} align="center"
                                  // onClick={() => handleAddAppointment(item)}
                                  >
                                    <Typography variant="h6" color="white !important">
                                      Available
                                    </Typography>
                                  </TableCell>
                                )
                              }

                              if (item.status === "booked") {
                                return (
                                  <TableCell className={classes.calenderData} style={{ width: "200px", backgroundColor: "#c31521", border: "1px solid white", cursor: "pointer" }} align="center"
                                    onClick={() => handleOpenPrescription(item)}
                                  >
                                    <Typography variant="h6" color="white !important">
                                      {item.patientName}
                                    </Typography>
                                    <Typography variant="h6" color="white !important">
                                      {item.status}
                                    </Typography>
                                  </TableCell>
                                )
                              }
                            })
                          }

                        </TableRow>
                      )
                    })
                  }

                </TableBody>
              </Table>
              {/* <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                <Button
                  type="submit"
                  onClick={handlePage}
                  variant="contained"
                  color="primary"
                  style={{ margin: '0 auto', }}
                >
                  {apiStatus ? <Loader width="200px" height="20px" color="white" /> : "Load More"}

                </Button>
              </div> */}
            </TableContainer>
            {loader ? <Loader width="200px" height="20px" color="white" /> : ""}


          </Box>
      }
    </>
  );
};

export default Calendar;
