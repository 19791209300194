import React from "react";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Provider } from "react-redux";
import ReduxStore from "./redux/store";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={ReduxStore}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Provider>
  </LocalizationProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
