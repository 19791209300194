import { CircularProgress } from "@mui/material";
import React from "react";

export const CustomCircularLoader = ({containerHeight, ...otherProps}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: containerHeight,
      }}
    >
      <CircularProgress {...otherProps}/>
    </div>
  );
};
