import React from "react";
import { Drawer } from "@material-ui/core";
import SidebarContent from "./sidebar-content";

const ChatSidebar = ({
  open,
  data,
  toggleSideTab,
  onResolvedBtnClicked,
  quesValue,
}) => {
  return (
    <Drawer anchor="right" open={open} onClose={toggleSideTab}>
      <SidebarContent
        onResolvedBtnClicked={onResolvedBtnClicked}
        data={data}
        quesValue={quesValue}
      />
    </Drawer>
  );
};

export default ChatSidebar;
