import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, Redirect } from "react-router-dom";

import Header from './header';
import Footer from './footer';
import Home from './home';


const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: "100%",
      backgroundColor: theme.palette.bg,
    },
  
    content: {
      height: "100%",
    },
  }));
  

const Precription = () => {
  return (
    <div >
   <Header />
   <main>
    <switch>
    <center>
    <Home />
    </center>
    </switch>
   </main>
   <Footer/>
    </div>
  )
}

export default Precription