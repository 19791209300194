import {  ADD_FORM_SUBMIT} from "../actionType";

const initialState = {
  FormInputData: []
};

const inputFormSubmit = (state = initialState, action) =>{
  switch (action.type) {
    case ADD_FORM_SUBMIT: {
      const { id, content } = action.payload;
      console.log(action.payload)
      
      return{
        ...state,
        FormInputData:[content ]
      }
    }  

    // case DELETE_TEXT_INPUT :{
    //   const { id } = action.payload;
    //   return {
    //     ...state,
    //     FormInputData: [
    //       ...state.FormInputData.slice(0, id),
    //       ...state.FormInputData.slice(id + 1)
    //     ]
    //   }
    // }

    default:
      return state;
  }
}

export default inputFormSubmit ;