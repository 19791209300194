import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Loader from "../../components/misc/loader";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { default as React, useEffect, useState } from "react";
import Header from "../../components/layout/header";

import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
}));

const Unvailibility = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  // const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
  //   ? AuthService.getUser()
  //   : {};

  const [reason, setReason] = useState("");


  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = mm + '-' + dd + '-' + yyyy;

  const [openAdd, setOpenAdd] = React.useState(false);
  const [ampm, ] = React.useState(undefined);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [getStatus, setGetStatus] = useState(true);
  const [uniqueID, setUniqueId] = useState("");  // for remove uniqueId //

  const [openRemove, setOpenRemove] = React.useState(false);
  const handleOpenRemove = (id) => (setOpenRemove(true), setUniqueId(id));
  const handleCloseRemove = () => setOpenRemove(false);
  const [removeStatus, setRemoveStatus] = useState(false)

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [uniqueData, setUniqueData] = useState(null);

  const handleOpenUpdate = (item) => (setOpenUpdate(true), setUniqueData(item));
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [fromDate, setFromDate] = React.useState(dayjs(today))
  const handleChangeFromDate = (newValue) => {
    setFromDate(newValue);
  };

  const [toDate, setToDate] = React.useState(dayjs(today))
  const handleChangeToDate = (newValue) => {
    setToDate(newValue);
  };

  const [fromTime, setFromTime] = React.useState(dayjs(uniqueData?.startTime));

  const handleChangeFromTime = (newValue) => {
    setFromTime(newValue);
  };

  const [toTime, setToTime] = React.useState(dayjs(uniqueData?.endTime));

  const handleChangetoTime = (newValue) => {
    setToTime(newValue);
  };

  const startDate = fromDate?.$y + "-" + ('0' + (fromDate?.$M + 1)).slice(-2) + "-" + ('0' + fromDate?.$D).slice(-2);
  const startTime = ("0" + fromTime?.$H).slice(-2) + ":" + ("0" + fromTime?.$m).slice(-2)
  const endDate = toDate?.$y + "-" + ('0' + (toDate?.$M + 1)).slice(-2) + "-" + ('0' + toDate?.$D).slice(-2);
  const endTime = ("0" + toTime?.$H).slice(-2) + ":" + ("0" + toTime?.$m).slice(-2)
  const userId = JSON.parse(localStorage.getItem("USER"));

  const [addLoadindStatus, setAddLoadingStatus] = useState(false);
  const [updateLoadingStatus, setUpdateLoadingStatus] = useState(false)

  const handleSubmitAdd = async () => {
    setAddLoadingStatus(true)
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("startDate", startDate)
    formData.append("startTime", startTime)
    formData.append("reason", reason);
    formData.append("endDate", endDate);
    formData.append("endTime", endTime);

    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}add_doctor_unavailability`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      })
      if (response) {

        if (response.data.message === "Added Successfully!") {
          NotificationManager.success(response.data.message);
          setOpenAdd(false)
          setAddLoadingStatus(false)
        }
        else if (response.data.message === "Dates should be after today!") {
          NotificationManager.error(response.data.message);
          setOpenAdd(true)
          setAddLoadingStatus(false)
        }
        else {
          NotificationManager.error(response.data.message);
          setAddLoadingStatus(false)
        }
      }
    }
    catch (error) {
    }
  }

  const [AllData, setAllData] = useState(null)

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_doctor_unavailability?id=${userId.id}`).then((resp) => {
      if (resp) {
        setAllData(resp.data.unAvailability);
        setGetStatus(false)
        // setLoading(true)
      }
    });
  }, [addLoadindStatus, updateLoadingStatus, removeStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdate = async () => {
    setUpdateLoadingStatus(true)
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("startDate", startDate)
    formData.append("startTime", startTime)
    formData.append("reason", reason);
    formData.append("endDate", endDate);
    formData.append("endTime", endTime);
    formData.append("itemId", uniqueData._id)
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}update_doctor_unavailability`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      })
      if (response) {
        if (response.data.message === "Updated Successfully!") {
          NotificationManager.success(response.data.message);
          setOpenUpdate(false)
          setUpdateLoadingStatus(false)
        }
        else if (response.data.message === "Dates should be after today!") {
          NotificationManager.error(response.data.message);
          setOpenUpdate(true)
          setUpdateLoadingStatus(false)
        }
        else {
          NotificationManager.error(response.data.message);
          setUpdateLoadingStatus(false)
        }
      }
    }
    catch (error) {
    }
  }

  useEffect(() => {
    setReason(uniqueData?.reason)
  }, [uniqueData])



  const handleRemove = async (e) => {
    setRemoveStatus(true)
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("itemId", uniqueID)
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}remove_doctor_unavailability`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      })
      if (response) {
        if (response.data.message === "Removed Successfully!") {
          NotificationManager.success(response.data.message);
          setOpenAdd(false)
          setOpenRemove(false)
          setRemoveStatus(false)

        }
        else {
          NotificationManager.error(response.data.message);
          setRemoveStatus(false)
          setOpenRemove(false)
        }
      }
    }
    catch (error) {
    }

  }


  return (
    <div>{
      getStatus ? <Loader /> :
        <>
          <Header title="My unavailability" />
          <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
            <Box className={classes.prescriptionSection}>
              <Box mt="1rem" display="flex" alignItems="center">
              </Box>

              <Paper
                className={clsx(classes.container, globalClass.roundWhiteBg)}
              >
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={handleOpenAdd}
                  >
                    ADD
                  </Button>
                </Box>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    id="summaryRow"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>From Day and Time</strong>
                        </TableCell>
                        <TableCell>
                          <strong>To day and Time</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Reason</strong>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {
                        AllData?.map((item) => (
                          <TableRow>
                            <TableCell>
                              <Box display="flex" flexDirection="column">
                                <Typography>{item.startDate}</Typography>
                                <Typography>{item.startTime}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" flexDirection="column">
                                <Typography>{item.endDate}</Typography>
                                <Typography>{item.endTime}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography>{item.reason}</Typography>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<EditIcon />}
                                onClick={() => handleOpenUpdate(item)}
                              >
                                Update
                              </Button>
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => handleOpenRemove(item._id)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      }

                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>

        </>

    }

      {
        openAdd &&
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add Unvailability
              </Typography>
              <Close onClick={handleCloseAdd} style={{cursor: "pointer"}}/>
            </Box>



            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  From Day and time
                </Typography>
              </Box>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <Grid container>
                      <Grid item xs={6}  >
                        <DesktopDatePicker
                          inputFormat="YYYY-MM-DD"
                          value={fromDate}
                          onChange={handleChangeFromDate}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                      <Grid item xs={6} >
                        <TimePicker
                          ampm={ampm}
                          value={fromTime}
                          onChange={handleChangeFromTime}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  To Day and time
                </Typography>
              </Box>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>

                    <Grid container>
                      <Grid item xs={6}  >
                        <DesktopDatePicker
                          inputFormat="YYYY/MM/DD"
                          value={toDate}
                          onChange={handleChangeToDate}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                      <Grid item xs={6} >
                        <TimePicker
                          ampm={ampm}
                          value={toTime}
                          onChange={handleChangetoTime}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </LocalizationProvider>
              </Grid>

            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Reason
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "97.25%" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Reason"
                  containerClassName="mr"
                  type="text"
                  // className={classes.style_1}
                  name="reason"
                  // onChange={handleChangeData}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Grid>
            </Box>

            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleSubmitAdd}
              >
                {addLoadindStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
              </Button>
            </Box>

          </Box>
        </Modal>
      }

      {
        openUpdate &&
        <Modal
          open={openUpdate}
          onClose={handleCloseUpdate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Update Unvailability
              </Typography>
              <Close onClick={handleCloseUpdate} style={{cursor: "pointer"}}/>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  From Day and time
                </Typography>
              </Box>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <Grid container>
                      <Grid item xs={6}  >
                        <DesktopDatePicker
                          inputFormat="YYYY-MM-DD"
                          value={fromDate}
                          onChange={handleChangeFromDate}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                      <Grid item xs={6} >
                        <TimePicker
                          ampm={ampm}
                          value={fromTime}
                          onChange={handleChangeFromTime}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  To Day and time
                </Typography>
              </Box>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>

                    <Grid container>
                      <Grid item xs={6}  >
                        <DesktopDatePicker
                          inputFormat="YYYY/MM/DD"
                          value={toDate}
                          onChange={handleChangeToDate}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                      <Grid item xs={6} >
                        <TimePicker
                          ampm={ampm}
                          value={toTime}
                          onChange={handleChangetoTime}
                          renderInput={(params) => <TextField style={{ width: "95%" }} variant="outlined" {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </LocalizationProvider>
              </Grid>

            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Reason
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "97.25%" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Reason"
                  containerClassName="mr"
                  type="text"
                  value={reason}
                  // className={classes.style_1}
                  name="reason"
                  // onChange={handleChangeData}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Grid>
            </Box>

            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleUpdate}
              >
                {updateLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "Update"}
              </Button>
            </Box>


          </Box>
        </Modal>
      }

      {
        openRemove &&
        <Modal
          open={openRemove}
          onClose={handleCloseRemove}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle} >
            <Box textAlign="center">
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Are you sure to remove.
              </Typography>
            </Box>

            <Grid container style={{ marginTop: "10%" }}>
              <Grid item xs={6}>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleCloseRemove}
                  style={{ marginTop: "2%", width: "95%" }}
                >
                  Cancel
                </Button>

              </Grid>

              <Grid item xs={6}>

                <Button
                  // type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleRemove}
                  style={{ marginTop: "2%", width: "95%" }}

                >
                  {removeStatus ? <Loader width="20px" height="20px" color="white" /> : "Remove"}
                </Button>

              </Grid>


            </Grid>

          </Box>
        </Modal>
      }
    </div >


  );
};

export default Unvailibility;
