import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  unseenStatusStyle: {
    color: theme.palette.primary.main,
    backgroundColor: 'green'
  },
  timeStatusStyle: {
    backgroundColor: 'grey',
  }
}));

export const ListItem = ({
  status,
  handleClick,
  image,
  name,
  text,
  time,
  itemStyle,
  timeStyle,
  nameVariant = "h6",
  mobileNumber,
  mobileNumberVariant = "subtitle2",
  textVariant = "subtitle2",
  timeVariant = "caption",
  unseenMsgStatus
}) => {
  const classes = useStyles();

  return (
    <CardActionArea onClick={handleClick} className={itemStyle}>
      <Box my={2} mx={1} display="flex" alignItems="center">
        <div className={classes.root}>
          {status ? (
            <Avatar alt="avatar-image" src={image} style={{ color: 'black', marginRight: 8 }}>
              {name.charAt(0)}
            </Avatar>
          ) : (
            <Avatar alt="avatar-image" src={image} style={{ marginRight: 8 }}>
              {name.charAt(0)}
            </Avatar>
          )}
        </div>
        <Box flexGrow={1} mx={2} className={classes.ellipsis}>
          <Typography className={classes.ellipsis} variant={nameVariant}>
            {name}
          </Typography>
          <Typography className={classes.ellipsis} variant={mobileNumberVariant}>
            {mobileNumber}
          </Typography>
          <Typography className={classes.ellipsis} variant={textVariant}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography variant={timeVariant}>
            {time != '' ? moment(time).format('DD/MM/YY') : ''}
          </Typography>
          <Typography variant={timeVariant}>
            {time != '' ? moment(time).format('HH:mm') : ''}
          </Typography>
          {unseenMsgStatus ? (
            <FiberManualRecordIcon color="primary" />
          ) : (
            <div></div>
          )}
        </Box>
      </Box>
    </CardActionArea>
  );
};