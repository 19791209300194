import FilterOptions from "../../utils/filter-options";

class OtpColumn {
  constructor(isEditMode) {
    this.allColumns = [
      {
        field: "_",
        colId: "_",
        hide: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
    ].concat(
      allFields().map((field) => {
        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          ...otpFilters.chooseAptFilter(field.dataType),
          pinned: field.pinned ? "left" : "",
          cellRendererSelector: function (params) {
            return {
              component: "customRenderer",
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: "customEditor",
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() {
    return this.allColumns;
  }
}

const allFields = () => {
  const allColumns = [
    {
      displayName: "Mobile Number",
      name: "authId",
      sequence: 1,
      dataType: "TEXT",
      validation: null,
      aggregationAllowed: false,
      localizedDisplay: null,
      collectionName: null,
      columnName: null,
      enumClass: null,
      defaultValue: null,
      allValues: null,
      locked: true,
      hidden: false,
      pinned: false,
    },
    {
      displayName: "OTP",
      name: "otp",
      sequence: 2,
      dataType: "TEXT",
      validation: null,
      aggregationAllowed: false,
      localizedDisplay: null,
      collectionName: null,
      columnName: null,
      enumClass: null,
      defaultValue: null,
      allValues: null,
      locked: true,
      hidden: false,
      pinned: false,
    },
    {
      displayName: "Date Modified",
      name: "dateModified",
      sequence: 3,
      dataType: "DATE",
      validation: null,
      aggregationAllowed: false,
      localizedDisplay: null,
      collectionName: null,
      columnName: null,
      enumClass: null,
      defaultValue: null,
      allValues: null,
      locked: false,
      hidden: false,
      pinned: false,
    },
  ];
  return allColumns;
};

const otpFilters = new FilterOptions({ collection: "otp" });

export default OtpColumn;
