import { useState } from "react";
import { useSetRecoilState } from "recoil";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { ChatsState, TempChatUsersState } from "../../states";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";

let stompClient;
const useSocket = () => {
  const user = AuthService.getUser();
  const [messageHistory, setMessageHistory] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [currentSessionSlug, setCurrentSessionSlug] = useState("");
  const [isScrollDown, setIsScrollDown] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMsgLoaded, setIsMsgLoaded] = useState(false);

  const setChats = useSetRecoilState(ChatsState);
  const setTempUsers = useSetRecoilState(TempChatUsersState);

  const startSession = (sessionSlug) => {
    if (sessionSlug) {
      var sock = new SockJS(`${process.env.REACT_APP_API_HOST}ws`);
      stompClient = Stomp.over(sock);
      sock.onopen = function () {};
      let header = {
        "X-AUTH-TOKEN": AuthService.getToken(),
      };

      stompClient.connect(header, function (frame) {
        setTimeout(function () {
          setIsConnected(true);
          if (stompClient) {
            try {
              stompClient.subscribe(
                `/app/topic/messages/${sessionSlug}`,
                function (msg) {
                  const msgBody = JSON.parse(msg.body) || {};
                  // const filter = msgBody.filter((search)=> search.text === msgB )
                  setTotalPages(msgBody.totalPages);
                  const newMessage = msgBody.slug ? msgBody : msgBody?.content;

                  if (newMessage) {
                    setIsMsgLoaded(true);
                    if (
                      !Array.isArray(newMessage) &&
                      /PTN/gi.test(newMessage?.fromUserSlug ?? "")
                    ) {
                      setChats((prev) => {
                        const data = JSON.parse(JSON.stringify(prev));
                        const foundChat = data.find(
                          (chat) => chat.slug === sessionSlug
                        );
                        if (foundChat) {
                          foundChat.hasResolvedMessages = true;
                        }
                        return [...data];
                      });
                      setTempUsers((prev) => {
                        const data = JSON.parse(JSON.stringify(prev));
                        const foundChat = data.find(
                          (chat) => chat.slug === sessionSlug
                        );
                        if (foundChat) {
                          foundChat.hasResolvedMessages = true;
                        }
                        return [...data];
                      });
                    }
                    setMessageHistory((prev) => prev.concat(newMessage));
                  }
                },
                { id: sessionSlug }
              );
            } catch (error) {
              console.log("socket error--");
              console.log(error);
              console.log(error.stack);
              //SHOW_ERROR_NOTIFICATION("Could not connect to socket!");
            }
          }
        }, 700);
      });
    }
  };

  const loadMoreMessages = (sessionSlug) => {
    if (totalPages >= currentPage) {
      DataService.loadMoreMessages(sessionSlug, currentPage).subscribe(
        (resp) => {
          setIsMsgLoaded(true);
          setMessageHistory((prev) =>
            Array.isArray(resp.content) ? resp.content.concat(prev) : prev
          );
          setCurrentPage(currentPage + 1);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  const unsubscribe = (val) => {
    stompClient.unsubscribe(val);
    setIsMsgLoaded(false);
  };

  const sendMessage = (val) => {
    stompClient.send(
      `/app/chat/${currentSessionSlug}`,
      {},
      JSON.stringify({
        fromUserSlug: user.slug,
        text: val["msg"],
        attachmentUrl: null,
        chatSessionSlug: currentSessionSlug,
        attachmentId: val["imgUrl"],
      })
    );
  };

  return {
    startSession,
    isConnected,
    messageHistory,
    setMessageHistory,
    sendMessage,
    currentPage,
    setCurrentPage,
    totalPages,
    isScrollDown,
    setIsScrollDown,
    loadMoreMessages,
    unsubscribe,
    setCurrentSessionSlug,
    isMsgLoaded,
  };
};

export default useSocket;
