import { ADD_FORM_INPUT , DELETE_TEXT_INPUT} from "../actionType";

const initialState = {
  FormInputData: []
};

const inputFormData = (state = initialState, action) =>{
  switch (action.type) {
    case ADD_FORM_INPUT: {
      const { id, content } = action.payload;
      console.log(action.payload)
      
      return{
        ...state,
        FormInputData:[content ]
      }
    }  

    case DELETE_TEXT_INPUT :{
      const { id } = action.payload;
      return {
        ...state,
        FormInputData: [
          ...state.FormInputData.slice(0, id),
          ...state.FormInputData.slice(id + 1)
        ]
      }
    }

    default:
      return state;
  }
}

export default inputFormData ;