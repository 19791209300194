import {
    Box,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Button,
    Checkbox,
  } from "@material-ui/core";
  import React from "react";
  import { useState } from "react";
  import { SelectInput } from "sixsprints-react-ui";
  import useGlobalStyles from "../../../utils/global-style";
  import { addInputText } from "../../../redux/action/action";
  import { useDispatch } from "react-redux";
  
  import {
    DEFAULT_ERROR_CALLBACK,
    SHOW_INFO_NOTIFICATION,
    SHOW_SUCCESS_NOTIFICATION,
    SHOW_ERROR_NOTIFICATION,
    transformArrToMap,
    validateAvailSummary,
    validatePaymentOptions,
  } from "../../../utils/app-util";
  
  const useStyles = makeStyles((theme) => ({
    mb: {
      marginBottom: theme.spacing(1),
    },
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 500,
      minWidth: 375,
      width: "25%",
      height: "auto",
      background: "white",
      boxShadow: 24,
      padding: "2%",
      borderRadius: "10px",
    },
    style_1: {
      width: "100%",
      marginLeft: "2%",
      marginRight: "4%",
      backgroundColor: "white",
      borderRadius: 14,
    },
  }));
  
  const InputTel = () => {
    const classes = useStyles();
    const globalClass = useGlobalStyles();
    const dispatch = useDispatch();
  
    const [inputData, setInputData] = useState({
      label: "",
      value: "",
      type: "tel",
      placeholder: "",
      required: false,
      uniqueId:"",
    });
    const [allData, setAllData] = useState([]);
  
    console.log(inputData);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputData({ ...inputData, [name]: value });
    };
  
    const handleSubmit = () => {
      dispatch(addInputText(inputData));
      SHOW_SUCCESS_NOTIFICATION("Added successfully");
    };
  
    return (
      <>
        <Box
          style={{
            width: "100%",
            padding: "2%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
  
  <Grid style={{ marginBottom: "2%", width: "100%" }}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Unique ID
            </Typography>
            <br />
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="uniqueId"
              field="uniqueId"
              placeholder="uniqueId"
              className={classes.style_1}
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
          <Grid style={{ marginBottom: "2%", width: "100%" }}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Label
            </Typography>
            <br />
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="label"
              field="Lables"
              placeholder="Labels"
              className={classes.style_1}
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
  
          <Grid style={{ marginBottom: "2%", width: "100%" }}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Placeholder
            </Typography>
            <br />
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="placeholder"
              field="placeholder"
              placeholder="Placeholder"
              className={classes.style_1}
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
  
          <Grid style={{ marginBottom: "2%", width: "100%" }}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Required
            </Typography>
   
            <Box style={{ width: "100%" }}>
              <SelectInput
                className={globalClass.roundWhiteBg}
                options={["true", "false"]}
                initialValue={"false"}
                labelKey="label"
                valuekey="value"
                name="required"
                field="headerSpacing"
                width="100%"
                // label="Header Space Size"
                validateOnBlur
                validateOnChange
                onChange={handleChange}
              />
            </Box>
          </Grid>
  
          <Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
              onClick={handleSubmit}
            >
              Add custom Feilds
            </Button>
          </Grid>
        </Box>
      </>
    );
  };
  
  export default InputTel;
  