import React, { useState } from "react";
import GenericGrid from "../../components/grid/generic-grid";
import PendingColumn from "./pending-column";
import Api from "../../utils/api";
import useGlobalStyles from "../../utils/global-style";
import {PaymentsState} from '../../states'
import { useSetRecoilState }  from 'recoil';
import moment from 'moment';
 

const PaymentPending = () => {
  const globalClasses = useGlobalStyles();
  const setPaymentStatus = useSetRecoilState(PaymentsState)
 
  const approve = (ids) => {
    setPaymentStatus((prevState)=>{
      const newState = JSON.parse(JSON.stringify(prevState))
      newState.filter((payment)=> (payment.id === ids[0]) && (payment.status = 'CONFIRMED'))
      return [...newState]
    })
    return Api.put("/order/status/CONFIRMED", ids)
  };
  const reject = (ids) => {
    setPaymentStatus((prevState)=>{
      const newState = JSON.parse(JSON.stringify(prevState))
      newState.filter((payment)=> (payment.id === ids[0]) && (payment.status = 'REJECTED'))
      return [...newState]
    })
    return Api.put("/order/status/REJECTED", ids);
  };

  const user = JSON.parse(localStorage.getItem("USER"));

  let filter = moment().startOf('isoWeek').valueOf();
  
  let filterTo = moment(filter).add(7, 'days').valueOf();
  
  // const defaultFilter = {
  //    doctorSlug: { filterType: 'set', values: [`${user.slug}`]} ,
  // }

  let defaultFilter = "" ;

  if((user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN")){
      defaultFilter = {doctorSlug: { filterType: 'set', values: [`${user.slug}`]} }
  }
  else{
    defaultFilter = {doctorSlug: { filterType: 'set', values: []} }
  }

  const [toggle, setToggle] = useState(false);
  const [patientObj, setPatientObj] = useState({});
  
  const onToggleMode = () => {
    setToggle(!toggle);
  };

  const onTransactionIdClick = (val) => {
    setPatientObj(val);
    onToggleMode()
  };

  const onbillbuttonBtnClicked = (val) => {
    console.log("dataatttt",val);
  };


  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        onTransactionIdClick={onTransactionIdClick}
        baseUrl="order"
        hideToggleEditMode
        hideGlobalSearch
        hideExport={false}
        onbillbuttonBtnClicked={onbillbuttonBtnClicked}
        hideOnCopy={true}
        hideAddNew
        hideOnUpload
        columnDefGenerator={PendingColumn}
        title="All Payments"
        csvName="payment"
        suppressAutoFit
        defaultFilter={defaultFilter}
        customActions={[
          { onClick: approve, label: "Approve" },
          { onClick: reject, label: "Reject", danger: true },
        ]}
      />
    </div>
  );
}; 

export default PaymentPending;
