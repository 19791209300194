// TODO: pick from localstorage
const userInitialState = {}

export const userReducer = (state = userInitialState, action) => {
    switch(action.type) {
        case 'USER_LOGIN':
            return action.payload
        case 'USER_LOGOUT': 
            return {}
        default:
            return state;
    }
}