import React from "react";
import QrReader from "react-qr-reader";

const QrScanner = () => {
  const handleScan = (data) => {
    if (data) {
      window.location.replace(data);
    }
  };

  const handleError = (error) => {};

  return (
    <div>
      {/* <QrReader
        delay={1000}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      /> */}
    </div>
  );
};

export default QrScanner;
