import {
  Box,
  Button, Grid,
  makeStyles,
  Paper, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { useCallback } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Axios from "axios";
import AppConstants from "../../utils/app-constants";
import {
  SHOW_ERROR_NOTIFICATION
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import { NotificationManager } from "react-notifications";
import EditIcon from "@material-ui/icons/Edit";
import Loader from "../../components/misc/loader";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: theme.primary,
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  previewProfilePic: {
    minWidth: "50%",

  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: theme.primary,
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding:'1.8%',
    //border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  logoContainer: {
    display: "flex",
  },
  addressgrid: {
    display: "flex",
    gap: "5%",
    marginBottom: "5px",
    gridTemplateColumns:
      "repeat(auto-fill, minmax(10rem, 1fr)) 100px repeat(3, 200px)",
    justifyContent: "spa",
  },
  select: {
    width: "100%",
    border: "1px solid #b4b0b0",
    padding: "13px",
    marginTop: "8px",
    minWidth: "80px",
    borderRadius: "10px",
  },
}));

const Website = (props) => {
  const { respo } = props;

  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
    ? AuthService.getUser()
    : {};

  const [docData, setDocData] = useState(userObj);
  const [socialMediaInfo, setSocialMediaInfo] = useState(null);
  const formApi = useRef();
  const presFileRef = useRef(null);
  const [qrIndex, setQrIndex] = useState(null);
  const [paymentModes, setPaymentModes] = useState([]);
  const [profileImg, setProfileImg] = useState(null);
  // const [initialValues, setInitialValues] = useState([]);
  const [paymentInitialValues, setPaymentInitialValues] = useState([]);
  const [docSlug, setDocSlug] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [speciality, setSpeciality] = useState(null);
  const [signImage, setSignImage] = useState(null);
  const weekDays = ["MONDAY", "TUESDAY", "WEDNESSDAY", "THURSDAY", "FRIDAY"];
  const Social = ["SocialPlatform", "Links"];
  const [profileSettings, setProfileSettings] = useState(null);

  const [appointmentTypes, setAppointmentTypes] = useState([
    {
      label: "Social Platform",
      active: false,
      disclaimer: "",
    },
    {
      label: "Links",
      active: false,
      disclaimer: "",
    },
  ]);

  const [socialMediaArr, setSocialMediaArr] = useState([
    {
      name: "FACEBOOK",
      link: "",
      id: 1,
    },
    {
      name: "INSTAGRAM",
      link: "",
      id: 2,
    },
    {
      name: "LINKEDIN",
      link: "",
      id: 3,
    },
    {
      name: "WEBSITE",
      link: "",
      id: 4,
    },
    {
      name: "TWITTER",
      link: "",
      id: 5,
    },
  ]);

  // const socialMediaArr =["FACEBOOK" , "INSTAGRAM" ,  "LINKEDIN" , "WEBSITE" , "TWITTER"]


  const handleChangeSettingDetails = (event) => {
    var events = event.values;
    events.signature = signImage;
    setProfileSettings(events);
  };

  // this needs to be moved to a comman file in future

  // const handleDebounce = (changeFunction, delay) => {
  //   let timer;
  //   return function () {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       settingsFormData.current && changeFunction(settingsFormData.current);
  //     }, delay);
  //   };
  // };

  const handleSubmitExtraFields = useCallback(
    (value) => {
      if (userObj.id) {
        Axios.post(
          `${process.env.REACT_APP_API2_HOST}user-settings`,
          value
        ).catch(() => {
          NotificationManager.error(AppConstants.MSG.GENERIC_ERROR);
        });
      }
    },
    [userObj]
  );

  // ------------------------//
  // ------------------------//
  // ------------------------//

  const [data, setData] = useState({
    title: "",
    supportEmail: "",
    contact: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    fontfamily: "",
    uhidPrefix: "",
    adminurl: "",
    websiteurl: "",
    id: "",
    logo: "",
    social_link: "",
    onlineFoodLink: "",
    currency:""
  });

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log("test_name",event.target.name ,event.target.value)
  };
  
  


  const [imgData, setImgData] = useState(respo?.logo);
  const [picture, setPicture] = useState(respo?.logo);

  const [faviconData, setFaviconData] = useState(respo?.favicon);
  const [faviconImage, setFaviconImage] = useState(respo?.favicon);

  const [logoStatus, setLogoStatus] = useState(false);
  const [faviState, setFaviState] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    const name = e.target.name;
    if (name === "logo") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
          setLogoStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "favicon") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setFaviconImage(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setFaviconData(reader.result);
          setFaviState(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const [SocialTitle, setSocialTitle] = useState("");
  const [url, setUrl] = useState("");

  const [initialValues, setInitialValues] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState({
    aboutus: "",
  });
  const contentFieldChanged = (data) => {
    setContent({ ...content, aboutus: data });
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setLoading(true)
    setData({
      title: respo?.title,
      supportEmail: respo?.support_email,
      contact: respo?.support_phone,
      address1: respo?.address,
      address2: respo?.address2,
      city: respo?.city,
      state: respo?.state,
      country: respo?.country,
      pincode: respo?.pincode,
      uhidPrefix: respo?.uhid_prefix,
      adminurl: respo?.admin_url,
      websiteurl: respo?.website_url,
      id: respo?.id,
      logo: respo?.logo,
      favicon: respo?.favicon,
      social_link: respo?.social_links,
      onlineFoodLink: respo?.onlineFoodLink,
      currency:respo?.currency

    });
    setInitialValues(respo?.social_links)

  }, [respo]);

  const SocialHandleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = [...initialValues];
    if (e.target.name == "name") {
      values[i].link = e.target.value;
    }
    else if (e.target.name == "url") {
      values[i].url = e.target.value;
    }
    setInitialValues(values);
  };

  const urlHandleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setUrl(e.target.value);
  };

  const addRow = async () => {
    let cin = initialValues.length;
    let ain = socialMediaArr.length;
    if (cin < ain) {


      await setInitialValues((prev) => [
        ...prev,
        {
          link: socialMediaArr[0].name || "",
          url: url,
        },

      ]);
    }

  };

  const onRemoveRow = (idx) => {
    const values = [...initialValues];
    values.splice(idx, 1);
    for (let i = 0; i < values.length; i++) { }
    setInitialValues(values || []);
    // handleKeyUp();
  };

  const handleSubmit = async (event) => {
    // console.log("test_event",event); 
    // if (event) {
    //   event.preventDefault();
    // }

    const req = {
      title: data.title,
      support_email: data.supportEmail,
      support_phone: data.contact,
      address: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      country: data.country,
      pincode: data.pincode,
      uhid_prefix: data.uhidPrefix,
      admin_url: data.adminurl,
      website_url: data.websiteurl,
      type: "websitedata",
      id: data.id,
      social_link: initialValues,
      onlineFoodLink: data.onlineFoodLink,
      currency:data.currency
    };

    if (
      data.social_link == { link: "", url: "" } ||
      data.title == "" ||
      data.supportEmail == "" ||
      data.id == "" ||
      data.websiteurl == "" ||
      data.supportEmail == "" ||
      data.contact == "" ||
      data.address1 == "" ||
      data.address2 == "" ||
      data.city == "" ||
      data.state == "" ||
      data.country == "" ||
      data.pincode == "" ||
      data.uhidPrefix == "" ||
      data.adminurl == "" ||
      data.websiteurl == "" ||
      data.onlineFoodLink == ""
      
    ) {
      SHOW_ERROR_NOTIFICATION("All feild required");
    } else {
      try {

        setLoadingStatus({
          logoLodingStatus: false,
          faviconLoadingStatus: false,
          formLoadingStatus: true,
        })

        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}websiteupdate`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });


        if (response.data.toString() == "succss") {
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus: false,
            formLoadingStatus: false,
          })
        } else {
          NotificationManager.success("Something went wrong Please try again");
        }
      } catch (error) {
        setLoadingStatus({
          logoLodingStatus: false,
          faviconLoadingStatus: false,
          formLoadingStatus: false,
        })
        console.log(error);
      }
    }
  };

  // const handleKeyUp = useCallback(handleDebounce(addRow, 10000), []);

  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
    faviconLoadingStatus: false,
    formLoadingStatus: false,
  })

  const handleSaveImage = async (type) => {
    if (type == "logo") {

      setLoadingStatus({
        logoLodingStatus: true,
        faviconLoadingStatus: false,
        formLoadingStatus: false,
      })

      const formData = new FormData();
      formData.append("file", picture);
      formData.append("type", type);
      formData.append("website_id", respo.id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/imageuploadbase`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {
          if (response.data.message.toString() === "successfully") {
            setLogoStatus(false);
            setLoadingStatus({
              logoLodingStatus: false,
              faviconLoadingStatus: false,
              formLoadingStatus: false,
            })
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          } else {
            NotificationManager.error(
              "Something went wrong Please try again"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (type == "favicon") {
      setLoadingStatus({
        logoLodingStatus: false,
        faviconLoadingStatus: true,
        formLoadingStatus: false,
      })

      const formData = new FormData();
      formData.append("file", faviconImage);
      formData.append("type", type);
      formData.append("website_id", respo.id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/imageuploadbase`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {
          if (response.data.toString() == "successfully") {
            setFaviState(false);
            setLoadingStatus({
              logoLodingStatus: false,
              faviconLoadingStatus: false,
              formLoadingStatus: false,
            })
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          } else {
            NotificationManager.success(
              "Something went wrong Please try again"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }



  };

  return (
    <div>
      <Header title="Website Setting" />
      <Form
        apiRef={formApi}
        onSubmit={handleSubmit}
        // onChange={handleChangeSettingDetails}
        // onKeyUp={handleKeyUp}
        initialValues={{
          availSummary: initialValues,
          paymentOptions: [
            {
              payTo: "",
              qrCode: "",
              description: "",
            },
          ],
        }}
      >
        <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
          <Grid className={classes.logoContainer}>
            <Box className={classes.prescriptionSection}>
              <Box
                mt="2rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Logo
                </Typography>
              </Box>
              <form method="post">
                <div style={{ minWidth: '100%' }}>
                  <div class="col-12">
                    <input
                      type="file"
                      placeholder="favicon"
                      name="logo"
                      class="form-control"
                      id="logo"
                      autocomplete="off"
                      onChange={handleInputs}
                      style={{ visibility: "hidden" }}
                    />
                    <div className="previewProfilePic">
                      {loading ? (<Loader width="150px" height="15px" color="primary" />) : (
                        <img
                          className="playerProfilePic_home_tile"
                          src={imgData || respo?.logo}
                          alt="logo"
                          width="160"

                        />
                      )}
                    </div>
                  </div>
                </div>
                <br />
                {logoStatus ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => handleSaveImage("logo")}
                  >
                    {lodingStatus.logoLodingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
                  </Button>
                ) : (
                  <label htmlFor="logo">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                    >
                      <EditIcon /> &nbsp; Edit
                    </Button>
                  </label>
                )}
                &nbsp;
              </form>
            </Box>

            <Box className={classes.prescriptionSection}>
              <Box
                mt="2rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  favicon
                </Typography>
              </Box>
              <form method="post">
                <div class="col-12">
                  <input
                    type="file"
                    placeholder="favicon"
                    name="favicon"
                    class="form-control"
                    id="favico"
                    autocomplete="off"
                    onChange={handleInputs}
                    style={{ visibility: "hidden" }}
                  />

                  <div className="previewProfilePic">
                    {loading ? (<Loader width="150px" height="15px" color="primary" />) : (
                      <img
                        className="playerProfilePic_home_tile"
                        src={faviconData || respo?.favicon}
                        alt="logo"
                        width="160"

                      />
                    )}
                  </div>
                </div>
                <br />
                {faviState ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => handleSaveImage("favicon")}
                  >
                    {lodingStatus.faviconLoadingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
                  </Button>
                ) : (
                  <label htmlFor="favico">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                    >
                      <EditIcon /> &nbsp; Edit
                    </Button>
                  </label>
                )}
                &nbsp;
              </form>
            </Box>
          </Grid>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Title
              </Typography>
            </Box>

            <Grid>
              <TextField
                //id="outlined-basic"
                label=""
                variant="outlined"
                name="title"
                field="title"
                placeholder="title"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.title}
              />
            </Grid>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Support Email
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="supportEmail"
                field="supportEmail"
                placeholder="Type here"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.supportEmail || ""}
              />
            </Grid>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Contact Number
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                field="ContactNumber"
                placeholder="Contact Number"
                type="number"
                className={classes.style_2}
                name="contact"
                onChange={onChangeData}
                value={data.contact || ""}
              />
            </Grid>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Address Line 1
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="address1"
                field="address1"
                placeholder="Address"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.address1 || ""}
              />
            </Grid>

            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Address Line 2
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="address2"
                field="address2"
                placeholder="Address"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.address2 || ""}
              />
            </Grid>

            <Box className={classes.addressgrid}>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    City
                  </Typography>
                </Box>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    name="city"
                    field="city"
                    placeholder="City"
                    className={classes.style_2}
                    onChange={onChangeData}
                    value={data.city || ""}
                  />
                </Grid>
              </Box>

              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    State
                  </Typography>
                </Box>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    name="state"
                    field="state"
                    placeholder="State"
                    className={classes.style_2}
                    onChange={onChangeData}
                    value={data.state || ""}
                  />
                </Grid>
              </Box>

              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"

                >
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Country
                  </Typography>
                </Box>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    name="country"
                    field="title"
                    placeholder="Country"
                    className={classes.style_2}
                    onChange={onChangeData}
                    value={data.country || ""}
                  />
                </Grid>
              </Box>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Pincode
                  </Typography>
                </Box>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    name="pincode"
                    field="title"
                    placeholder="Pincode"
                    className={classes.style_2}
                    onChange={onChangeData}
                    value={data.pincode || ""}
                  />
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                UHID Prefix
              </Typography>
            </Box>
            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="uhidPrefix"
                field="title"
                placeholder="UHID Prefix"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.uhidPrefix}
              />
            </Grid>
          </Box>

          <Box>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Website URL
              </Typography>
            </Box>
            <Grid>
              <TextField
                disabled

                id="outlined-basic"
                label=""
                variant="outlined"
                name="websiteurl"
                field="title"
                placeholder="Website URL"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.websiteurl || ""}

              />
            </Grid>
          </Box>

          <Box>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Admin URL
              </Typography>
            </Box>
            <Grid>
              <TextField
                disabled
                id="outlined-basic"
                label=""
                variant="outlined"
                name="adminurl"
                field="title"
                placeholder="Admin URL"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.adminurl}

              />
            </Grid>
          </Box>
          <Box>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Online Food Links
              </Typography>
            </Box>
            <Grid>
              <TextField
                // disabled
                id="outlined-basic"
                label=""
                variant="outlined"
                name="onlineFoodLink"
                field="title"
                placeholder="Online Food Link"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.onlineFoodLink}

              />
            </Grid>
          </Box>
          <Box>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Currency
              </Typography>
            </Box>
            <Grid>
              <TextField
                // disabled
                id="outlined-basic"
                label=""
                variant="outlined"
                name="currency"
                field="title"
                placeholder="Currency"
                className={classes.style_2}
                onChange={onChangeData}
                value={data.currency}

              />
            </Grid>
          </Box>
         

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Social Links
              </Typography>
            </Box>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <Box className={classes.prescriptionSection}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={addRow}
                >
                  ADD
                </Button>
              </Box>

              <TableContainer>
                <Table
                  aria-label="simple table"
                  id="summaryRow"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Social Plateform</strong>
                      </TableCell>
                      <TableCell>
                        <strong>link</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {initialValues?.map((item, i) => {

                      return (
                        <Scope scope={`availSummary[${i}]`} key={i}>
                          <TableRow key={i}>
                            <TableCell>
                              <Box display="flex" flexDirection="column">

                                <Select
                                  labelId="demo-simple-select-label"
                                  id={`demo-simple-select-${i}`}
                                  name="name"
                                  value={item.link}
                                  onChange={(e) => SocialHandleChange(e, i)}
                                >
                                  {
                                    socialMediaArr.map((value) => {
                                      let selected = "false"
                                      if (item?.link == value.name) {
                                        selected = "true";
                                      }
                                      return (
                                        <MenuItem value={value.name} selected={selected}>{value.name} </MenuItem>
                                      )
                                    })
                                  }
                                </Select>

                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box display="flex" flexDirection="column">
                                <TextInput
                                  field="Name"
                                  placeholder="URL"
                                  containerClassName="Name"
                                  label="Name"
                                  type="text"
                                  value={item.url}
                                  validateOnBlur
                                  validateOnChange
                                  name="url"
                                  onChange={(e) => SocialHandleChange(e, i)}
                                  className={classes.style_1}
                                />
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => onRemoveRow(i)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Scope>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <Box mb={3} style={{ marginTop: "2%" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
            >
              {lodingStatus.formLoadingStatus ? <Loader width="20px" height="20px" color="primary" /> : "SAVE"}
            </Button>
          </Box>
        </div>
      </Form>
    </div>
  );
};

export default Website;
