import Bro from "brototype";
import Api from "./api";

const MasterDataFilter = {
  fetch(params, collection, filters) {
    let field = Bro(params).iCanHaz("colDef.field");
    let col = collection;
    Api.post(encodeURI(`/${col}/column/master?column=${field}`), {
      filterModel: filters || {}
    }).subscribe(resp => {
      params.success(resp.data.data);
    });
  }
};

export default MasterDataFilter;
