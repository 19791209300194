import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { useMediaQuery } from "@mui/material";

function useSidebar() {
  const isDesktop = useMediaQuery("(min-width: 1279px)");
  // if desktop, open by default with width as 240
  const [openSidebar, setOpenSidebar] = useState(isDesktop);
  const [sidebarWidth, setSidebarWidth] = useState(isDesktop ? 240 : 0);

  useEffect(() => {
    setOpenSidebar(isDesktop);
    setSidebarWidth(isDesktop ? 240 : 0);
  }, [isDesktop]);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
    setSidebarWidth(240);
  };

  const handleSidebarClose = () => {
    if (isDesktop) setSidebarWidth(50);
    else setOpenSidebar(false);
  };

  // if any menu item is clicked
  const handleItemClick = () => {
    if (!isDesktop) {
      handleSidebarClose();
    }
  };

  // reset the sidebar incase of logout
  const handleResetSidebarState = () => {
    setOpenSidebar(isDesktop);
    setSidebarWidth(isDesktop ? 240 : 0);
  };

  return {
    openSidebar,
    sidebarWidth,
    handleSidebarOpen,
    handleSidebarClose,
    handleItemClick,
    handleResetSidebarState
  };
}

export const SidebarContext = createContainer(useSidebar);
