import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Paper,
  Button,
  CircularProgress,
  Typography,
  Dialog,
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MiniHeader from "../dashboard/mini-header";
import TextItem from "../../components/layout/text-item";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import Api from "../../utils/api";
import {
  DEFAULT_ERROR_CALLBACK,
  getTransformArr,
  getFormattedDate,
} from "../../utils/app-util";
import DataService from "../../utils/services/data-service";
import Routes from "../../routes/routes";
import TreatmentSummery from "./treatmentSummery";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  drawer: {
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.bg,
    marginTop: "-10px",
  },
  my: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "97%",
    margin: "auto",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportCard: {
    margin: "2px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReport: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  closeContainer: {
    display: "flex",
  },
  closeHeading: {
    fontSize: "12px",
  },
  reportTitle: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },
}));

const SidebarContent = ({
  data,
  quesValue,
  onResolvedBtnClicked,
  onUnResolvedBtnClicked,
}) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [presHistory, setPresHistory] = useState([]);
  const [patientReports, setPatientReports] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectSingleImage, setSelectSingleImage] = useState(null)
  const [reportType, setReportType] = useState(null);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchPatientPresHistory = useCallback(() => {
    if (data) {
      setIsLoading(true);
      DataService.getPatientAppointment(data?.patient?.slug).subscribe(
        (resp) => {
          setPresHistory(getTransformArr(resp?.data));
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  }, [data]);

  const fetchReports = useCallback(() => {
    DataService.fetchList("report/search", 0, 100, {
      patientSlug: {
        values: [data && data.patientSlug],
        filterType: "set",
      },
    }).subscribe((resp) => {
      const reports = [...resp.content];
      const newestReports = reports.reverse();
      setPatientReports(newestReports);
    });
  }, [data]);

  useEffect(fetchReports, [data]);
  useEffect(fetchPatientPresHistory, [data]);

  const handleSelectedReport = (report) => {
    setSelectedReport(report);
    Api.get(`report/${report.slug}`).subscribe(
      (resp) => {

        setSelectSingleImage(resp.data.data)
        setOpenReport(true);
        if (resp.data.data?.images) {
          let fileType = null;
          fileType = resp.data.data.images.map((data) => data.contenttype);
          setReportType(fileType);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );


    // if(report?.images?.[0]){
    //   let fileType = null;
    //   fileType = report.images[0].split(".").pop()
    //   setReportType(fileType);
    // }


  };

  const handleCloseReport = useCallback(() => {
    setOpenReport(false);
  }, []);

  const handleImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  return (
    <Box className={classes.drawer}>
      {data && (
        <>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Contact Info
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div>
                  <Paper className={classes.containerBox}>
                    <Box flexWrap="wrap">
                      <TextItem
                        className={classes.my}
                        strongValue
                        labelVariant="subtitle1"
                        valueVariant="body2"
                        label="Email Address"
                        value={data.patient?.email ? data.patient.email : "NA"}
                      />
                      <TextItem
                        className={classes.my}
                        strongValue
                        labelVariant="subtitle1"
                        valueVariant="body2"
                        label="UHID"
                        value={data?.patient?.uhid ? data.patient.uhid : "NA"}
                      />
                      <TextItem
                        className={classes.my}
                        strongValue
                        labelVariant="subtitle1"
                        valueVariant="body2"
                        label="Phone Number"
                        value={data?.patient?.mobileNumber}
                      />
                    </Box>
                  </Paper>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFifth">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifth" aria-expanded="false" aria-controls="collapseFifth">
                  Treatment Summary
                </button>
              </h2>
              <div id="collapseFifth" className="accordion-collapse collapse" aria-labelledby="headingFifth" data-bs-parent="#accordionExample">
                <div>
                  <TreatmentSummery patientSlug={data?.patient?.slug} />
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Prescription(s)
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div>
                  <Grid container item>
                    <Paper className={classes.containerBox}>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "2rem",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            className={classes.loaderStyle}
                            size={20}
                          />
                        </div>
                      ) : presHistory?.length > 0 ? (
                        <Box className={clsx(classes.presBox, globalClass.srollBar)}>
                          {presHistory.map((data, idx) => (
                            <div style={{ margin: "2px" }} key={idx}>
                              <a
                                href={
                                  `/#!` +
                                  Routes.PRESCRIPTION +
                                  `?slug=${data.appointmentSlug}`
                                }
                                className={classes.title}
                                // eslint-disable-next-line react/jsx-no-target-blank
                                target="_blank"
                              >
                                {getFormattedDate(data.appointmentDate)}
                              </a>
                            </div>
                          ))}
                        </Box>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">
                            No Prescription Data Found
                          </Typography>
                        </div>
                      )}
                    </Paper>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Question(s) Remaining
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div >
                  <Grid container item>
                    <Paper className={classes.containerBox}>
                      <TextItem
                        className={classes.my}
                        labelVariant="subtitle1"
                        valueVariant="h5"
                        label="Question(s) Remaining"
                        value={quesValue}
                      />
                      <Box display="flex" justifyContent="center">
                        <Button
                          disabled={data.totalQuestions === 0}
                          onClick={onResolvedBtnClicked}
                          variant="contained"
                          color="primary"
                          disableElevation
                          style={{ marginRight: "8px" }}
                        >
                          Mark Resolved
                        </Button>
                        <Button
                          // disabled={data.totalQuestions === 0 || data.totalDays === 0}
                          onClick={onUnResolvedBtnClicked}
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          Add Extra Question
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Report(s)
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div >
                  <Grid container item>
                    <Paper className={classes.containerBox}>
                      <MiniHeader title="Report(s)" />
                      {patientReports.length > 0 ? (
                        <Box className={clsx(classes.presBox, globalClass.srollBar)}>
                          {patientReports.map((data) => (
                            <div
                              className={classes.reportCard}
                              key={data.id}
                              onClick={() => handleSelectedReport(data)}
                            >
                              <h4 className={classes.title}>
                                {`${data.reportName ?? ""} / (${getFormattedDate(data.reportDate, "Do MMMM YYYY") ??
                                  ""
                                  })`}
                              </h4>
                            </div>
                          ))}
                        </Box>
                      ) : (
                        <div className={classes.noReport}>
                          <Typography variant="h6">No Reports Found</Typography>
                        </div>
                      )}
                    </Paper>
                  </Grid>
                </div>
              </div>
            </div>
          </div>


          {/* <Grid container>
            <Paper className={classes.containerBox}>
              <MiniHeader title="Contact Info" />
              <Box flexWrap="wrap">
                <TextItem
                  className={classes.my}
                  strongValue
                  labelVariant="subtitle1"
                  valueVariant="body2"
                  label="Email Address"
                  value={data.patient?.email ? data.patient.email : "NA"}
                />
                <TextItem
                  className={classes.my}
                  strongValue
                  labelVariant="subtitle1"
                  valueVariant="body2"
                  label="UHID"
                  value={data?.patient?.uhid ? data.patient.uhid : "NA"}
                />
                <TextItem
                  className={classes.my}
                  strongValue
                  labelVariant="subtitle1"
                  valueVariant="body2"
                  label="Phone Number"
                  value={data?.patient?.mobileNumber}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid container item>
            <Paper className={classes.containerBox}>
              <MiniHeader title="Prescription(s)" />
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2rem",
                  }}
                >
                  <CircularProgress
                    disableShrink
                    className={classes.loaderStyle}
                    size={20}
                  />
                </div>
              ) : presHistory?.length > 0 ? (
                <Box className={clsx(classes.presBox, globalClass.srollBar)}>
                  {presHistory.map((data, idx) => (
                    <div style={{ margin: "2px" }} key={idx}>
                      <a
                        href={
                          `/#!` +
                          Routes.PRESCRIPTION +
                          `?slug=${data.appointmentSlug}`
                        }
                        className={classes.title}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                      >
                        {getFormattedDate(data.appointmentDate)}
                      </a>
                    </div>
                  ))}
                </Box>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    No Prescription Data Found
                  </Typography>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid container item>
            <Paper className={classes.containerBox}>
              <TextItem
                className={classes.my}
                labelVariant="subtitle1"
                valueVariant="h5"
                label="Question(s) Remaining"
                value={quesValue}
              />
              <Box display="flex" justifyContent="center">
                <Button
                  disabled={data.totalQuestions === 0}
                  onClick={onResolvedBtnClicked}
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginRight: "8px" }}
                >
                  Mark Resolved
                </Button>
                <Button
                  // disabled={data.totalQuestions === 0 || data.totalDays === 0}
                  onClick={onUnResolvedBtnClicked}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Add Extra Question
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid container item>
            <Paper className={classes.containerBox}>
              <MiniHeader title="Report(s)" />
              {patientReports.length > 0 ? (
                <Box className={clsx(classes.presBox, globalClass.srollBar)}>
                  {patientReports.map((data) => (
                    <div
                      className={classes.reportCard}
                      key={data.id}
                      onClick={() => handleSelectedReport(data)}
                    >
                      <h4 className={classes.title}>
                        {`${data.reportName ?? ""} / (${
                          getFormattedDate(data.reportDate, "Do MMMM YYYY") ??
                          ""
                        })`}
                      </h4>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className={classes.noReport}>
                  <Typography variant="h6">No Reports Found</Typography>
                </div>
              )}
            </Paper>
          </Grid> */}
          <Dialog
            className={classes.dialogBackground}
            fullScreen
            open={openReport}
            onClose={handleCloseReport}
          >
            <div className={classes.closeContainer}>
              <div className={classes.reportClose} onClick={handleCloseReport}>
                <p className={classes.closeHeading} style={{ marginTop: "14px" }}>Close</p>
              </div>
              <div className={classes.reportTitle}>
                <span>
                  {`${selectSingleImage?.reportName ?? ""} / ${getFormattedDate(
                    selectSingleImage?.reportDate,
                    "Do MMMM YYYY"
                  ) ?? ""
                    }/ 
                  Value: ${selectSingleImage?.value ?? ""}`}
                </span>
              </div>
            </div>
            {selectSingleImage?.images ? (
              selectSingleImage?.images?.length === 1 ? (
                selectSingleImage?.images[0].contenttype === "application/pdf" ? (
                  <iframe
                    title="Report picture"
                    src={selectSingleImage.images[0].content}
                    alt="Report"
                    className={classes.reportImage}
                    onClick={() =>
                      handleImageClick(selectSingleImage.images[0].content)
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    title="Report picture"
                    src={selectSingleImage.images[0].content}
                    alt="Report"
                    className={classes.reportImage}
                    onClick={() =>
                      handleImageClick(selectSingleImage.images[0].content)
                    }
                    style={{ cursor: "pointer" }}
                  />
                )
              ) : (
                <div style={{}} className={classes.multipleImageContainer}>
                  <Slider {...settings}>
                    {selectSingleImage.images.map((im, index) => {


                      return (
                        <div key={index} style={{ width: "100%", height: "90vh" }}>
                          {im?.contenttype === "application/pdf" ? (
                            <iframe
                              title="Report picture"
                              src={im.content}
                              alt="Report"
                              className={classes.reportImage}
                              onClick={() => handleImageClick(im?.content)}
                              style={{ cursor: "pointer", width: "100%", height: "90vh" }}
                            />
                          ) : (
                            <img
                              title="Report picture"
                              src={im?.content}
                              alt="Report"
                              className={classes.reportMultipleImage}
                              onClick={() => handleImageClick(im?.content)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )
            ) : (
              <div className={classes.noReportImage}>
                Report image is not available.
              </div>
            )}
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default SidebarContent;
