import React from "react";
import { Box, Button, Typography } from "@material-ui/core";

const MiniHeader = ({ title, totalApmnt, handleClick }) => {
  return (
    <Box my={2} display="flex" justifyContent="space-between">
      <Typography variant="h5">{title} {totalApmnt > 0 ? `: ${totalApmnt}` : null}</Typography>
      {handleClick && (
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={handleClick}>View</Button>
      )}
    </Box>
  );
};
export default MiniHeader;