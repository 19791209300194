import { useState } from "react";
import { createContainer } from "unstated-next";
import DataService from "../utils/services/data-service";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
} from "../utils/app-util";
import AppConstants from "../utils/app-constants";

function useAppointment() {
  const [currentAptmnt, setCurrentAptmnt] = useState([]);
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);

  const toggleSideTab = (val) => {
    if (!isSideTabOpen) {
      setCurrentAptmnt(val);
    }
    setIsSideTabOpen(!isSideTabOpen);
  };

  const onQueueUpdateClicked = (e, row) => {
    if (e.target.value) {
      const newRow = JSON.parse(JSON.stringify(row))
      newRow.queue = e.target.value
      DataService.updateData(`appointment?propChanged=queue`, newRow).subscribe(
        () => {
          SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  return {
    toggleSideTab,
    isSideTabOpen,
    currentAptmnt,
    onQueueUpdateClicked
  };
}
export const AppointmentContext = createContainer(useAppointment);
