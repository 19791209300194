import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../utils/api";
import "./receipt.css";
import moment from "moment";
import Loader from "../../components/misc/loader";
import Routes from "../../routes/routes";

const Receipt = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const slug = searchParams.get('slug');

  const styles = {
    main: {
      background: "white !important"
    }
  };

  const data = (props.location && props.location.state) || {};
  const printDuplicateCopy = data?.copy;

  const [invoice, setInvoice] = useState({});
  const [fetchbillingData, setfetchbillingdata] = useState([])
  const [receivedAmount1, setreceivedAmount] = useState()
  const [totalBill, settotalBill] = useState()
  const [pendingamount, setpendingAmount] = useState()
  const [patientSlug, setpatientSlug] = useState()
  const [dateCreated, setDateCreated] = useState()

  var today = new Date(),
    currentdate =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();

  var currenttime = today.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const renderfetch = async () => {
    try {
      if (data) {
        if (data?.custom) {
          if (slug || slug !== '') {
            await Api.get(`appointment/receipt/print/${data?.slug}`).subscribe((resp) => {
              let totalf = resp.data.data?.appointment?.fees - resp.data.data?.appointment?.discount
              settotalBill(totalf);
              setInvoice(resp?.data?.data);
            });
          }
          else {
            window.open(`/#!` + Routes.APPOINTMENT)
          }
        }
        else {
          await Api.get(`appointment/receipt/print/${data?.slug}`).subscribe((resp1) => {
            setInvoice(resp1?.data?.data);
          });
          await Api.get(`order/appointment/${data?.slug}`).subscribe((resp) => {
            setfetchbillingdata(resp?.data?.data?.billings)
            setreceivedAmount(resp?.data?.data?.receivedAmount)
            settotalBill(resp?.data?.data?.totalBill.toFixed(2))
            setpendingAmount(resp?.data?.data?.pendingAmount)
            setpatientSlug(resp?.data?.data?.patientSlug)
            setDateCreated(resp?.data?.data?.dateCreated)
          })
        }
      }
      else {
        window.open(`/#!` + Routes.APPOINTMENT)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const PrintReceipt = () => {
    window.print();
  };

  useEffect(() => {
    renderfetch()
  }, [])

  return (
    <>
      {invoice ? (
        <>
          <div className="invoice-container">
            <div className="invoice-header">
              <img
                src={`data:${invoice?.appointment?.doctor?.topLetterHead?.contenttype};base64,${invoice?.appointment?.doctor?.topLetterHead?.data.data}`}
                alt="header"
                style={{ width: "100%", height: "80%" }}
              />
            </div>
            <div className="invoice-details">
              <div className="patient-info">
                <h5>INVOICE TO</h5>
                <p>
                  <b>{invoice?.patient?.name}</b> <br />
                  <small>UHID: {invoice?.patient?.uhid}</small>
                </p>
                {data.custom && (
                  <p>Payment Method: {invoice?.appointment?.mode}</p>
                )}
              </div>
              <div className="invoice-summary">
                <div className="invoice-summary-row">
                  <div>
                    <p><b>NO.</b></p>
                    <p>#{data.slug}</p>
                  </div>
                  <div>
                    <p><b>Date:</b></p>
                    <p>{data.receiptDate.format("DD-MM-YYYY")}</p>
                  </div>
                </div>
                <div className="total-price">
                  TOTAL INR: {data?.receiptData?.fee - data?.receiptData?.discount}
                </div>
              </div>
            </div>
            <div className="invoice-items">
              <div className="invoice-item header">
                <div>S.No</div>
                <div>ITEM DESCRIPTION</div>
                <div>QTY</div>
                <div>PRICE</div>
                <div>TOTAL</div>
              </div>
              {data.custom ? (
                <>
                  <div className="invoice-item">
                    <div>1</div>
                    <div>{data?.receiptData?.itemDescription}</div>
                    <div>1</div>
                    <div>INR {data.receiptData?.fee}</div>
                    <div>INR {data.receiptData?.fee}</div>
                  </div>
                  {data.receiptData.discount && data.receiptData?.discount > 0 && (
                    <div className="invoice-item">
                      <div>2</div>
                      <div>Discount</div>
                      <div>1</div>
                      <div>INR {data.receiptData?.discount}</div>
                      <div>INR {data.receiptData?.discount}</div>
                    </div>
                  )}
                </>
              ) : (
                fetchbillingData?.map((row, index) => (
                  <div className="invoice-item" key={index}>
                    <div>{index + 1}</div>
                    <div>
                      {row.activityName}
                      <br />
                      Date: {moment(row?.billingDateTime).format("DD-MM-YYYY HH:mm:ss")}
                      <br />
                      {row?.discountValue > 0 && (
                        <>
                          Discount: {row.discountType === "percentage" 
                            ? `${row.discount} (${row?.discountValue}%)`
                            : row?.discountValue}
                        </>
                      )}
                    </div>
                    <div>{row.type === "credit" ? `${row.number}x` : ""}</div>
                    <div>{row.type === "credit" ? row.price : ""}</div>
                    <div>
                      ({row.type === "credit" ? "+" : "-"}) {row.totalAmount}
                    </div>
                  </div>
                ))
              )}
            </div>
            {!data.custom && (
              <div className="invoice-total">
                {pendingamount != 0 && (
                  <div className="pending-amount">
                    <h6 className="total-price">
                      {pendingamount > 0 ? "Pending" : "Advance"} INR: {pendingamount}
                    </h6>
                  </div>
                )}
                {receivedAmount1 != 0 && (
                  <div className="received-amount">
                    <h6 className="total-price">
                      RECEIVING INR: {receivedAmount1}
                    </h6>
                  </div>
                )}
              </div>
            )}
            <div className="print-date">
              <small>
                This is computer generated receipt no signature required | Print Date and time:
                {data.printDateSelected.format("DD-MM-YYYY")} | {data.PrintingTime}
              </small>
            </div>
          </div>
          {printDuplicateCopy && <div className="dotted-line"></div>}
          {printDuplicateCopy && <div className="invoice-container">
            <div className="invoice-header">
              <img
                src={`data:${invoice?.appointment?.doctor?.topLetterHead?.contenttype};base64,${invoice?.appointment?.doctor?.topLetterHead?.data.data}`}
                alt="header"
                style={{ width: "100%", height: "80%" }}
              />
            </div>
            <div className="invoice-details">
              <div className="patient-info">
                <h5>INVOICE TO</h5>
                <p>
                  <b>{invoice?.patient?.name}</b> <br />
                  <small>UHID: {invoice?.patient?.uhid}</small>
                </p>
                {data.custom && (
                  <p>Payment Method: {invoice?.appointment?.mode}</p>
                )}
              </div>
              <div className="invoice-summary">
                <div className="invoice-summary-row">
                  <div>
                    <p><b>NO.</b></p>
                    <p>#{data.slug}</p>
                  </div>
                  <div>
                    <p><b>Date:</b></p>
                    <p>{data.receiptDate.format("DD-MM-YYYY")}</p>
                  </div>
                </div>
                <div className="total-price">
                  TOTAL INR: {data?.receiptData?.fee - data?.receiptData?.discount}
                </div>
              </div>
            </div>
            <div className="invoice-items">
              <div className="invoice-item header">
                <div>S.No</div>
                <div>ITEM DESCRIPTION</div>
                <div>QTY</div>
                <div>PRICE</div>
                <div>TOTAL</div>
              </div>
              {data.custom ? (
                <>
                  <div className="invoice-item">
                    <div>1</div>
                    <div>{data?.receiptData?.itemDescription}</div>
                    <div>1</div>
                    <div>INR {data.receiptData?.fee}</div>
                    <div>INR {data.receiptData?.fee}</div>
                  </div>
                  {data.receiptData.discount && data.receiptData?.discount > 0 && (
                    <div className="invoice-item">
                      <div>2</div>
                      <div>Discount</div>
                      <div>1</div>
                      <div>INR {data.receiptData?.discount}</div>
                      <div>INR {data.receiptData?.discount}</div>
                    </div>
                  )}
                </>
              ) : (
                fetchbillingData?.map((row, index) => (
                  <div className="invoice-item" key={index}>
                    <div>{index + 1}</div>
                    <div>
                      {row.activityName}
                      <br />
                      Date: {moment(row?.billingDateTime).format("DD-MM-YYYY HH:mm:ss")}
                      <br />
                      {row?.discountValue > 0 && (
                        <>
                          Discount: {row.discountType === "percentage" 
                            ? `${row.discount} (${row?.discountValue}%)`
                            : row?.discountValue}
                        </>
                      )}
                    </div>
                    <div>{row.type === "credit" ? `${row.number}x` : ""}</div>
                    <div>{row.type === "credit" ? row.price : ""}</div>
                    <div>
                      ({row.type === "credit" ? "+" : "-"}) {row.totalAmount}
                    </div>
                  </div>
                ))
              )}
            </div>
            {!data.custom && (
              <div className="invoice-total">
                {pendingamount != 0 && (
                  <div className="pending-amount">
                    <h6 className="total-price">
                      {pendingamount > 0 ? "Pending" : "Advance"} INR: {pendingamount}
                    </h6>
                  </div>
                )}
                {receivedAmount1 != 0 && (
                  <div className="received-amount">
                    <h6 className="total-price">
                      RECEIVING INR: {receivedAmount1}
                    </h6>
                  </div>
                )}
              </div>
            )}
            <div className="print-date">
              <small>
                This is computer generated receipt no signature required | Print Date and time:
                {data.printDateSelected.format("DD-MM-YYYY")} | {data.PrintingTime}
              </small>
            </div>
          </div>}
          <center>
            <button
              className="btn btn-primary"
              onClick={PrintReceipt}
              id="printPageButton"
            >
              Print
            </button>
          </center>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Receipt;
