import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const CustomActionButton = ({
  color,
  icon,
  onClick,
  buttonTitle,
  sx,
  variant,
  type,
  className,
  disabled,
}) => {
  return (
    <Button
      color={color || "primary"}
      startIcon={icon || null}
      onClick={onClick}
      sx={{
        mr: 2,
        backgroundColor: "#445b79",
        "&:hover": {
          backgroundColor: "#9aa7bc",
        },
        borderRadius: "10px",
        fontFamily: 'inherit',
        ...sx,
      }}
      variant={variant || "contained"}
      type={type || "button"}
      className={className || ""}
      disabled={disabled || false}
    >
      {buttonTitle}
    </Button>
  );
};

CustomActionButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default CustomActionButton;
