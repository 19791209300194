import React from "react";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const Autocomplete = ({ id, options, placeholder, disablePortal = true, ...rest }) => {
  const spanFullWidth = 'spanFullWidth' in rest ? true : false;

  return (
    <MuiAutocomplete
      // Include disablePortal by default, exclude if explicitly false
      {...(disablePortal && { disablePortal: true })}
      id={id}
      options={options}
      getOptionLabel={(option) => option.label}
      style={spanFullWidth ? { width: "100%" } : { width: 200 }}
      renderInput={(params) => (
        <TextField {...params} label={placeholder} variant="outlined" />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
