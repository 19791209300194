import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  TextField,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import * as Yup from "yup";

import "./QueueForm.scss";
import DynamicFieldArray from "../../../components/common/DynamicFieldArray";
import Header from "../../../components/layout/header";
import CustomActionButton from "../../../components/common/CustomActionButton";
import { SettingsAPI } from "../../../apis/SettingsAPI";
import { SHOW_SUCCESS_NOTIFICATION } from "../../../utils/app-util";

const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = Yup.object({
  doctorStatusOptions: Yup.array().of(Yup.string().required("Required")),
  customUpdates: Yup.array().of(Yup.string().required("Required")),
  videosOnLoop: Yup.array().of(
    Yup.string()
      .required("Required")
      .matches(
        /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
        "Only YouTube video URLs are allowed"
      )
  ),
  bgImage: Yup.mixed()
    .required("An image is required")
    .test("fileSize", "File is too large", (value) => {
      // Check if value is a File object and validate its size
      if (value instanceof File) {
        return value.size <= MAX_IMAGE_SIZE;
      }
      // If it's not a file (e.g., a base64 string), skip size validation
      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      // Check if value is a File object and validate its format
      if (value instanceof File) {
        return SUPPORTED_FORMATS.includes(value.type);
      }
      // If it's not a file (e.g., a base64 string), skip format validation
      return true;
    }),
});

const QueueForm = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    SettingsAPI.getWaitingScreenSettingsInfo()
      .then((response) => {
        setFormData(response);
        setPreviewImage(response?.bgImage);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setFieldValue("bgImage", file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setPreviewImage(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    formData.append("doctorStatusOptions", values.doctorStatusOptions);
    formData.append("customUpdates", values.customUpdates);
    formData.append("videosOnLoop", values.videosOnLoop);

    let bgImageFile = values.bgImage;

    // If bgImage is a base64 string, convert it to a binary file
  if (typeof bgImageFile === "string" && bgImageFile.startsWith("data:")) {
    const mimeType = bgImageFile.split(";")[0].split(":")[1]; // Extract MIME type
    const blob = base64ToBlob(bgImageFile, mimeType);
    bgImageFile = new File([blob], "bgImage", { type: mimeType });
  }

    if (values.bgImage) {
      formData.append("bgImage", bgImageFile);
    }

    try {
      await SettingsAPI.setWaitingScreenSettingsInfo(formData);
      SHOW_SUCCESS_NOTIFICATION("Saved Successfully");
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Header title="Queue" />
      <Paper
        sx={{
          p: 2,
          borderRadius: "1rem",
        }}
      >
        <Formik
          initialValues={
            formData || {
              doctorStatusOptions: [],
              customUpdates: ["", ""],
              videosOnLoop: ["", ""],
              bgImage: null,
            }
          }
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            isValid,
            handleChange,
          }) => {
            return (
              <Form className="queue-form">
                <Box className="form-section">
                  <label>Doctor Current status options</label>
                  <DynamicFieldArray
                    name="doctorStatusOptions"
                    values={values.doctorStatusOptions}
                    handleChange={handleChange}
                    placeholder="Enter status option text"
                    maxLength={18}
                  />
                </Box>

                <Box className="form-section">
                  <label>Custom updates</label>
                  {values.customUpdates.map((_, index) => (
                    <div key={index}>
                      <Field
                        name={`customUpdates[${index}]`}
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        placeholder={`Enter update ${index + 1}`}
                        inputProps={{ maxLength: 40 }}
                        error={
                          touched.customUpdates?.[index] &&
                          Boolean(errors.customUpdates?.[index])
                        }
                        helperText={
                          touched.customUpdates?.[index] &&
                          errors.customUpdates?.[index]
                        }
                        className="text-field"
                      />
                    </div>
                  ))}
                </Box>

                <Box className="form-section">
                  <label>Videos on loop</label>
                  {values.videosOnLoop.map((_, index) => (
                    <div key={index}>
                      <Field
                        name={`videosOnLoop[${index}]`}
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        placeholder={`Video ${index + 1}`}
                        error={
                          touched.videosOnLoop?.[index] &&
                          Boolean(errors.videosOnLoop?.[index])
                        }
                        helperText={
                          touched.videosOnLoop?.[index] &&
                          errors.videosOnLoop?.[index]
                        }
                        className="text-field"
                      />
                    </div>
                  ))}
                </Box>

                <Box className="form-section">
                  <label>Upload an Image</label>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => handleImageChange(e, setFieldValue)}
                  />
                  {errors.bgImage && touched.bgImage && (
                    <Typography color="error">{errors.bgImage}</Typography>
                  )}
                  {previewImage && (
                    <Box className="image-preview">
                      <img src={previewImage} alt="Preview" />
                    </Box>
                  )}
                  <Typography variant="body2" color="textSecondary">
                    * Only JPG, JPEG, PNG files are allowed. Max size: 2MB.
                    (Recommended 1470*912)
                  </Typography>
                </Box>

                <Box className="form-section">
                  <CustomActionButton
                    buttonTitle="Save"
                    type="submit"
                    variant="contained"
                    className="submit-button"
                    disabled={!isValid}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};

export default QueueForm;

const base64ToBlob = (base64Data) => {
  // Extract the base64 data without the prefix
  const byteString = atob(base64Data.split(",")[1]);
  const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];

  // Convert base64 to binary data
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
};
