import React from "react";
import Header from "../../components/layout/header";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
} from "../../utils/app-util";
import Api from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import {
  Box,
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import useGlobalStyles from "../../utils/global-style";
import { Form, Text, TextArea, Option, Select } from "informed";
import { medicationFrequencySelectOptions, medicationIntervalSelectOptions } from "../../constants/options";

const AddMedication = () => {
  let history = useHistory();
  const globalClass = useGlobalStyles();

  const handleSubmit = (val) => {
    Api.post("/medication", val).subscribe(
      () => {
        history.push(Routes.MEDICATION);
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CREATE_SUCCESS);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Header showBackBtn title="Add Medication" />
      <Box m={10}>
        <Form onSubmit={handleSubmit}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Dose</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Interval</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Remarks</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">
                    <TextArea placeholder="Name" field="name" />
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Text
                        style={{ marginRight: ".5rem" }}
                        label="Dose"
                        field="dose"
                      />
                      <Select
                        style={{ width: "90px" }}
                        required
                        field="frequency"
                        placeholder="Frequency"
                        initialValue="1-0-1"
                        label="Frequency"
                        validateOnBlur
                        validateOnChange
                      >
                        {medicationFrequencySelectOptions.map(({value, label}, index) => (
                          <Option value={value} key={`${value}_${index}`}>{label}</Option>
                        ))}
                      </Select>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Text
                        style={{ width: "60px", marginRight: ".5rem" }}
                        label="Num"
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        field="num"
                      />
                      <Select
                        style={{ width: "90px" }}
                        required
                        field="interval"
                        placeholder="Interval"
                        initialValue="Days"
                        label="Interval"
                        validateOnBlur
                        validateOnChange
                      >
                        {medicationIntervalSelectOptions.map(({value, label}, index) => (
                          <Option value={value} key={`${value}_${index}`}>{label}</Option>
                        ))}
                      </Select>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TextArea placeholder="Notes" field="remarks" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box mx=".4rem" my={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.mediumBtnContainer}
            >
              Save
            </Button>
          </Box>
        </Form>
      </Box>
    </div>
  );
};

export default AddMedication;
