import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Api2 from '../../utils/api2'
import moment from 'moment'


const Prescriptionchat = ({ patientSlug }) => {
    // patientSlug = PTN30086


    const history = useHistory();
    const [summary, setSummary] = useState([]);
    const [page, setPage] = useState(0);
    const [profilePic, setprofilePic] = useState("");
    const [loader, setLoader] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [waitResponse, setWaitResponse] = useState(false);



    const handleScroll = (e) => {

        if (!hasMore) {

            return;
        }

        if (!waitResponse) {
            const { scrollTop, clientHeight, scrollHeight } = e.target;


            if (Math.floor(scrollTop) + clientHeight + 2 >= scrollHeight) {


                setPage(page + 1);
            }
        }
    };

    useEffect(() => {
        Api2.get(
            `/get_treatment_summary?patientSlug=${patientSlug}&page=${page}&size=20`
        ).subscribe((resp) => {
            if (resp) {
                console.log("response_patient_summary",resp?.data?.data)
                console.log("response_patient_summary_1",resp?.data?.data?.activities)
                setSummary(resp?.data?.data?.activities);
                console.log("test_summary",resp?.data?.data?.activities)
                if (resp.data.data.profilePic) {
                    setprofilePic(resp.data.data.profilePic);
                    
                  }else{
                    setprofilePic('/profilePic.png');
          
                  }
            }
        });
        setPage(0);
    }, [patientSlug]);

    useEffect(() => {
        if (!hasMore) {
            setLoader(false);
            return;
        }

        setLoader(true);

        setWaitResponse(true);
        Api2.get(
            `/get_treatment_summary?patientSlug=${patientSlug}&page=${page}&size=20`
        ).subscribe((resp) => {
            if (resp) {
                if (resp?.data?.data?.activities.length === 0) {
                    setHasMore(false);
                    setLoader(false);

                    return;
                }
                setSummary([...summary, ...resp.data.data.activities]);
                setprofilePic(resp.data.data.profilePic);
                setLoader(false);
                setWaitResponse(false);

            }
        });
    }, [page,patientSlug]);


    return (
        <>
            {/* <div style={{ "width": "390px", "marginLeft": "20px", "height": "700px", "overflowY": "auto" }} onScroll={handleScroll}> */}
            <div>
                {/* <h5 style={{ "fontWeight": "bold", "textAlign": "center" }}>Patients Summary</h5> */}
                <div style={{ height: "750px", overflow: "auto", fontSize: "10px",backgroundColor:"white",padding:"5px" }} onScroll={handleScroll}>



                    <section >
                        
                        <ul style={{ "listStyle": "none",paddingLeft:"0px",textAlign:'left' }}>
                            {  
                                
                               summary.length!==0?(
                                
                                    summary.map((s) => {
                                        
                                        return (
                                            <>
                                                {
                                                    s?.activity == "Patient was created" && (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span><img src={profilePic} alt="" className="imageClass" /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                {s?.fromSlug == s?.toSlug ? (
    
                                                                    <b>Registered on proclinic by self</b>
    
                                                                ) : (
                                                                    <>
                                                                        <b>Registered</b> on proclinic by
                                                                        <b>{s?.fromName}</b>
                                                                    </>
                                                                )}
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
    
                                                {
                                                    s?.activity == "Booked appointment!" &&
                                                    s?.fromSlug == s?.toSlug && (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span><img
                                                                src={profilePic}
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Booked Appointment for {s?.doctorName} by self for &nbsp;
                                                                <b>
                                                                    {moment(s?.appointmentDate).format(
                                                                        "YYYY-MM-DD, HH:mm:ss"
                                                                    )}
                                                                </b> &nbsp;  {s?.paymentMode}
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
    
                                                {
                                                    s?.activity == "Booked appointment!" &&
                                                    s?.fromSlug == s?.toSlug && (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span><img
                                                                src={profilePic}
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Booked Appointment for {s?.doctorName} by self for
                                                                {s?.doctorName} by {s?.fromName} for  &nbsp;
                                                                <b>
                                                                    {moment(s?.appointmentDate).format(
                                                                        "YYYY-MM-DD, HH:mm:ss"
                                                                    )}
                                                                </b>  &nbsp;
                                                                {s?.paymentMode}
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
                                                {
                                                    s?.activity == "Message sent!" &&
                                                    s?.fromSlug == patientSlug && (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span> <img
                                                                src="./images/Frame 151.png"
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Sent Chat to {s?.toName} <br />
                                                                {/* <input
                                                                    readOnly
                                                                    type="text"
                                                                    className="shadow p-3 mb-1 bg-body rounded w-100 "
                                                                    placeholder={s?.description}
                                                                /> */}
                                                                <b>{s?.description}</b>
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
    
                                                {
                                                    s?.activity == "Message sent!" &&
                                                    s?.fromSlug !== patientSlug && (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span><img
                                                                src="./images/Frame 151.png"
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Replied by {s?.fromName} <br />
                                                                {/* <input
                                                                    readOnly
                                                                    type="text"
                                                                    className="shadow p-3 mb-1 bg-body rounded w-100 "
                                                                    placeholder={s?.description}
                                                                /> */}
                                                                 <b>{s?.description}</b>
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
    
                                                {
                                                    s?.activity == "Updated appointment!" &&
                                                    (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span> <img
                                                                src="./profilePic.png"
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Marked {s?.description.replace("status:", "")} by
                                                                {s?.fromName}
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
                                                {
                                                    s?.activity == "Added prescription!" &&
                                                    (
                                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>
                                                            <span><img
                                                                src="./images/Frame 151 (1).png"
                                                                alt=""
                                                                className="imageClass"
                                                            /></span>
                                                            <small >{moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}<br />
    
                                                                Prescription generated by {s?.fromName} <br/>
                                                                <div style={{"marginTop":"5px"}} dangerouslySetInnerHTML={{ __html: s?.prescriptionHtml }} />
    
    
                                                                {/* <a
                                                href={s?.prescriptionHtml}
                                                style={{ textDecoration: "none" }}
                                            >
                                                View Prescription
                                            </a> */}
    
    
                                                            </small>
                                                        </li>
                                                    )
                                                }
    
    
                                            </>
                                        )
                                    })
                                
                               ):(
                                <>
                                <li style={{ "marginBottom": "20px", "display": "flex" }}>No Prescription </li>
                                </>
                               ) 
                            }
                            


                        </ul>
                    </section>
                    <section className="py-3">
                        <ul className="timeline-with-icons" style={{ listStyle: "none" }}>
                            {loader && (
                                <li className="timeline-item mb-5 text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </section>



                </div>
            </div>
        </>
    )
}

export default Prescriptionchat
