import React, { useEffect, useRef } from "react";
import Texteditor from "./texteditor";
import Header from "../../components/layout/header";
import Axios from "axios";
import JoditEditor from "jodit-react";
import { ToastContainer, Toast } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import EditIcon from "@material-ui/icons/Edit";

import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { useState } from "react";
import Api from "../../utils/api";
import { Form, Scope } from "informed";
import Loader from "../../components/misc/loader";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionTitle_1: {
    fontSize: "1.1rem",
    fontWeight: "800",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  
  prescriptionTitle1: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    width: "100%",
    marginBottom: "5px",
    marginBottom: "2rem",
    backgroundColor: "white",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding:'1.8%',
    border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  logoContainer: {
    display: "flex",
  },
  addressgrid: {
    display: "flex",
    gap: "5%",
    marginBottom: "5px",
    gridTemplateColumns:
      "repeat(auto-fill, minmax(10rem, 1fr)) 100px repeat(3, 200px)",
    justifyContent: "spa",
  },
  select: {
    width: "100%",
    border: "1px solid #b4b0b0",
    padding: "13px",
    marginTop: "8px",
    minWidth: "80px",
    borderRadius: "10px",
  },
}));

const Aboutus = (props) => {
  const classes = useStyles();

  const { respo } = props;
  const [Loading, setLoading] = useState(false);

  const globalClass = useGlobalStyles();

  const editor = useRef(null);
  const [content, setContent] = useState({
    aboutus: "",
  });



  const contentFieldChanged = (data) => {
    setContent({ ...content, aboutus: data });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
   
    const req = {
      about: content.aboutus,
      id: content.id,
      type: "aboutus",
      banner_title: bannerTilte
    };

    if (content.aboutus === "\n") {
      SHOW_ERROR_NOTIFICATION("All feild required");
      setLoading(false);
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/websiteupdate`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.toString() === "succss") {
          setLoading(false);
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
        } else {
          NotificationManager.success("Something went wrong Please try again");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    // Api.post(
    //   `${process.env.REACT_APP_API2_HOST}/websiteupdate`,
    //   req
    // ).subscribe(
  };
  const [bannerTilte, setBannerTitle] = useState("");

  const handlebanner = (e) => {
    setBannerTitle(e.target.value);
  };

  useEffect(() => {
    setContent({
      aboutus: respo?.aboutus,
      id: respo?.id,
      type: "aboutus",
    });

    setBannerTitle(respo?.banner_title);
  }, []);

  const [imgData, setImgData] = useState(respo?.banner);
  const [picture, setPicture] = useState(respo?.banner);
  const [logoStatus, setLogoStatus] = useState(false);
  const [lodingStatus, setLoadingStatus] = useState(false);
  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    const name = e.target.name;
    if (name === "logo") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
          setLogoStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const handleSaveImage = async (type) => {
    if (type === "banner") {
      setLoadingStatus({
        logoLodingStatus: true,
        faviconLoadingStatus: false,
        formLoadingStatus: false,
      });

      const formData = new FormData();
      formData.append("file", picture);
      formData.append("type", type);
      formData.append("website_id", respo.id);
      
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/imageuploadbase`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {
          if (response.data.toString() === "successfully") {
            setLogoStatus(false);
            setLoadingStatus({
              logoLodingStatus: false,
              faviconLoadingStatus: false,
              formLoadingStatus: false,
            });
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          } else {
            NotificationManager.success(
              "Something went wrong Please try again"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Header title="About Us" />
      <Form
        onSubmit={handleSubmit}
        //onChange={contentFieldChanged}
      >
        <Box className={classes.prescriptionSection}>
          <Box
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.prescriptionTitle_1}>
              Bannner Image  (1920px*420px)
            </Typography>
          </Box>
          <form method="post">
            <div class="col-12">
              <input
                type="file"
                placeholder="favicon"
                name="logo"
                class="form-control"
                id="logo"
                autocomplete="off"
                onChange={handleInputs}
                style={{ visibility: "hidden" }}
              />
              <div className="previewProfilePic">
                <img
                  className="playerProfilePic_home_tile"
                  src={imgData || respo?.banner}
                  alt="logo"
                  width="100vw" 
                />
              </div>
              <br />
              {logoStatus ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("banner")}
              >
                {lodingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "SAVE"
                )}
              </Button>
            ) : (
              <label htmlFor="logo">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;
            <Box>
            <Box
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Bannner Title
            </Typography>
          </Box>
                <TextField
                  className={classes.prescriptionTitle1}
                  onChange={handlebanner}
                  value={bannerTilte}
                  variant="outlined"
                />
              </Box>
            </div> 
          </form>
        </Box>

        <Box className={classes.prescriptionSection}>
          <Box
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.prescriptionTitle}>
              About us
            </Typography>
          </Box>
          <JoditEditor
            ref={editor}
            value={content.aboutus}
            tabIndex={1}
            onChange={(newContent) => contentFieldChanged(newContent)}
          />
        </Box>

        <Box mb={3} style={{ marginTop: "2%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
          >
            {Loading ? (
              <Loader width="200px" height="20px" color="white" />
            ) : (
              "Save"
            )}
          </Button>
          <ToastContainer />
        </Box>
      </Form>
    </div>
  );
};

export default Aboutus;
