import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  HashRouter,

} from 'react-router-dom';
import { SCROLL_TO_TOP } from '../utils/app-util';
import { NotificationContainer } from 'react-notifications';
import Routes from './routes';
import NonPrivateRoute from './non-private-route';
import PrivateRoute from './private-route';
import LoginPage from '../screens/login/login-page'
import Main from '../screens/main/main';
import QrScanner from '../screens/qr-scanner/qr-scanner';
import Forgetpassword from '../screens/login/forgetpassword';


const AllRoutes = () => {
  return (
    <HashRouter hashType="hashbang" basename={'/admin'} >
      <React.Fragment>
        <Route component={SCROLL_TO_TOP}>
          <Switch>
            <PrivateRoute path={Routes.MAIN} component={Main} />
            <NonPrivateRoute exact path={Routes.LOGIN} component={LoginPage} />
            <NonPrivateRoute exact path={Routes.FORGETPASSWORD} component={Forgetpassword} />
            <NonPrivateRoute
              exact
              path={Routes.SCANNER}
              component={QrScanner}
            />
            <Redirect to={Routes.LOGIN} />
          </Switch>
          <NotificationContainer />
        </Route>
      </React.Fragment>
    </HashRouter>
  );
};


export default AllRoutes;
