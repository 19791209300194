import React, { useEffect,useRef } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import Api from "../../utils/api";
import { setDate } from "date-fns";
import EditIcon from "@material-ui/icons/Edit";

import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import JoditEditor from "jodit-react";





const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "14px",
    marginTop: "30px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,


  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    padding: "0.8%",
    border: "1px solid #a4a0a0",
    marginTop: "1%"
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const Other = (props) => {
  const { respo } = props;


  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const [data, setData] = useState({
    googleMapKey: '',
    defOtpPatient: '',
    defOtpBackend: '',
    loginPageHelp: '',
  })

  const editor = useRef(null);
  const [content, setContent] = useState({
    loginPageHelp: "",
  });
  const contentFieldChanged = (data) => {
    setContent({ ...content, loginPageHelp: data });
  };

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const [status, setStatus] = useState(false)
  const [addLoadingStatus, setAddLoadingStatus] = useState(false)

  const handleSubmit = async (event) => {
    setAddLoadingStatus(true)
    const formData = new FormData();
    formData.append("googleMapKey", data?.googleMapKey);
    formData.append("defOtpPatient", data?.defOtpPatient);
    formData.append("defOtpBackend", data?.defOtpBackend);
    formData.append("loginPageHelp", content?.loginPageHelp);
    if (
      data.defOtpPatient == '' ||
      data.googleMapKey == '' ||
      data.defOtpBackend == '' ||
      content.loginPageHelp == ''
    ) {
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/add_other_settings`,
          data: formData,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.message.toString() == "Updated Successfully!") {
          NotificationManager.success(response.data.message);
          setAddLoadingStatus(false)
        } else {
          NotificationManager.error(response.data.message);
          setAddLoadingStatus(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };



  const [logoStatus, setLogoStatus] = useState(false);
  const [imgData, setImgData] = useState();
  const [picture, setPicture] = useState();


  const [logoStatusbackend, setLogoStatusbackend] = useState(false);
  const [imgDatabackend, setImgDatabackend] = useState();
  const [picturebackend, setPicturebackend] = useState();

  const [loginBgBackEnd, setloginBgBackEnd] = useState(null);
  const [loginBgFrontEnd, setloginBgFrontEnd] = useState(null);

  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
    loadingStatusBackend: false,
  })

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setImgDatabackend(resp1?.data[0]?.loginBgBackEnd)
        setImgData(resp1?.data[0]?.loginBgFrontEnd)
      }
    });
  }, [lodingStatus.logoLodingStatus, lodingStatus.loadingStatusBackend]);



  useEffect(() => {
    setData({
      googleMapKey: respo?.googleMapKey,
      defOtpPatient: respo?.defOtpPatient,
      defOtpBackend: respo?.defOtpBackend,
      loginPageHelp : respo?.loginPageHelp,

    })
    setContent({
      loginPageHelp:respo?.loginPageHelp
    })
    

  }, [respo, imgData,]);

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    const name = e.target.name;
    if (name === "topLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
          setLogoStatus(true);

        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    if (name === "bottomLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicturebackend(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgDatabackend(reader.result);
          setLogoStatusbackend(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };



  const handleSaveImage = async (type) => {
    const formData = new FormData();
    if (type === "loginBgFrontEnd") {
      formData.append("file", picture);
      setLoadingStatus({
        logoLodingStatus: true,
        faviconLoadingStatus: false,
      })
    }
    if (type === "loginBgBackEnd") {
      formData.append("file", picturebackend);
      setLoadingStatus({
        logoLodingStatus: false,
        faviconLoadingStatus: true,
      })
    }
    formData.append("type", type);

    setLoadingStatus({
      logoLodingStatus: true,
      faviconLoadingStatus: false,
      formLoadingStatus: false,
      receiptFooterStatus: false,
      receiptHeaderStatus: false,
    })

    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}imageuploadbase`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response) {

        if (response) {
          setLogoStatus(false);
          setLoadingStatus({ logoLodingStatus: false })
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus: false,
          })
          setLogoStatus(false)
          setLogoStatusbackend(false)

        } else {
          setLoadingStatus({ logoLodingStatus: false, })
          NotificationManager.error(
            "Something went wrong Please try again"
          );
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus: false,
          })
          setLogoStatus(false)
          setLogoStatusbackend(false)

        }
      }
    } catch (error) {
      console.log(error);
    }

  };



  return (
    <>
      <Header title="Other" />

      <Box className={classes.prescriptionSection}>
        <Box
          mt="2rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Frontend Login Screen Image
          </Typography>
        </Box>

        <div style={{ width: '100%' }}>
          <div class="col-12">
            <input
              type="file"
              placeholder="favicon"
              name="topLetterHead"
              class="form-control"
              id="logo"
              autocomplete="off"
              onChange={handleInputs}
              style={{ visibility: "hidden" }}
            />
            <div className="previewProfilePic">
              {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
              <img
                className="playerProfilePic_home_tile"
                src={imgData}
                alt="logo"
                style={{ width: '100%' }}
              // width="160"
              // style={{height:'30vh'}}
              />
              {/* )} */}
            </div>
          </div>
        </div>
        <br />
        {logoStatus ? (
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={() => handleSaveImage("loginBgFrontEnd")}

          >
            {lodingStatus.logoLodingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
          </Button>
        ) : (
          <label htmlFor="logo">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
            >
              <EditIcon /> &nbsp; Edit
            </Button>
          </label>
        )}
        &nbsp;

      </Box>

      <Box className={classes.prescriptionSection}>
        <Box
          mt="2rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Backend Login Screen Image
          </Typography>
        </Box>

        <div style={{ width: '100%' }}>
          <div class="col-12">
            <input
              type="file"
              placeholder="favicon"
              name="bottomLetterHead"
              class="form-control"
              id="backend"
              autocomplete="off"
              onChange={handleInputs}
              style={{ visibility: "hidden" }}
            />
            <div className="previewProfilePic">
              {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
              <img
                className="playerProfilePic_home_tile"
                src={imgDatabackend}
                alt="logo"
                style={{ width: '100%' }}
              // width="160"
              // style={{height:'30vh'}}
              />
              {/* )} */}
            </div>
          </div>
        </div>
        <br />
        {logoStatusbackend ? (
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={() => handleSaveImage("loginBgBackEnd")}

          >
            {lodingStatus.faviconLoadingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
          </Button>
        ) : (
          <label htmlFor="backend">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
            >
              <EditIcon /> &nbsp; Edit
            </Button>
          </label>
        )}
        &nbsp;

      </Box>



      <Typography variant="h6" className={classes.prescriptionTitle}>
        Google Map Key
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="googleMapKey"
          field="title"
          value={data.googleMapKey}
          placeholder="Google Map Key"
          onChange={onChangeData}
          className={classes.style_2}
        />
      </Grid>


      <Typography variant="h6" className={classes.prescriptionTitle}>
        Default OTP for Backend
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="defOtpBackend"
          field="title"
          value={data.defOtpBackend}
          placeholder="Default OTP for Patient"
          onChange={onChangeData}
          className={classes.style_2}
        />
      </Grid>

      
      <Typography variant="h6" className={classes.prescriptionTitle}>
        Default OTP for Patient
      </Typography>
      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="defOtpPatient"
          value={data.defOtpPatient}
          field="title"
          placeholder="Default OTP for Backend"
          onChange={onChangeData}
          className={classes.style_2}
        />
      </Grid>




      <Box className={classes.prescriptionSection}>
          <Box
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.prescriptionTitle}>
             Login Page Help
            </Typography>
          </Box>
          <JoditEditor
            ref={editor}
            value={data.loginPageHelp}
            tabIndex={1}
            onChange={(newContent) => contentFieldChanged(newContent)}
          />
        </Box>


      <Box mb={3} style={{ marginTop: "2%", padding: "1%" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={globalClass.mediumBtnContainer}
          onClick={handleSubmit}
        >
          {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
        </Button>
      </Box>

    </>
  );
};

export default Other;
