import React, { useEffect, useRef, useState } from "react";
import { Form, Scope } from "informed";
import useGlobalStyles from "../../utils/global-style";
import { TextInput, TextAreaInput, SelectInput } from "sixsprints-react-ui";
import Medication from "../../components/layout/medication";
import DataService from "../../utils/services/data-service";
import Api2 from "../../utils/api2";
import Loader from "../../components/misc/loader";
import Api from "../../utils/api";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  useQuery,
} from "../../utils/app-util";
import { isRequired } from "../../utils/validations";

import {
  Box,
  makeStyles,
  Paper,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
  Select,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    marginLeft: "-1rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  templateName: {
    marginRight: "1rem",
  },
  input: {
    display: "none",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },

  prescriptionSection: {
    marginBottom: "2rem",
  },

  prescriptionTitle: {
    lineHeight: "normal",
    marginBottom: "10px",
  },
}));

const Template3 = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  let history = useHistory();
  const formApi = useRef();
  const fileRef = useRef(null);
  const [specialities, setSpecialities] = useState([]);
  const [emrTemplate, setEmrTemplate] = useState(null);
  const [medications, setMedications] = useState([]);
  const [uploadedDiagram, setUploadedDiagram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [specialistData, setSepcilistData] = useState(null);
  const [special, setSpecial] = useState("");

  const handleChangeSelect = (event) => {
    setSpecial(event.target.value);
  };

  const query = useQuery();
  const slug = query.get("slug");

  const fetchEmrTemplate = () => {
    if (slug) {
      Api.get(`/emr-template/${slug}`).subscribe((resp) => {
        setEditMode(true);
        setEmrTemplate(resp?.data?.data);
        setUploadedDiagram(resp?.data?.data?.template?.content || []);
        // formApi.current.setValues(resp?.data?.data);
      });
    }
  };

  useEffect(() => {
    if (emrTemplate) {
      setMedications(emrTemplate?.template?.medications);
    }
  }, [emrTemplate]);

  useEffect(fetchEmrTemplate, [slug])

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSepcilistData(resp?.data);
          setSpecial(resp?.data?.name);
        }
      }
    );
  }, []);

  const onRemoveDiagramsClicked = () => {
    setUploadedDiagram([]);
  };

  const handleChange = (evt) => {
    setIsLoading(true);
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    for (let i = 0; i < fileArray.length; i++) {
      formData.append("files", fileArray[i]);
    }
    Api2.post(`file/upload/secure?dir=prescriptions`, formData).subscribe(
      (resp) => {
        setUploadedDiagram(resp?.data?.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  useEffect(() => {
    if (emrTemplate) {
      setMedications(emrTemplate?.template?.medications);
    }
  }, [emrTemplate]);

  const onMedicationSelected = (val, index) => {
    formApi.current.setValue(`medications[${index}].name`, val.name);
    formApi.current.setValue(`medications[${index}].dose`, val.dose);
    formApi.current.setValue(`medications[${index}].frequency`, val.frequency);
    formApi.current.setValue(`medications[${index}].num`, val.num);
    formApi.current.setValue(`medications[${index}].interval`, val.interval);
    formApi.current.setValue(`medications[${index}].remarks`, val.remarks);
    formApi.current.setValue(`medications[${index}].id`, val.id);
    setMedications((prev) => {
      const medication = JSON.parse(JSON.stringify(prev));
      medication[index] = val;
      return [...medication];
    });
  };

  const fetchSpecialities = () => {
    DataService.getList(`/data/specialities`).subscribe((resp) => {
      setSpecialities(resp);
    });
  };

  const onRemoveMedicationClicked = (val) => {
    setMedications((prevState) => {
      const newMedications = JSON.parse(JSON.stringify(prevState));
      newMedications.splice(val, 1);
      return [...newMedications];
    });
  };

  useEffect(fetchSpecialities, []);
  const copyMedicationsInterVal = (data) => {
    const { interval, num: number } = data;
    setMedications((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((item) => {
        item.num = number;
        item.interval = interval;
      });
      return [...newState];
    });
  };

  const DocSlug = JSON.parse(localStorage.getItem("USER"));

  const [type, setType] = React.useState("");

  const handleSubmit = (val) => {
    let medicationData = medications?.filter((med) => med?.name && med?.dose);
    if (val.template) {
      val.template.diagrams = null;
      val.template.diagramIds = uploadedDiagram?.map(
        (imageId) => imageId.imageId
      );
      val.template.medications = medicationData;
      val.createdBySlug = DocSlug.slug;
    }
    if (editMode) {
      let tempEmr = emrTemplate;
      tempEmr.name = val.name;
      tempEmr.specialitySlug = val.specialitySlug;
      tempEmr.createdBySlug = DocSlug.slug;
      tempEmr.template = val.template;
      Api.put(`emr-template/${emrTemplate.id}`, tempEmr).subscribe(
        (resp) => {
          if (resp.data.status === true) {
            SHOW_SUCCESS_NOTIFICATION(resp.data.message);
          } else {
            SHOW_ERROR_NOTIFICATION(resp.data.message);
          }
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    } else {
      Api.post(`emr-template`, val).subscribe(
        () => {
          SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
          history.goBack();
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  return (
    <>
      <Form apiRef={formApi} onSubmit={handleSubmit}>
        <Box className={classes.prescriptionSection}>
          <Paper
            className={clsx(classes.container, globalClass.roundWhiteBg)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box display="flex">
              <TextInput
                className={globalClass.roundWhiteBg}
                containerClassName={classes.templateName}
                required
                field="name"
                type="text"
                label="Template Name"
                validateOnBlur
                validateOnChange
              />

              <SelectInput
                required
                className={globalClass.roundWhiteBg}
                options={specialistData}
                validate={isRequired}
                field="specialitySlug"
                placeholder="Speciality"
                label="Speciality"
                labelKey="name"
                valueKey="_id"
                onChange={handleChangeSelect}
                validateOnBlur
                validateOnChange
              />
            </Box>
          </Paper>
        </Box>

        <Scope scope="template">
          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Prov.
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput field="prov" placeholder="Type here...." />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Chief Complaints
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput
                field="chiefComplaints"
                placeholder="Type here...."
              />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              HOPI
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput field="hopi" placeholder="Type here...." />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Examination
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput field="examination" placeholder="Type here...." />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Medications
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <Medication
                onRemoveMedicationClicked={onRemoveMedicationClicked}
                data={medications}
                onMedicationSelected={onMedicationSelected}
                setMedicationData={setMedications}
                copyMedicationsInterVal={copyMedicationsInterVal}
              />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Surgical Plan
            </Typography>

            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput field="surgicalPlan" placeholder="Type here...." />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Restrictions & Instructions
            </Typography>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput
                field="dietaryRestriction"
                placeholder="Type here...."
              />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Investigation
            </Typography>

            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput
                field="investigation"
                placeholder="Type here...."
              />
            </Paper>
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              my="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Diagram
              </Typography>

              {uploadedDiagram && uploadedDiagram.length > 0 && (
                <Button
                  onClick={onRemoveDiagramsClicked}
                  color="primary"
                  variant="outlined"
                >
                  Remove Diagrams
                </Button>
              )}
            </Box>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <input
                ref={fileRef}
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                multiple
                onChange={handleChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  {isLoading ? (
                    <Loader color="secondary" height="20px" size={20} />
                  ) : (
                    "Click to Upload Diagram"
                  )}
                </Button>
              </label>
              <Box display="flex" flexWrap="wrap" mr={4} mt={10}>
                {uploadedDiagram &&
                  uploadedDiagram.length > 0 &&
                  uploadedDiagram.map((item, index) => (
                    <img
                      key={index}
                      alt="diagram"
                      width="200"
                      src={item.content}
                    />
                  ))}
              </Box>
            </Paper>
          </Box>
        </Scope>

        <Box textAlign="center" my={8}>
          <Button
            className={globalClass.largeBtnContainer}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default Template3;
