export const medicationFrequencySelectOptions = [
    { value: "1-0-1", label: "1-1-1" },
    { value: "1-1-1", label: "1-1-1" },
    { value: "1-0-0", label: "1-0-0" },
    { value: "0-1-0", label: "0-1-0" },
    { value: "0-0-1", label: "0-0-1" },
    { value: "0-1-1", label: "0-1-1" },
    { value: "1-1-0", label: "1-1-0" },
  ];
export const medicationIntervalSelectOptions = [
    { value: "Days", label: "Days" },
    { value: "Weeks", label: "Weeks" },
    { value: "Months", label: "Months" },
    { value: "Years", label: "Years" }
  ];

export const genderSelectOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" }
];
export const marriedSelectOptions = [
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" }
];