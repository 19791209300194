import React, { useContext, useState } from "react";
// import { SampleContext } from "../../contexts/SampleContext";
import "./pres.css";

import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import moment from "moment";
import Api from "../../utils/api";
import Routes from "../../routes/routes";
import Axios from "axios";
import AuthService from "../../utils/services/auth-service";
import Loader from "../../components/misc/loader";

const PrintLetterHead = () => {
    // const { back1, back2 } = useContext(SampleContext);
    const [pres, setPres] = useState();
    const [vital, setvital] = useState({});
    const [coMorbidities, setcoMorbidities] = useState({});

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const slug = searchParams.get("slug");

    const history = useHistory();

    const [age, setAge] = useState("");


    const getAppointment = async () => {
        const req = {
          filterModel: {
            id: slug,
          },
        };
        try{
            Api.get(`appointment/qr-code/${slug}`).subscribe(
            async (resp) => {
                setPres(resp.data.data);
                let age = resp?.data?.data?.patient?.dob;
                if(age) {
                    age = moment(age).format("YYYY-MM-DD");
                    var years = moment().diff(age, 'years', true);
                    var fyear = Math.floor(years)+"/";
                    setAge(fyear)
                }
             
            });
        }
        catch {
    
        }
      }


      useEffect(()=>{
        getAppointment();
      },[])

    // useEffect(() => {
    //     if (slug?.length) {

    //         Api.get(`appointment/print/${slug}`).subscribe((resp) => {

    //             if (resp.data.status === true) {
    //                 setPres(resp.data.data);
                    
    //             }
    //             else {
    //                 window.location.replace("/");
    //             }
    //         });
    //     }
    //     else {
    //         window.location.replace("/");
    //     }
    // }, []);

    const [getwebsite, setGetWebsite] = useState();
    const token = AuthService.getToken();


    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
            if (resp1) {
                setGetWebsite(resp1.data[0].admin_url);
            }
        });
    }, [slug])


    return (
        <>
            {
                pres?

                <div
                    // style={back1}
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", }}
                >

                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <table
                                className="report-container"
                                style={{ fontFamily: "Comfortaa, cursive", fontSize: "9px", width: "100%", }}
                            >
                                <thead className="report-header">
                                    <tr>
                                        <th className="report-header-cell">
                                            <div className="header-info">
                                                <img src={`data:${pres?.doctor?.topetterHead?.contenttype};base64,${pres?.doctor?.topLetterHead?.data?.data}`} alt="" style={{ "width": "100%" }} />

                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tfoot className="report-footer">
                                    <tr>
                                        <td className="report-footer-cell">
                                            <div className="footer-info footer_image">
                                                <img className='' src={` data:${pres?.doctor?.bottomLetterHead?.contenttype};base64,${pres?.doctor?.bottomLetterHead?.data?.data}`} alt="" style={{ "position": "static", "bottom": "0px", "width": "100%" }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                                <tbody className="report-content">
                                    <tr>
                                        <td className="report-content-cell">
                                            <div className="main">
                                                <main class="overflow-hidden">
                                                    <section class="pt-4">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-12" style={{ fontSize: "12px" }}>
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <b>
                                                                                <span style={{ fontSize: "14px " }}> {pres?.patient?.gender === "MALE" ? "Mr. " : ""}{pres?.patient?.gender === "FEMALE" ? "Ms. " : ""}{pres?.patient?.name} </span> ({age}
                                                                                <span className="text-capitalize">{pres?.patient?.gender === "MALE" ? "M" : ""}{pres?.patient?.gender === "FEMALE" ? "F" : ""}</span>)
                                                                            </b>


                                                                        </div>
                                                                        <div class="col-4">
                                                                            <b>UHID: </b>
                                                                            {pres?.patient?.uhid}
                                                                        </div>

                                                                        <div class="col-4">
                                                                            <b>Ph:</b> {pres?.patient?.mobileNumber}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <b>Date:</b> {moment(pres?.prescription?.date).format("Do, MMMM YYYY")}

                                                                        </div>
                                                                        <div class="col-8">
                                                                            <b>Address:</b> {pres?.patient?.address}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <center>
                                                                <button
                                                                    class="btn btn-primary"
                                                                    onClick={window.print}
                                                                    id="printPageButton"
                                                                >
                                                                    Print
                                                                </button>
                                                            </center>

                                                        </div>
                                                    </section>


                                                </main>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                :
                <Loader/>


            }
        </>

    );
};

export default PrintLetterHead;
