import React from "react";
import { asField } from "informed";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormHelperText from "@material-ui/core/FormHelperText";

export const TextAreaInput = asField(({ fieldState, fieldApi, ...props }) => {
  const {
    onChange: _onChange,
    onBlur: _onBlur,
    onFocus: _onFocus,
    initialValue,
    dontUpdate,
    styleClass,
    format,
    ...rest
  } = props;

  const { error, value, touched } = fieldState;
  const { setValue, setTouched } = fieldApi;

  return (
    <div style={{ width: "100%" }}>
      <TextareaAutosize
        ref={(refer) => {}}
        defaultValue={initialValue}
        value={value || ""}
        margin="normal"
        type="text"
        style={{
          width: "100%",
          border: "none",
          outline: "none",
        }}
        className={styleClass}
        {...rest}
        onChange={(e) => {
          let val = e.target.value;
          if (format) {
            val = format(val);
          }
          if (_onChange) {
            _onChange(e, val);
          }
          if (!dontUpdate) {
            setValue(val);
          }
        }}
        onBlur={(e) => {
          setTouched();
          if (_onBlur) {
            _onBlur(e);
          }
        }}
        onFocus={(e) => {
          setTouched(false);
          if (_onFocus) {
            _onFocus(e);
          }
        }}
      />
      <FormHelperText variant="standard">{error}</FormHelperText>
    </div>
  );
});
