import React from 'react'
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ChatBubble = () => {
  const location = useLocation();

  
 

  return (
    <>
    <img src= {location.state} widt />
    </> 
     
  )
}

export default ChatBubble