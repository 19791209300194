import { useQuery, useMutation } from '@tanstack/react-query'
import Api from "../utils/api"
import { SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from '../utils/app-util';

export const useGetAllActiveRooms = () => {
    return useQuery({
        queryKey: ['activeRooms'],
        queryFn: async () => {
            const response = await Api.get("admit/get_all_rooms?status=Active").toPromise();
            return response.data?.data;
        },
    })
}

export const useSearchDoctors = () => {
    return useQuery({
        queryKey: ['doctors'],
        queryFn: async () => {
            const response = await Api.post("doctor/search", {
                page: 0,
                size: 300,
                filterModel: {
                    roleName: {
                        filterType: "set",
                        values: ["DOCTOR", "DOCTOR_ADMIN"],
                    },
                },
                sortModel: [],
            }).toPromise();
            console.log('df', response.data?.data?.content)
            return response.data?.data?.content;
        },
    })
}

export const useAdmitPatient = () => {
    return useMutation({
        mutationFn: async (addPatientData) => {
            const response = await Api.post("/admit/add", addPatientData).toPromise();
            return response.data;
        },
        onSuccess: (data) => {
            if (data.status === true) {
                SHOW_SUCCESS_NOTIFICATION(data.message);
            } else {
                SHOW_ERROR_NOTIFICATION(data.message);
            }
        },
        onError: (error) => {
            SHOW_ERROR_NOTIFICATION("An error occurred while admitting the patient");
        }
    });
}