import React, { memo, useEffect, useState } from "react";
import { TextAreaInput, TextInput } from "sixsprints-react-ui";

const TextAreaCustom = (params) => {
    const [inputField, setInputField] = useState("");

    useEffect(() => {
        setInputField(params.value ?? '');
    }, [params.value]);

    const timeoutRef = React.useRef(null);

    return (
        <TextAreaInput
            field={params.field}
            placeholder={params.placeholder}
            name={params.field}
            value={inputField}
            onChange={(event) => {
                const inputValue = event.target.value;
                setInputField(inputValue);
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    params.onChange(event)
                }, 700);
            }}

        />
    );
};


const TextInputCustom = (params) => {
    const [inputField, setInputField] = useState("");

    useEffect(() => {
        setInputField(params.value ?? '');
    }, [params.value]);

    const timeoutRef = React.useRef(null);

    return (
        <TextInput
            field={params.field}
            placeholder={params.placeholder}
            name={params.field}
            value={inputField}
            onChange={(event) => {
                const inputValue = event.target.value;
                setInputField(inputValue);
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    params.onChange(event)
                }, 700);
            }}

        />
    );
};


export {TextAreaCustom,TextInputCustom};