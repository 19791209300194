import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import Api from "../../utils/api";
import { setDate } from "date-fns";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { LaptopWindows } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "14px",
    marginTop: "40px"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    //maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    marginTop: "1%"
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const Email = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [age, setAge] = React.useState('');
  const [data, setData] = useState({
    encryption: '',
    fromMail: '',
    fromName: '',
    host: '',
    password: '',
    port: '',
    username: '',
  })

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };




  useEffect(() => {
    setData({
      encryption: respo?.encryption,
      fromMail: respo?.fromMail,
      fromName: respo?.fromName,
      host: respo?.host,
      password: respo?.password,
      port: respo?.port,
      username: respo?.username,
    })
  }, []);


 const userId = JSON.parse(localStorage.getItem("USER"));

 const [addLoadingStatus, setAddLoadingStatus] = useState(false);

  const handleSubmit = async (event) => {
    setAddLoadingStatus(true)
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("encryption", data.encryption);
    formData.append("fromMail", data.fromMail);
    formData.append("fromName", data.fromName);
    formData.append("password", data.password);
    formData.append("port", data.port);
    formData.append("host", data.host);
    formData.append("username", data.username);

    if (
       data.encryption == "" ||
      data.fromMail == "" ||
      data.fromName == "" ||
      data.host == "" ||
      data.password == "" ||
      data.port == "" ||
      data.username == ""
    ){
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}add_email_settings`,
          data: formData,
          headers: { "Content-Type": "application/json" },
        });
        if(response){ 
          if (response.data.message.toString() === "Updated Successfully!") {
            NotificationManager.success(response.data.message);
            setAddLoadingStatus(false)
          } else {
            NotificationManager.error(response.data.message);
            setAddLoadingStatus(false)
          }
        }else{
          NotificationManager.error(response.data.message);
          setAddLoadingStatus(false)
        }
      
      } catch (error) {
        console.log(error);
      }
    }
  };
        
  const handleChange = (event) => {
    setAge(event.target.value);
  };
 

  return (
    <>
    <Header title="Email"/>
      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail From Name
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="fromName"
          value={data.fromName}
          field="title"
          placeholder="Mail From Name"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>

      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail From Email
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          name="fromMail"
          value={data.fromMail}
          variant="outlined"
          field="title"
          placeholder="Mail From Email"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>

      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail Host
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          field="title"
          name="host"
          value={data.host}
          placeholder="Mail Host"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>

      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail Port
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          field="title"
          name="port"
          value={data.port}
          placeholder="Mail Port"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>


      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail Encryption
      </Typography>

      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={data.encryption}
        onChange={onChangeData}
        label="Mail Encryption"
        name="encryption"
        className={classes.dropdown}
        variant="outlined"
      >
        <MenuItem value={"SSL"}>SSL</MenuItem>
        <MenuItem value={"TLS"}>TLS</MenuItem>
        <MenuItem value={"None"}>None</MenuItem>
      </Select>


      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail Username
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="username"
          value={data.username}
          field="title"
          placeholder="Mail Username"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>

      <Typography variant="h6" className={classes.prescriptionTitle}>
        Mail Password
      </Typography>

      <Grid>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          name="password"
          value={data.password}
          field="title"
          placeholder="Mail Password"
          className={classes.style_1}
          onChange={onChangeData}
        />
      </Grid>


      <Box mb={3} style={{ marginTop: "2%", padding: "1%" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={globalClass.mediumBtnContainer}
          onClick={handleSubmit}
        >
         {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
        </Button>
      </Box>

    </>
  );
};

export default Email;
