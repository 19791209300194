import React from "react";
import { Box, Typography } from "@material-ui/core";

const TextItem = ({
  label,
  value,
  labelClass,
  valueClass,
  labelVariant,
  valueVariant,
  strongValue,
  strongLabel,
  className,
}) => {
  return (
    <Box className={className}>
      <Typography className={labelClass} variant={labelVariant}>
        {strongLabel ? <strong>{label}</strong> : label}
      </Typography>

      {value && (
        <Typography className={valueClass} variant={valueVariant}>
          {strongValue ? <strong>{value}</strong> : value}
        </Typography>
      )}
    </Box>
  );
};

export default TextItem;
