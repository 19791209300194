import {
  ADD_TEXT_INPUT,
  DELETE_TEXT_INPUT,
  ADD_FORM_INPUT,
  DELETE_FORM_INPUT,
  ADD_FORM_SUBMIT,
  USER_LOGIN,
  USER_LOGOUT,
  SWITCH_PROFILE,
  ADD_PRIMARY_PROFILE,
  SET_TENANT,
  SET_SHARED_ACCESS_BY,
  CHANGE_DOCTOR_CURRENT_STATUS,
  CHANGE_PRIMARY_USER_CURRENT_STATUS,
} from "../actionType";

let nextTodoId = 0;

export const addInputText = (content) => {
  return {
    type: ADD_TEXT_INPUT,
    payload: {
      id: ++nextTodoId,
      content,
    },
  };
};

export const deleteInputText = (index) => {
  return {
    type: DELETE_TEXT_INPUT,
    payload: {
      id: index,
    },
  };
};

export const addFormInput = (content) => {
  return {
    type: ADD_FORM_INPUT,
    payload: {
      id: ++nextTodoId,
      content,
    },
  };
};

export const deleteFormInput = (index) => {
  return {
    type: DELETE_FORM_INPUT,
    payload: {
      id: index,
    },
  };
};

export const addFormSubimt = (content) => {
  return {
    type: ADD_FORM_SUBMIT,
    payload: {
      id: ++nextTodoId,
      content,
    },
  };
};

// ----------------------------------------

export const userLogin = (user) => {
  return {
    type: USER_LOGIN,
    payload: user,
  };
};

export const userLogout = () => {
  return {
    type: USER_LOGOUT,
  };
};

// --------------------------------------

export const switchProfile = (profileInfo) => {
  return {
    type: SWITCH_PROFILE,
    payload: profileInfo,
  };
};

export const addPrimaryProfile = (primaryProfile) => {
  return {
    type: ADD_PRIMARY_PROFILE,
    payload: primaryProfile,
  };
};

export const setSharedAccessBy = (sharedAccessByList) => {
  return {
    type: SET_SHARED_ACCESS_BY,
    payload: sharedAccessByList,
  };
};

export const changePrimaryUserCurrentStatus = (updateCurrentStatus) => {
  return {
    type: CHANGE_PRIMARY_USER_CURRENT_STATUS,
    payload: updateCurrentStatus,
  };
};

// ---------------------------------------------

export const setTenant = (tenantData) => {
  return {
    type: SET_TENANT,
    payload: tenantData,
  };
};
