import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import Bro from "brototype";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { useCallback } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import Api from "../../utils/api";
import Axios from 'axios';
import AppConstants, { LOCAL_STORAGE_CONSTANTS } from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { getDayFromStartAndEnd, getTimeFromStartAndEnd } from "../../utils/helper";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import { NotificationManager } from "react-notifications";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { async } from "rxjs/internal/scheduler/async";
import Loader from "../../components/misc/loader";
import { Edit } from "@material-ui/icons";
import { retry } from "rxjs/operators";



const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  }
}));

const PaymentProfileTab = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [addLoadingStatus, setLoadingStatus] = useState(false);
  const [addStatus, setAddStatus] = useState(false)

  const [updateLoadingStatus, setupdateLoadingStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false)
  const [status, SetStatus] = useState(false)


  const [uniqueData, setUniqueData] = useState(null)

  const [uniqueID, setUniqueId] = useState(null); // for remove

  const [updateQrCode, setUpdateQrCode] = useState(null);

  const [UpdateQrCodeData, setUpdateQrCodeData] = useState(null);

  const handleStatus = (e) => {
    const value = e.target.checked
    SetStatus(value)
  }



  const [AddModalOpen, setAddModalOpen] = useState(false);
  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };
  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const handleUpdateModalOpen = (item) => (setUpdateModalOpen(true), setUniqueData(item)
  );


  const [openRemove, setOpenRemove] = React.useState(false);
  const handleOpenRemove = (_id) => (setOpenRemove(true), setUniqueId(_id));
  const handleCloseRemove = () => setOpenRemove(false);
  const [removeStatus, setRemoveStatus] = useState(false)

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
  };

  const [payTo, setPayTo] = useState(null)

  const handlePayTo = (e) => {
    setPayTo(e.target.value)
  }



  const handleAddQrCode = (e) => {

    const name = e.target.name;
    const value = e.target.files[0];
    if (e.target.files[0]) {

      setUpdateQrCode(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setUpdateQrCodeData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const [paymentType, setPaymentType] = useState(null);

  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);

  }

  const [description, setDescription] = useState(null);

  const handleDescription = (e) => {
    setDescription(e.target.value)
  }

  const [paymentTypeData, setPaymentTypeData] = useState(null);


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_payment_types?status=Active`).then(
      (resp) => {
        if (resp) {
          setPaymentTypeData(resp.data);
        }
      }
    );
  }, [])



  const [AllData, setAllData] = useState(null)

 

  const [getStatus, setGetStatus] = useState(true);
  const userId = JSON.parse(localStorage.getItem("USER"));
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_doctor_payment_options?id=${userId.id}`).then(
      (resp) => {
        if (resp) {
          setAllData(resp.data.paymentOptions);
          setGetStatus(false);
        }
      }
    );
  }, [addStatus, updateStatus])

  useEffect(() => {
    setPaymentType(uniqueData?.paymentModeName)
    setDescription(uniqueData?.description)
    setPayTo(uniqueData?.payTo)
    SetStatus(uniqueData?.status)
  }, [updateModalOpen, openRemove])

  const handleSave = async () => {
    setLoadingStatus(true)
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("paymentMode", paymentType);
    formData.append("payTo", payTo !== undefined ? payTo : description);
    formData.append("description", description);
    formData.append("qrCode", updateQrCode);


    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}add_doctor_payment_options`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (response) {
        if (response.data.message === "Added Successfully!") {
          NotificationManager.success(response.data.message);
          setAddModalOpen(false)
          setLoadingStatus(false)
          setAddStatus(!addStatus)
        }
        else {
          NotificationManager.error(response.data.message);
          setAddModalOpen(true)
          setLoadingStatus(false)
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  const handleUpdate = async () => {
  
    setupdateLoadingStatus(true);
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("paymentMode" ,uniqueData.paymentMode);
    formData.append("payTo", payTo);
    formData.append("description", description);
    formData.append("qrCode", updateQrCode);
    formData.append("itemId", uniqueData._id)
    formData.append("status", status)

    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}update_doctor_payment_options`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      })
      if (response) {
        if (response.data.message === "Updated Successfully!") {
          NotificationManager.success(response.data.message);
          setupdateLoadingStatus(false);
          setUpdateStatus(true)
          setUpdateModalOpen(false)

        }

        else {
          NotificationManager.error(response.data.message);
          setupdateLoadingStatus(false);
          setUpdateStatus(true)
        }
      }
    }
    catch (error) {
    }
  }

  return (
    <div>
      <Header title="Payment" />
      {
        getStatus ? <Loader /> :
          <Form>
            <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
              <Box className={classes.prescriptionSection}>
                <Box mt="1rem" display="flex" alignItems="center">

                </Box>

                <Paper className={clsx(classes.container, globalClass.roundWhiteBg)}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={handleAddModalOpen}
                    >
                      ADD
                    </Button>
                  </Box>
                  <TableContainer>
                    <Table aria-label="simple table" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>PayTo</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Type</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Description</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Status</strong>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          AllData?.map((item) => (
                            <TableRow>
                              <TableCell>
                                <Typography>{item.payTo}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{item.paymentModeName}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{item.description}</Typography>
                              </TableCell>

                              <TableCell>
                                <Typography>{`${item.status ?? "N/A"}`}</Typography>
                              </TableCell>
                              <TableCell>
                                {item.delete === false ? null :
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  startIcon={<Edit />}
                                  onClick={() => handleUpdateModalOpen(item)}
                                >
                                  Update
                                </Button>}
                              </TableCell>
                            </TableRow>)
                          )
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </div>
          </Form>
      }

      {AddModalOpen && (
        <Modal
          open={AddModalOpen}
          onClose={handleAddModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>

            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add Payment Details
              </Typography>
              <CloseIcon onClick={handleAddModalClose} style={{cursor: "pointer"}}/>
            </Box>

            {/* <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Pay to
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label="payment Type"
                  variant="outlined"
                  name="slug"
                  field="slug"
                  placeholder="1234567890@upi"
                  className={classes.style_2}
                  onChange={handlePayTo}

                />
              </Grid>
            </Box> */}

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Type
                </Typography>
              </Box>
              <Grid >

                <SelectInput
                  style={{ width: "100%" }}
                  required={true}
                  options={paymentTypeData}
                  onChange={handlePaymentType}
                  valueKey="_id"
                  labelKey="name"
                  field="doctorSlug"
                  placeholder="Select Doctor"
                  label="Payment type"
                  className={classes.style_2}
                />
              </Grid>
            </Box>
              {/* ID for UPI -> 635ba3683682b440056a3506 */}

            {paymentType === "635ba3683682b440056a3506" ? <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  UPI ID
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label="UPI ID"
                  variant="outlined"
                  name="slug"
                  field="slug"
                  placeholder="1234567890@upi"
                  className={classes.style_2}
                  onChange={handleDescription}
                />
              </Grid>
            </Box> : null}

            {
              <Box mb={3} style={{ marginTop: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleSave}
                >
                  {addLoadingStatus ? <Loader width="20px" height="20px" color="inherit" /> : "SAVE"}
                </Button>
              </Box>
            }
          </Box>
        </Modal>
      )}


      {updateModalOpen && (
        <Modal
          open={updateModalOpen}
          onClose={handleAddModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>

            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Update Payment Details
              </Typography>
              <CloseIcon onClick={handleUpdateModalClose} style={{cursor: "pointer"}}/>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Pay to
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="slug"
                  placeholder="1234567890@upi"
                  className={classes.style_2}
                  onChange={handlePayTo}
                  value={payTo}

                />
              </Grid>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <Box mt="2rem" display="flex" alignItems="center">
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Status
                  </Typography>
                </Box>
                <Grid container>
                  <Switch aria-label="File" checked={status} value={status} onChange={handleStatus} />
                </Grid>
              </Box>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Type
                </Typography>
              </Box>
              <Grid style={{ width: "100%" }}>

                <SelectInput
                  required={true}
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth
                  )}
                  options={paymentTypeData}
                  // options={["DCT1", "DCT1"]}
                  onChange={handlePaymentType}
                  value={uniqueData.paymentMode}
                  valueKey="_id"
                  labelKey="name"
                  field="doctorSlug"
                  placeholder="Select Doctor"
                  label="paymentType"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>
             
            </Box>



            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Description
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="slug"
                  value={description}
                  placeholder="1234567890@upi"
                  className={classes.style_2}
                  onChange={handleDescription}

                />
              </Grid>
            </Box>

            {
              <Box mb={3} style={{ marginTop: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}

                  onClick={handleUpdate}
                >
                  {updateLoadingStatus ? <Loader width="20px" height="20px" color="inherit" /> : "UPDATE"}
                </Button>
              </Box>
            }
          </Box>
        </Modal>
      )}

      {/* { openRemove &&
        <Modal
          open={openRemove}
          onClose={handleCloseRemove}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle} >
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Are you sure to remove.
            </Typography>

            <Grid container>
              <Grid item xs={6}>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleCloseRemove}
                  style={{ marginTop: "2%", width: "95%" }}
                >
                  Cancel
                </Button>

              </Grid>

              <Grid item xs={6}>

                <Button
                  // type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleRemove}
                  style={{ marginTop: "2%", width: "95%" }}
                 
                >
                  {removeStatus ? <Loader width="20px" height="20px" color="white" /> : "Remove"}
                </Button>

              </Grid>


            </Grid>

          </Box>
        </Modal>
      } */}

    </div>
  );
};


export default PaymentProfileTab;