import AppConstants from "../../utils/app-constants";

const getInitialState = () => {
  const storedSwitchProfileState = localStorage.getItem(
    AppConstants.SWITCH_PROFILE_REDUX_STATE
  );
  let switchProfile = {
    isPrimaryProfile: true,
    primaryProfileInfo: {},
    accessSharedBy: [],
    switchedProfileInfo: {},
  };
  if (storedSwitchProfileState) {
    try {
      switchProfile = JSON.parse(storedSwitchProfileState);
    } catch (error) {
      console.error("Failed to parse stored switch-profile state:", error);
    }
  }
  return switchProfile;
};

const profileSwitchInitialState = getInitialState();

export const profileSwitchReducer = (
  state = profileSwitchInitialState,
  action
) => {
  switch (action.type) {
    case "SWITCH_PROFILE":
      const isPrimaryProfile =
        state.primaryProfileInfo?.slug === action.payload?.doctorSlug;
      return {
        ...state,
        switchedProfileInfo: isPrimaryProfile ? {} : action.payload,
        isPrimaryProfile: isPrimaryProfile,
      };
    case "ADD_PRIMARY_PROFILE":
      return {
        ...state,
        primaryProfileInfo: action.payload,
        accessSharedBy: action.payload?.accessSharedBy,
      };
    case "SET_SHARED_ACCESS_BY":
      const primaryProfileForAccessSharedList =
        transformPrimaryProfileToAccessSharedBy(state.primaryProfileInfo);
      return {
        ...state,
        accessSharedBy: [
          ...action.payload,
          Object.fromEntries(primaryProfileForAccessSharedList),
        ],
      };
    case "CHANGE_PRIMARY_USER_CURRENT_STATUS":
      return {
        ...state,
        primaryProfileInfo: {
          ...state?.primaryProfileInfo,
          currentStatus: action.payload,
        },
      };
    default:
      return state;
  }
};

const transformPrimaryProfileToAccessSharedBy = (profile) => {
  let transformedProfile = new Map();
  transformedProfile.set("doctorSlug", profile?.slug);
  transformedProfile.set("doctorName", profile?.name);
  transformedProfile.set("doctorEmail", profile?.email);
  transformedProfile.set("doctorMobileNumber", profile?.mobileNumber);
  transformedProfile.set("doctorSpeciality", profile?.specialityName);
  transformedProfile.set("doctorProfilePicString", profile?.profilePicString);
  transformedProfile.set("accessSharedBy", []);
  transformedProfile.set("accessSharedTo", []);
  return transformedProfile;
};
