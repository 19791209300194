import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Scope } from "informed";
import useGlobalStyles from "../../utils/global-style";
import { TextInput, TextAreaInput, SelectInput } from "../../components/sixsprint-react-ui";
import Medication from "../../components/layout/medication";
import DataService from "../../utils/services/data-service";
import Api2 from "../../utils/api2";
import Loader from "../../components/misc/loader";
import Api from "../../utils/api";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  useQuery,
} from "../../utils/app-util";
import { isRequired } from "../../utils/validations";

import {
  Box,
  makeStyles,
  Paper,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
  Select,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import Axios from "axios";
import Vitals from "./vitals/vitals";
import CoMorbidities from "./Co-morbidities/Co-morbidities";
import Diagrams from "./diagrams/diagrams";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    marginLeft: "-1rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  templateName: {
    marginRight: "1rem",
  },
  input: {
    display: "none",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },

  prescriptionSection: {
    marginBottom: "2rem",
  },

  prescriptionTitle: {
    lineHeight: "normal",
    marginBottom: "10px",
  },
}));

const Template1 = ({ data, id, onDataUpdate, handleTypeChange }) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  let history = useHistory();
  const formApi = useRef();
  const fileRef = useRef(null);

  const [medications, setMedications] = useState([]);

  const selectedData = data?.filter((item) => {
    if (item._id === id) {
      return item;
    }
  });

  const query = useQuery();
  const slug = query.get("slug");
  const [editMode, setEditMode] = useState(false);
  const [emrTemplate, setEmrTemplate] = useState(null);
  const [uploadedDiagram, setUploadedDiagram] = useState([]);
  const [specialistData, setSepcilistData] = useState(null);
  const [uploadedDiagramid, setUploadedid] = useState([]);
  const [special, setSpecial] = useState("");
  const [emrTemplateName, setEmrTemplateName] = useState("");

  const [selectedVitals, setSelectedVitals] = useState([]);
  const [selectedCoMorbidities, setSelectedCoMorbidities] = useState([]);
  const [removeDiagram, setRemoveDiagram] = useState(false);

  const user = JSON.parse(localStorage.getItem("USER"));
  const [obj, setObject]=useState({});

  const handleChangeDiagram = (type)=>{
    setRemoveDiagram(type)
  }

  const fetchEmrTemplate = () => {
    if (slug) {
      onDataUpdate(true);
      setEditMode(true);
      Api.get(`/emr-template/new/${slug}`).subscribe((resp) => {
        handleTypeChange(resp?.data?.data?.emrTemplateTypeId);
        setEmrTemplate(resp?.data?.data);
        setUploadedDiagram(resp?.data?.data?.template?.diagrams || []);
        formApi.current.setValues(resp?.data?.data);
        setSpecial(resp?.data?.data?.specialitySlug); 
        setObject(resp?.data?.data?.template);
      });
    }
  };

  useEffect(() => {
    if (emrTemplate) {
      setMedications(emrTemplate?.template?.medications);
    }
  }, [emrTemplate]);

  useEffect(fetchEmrTemplate, [slug]);

  const onMedicationSelected = (val, index) => {
    formApi.current.setValue(`medications[${index}].name`, val.name);
    formApi.current.setValue(`medications[${index}].dose`, val.dose);
    formApi.current.setValue(`medications[${index}].frequency`, val.frequency);
    formApi.current.setValue(`medications[${index}].num`, val.num);
    formApi.current.setValue(`medications[${index}].interval`, val.interval);
    formApi.current.setValue(`medications[${index}].remarks`, val.remarks);
    formApi.current.setValue(`medications[${index}].id`, val.id);
    setMedications((prev) => {
      const medication = JSON.parse(JSON.stringify(prev));
      medication[index] = val;
      return [...medication];
    });
  };

  const onRemoveMedicationClicked = (val) => {
    setMedications((prevState) => {
      const newMedications = JSON.parse(JSON.stringify(prevState));
      newMedications.splice(val, 1);
      return [...newMedications];
    });
  };

  const copyMedicationsInterVal = (data) => {
    const { interval, num: number } = data;
    setMedications((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((item) => {
        item.num = number;
        item.interval = interval;
      });
      return [...newState];
    });
  };

  const handleChangeSelect = (event) => {
    setSpecial(event.target.value);
  };

  const handleEmrTemplateNameChange = (event) => {
    setEmrTemplateName(event.target.value);
  }

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSepcilistData(resp?.data);
          // setSpecial(resp?.data?.name);
        }
      }
    );
  }, []);

  const getVital = useCallback((currentVital) => {
    const finalVitalObj = {};
    currentVital.map((vital) => {
      let newObj = {};
      newObj[vital.label] = vital.val;
      return Object.assign(finalVitalObj, newObj);
    });
    return { ...finalVitalObj };
  }, []);


  const handleTextChange = (event, field) => {
    const newValue = event.target.value;
  
    setObject((prevFormData) => ({
      ...prevFormData,
      [field]: newValue
    }));
  };


  const handleSubmit = (val) => {
    const data = val;

    let tempname = data.name;
    delete data["name"];

    const templateObject = data;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        templateObject[key] = obj[key];
      }
    }
    let medicationData = medications?.filter((med) => med?.name && med?.dose);

    data.medications = medicationData;
    data.coMorbidities = getVital(selectedCoMorbidities);
    data.vitals = getVital(selectedVitals);
    data.diagramIds = uploadedDiagramid?.map((imageId) => imageId.imageId);

    const req = {
      name: emrTemplateName,
      specialitySlug: special,
      emrTemplateTypeId: id,

      template: data,
      createdBySlug: user.slug,
    };

    if (editMode) {
      const requpdate = {
        name: tempname,
        specialitySlug: special,
        emrTemplateTypeId: id,
        template: obj,
        createdBySlug: user.slug,
      };

      let tempEmr = requpdate;
      data.removeDiagrams = removeDiagram;

      tempEmr.template = data;

      Api.put(`emr-template/new/${emrTemplate._id}`, tempEmr).subscribe(
        (resp) => {
          if (resp.data.status === true) {
            SHOW_SUCCESS_NOTIFICATION(resp.data.message);
          } else {
            SHOW_ERROR_NOTIFICATION(resp.data.message);
          }
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    } else {
      Api.post(`emr-template/add_emr_template`, req).subscribe(
        (resp) => {
          SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  return (
    <>
      <Form apiRef={formApi} onSubmit={handleSubmit}>
            <Box className={classes.prescriptionSection}>
              <Paper
                className={clsx(classes.container, globalClass.roundWhiteBg)}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box display="flex">
                  <TextInput
                    className={globalClass.roundWhiteBg}
                    containerClassName={classes.templateName}
                    required
                    field="name"
                    type="text"
                    label="Template Name"
                    validateOnBlur
                    validateOnChange
                    onChange={handleEmrTemplateNameChange}
                  />

                  <SelectInput
                    required
                    className={globalClass.roundWhiteBg}
                    options={specialistData}
                    validate={isRequired}
                    field="specialitySlug"
                    placeholder="Speciality"
                    label="Speciality"
                    labelKey="name"
                    valueKey="_id"
                    onChange={handleChangeSelect}
                    validateOnBlur
                    validateOnChange
                  />
                </Box>
              </Paper>
            </Box>

            {editMode ? (
              <>
                <Box className={classes.prescriptionSection}>
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Template Name : {emrTemplate?.name}
                  </Typography>

                  {selectedData[0]?.fields.map((fields) => {
                    return (
                      <>
                        <>
                          {fields.showInAdd === 1 && (
                            <Typography
                              variant="h6"
                              className={classes.prescriptionTitle}
                            >
                              {fields.name}
                            </Typography>
                          )}

                          {fields.showInAdd === 1 && (
                            <Paper
                              className={clsx(
                                classes.container,
                                globalClass.roundWhiteBg
                              )}
                            >
                              {fields.type === "medications" ? (
                                <Medication
                                  onRemoveMedicationClicked={
                                    onRemoveMedicationClicked
                                  }
                                  data={medications}
                                  onMedicationSelected={onMedicationSelected}
                                  setMedicationData={setMedications}
                                  copyMedicationsInterVal={
                                    copyMedicationsInterVal
                                  }
                                />
                              ) : null}

                              {fields.type === "vitals" ? (
                                <Vitals
                                  setSelectedVitals={setSelectedVitals}
                                  selectedVitals={selectedVitals}
                                />
                              ) : null}

                              {fields.type === "coMorbidities" ? (
                                <CoMorbidities
                                  setSelectedCoMorbidities={
                                    setSelectedCoMorbidities
                                  }
                                  selectedCoMorbidities={selectedCoMorbidities}
                                />
                              ) : null}

                              {fields.type === "text" ||
                              fields.type === "dietaryRestriction" ||
                              fields.type === "investigation" ? (
                                <TextAreaInput
                                  field={fields.field}
                                  placeholder={fields.placeholder}
                                  value={obj[`${fields.field}`]}
                                  onChange={(event) =>
                                    handleTextChange(event, fields.field)
                                  }
                                />
                              ) : null}

                              {fields.type === "diagrams" ? (
                                <Diagrams
                                  setUploadedid={setUploadedid}
                                  uploadedDiagrams={uploadedDiagram}
                                  handleChangeDiagram1={handleChangeDiagram}
                                />
                              ) : null}
                            </Paper>
                          )}
                        </>

                        <br />
                      </>
                    );
                  })}
                </Box>

                {id && (
                  <Box textAlign="center" my={8}>
                    <Button
                      className={globalClass.largeBtnContainer}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box className={classes.prescriptionSection}>
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Template Name : {selectedData[0]?.name}
                  </Typography>

                  {selectedData[0]?.fields.map((fields) => {
                    return (
                      <>
                        <>
                          {fields.showInAdd === 1 && (
                            <Typography
                              variant="h6"
                              className={classes.prescriptionTitle}
                            >
                              {fields.name}
                            </Typography>
                          )}

                          {fields.showInAdd === 1 && (
                            <Paper
                              className={clsx(
                                classes.container,
                                globalClass.roundWhiteBg
                              )}
                            >
                              {fields.type === "medications" ? (
                                <Medication
                                  onRemoveMedicationClicked={
                                    onRemoveMedicationClicked
                                  }
                                  data={medications}
                                  onMedicationSelected={onMedicationSelected}
                                  setMedicationData={setMedications}
                                  copyMedicationsInterVal={
                                    copyMedicationsInterVal
                                  }
                                />
                              ) : null}

                              {fields.type === "vitals" ? (
                                <Vitals
                                  setSelectedVitals={setSelectedVitals}
                                  selectedVitals={selectedVitals}
                                />
                              ) : null}

                              {fields.type === "coMorbidities" ? (
                                <CoMorbidities
                                  setSelectedCoMorbidities={
                                    setSelectedCoMorbidities
                                  }
                                  selectedCoMorbidities={selectedCoMorbidities}
                                />
                              ) : null}

                              {fields.type === "text" ||
                              fields.type === "dietaryRestriction" ||
                              fields.type === "investigation" ? (
                                <TextAreaInput
                                  field={fields.field}
                                  placeholder={fields.placeholder}
                                />
                              ) : null}

                              {fields.type === "diagrams" ? (
                                <Diagrams
                                  setUploadedid={setUploadedid}
                                  uploadedDiagrams={uploadedDiagram}
                                  handleChangeDiagram1={handleChangeDiagram}
                                />
                              ) : null}
                            </Paper>
                          )}
                        </>

                        <br />
                      </>
                    );
                  })}
                </Box>

                {id && (
                  <Box textAlign="center" my={8}>
                    <Button
                      className={globalClass.largeBtnContainer}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </>
            )}
      </Form>
    </>
  );
};

export default Template1;
