import Api from "./api";
import { DEFAULT_ERROR_CALLBACK } from "./app-util";
import Bro from "brototype";
import Axios from "axios";
import AuthService from "./services/auth-service";

const ServerDataSource = {
  instance({ size, url, defaultFilter, deepFilter }) {
    const tenant = AuthService.getTenant();

    return {
      getRows(params) {
        const page =
          (Bro(params).iCanHaz("request.endRow") || 0) / (size || 1) - 1;

          let filters ;
          if(url==="patient"){
            filters =  {...defaultFilter,...Bro(params).iCanHaz("request.filterModel")}
          }
          else if(url==="doctor"){
            filters = defaultFilter || Bro(params).iCanHaz("request.filterModel") || {};
            filters['custom'] = {
              "filterType": "exact",
              "filter": "removeImages"
            }
          }
          else{
            filters = defaultFilter || Bro(params).iCanHaz("request.filterModel") || {};
          }


        const obj = {
          deepFilter,
          page,
          size,
          filterModel: filters,
          sortModel: (Bro(params).iCanHaz("request.sortModel") || [{callId:"dateTime", sort: "ASC" }]).map(
            aSort => {
              return {
                sort: aSort.sort.toUpperCase(),
                colId: aSort.colId
              };
            }
          )
        };



        if (url === "referral-doctors") {
          Axios.get(`${process.env.REACT_APP_API2_HOST}referral-doctors?size=${size}&page=${page}&tenant=${tenant.id}`)
          .then((resp) => {
              const { content, ...pageProps } =
                Bro(resp).iCanHaz("data.data") || {};
              params.successCallback(content, pageProps.totalElements);
            })
            .catch((err) => {
              params.failCallback();
              DEFAULT_ERROR_CALLBACK(err);
            });
        } 
        
        else if(url === "emr-template"){
          Api.post(`${url}/search_new`, obj).subscribe(
            (resp) => {
              const { content, ...pageProps } =
                Bro(resp).iCanHaz("data.data") || {};
              params.successCallback(content, pageProps.totalElements);
            },
            (err) => {
              params.failCallback();
              DEFAULT_ERROR_CALLBACK(err);
            }
          );
        }
        else {
          Api.post(`${url}/search`, obj).subscribe(
            (resp) => {
              const { content, ...pageProps } =
                Bro(resp).iCanHaz("data.data") || {};
              params.successCallback(content, pageProps.totalElements);
            },
            (err) => {
              params.failCallback();
              DEFAULT_ERROR_CALLBACK(err);
            }
          );
        }
      }
    };
  }
};

export default ServerDataSource;
