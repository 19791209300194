import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../components/misc/loader";
import Api2 from "../../../utils/api2";
import { DEFAULT_ERROR_CALLBACK, SHOW_ERROR_NOTIFICATION } from "../../../utils/app-util";

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    appbar: {
      background: theme.palette.bg,
      paddingTop: "1rem",
    },

    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: theme.palette.common.black,
      fontWeight: "bold",
      opacity: "0.9",
    },
    input: {
      display: "none",
    },
    prescriptionSection: {
      marginBottom: "2rem",
    },
    container: {
      padding: theme.spacing(10),
    },
    textField: {
      width: "80%",
    },

    printBtnContainer: {
      width: "100%",
      padding: theme.spacing(6),
    },

    loaderStyle: {
      color: "white",
    },
    dialogBackground: {
      backgroundColor: theme.palette.bg,
      width: "90%",
      height: "90%",
      margin: "auto",
    },
    containerBox: {
      width: "100%",
      flexGrow: 1,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 5px 20px #00000008",
      borderRadius: 14,
      margin: theme.spacing(5, 0),
      padding: theme.spacing(8, 12),
    },
    presBox: {
      height: "20vh",
      overflowY: "scroll",
    },
    reportTitle: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontSize: "0.7rem",
    },
    reportClose: {
      height: "10px",
      width: "20px",
      display: "flex",
      margin: "5px",
      borderRadius: "20px",
      padding: "10px 15px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "lightgray",
    },
    reportImage: {
      height: "94%",
      width: "90%",
      margin: "auto",
      borderRadius: "15px",
    },
    multipleImageContainer: {
      width: "89%",
      maxHeight: "70%",
      margin: "0px auto",
    },
    reportMultipleImage: {
      maxHeight: "60%",
      width: "60%",
      margin: "0px auto",
      borderRadius: "15px",
    },
    reportPdf: {
      width: "80%",
      height: "500px",
      margin: "0px 70px",
    },
    noReportImage: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "30%",
    },
    noReports: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    reportContainer: {
      display: "flex",
    },
    closeTitle: {
      fontSze: "12px",
    },
    reportHeading: {
      alignSelf: "center",
      marginLeft: "50%",
      transform: "translate(-75%)",
    },
    prescriptionTitle: {
      fontSize: "0.8rem",
      fontWeight: "700",
      opacity: "0.7",
      lineHeight: "normal",
      marginBottom: "10px",
    },
    diagramContainer: {
      display: "flex",
    },
    diagramClose: {
      height: "10px",
      width: "20px",
      display: "flex",
      margin: "5px",
      borderRadius: "20px",
      padding: "10px 15px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "lightgray",
    },
    closeTitle: {
      fontSze: "12px",
    },
    diagramImage: {
      height: "90%",
      width: "90%",
      margin: "auto",
      borderRadius: "15px",
    },

    "prescription-details": {
      padding: "40px 30px",
      marginBottom: "2.5rem",

      "&__column": {
        [theme.breakpoints.down("sm")]: {
          "&:nth-last-child(n+3)": { marginBottom: "20px" },
        },

        [theme.breakpoints.up("md")]: {
          "&:nth-last-child(n+4)": { marginBottom: "20px" },
        },
      },

      "&__title": {
        color: "#1A1A1A",
        font: "bold 16px/18px Comfortaa",
        marginBottom: "8px",
      },

      "&__sub-title": {
        color: "#6D6D6D",
        font: "lighter 16px/18px Comfortaa",
        paddingBottom: "12px",
        borderBottom: "1px solid #E6E6E6",
      },
    },

    "prescription-qr": {
      textAlign: "center",
      padding: "20px 30px 30px",
      marginBottom: "2.5rem",

      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },

      "&__img": {
        maxWidth: "100%",
        margin: "-15px",
      },
    },
    vitals: {
      "&__column": {
        [theme.breakpoints.down("sm")]: {
          "&:nth-last-child(n+3)": { marginBottom: "30px" },
        },

        [theme.breakpoints.up("md")]: {
          "&:nth-last-child(n+4)": { marginBottom: "30px" },
        },
      },
    },
    otherEmailField: {
      width: "400px",
    },
    vitalSign: {
      maxWidth: "300px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "230px",
      },

      "& h6": {
        width: "40px",
      },

      "& .MuiPaper-root": {
        flex: 1,
      },
    },
    restrictionControlBox: {
      display: "flex",
      alignItems: "center",
    },
    clrBtnBox: {
      padding: 20,
    },

    imageDiv: {
      maxWidth: "100px",
      width: "100%",
      height: "100px",
      maxHeight: "100%",
      border: "1px solid black",
      borderRadius: "50%",
    },
  }));

const Diagrams = ({setUploadedid, uploadedDiagrams, handleChangeDiagram1}) => {
  const classes = useStyles();
  const [uploadedDiagramid, setUploadedDiagramid] = useState([]);
  const [uploadedDiagram, setUploadedDiagram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [removeImages, setRemoves] = useState(false);
  const [removeDiagram, setRemoveDiagram] = useState(false);

  
  useEffect(()=>{
      setUploadedDiagram((prev)=>([
        ...prev, ...uploadedDiagrams
      ]))    
  },[uploadedDiagrams])
  console.log(uploadedDiagram);

  const handleChangeDiagram = (evt) => {
    setIsLoading(true);

    const files = Array.prototype.slice.call(evt.target.files);
    const supportedFormats = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only PDF, JPG, JPEG, and PNG files are allowed."
      );
      setIsLoading(false);
      return;
    }
    
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();

    for (let i = 0; i < fileArray.length; i++) {
      formData.append("files", fileArray[i]);
    }
    Api2.post(`upload_temp_image`, formData).subscribe(
      (resp) => {
        setUploadedDiagramid(resp.data.data);
        setUploadedid(resp.data.data)
        setUploadedDiagram(resp.data.data);
        setIsLoading(false);

        // setRemoveDiagram(false);
      },
      (error) => {
        setIsLoading(false);
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };



  const onRemoveDiagramsClicked = () => {
    setUploadedid([]);
    setUploadedDiagram([]);
    setRemoveDiagram(true);
    handleChangeDiagram1(true);
  };

  return (
    <>
      <Box style={{ display: "flex", position: "relative" }}>
        <Box my="1rem" style={{ float: "left" }}>
          <Typography
            variant="h6"
            className={classes.prescriptionTitle}
            style={{ float: "left" }}
          >
            Diagram
          </Typography>
        </Box>

        {uploadedDiagram && uploadedDiagram?.length > 0 && (
          <Button
            style={{ position: "absolute", right: "3%" }}
            // onClick={onRemoveImageClicked.bind(this, "diagram")}
            onClick={onRemoveDiagramsClicked}
            color="primary"
            variant="outlined"
          >
            Remove Diagrams
          </Button>
        )}
      </Box>

      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        multiple
        onChange={handleChangeDiagram}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {isLoading ? (
            <Loader color="secondary" height="20px" size={20} />
          ) : (
            "Click to Upload Diagrams"
          )}
        </Button>
      </label>
      <Box display="flex" flexWrap="wrap" mr={4} mt={10}>
        {uploadedDiagram &&
          uploadedDiagram?.length > 0 &&
          uploadedDiagram?.map((item, index) => {
            return (
              <img
                key={index}
                alt="diagram"
                width="200"
                src={
                  item?.content
                }
              />
            );
          })}
      </Box>
    </>
  );
};

export default Diagrams;
