import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import Api from "../../utils/api";
import { setDate } from "date-fns";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding: "1.8%",
    border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const SeoSetting = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const [Loading, setLoading] = useState(false);
  const [seoData, setSeoData] = useState({
    meta: "",
    metaDes: "",
    metakeyword: "",
    type: "",
    id: "",
  });

  const onChangeData = (event) => {
    setSeoData({ ...seoData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setSeoData({
      meta: respo?.meta_title,
      metaDes: respo?.meta_desp,
      metakeyword: respo?.meta_keyword,
      id: respo?.id,
    });
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true)
    const req = {
      meta_title: seoData?.meta,
      meta_desp: seoData?.metaDes,
      meta_keyword: seoData?.metakeyword,
      type: "metadata",
      id: seoData?.id,
    };
    if (
      seoData.meta == "" ||
      seoData.metaDes == "" ||
      seoData.metakeyword == ""
    ) {
      SHOW_ERROR_NOTIFICATION("All feild required");
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/websiteupdate`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.toString() == "succss") {
          setLoading(false)
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
        } else {
          NotificationManager.success("Something went wrong Please try again");
        }
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
  };

  return (
    <>
      <Header title="SEO Setting" />
      <Form onSubmit={handleSubmit}>
        <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Meta Title
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="meta"
                field="title"
                placeholder="Meta title"
                className={classes.style_1}
                value={seoData.meta}
                onChange={onChangeData}
              />
            </Grid>
          </Box>
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Meta Description
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="metaDes"
                field="title"
                placeholder="meta description"
                className={classes.style_1}
                value={seoData.metaDes}
                onChange={onChangeData}
              />
            </Grid>
          </Box>
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Meta Keyword
              </Typography>
            </Box>

            <Grid>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="metakeyword"
                field="title"
                placeholder="meta keywords"
                className={classes.style_1}
                value={seoData.metakeyword}
                onChange={onChangeData}
              />
            </Grid>
          </Box>

          <Box mb={3} style={{ marginTop: "2%" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
            >
              {Loading ? <Loader width="200px" height="20px" color="white"/> : "Save"}
            </Button>
          </Box>
        </div>
      </Form>
    </>
  );
};

export default SeoSetting;
