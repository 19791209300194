import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
  Checkbox
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import Api from "../../utils/api";
import { setDate } from "date-fns";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
    marginTop: "20px"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding: "1.8%",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const Que = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [data, setData] = useState({
    video1:'',
    sound1: '',
    video2: '',
    sound2: '',
  })



  const onChangeData = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  }

  const [checkedOne, setCheckedOne] = useState(false);

  const [checkedTwo, setCheckedTwo] = useState(true);
  const updateOne = () => setCheckedOne((prev) => !prev);
  const updateTwo = () => setCheckedTwo((prev) => !prev);

  useEffect(() => {
    setData({ 
      video1: respo?.video1,
      sound1: respo?.sound1 ,
      video2: respo?.video2,
      sound2: respo?.sound2,

    })
  }, []);

  
  const [addLoadingStatus, setAddLoadingStatus] = useState(false);
  const handleSubmit = async (event) => {
    setAddLoadingStatus(true)
    const formData = new FormData();
    formData.append("video1", data.video1);
    formData.append("sound1", checkedOne);
    formData.append("video2", data.video2);
    formData.append("sound2", checkedTwo);
    if (
      data.video1 == "" ||
      data.video2 == "" 
    
   ) {
    SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
  } else {
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}add_que`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.message.toString() == "Updated Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false)
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false)
      }
    } catch (error) {
      console.log(error);
    }
  }
};
 

  return (
    <>
        <Header title="Queue" />

          <Typography variant="h6" className={classes.prescriptionTitle}>
            Video 1
          </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="video1"
            value={data.video1}
            field="title"
            placeholder="Url link"
            onChange={onChangeData}
            className={classes.style_1}
          />
        </Grid>
        <Typography variant="h6" className={classes.prescriptionTitle}>
          Audio
          <Checkbox
            name="sound1"
            color="primary"
            size="medium"
            checked={checkedOne} 
            
            onChange={updateOne}
          />
        </Typography>

        <Typography variant="h6" className={classes.prescriptionTitle}>
          Video 2
        </Typography>
        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="video2"
            value={data.video2}
            field="title"
            placeholder="Url link"
            onChange={onChangeData}
            className={classes.style_1}
          />
        </Grid>
        <Typography variant="h6" className={classes.prescriptionTitle}>
          Audio
          <Checkbox
            name="sound2"
            color="primary"
            size="medium"
            checked={checkedTwo} 
            onChange={updateTwo}
          />
        </Typography>

        <Box mb={3} style={{ marginTop: "2%",padding: "%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.mediumBtnContainer}
            onClick={handleSubmit}
          >
            {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
          </Button>
        </Box>
    </>
  );
};

export default Que;
