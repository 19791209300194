import {
  Grid,
  Box,
  DialogContent,
  Divider,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";
import { Form } from "informed";
import React, { useState } from "react";
import { TextInput } from "sixsprints-react-ui";
import Api from "../../utils/api";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: ".7rem",
    fontWeight: "bold",
  },
  value: {
    fontSize: ".7rem",
  },
  loaderStyle: {
    color: theme.palette.common.primary,
  },
}));

const AddDiscountDialog = ({ open = false, handleClose, appointmentObj }) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [isFriends, setIsFriends] = useState(false);

  const handleFriendCheckedChange = () => {
    setIsFriends(!isFriends);
  };

  const handleSubmit = (val) => {
    val.isFriends = isFriends;
    val.isFamily = false;
    if (appointmentObj) {
      Api.put(`/appointment/discount/${appointmentObj.slug}`, val).subscribe(
        () => {
          SHOW_SUCCESS_NOTIFICATION("Discount Added successfully !");
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
      handleClose();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.heading}>
        Add Discount
      </DialogTitle>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        // initialValues={{ date: appointmentObj.discount }}
      >
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <TextInput
              required
              className={clsx(globalClass.roundWhiteBg, globalClass.inputWidth)}
              field="discount"
              type="number"
              label="Discount"
              validateOnBlur
              validateOnChange
            />

            <Box
              mt=".5rem"
              ml="1rem"
              px="1.7rem"
              py="0.4rem"
              border="1px solid rgba(0, 0, 0, 0.22);"
              className={clsx(globalClass.roundWhiteBg, globalClass.pointer)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFriends}
                    onChange={handleFriendCheckedChange}
                    name="friends"
                    color="primary"
                  />
                }
                label="Friends & Family"
              />
            </Box>
          </Grid>
          <Button
            style={{ marginTop: "18px" }}
            className={globalClass.largeBtnContainer}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogContent>
      </Form>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDiscountDialog;
