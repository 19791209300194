import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
  Select,
  IconButton,
} from "@material-ui/core";
import { Form, Scope } from "informed";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import { TextInput, TextAreaInput, SelectInput } from "../../components/sixsprint-react-ui";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  useQuery,
} from "../../utils/app-util";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import DataService from "../../utils/services/data-service";
import MenuIcon from "@material-ui/icons/Menu";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { SidebarContext } from "../../context/sidebar";
import { isRequired } from "../../utils/validations";
import { useHistory } from "react-router-dom";
import Loader from "../../components/misc/loader";
import Medication from "../../components/layout/medication";
import Axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import CustomPrescriptionField from "../customPrescriptionFeild/customPrescriptionFeilds";
import Template1 from "./template1";
import Template2 from "./template2";
import Template3 from "./template3";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    marginLeft: "-1rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  templateName: {
    marginRight: "1rem",
  },
  input: {
    display: "none",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },

  prescriptionSection: {
    marginBottom: "2rem",
  },

  prescriptionTitle: {
    lineHeight: "normal",
    marginBottom: "10px",
  },
}));

const NewEmr = () => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();

  let history = useHistory();
  const [specialities, setSpecialities] = useState([]);
  const [emrTemplate, setEmrTemplate] = useState(null);
  const [medications, setMedications] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedDiagram, setUploadedDiagram] = useState([]);
  const [imageid, setImageId] = useState(null);
  const sidebarContext = SidebarContext.useContainer();

  const handleDataUpdate = (updatedData) => {
    setEditMode(updatedData);
  };

  const handleChange = (evt) => {
    setIsLoading(true);
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    for (let i = 0; i < fileArray.length; i++) {
      formData.append("files", fileArray[i]);
    }
    Api2.post(`22`, formData).subscribe(
      (resp) => {
        setUploadedDiagram(resp?.data?.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const onRemoveDiagramsClicked = () => {
    setUploadedDiagram([]);
  };

  const fetchSpecialities = () => {
    DataService.getList(`/data/specialities`).subscribe((resp) => {
      setSpecialities(resp);
    });
  };

  const onRemoveMedicationClicked = (val) => {
    setMedications((prevState) => {
      const newMedications = JSON.parse(JSON.stringify(prevState));
      newMedications.splice(val, 1);
      return [...newMedications];
    });
  };

  useEffect(fetchSpecialities, []);
  const copyMedicationsInterVal = (data) => {
    const { interval, num: number } = data;
    setMedications((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((item) => {
        item.num = number;
        item.interval = interval;
      });
      return [...newState];
    });
  };

  const DocSlug = JSON.parse(localStorage.getItem("USER"));

  const handleSubmit = (val) => {
    let medicationData = medications?.filter((med) => med?.name && med?.dose);
    if (val.template) {
      val.template.diagrams = null;
      val.template.diagramIds = uploadedDiagram?.map(
        (imageId) => imageId.imageId
      );
      val.template.medications = medicationData;
      val.createdBySlug = DocSlug.slug;
    }
    if (editMode) {
      let tempEmr = emrTemplate;
      tempEmr.name = val.name;
      tempEmr.specialitySlug = val.specialitySlug;
      tempEmr.createdBySlug = DocSlug.slug;

      tempEmr.template = val.template;
      Api.put(`emr-template/new/${emrTemplate.id}`, tempEmr).subscribe(
        (resp) => {
          if (resp.data.status === true) {
            SHOW_SUCCESS_NOTIFICATION(resp.data.message);
          } else {
            SHOW_ERROR_NOTIFICATION(resp.data.message);
          }
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    } else {
      Api.post(`emr-template`, val).subscribe(
        () => {
          SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
          history.goBack();
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  const onBackIconClicked = () => {
    history.goBack();
  };

  const [specialistData, setSepcilistData] = useState(null);
  const [special, setSpecial] = useState("");

  const handleChangeSelect = (event) => {
    setSpecial(event.target.value);
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSepcilistData(resp?.data);
          setSpecial(resp?.data?.name);
        }
      }
    );
  }, []);

  // ----------------Start from Here ----------------//
  const [type, setType] = useState("urology Template");
  const [emrTemplateType, setEmrTemplateType] = useState([]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleTypeChange2 = (data) => {
    setType(data);
  };

  useEffect(() => {
    Api.get(
      `${process.env.REACT_APP_API_HOST}emr-template/get_emr_template_type`
    ).subscribe((resp) => {
      setEmrTemplateType(resp.data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSepcilistData(resp?.data);
          setSpecial(resp?.data?.name);
        }
      }
    );
  }, []);

  return (
    <Box minHeight="100vh">
      <Box className={globalClass.container}>
        <AppBar
          className={classes.appbar}
          color="default"
          position="static"
          elevation={0}
        >
          <Toolbar>
            <IconButton color="inherit" onClick={onBackIconClicked}>
              <BackIcon />
            </IconButton>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={sidebarContext.handleSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>

            <Typography variant="h5" className={classes.title}>
              {`${editMode ? "Update " : "Add "}EMR Template`}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box className={classes.prescriptionSection}>
          <Paper
            className={clsx(classes.container, globalClass.roundWhiteBg)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Select
                id="demo-simple-select"
                value={type}
                label="Age"
                onChange={handleTypeChange}
                variant="outlined"
                // className={globalClass.roundWhiteBg}
                style={{ width: "200px", margin: "2.1%" }}
              >
                {emrTemplateType?.map((items) => {
                  return <MenuItem value={items._id}>{items.name}</MenuItem>;
                })}
              </Select>
            </Box>
          </Paper>
        </Box>

        <Template1 data={emrTemplateType} id={type} onDataUpdate={handleDataUpdate} handleTypeChange={handleTypeChange2}/>

        {/*       
        {type === "type2" ? <Template2 /> : null}

        {type === "type3" ? <Template3 /> : null} */}

      </Box>
    </Box>
  );
};

export default NewEmr;
