import React, { Component } from "react";

import { Form } from "informed";
import { isRequired } from "../../utils/validations";
import TextInput from "../form/text-input";
import Bro from "brototype";
import moment from "moment";
import CustomDatePicker from "../form/date-picker";
import { CustomDateInput } from "sixsprints-react-ui";

const map = {
  equals: "isSame",
  notEqual: "isSame",
  lessThan: "isBefore",
  lessThanOrEqual: "isSameOrBefore",
  greaterThan: "isAfter",
  greaterThanOrEqual: "isSameOrAfter",
  inRange: "isBetween",
};

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "equals",
      field: Bro(props).iCanHaz(`colDef.field`),
    };
    this.formApi = {};
  }

  setModel = (params) => {
    if (!params) {
      this.clear(this.formApi);
    }
  };

  getModel = () => {
    const { data } = this.state;
    if (!data || !data.filter) {
      return null;
    }
    return { ...data, filterType: "date" };
  };

  isFilterActive = () => {
    const { data } = this.state;
    return data && data.filter;
  };

  handleChange = (evt, formApi) => {
    const val = evt.target.value;
    formApi.setValue("type", val);
    this.setState({ type: val }, () => formApi.submitForm());
  };

  handleSubmit = (data) => {
    this.setState({ data }, () => this.props.filterChangedCallback());
  };

  clear = (formApi) => {
    formApi.reset();
    this.setState({ data: null, type: "equals" }, () =>
      this.props.filterChangedCallback()
    );
  };

  setFormApi = (formApi) => {
    this.formApi = formApi;
  };

  doesFilterPass = (params) => {
    const { field, type, data } = this.state;
    const value = Bro(params).iCanHaz(`data.${field}`);
    const dateFormat = "YYYY/MM/DD";
    if (!value) {
      return false;
    }
    if (type !== "inRange") {
      const answer = moment(value, dateFormat)[map[type]](data.filter);
      return type === "notEqual" ? !answer : answer;
    }
    const answer = moment(value, dateFormat).isBetween(
      data.filter,
      data.filterTo,
      null,
      "[]"
    );
    return answer;
  };

  render() {
    const { type } = this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{ padding: "0 10px", height: 350, width: 260 }}
        initialValues={{ type }}
        getApi={this.setFormApi}
      >
        {({ formApi }) => (
          <React.Fragment>
            <select
              value={type}
              onChange={(evt) => this.handleChange(evt, formApi)}
              style={{ marginBottom: "10px" }}
            >
              <option value="equals">Equal</option>
              <option value="notEqual">Not Equal</option>
              <option value="lessThan">Less Than</option>
              <option value="lessThanOrEqual">Less Than or Equal</option>
              <option value="greaterThan">Greater Than</option>
              <option value="greaterThanOrEqual">Greater Than or Equal</option>
              <option value="inRange">In Range</option>
            </select>

            <TextInput type="hidden" field="type" hideTick />

            <CustomDatePicker
              hideLabel={true}
              className="form-control"
              field="filter"
              placeholder="From Date"
              validate={isRequired}
              validateOnChange
            />

            <div style={{ margin: "20px 0" }}>
              {type === "inRange" && (
                <CustomDatePicker
                  hideLabel={true}
                  validate={isRequired}
                  className="form-control"
                  field="filterTo"
                  placeholder="To Date"
                />
              )}
            </div>

            <button type="submit">Apply Filters</button>

            <button
              type="button"
              style={{ marginLeft: 8 }}
              onClick={() => this.clear(formApi)}
            >
              Clear Filters
            </button>
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default DateFilter;
