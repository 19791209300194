import React from "react";
import { Route, Redirect } from "react-router-dom";
import Routes from "./routes";
import AuthService from "../utils/services/auth-service";

const NonPrivateRoute = ({ component: Component, ...rest }) => {
  const redirectPath = rest.redirectPath || Routes.MAIN;
  return (
    <Route
      {...rest}
      render={props =>
        !AuthService.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: localStorage.getItem('intendedUrl') || redirectPath }} />
        )
      }
    />
  );
};

export default NonPrivateRoute;
