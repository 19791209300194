import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";

import {
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "14px",
    marginTop: "30px"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding: "1.8%",

  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    padding: "0.8%",
    marginTop: "1%"
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const Sms = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [data, setData] = useState({
    apikey: '',
    baseUrl: '',
    senderid: '',

  })

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setData({
      apikey: respo?.apikey,
      baseUrl: respo?.baseUrl,
      senderid: respo?.senderid,
      
    })
  }, []);

  const userId = JSON.parse(localStorage.getItem("USER"));

  const [addLoadingStatus, setAddLoadingStatus] = useState(false)

  const handleSubmit = async (event) => {
    setAddLoadingStatus(true)
    const formData = new FormData();
    formData.append("id", userId.id);
    formData.append("apikey", data.apikey);
    formData.append("baseUrl", data.baseUrl);
    formData.append("senderid", data.senderid);
    if (
      data.apikey == "" ||
     data.baseUrl == "" ||
     data.senderid == ""
   ) {
    SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
  } else {
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}/add_sms_settings`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });
      if(response){
      if (response.data.message.toString() == "Updated Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false)
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false)
      }
    }
    else{
      NotificationManager.error(response.data.message);
      setAddLoadingStatus(false)
    }
    } catch (error) {
      console.log(error);
    }
  }
};
    
  
  return (
    <>
    <Header title="SMS"/>
        <Typography variant="h6" className={classes.prescriptionTitle}>
            Sender ID
        </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="senderid"
            value={data.senderid}
            field="title"
            placeholder="Sender ID"
            className={classes.style_2}
            onChange={onChangeData}
          />
        </Grid>

        <Typography variant="h6" className={classes.prescriptionTitle}>
            Api Key
        </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="apikey"
            value={data.apikey}
            field="title"
            placeholder="Api Key"
            onChange={onChangeData}
            className={classes.style_2}
          />
        </Grid>
        
        <Box mb={3} style={{ marginTop: "2%",padding: "1%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.mediumBtnContainer}
            onClick={handleSubmit}
          >
            {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
          </Button>
        </Box>

    </>
  );
};

export default Sms;
