import { ADD_TEXT_INPUT, DELETE_TEXT_INPUT } from "../actionType";
import { useDispatch } from "react-redux";
import { addFormInput } from "../action/action";

const initialState = {
  inputTextDAta: [],
};

const inputList = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEXT_INPUT: {
      const { id, content } = action.payload;
      console.log(action.payload);

      return {
        ...state,
        inputTextDAta: [...state.inputTextDAta, content],
      };
    }

    case DELETE_TEXT_INPUT: {
      const { id } = action.payload;

      return {
        ...state,
        inputTextDAta: [
          ...state.inputTextDAta.slice(0, id),
          ...state.inputTextDAta.slice(id + 1),
        ],
      };
    }

    default:
      return state;
  }
};

export default inputList;
