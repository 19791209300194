import { useQuery } from "@tanstack/react-query";
import Api2 from "../utils/api2";
import { firstValueFrom } from 'rxjs';

export const ProfileAPI = {
    updateProfile: async function (requestBody) {
        try {
            const response = await firstValueFrom(Api2.post("/updateprofile", requestBody));
            return response;
        } catch (error) {
            console.error('Error fetching doctors:', error);
            throw error;
        }
    },
};

export const useGetProfileById = (profileId) => {
    return useQuery({
        queryKey: ['profileById'],
        queryFn: async () => {
            const response = await Api2.get(`getprofile?id=${profileId}`).toPromise();
            return response.data?.data;
        },
    })
}