import {atom} from 'recoil'

export const LoginState = atom({
    key: "login-cred",
    default: {
        countryCode: String,
        mobileNumber: String,
        email: String
    }
})
