import Api2 from "../utils/api2";
import { firstValueFrom } from "rxjs";

export const SettingsAPI = {
  getWaitingScreenSettingsInfo: async function () {
    try {
      const response = await firstValueFrom(
        Api2.get(`${process.env.REACT_APP_API2_HOST}get_que`)
      );
      return response.data.data.que;
    } catch (error) {
      console.error("Error fetching waiting screen settings info:", error);
      throw error;
    }
  },

  setWaitingScreenSettingsInfo: async function (requestBody) {
    try {
      const response = await firstValueFrom(
        Api2.post(`${process.env.REACT_APP_API2_HOST}add_que`, requestBody, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      );
      return response.data;
    } catch (error) {
      console.error("Error setting waiting screen settings: ", error);
      throw error;
    }
  },
};
