import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Drawer, List } from '@material-ui/core';
import ListItems from '../../routes/nav-items';
import { SidebarContext } from "../../context/sidebar";


const useStyles = makeStyles((theme) => ({
  drawer: {
    // width: 240,
    [theme?.breakpoints?.up('lg')]: {
      height: 'calc(100%)',
    },
  },
  root: {
    backgroundColor: theme?.palette?.primary?.main,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  divider: {
    margin: theme?.spacing(2, 0),
  },
  nav: {
    marginBottom: theme?.spacing(2),
  },
  fontColor: {
    color: theme?.palette?.common?.white,
  },
}));

const Sidebar = ({ variant, onClose, className, shiftContent, open, reducewidth, ...rest }) => {
  const sidebarContext = SidebarContext.useContainer();
  const classes = useStyles();
  
  // clicking on expand should always open full width
  const handleClick = () => {
    sidebarContext.handleSidebarOpen();
  };

  // should close sidebar entirely if mobile, otherwise partial
  const handleClose = () => {
    sidebarContext.handleSidebarClose();
  };
  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={sidebarContext.openSidebar}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, classes.fontColor, className)}
        style={{width:sidebarContext.sidebarWidth}}
      >
        <List>
          <ListItems
            shiftContent={shiftContent}
            onClose={handleClose}
            onClick={handleClick}
            reducewidth={reducewidth}
          />
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;