import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const FallBackComponent = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f5f5f5"
      padding={3}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 500,
          textAlign: 'center',
          backgroundColor: 'white',
        }}
      >
        <ErrorOutlineIcon color="error" sx={{ fontSize: 50 }} />
        <Typography variant="h5" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          An error occurred while processing your request.
        </Typography>
        <Typography
          variant="body2"
          color="error"
          gutterBottom
          sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          {error?.message || "Check logs for error"}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
          sx={{ marginTop: 2 }}
        >
          Try Again
        </Button>
      </Paper>
    </Box>
  );
};
