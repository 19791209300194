import FilterOptions from "../../utils/filter-options";
import AuthService from "../../utils/services/auth-service";
import AppConstants from "../../utils/app-constants";

class MedicationColumn {
  constructor(isEditMode) {
    this.allColumns = [
      {
        field: "_",
        colId: "_",
        hide: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
    ].concat(
      allFields().map((field) => {
        // if (field.name === "frequency") {
        //   return {
        //     field: field.name,
        //     colId: field.name,
        //     headerName: field.displayName,
        //     editable: true && !field.locked,
        //     cellEditor: "agRichSelectCellEditor",
        //     cellEditorParams: {
        //       values: AppConstants.MEDICATION_FREQUENCIES,
        //     },
        //   };
        // }

        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          ...medicationFilters.chooseAptFilter(field.dataType),
          pinned: field.pinned ? "left" : "",
          cellRendererSelector: function (params) {
            return {
              component: "customRenderer",
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: "customEditor",
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
                context: params.context,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() {
    return this.allColumns;
  }
}

const allFields = () => {
  const configData = AuthService.getConfig().medicationField;
  return configData;
};

const medicationFilters = new FilterOptions({ collection: "medication" });

export default MedicationColumn;
