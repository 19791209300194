import {
  Grid
} from "@mui/material";
import Header from "../../components/layout/header";
import React, { useEffect, useState } from "react";
import { DoctorAPI } from "../../apis/DoctorAPI";
import { CustomCircularLoader } from "../../components/common/CustomCircularLoader";
import { AccessSharingGrid } from "./AccessSharingGrid";
import { AccessLevelAPI } from "../../apis/AccessLevelAPI";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";

// new endpoint to save access sharing to a doctor

export const AccessSharing = () => {
  // ------------- local states --------------------
  const [isLoading, setIsLoading] = useState(true);
  const [sharedAccessInfoOfTheDoctor, setSharedAccessInfoOfTheDoctor] =
    useState([]);
  const [reloadGridRows, setReloadGridRows] = useState(false);

  // ------------- side-effects -------------------------
  useEffect(() => {
    DoctorAPI.getSharedAccessInfoOfADoctor().then((sharedAccessInfo) => {
      setSharedAccessInfoOfTheDoctor(sharedAccessInfo);
      setIsLoading(false);
    });
  }, [reloadGridRows]);

  // ---------- event handlers --------------------------

  const handleShareAccessDialogSubmit = (
    selectedDoctor,
    selectedAccesslevels
  ) => {
    if (selectedDoctor !== "" && selectedAccesslevels.length !== 0) {
      AccessLevelAPI.setSharedAccessLevels(
        selectedDoctor,
        selectedAccesslevels
      ).then((response) => {
        if (response.status === 201) {
          setReloadGridRows(prev => !prev);
          SHOW_SUCCESS_NOTIFICATION("Shared Successfully");
        } else {
          SHOW_ERROR_NOTIFICATION("Failed to share");
        }
      });
    }
  };

  // ---------- Fallback when loading data --------------
  if (isLoading) {
    return <CustomCircularLoader containerHeight={"80vh"} />;
  }

  return (
    <Grid container direction="column" style={{ width: "100%" }}>
      <Grid item style={{ maxWidth: "100%" }}>
        <Header title="Access Sharing" />
      </Grid>
      <Grid item style={{ maxWidth: "100%" }}>
        <AccessSharingGrid
          rows={sharedAccessInfoOfTheDoctor?.accessSharedTo ?? []}
          handleShareAccessDialogSubmit={handleShareAccessDialogSubmit}
        />
      </Grid>
    </Grid>
  );
};