import AuthService from "./services/auth-service";

const isAuthorised = (persmission, access) => {
  const user = AuthService.getUser(); 
 
  let perms = user?.permissions?.filter(
    (item) => item.entityPermission === persmission
  );

  if (!perms?.length) {
    return false;
  }

  if (!access) {
    return true;
  }

  const accessPerms = perms[0].accessPermissions.filter(
    (item) => item === "ANY" || item === access
  );

  if (!accessPerms || !accessPerms.length) {
    return false;
  }

  return true;
};

export function isCurrentRole(role) {
  const user = AuthService.getUser();
  return user?.roleName === role;
}
export default isAuthorised;
