import {
  Box,
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
  TextField
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import { Form, Scope } from "informed";
import { default as React, useEffect, useRef, useState } from "react";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import Axios from 'axios';
import AppConstants, { LOCAL_STORAGE_CONSTANTS } from "../../utils/app-constants";

import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import { NotificationManager } from "react-notifications";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from "../../components/textEditor/CustomTools";
import Routes from "../../routes/routes";
import { Select, MenuItem } from '@material-ui/core';
import Api from "../../utils/api";




const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionTitle_1: {
    fontSize: "1.1rem",
    fontWeight: "800",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  }
}));

const OtherTab = () => {



  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const formApi = useRef();
  const [initialValues, setInitialValues] = useState([]);


  const [addLoadingStatus, setAddLoadingStatus] = useState(false);
  const [showRecord, setShowRecord] = useState(false)
  const [futureBooking, setFutureBooking] = useState(null);
  const [noShowPatient, setNoShowPatient] = useState(null);

  const [feedback, setFeedback] = useState(null)
  const [checkinAudio, setCheckinAudio] = useState(false)
  const [resp, setResp] = useState(null);
  const [status, setStatus] = useState(false);
  const [terms, setterms] = useState('');
  const [appointmentUrl, setAppointmentUrl] = useState(null)
  const [checkIn, setcheckin] = useState(null)
  const [emrTemplate, setEmrTemplate] = useState([]);

  const [defaultOption, setDefaultOption] = useState('');


  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const user = AuthService.getUser()

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getprofile?id=${user.id}`).then((resp) => {
      if (resp) {
        setResp(resp?.data?.data);
      }

    });
  }, [status]);





  useEffect(() => {
    setCheckinAudio(resp?.checkinAudio)
    setShowRecord(resp?.showRecord)
    setFutureBooking(resp?.futureBooking)
    setNoShowPatient(resp?.no_show_patient)
    setFeedback(resp?.feedback)
    setterms(resp?.terms)
    setAppointmentUrl(resp?.appointmentUrl)
    setcheckin(resp?.checkIn)
  }, [resp, addLoadingStatus])


  const handleCheckinAudio = (e) => {

    const value = e.target.checked
    setCheckinAudio(value)
  }

  const handleShowRecord = (e) => {
    const value = e.target.checked
    setShowRecord(() => value)
  }

  const handleChangeterms = (html) => {
    setterms(html)
  }

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  }
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ]





  const handleSave = async (event) => {
    setAddLoadingStatus(true)
    event.preventDefault();

    const req = {
      checkinAudio: checkinAudio,
      futureBooking: futureBooking,
      feedback: feedback,
      appointmentUrl: appointmentUrl,
      showRecord: showRecord,
      id: user.id,
      terms: terms,
      checkIn: checkIn,
      defaultTemplateType: selectedOption,
      noShowPatient: noShowPatient

    };
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}/update_profile_other`,
        data: req,
        headers: { "Content-Type": "application/json" },
      });
      if (response?.data?.message.toString() === "Updated Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false)
        setStatus(!status)
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false)
      }
    }
    catch (error) {
      setAddLoadingStatus(false);
    }

  };

  const openQrCode = () => {
    window.open(`/#!` + Routes.QR_STRING + `?checkin=${checkIn}`)
  }

  useEffect(() => {
    Api.get(`emr-template/get_emr_template_type`).subscribe((resp) => {
      if (resp) {
        setEmrTemplate(resp?.data?.data);

        resp.data.data.map((e, i) => {
          if (e.default == true) {
            setSelectedOption(e._id);
          }
        })
      }
    });
  }, []);

  return (
    <>
      <Header title="Other" />
      <Form
        apiRef={formApi}
        initialValues={{
          availSummary: initialValues,
          paymentOptions: [
            {
              payTo: "",
              qrCode: "",
              description: "",
            },
          ],
        }}
      >
        <Typography variant="h6" className={classes.prescriptionTitle}>
          Booking For Future Availability  (Days)
        </Typography>

        <Grid container>
          <Grid style={{ width: '100%', marginRight: '3%' }}>
            {/* <TextInput
              type="Number"
              field="Number"
              placeholder="Features Booking"
              containerClassName="mr"
              value={futureBooking}
              name="futureBooking"
              className={classes.style_1}
              onChange={(e) => setFutureBooking(e.target.value)}
            /> */}
            <TextField
              type="Number"
             style={{paddingTop:"20px",paddingLeft:"15px"}}
              field=""
              placeholder="Features Booking"
              containerClassName="mr"
              value={futureBooking}
              // name="futureBooking"
              className={classes.style_1}
              onChange={(e) => setFutureBooking(e.target.value)}


            />
            {/* <input 
            type="number"
            placeholder="future booking"
            value={futureBooking}
            onChange={(e) => setFutureBooking(e.target.value)}
            
            /> */}
          </Grid>
        </Grid>

        <Box mt="2rem" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            FeedBack  Link
          </Typography>
          <Grid container>
            <Grid style={{ width: '100%', marginRight: '3%' }}>
              <TextInput
                field="title"
                placeholder="Feedback"
                name="feedback"
                value={feedback}
                className={classes.style_1}
                onChange={(e) => setFeedback(e.target.value)}

              />
            </Grid>
          </Grid>
        </Box>

        <Box mt="2rem" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Checkin QR String
          </Typography>
          <Grid container>
            <Grid style={{ width: '80%', marginRight: '3%' }}>
              <TextInput
                field="checkin"
                placeholder="Checkin QR string"
                value={checkIn}
                className={classes.style_1}
                onChange={(e) => setcheckin(e.target.value)}
              />
            </Grid>
            <Grid>
              {
                (checkIn === null || checkIn === "") ?
                  null
                  :
                  <Button
                    style={{ marginTop: '5%', width: '100%' }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={globalClass.largeBtnContainer}
                    onClick={openQrCode}>Print QR</Button>
              }
            </Grid>
          </Grid>

        </Box>



        <Box mt="2rem" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Appointment Url
          </Typography>
          <Grid container>
            <Grid style={{ width: '100%', marginRight: '3%' }}>
              <TextField
                variant="outlined"
                placeholder="Appointment Url"
                value={appointmentUrl}
                className={classes.style_1}
                onChange={(e) => setAppointmentUrl(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>


        <Box mt="2rem" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Default EMR
          </Typography>
          <Grid container>
            <Grid style={{ width: '100%', marginRight: '3%' }}>
              <Select value={selectedOption} className={classes.style_1} onChange={handleChange} variant="outlined" style={{ width: '50%', marginLeft: '2%' }}>
                {emrTemplate?.map((e) => {
                  return (

                    <MenuItem value={e._id}>{e.name}</MenuItem>
                  )
                })}

              </Select>
            </Grid>
          </Grid>
        </Box>
        {/* no show_patient */}
        <Typography variant="h6" className={classes.prescriptionTitle}>
          No show Patient
        </Typography>

        <Grid container>
          <Grid style={{ width: '100%', marginRight: '3%' }}>
            <TextInput
              type="Number"
              field="Number"
              placeholder="No Show Patient"
              containerClassName="mr"
              value={noShowPatient}
              name="noShowPatient"
              className={classes.style_1}
              onChange={(e) => setNoShowPatient(e.target.value)}
            />
          </Grid>
        </Grid>




        <Box mt="2rem" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Terms And Condition
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>

              <CustomToolbar />
              <ReactQuill
                theme="snow"
                value={terms}
                modules={modules}
                formats={formats}
                onChange={handleChangeterms}
              />

            </Grid>
          </Grid>
        </Box>

        <Box mt="2rem" display="flex" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Show Records
          </Typography>
        </Box>
        <Grid container>
          <Switch aria-label="File" checked={showRecord} value={showRecord} onChange={handleShowRecord} />
        </Grid>


        <Box mt="2rem" display="flex" alignItems="center">
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Checkin Audio
          </Typography>
        </Box>
        <Grid container>
          <Switch aria-label="Audio" checked={checkinAudio} value={checkinAudio} onChange={handleCheckinAudio} />
        </Grid>



        <Box mb={3} style={{ marginTop: "2%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
            onClick={handleSave}
          >
            {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
          </Button>
        </Box>
      </Form>
    </>
  );
};



export default OtherTab
