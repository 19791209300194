import {
  Grid, DialogContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import { Form } from 'informed';
import React from 'react';
import { TextInput } from 'sixsprints-react-ui';
import Api from '../../utils/api';
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
} from '../../utils/app-util';
import useGlobalStyles from '../../utils/global-style';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '.7rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.7rem',
  },
  loaderStyle: {
    color: theme.palette.common.primary,
  },
}));

const UpdateAppointmentDateDialog = ({
  open = false,
  rowCount = 0,
  gridApiInfo,
  handleClose,
}) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const handleSubmit = (val) => {
    Api.post(`/appointment/update/date?months=${val.numOfMonths || 120}`, {
      filterModel: gridApiInfo.getFilterModel(),
    }).subscribe(
      (response) => {
        gridApiInfo.purgeServerSideCache();
        SHOW_SUCCESS_NOTIFICATION("Appointment Date updated successfully");
     },
      (error) => {
        if (error.response) {
          DEFAULT_ERROR_CALLBACK(error);
        }
      }
    );
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.heading}>
        Are you sure you want to update the {rowCount + ' '} appointment date(s)
        ?
      </DialogTitle>
      <Divider />
      <Form onSubmit={handleSubmit} initialValues={{ numOfMonths: 120 }}>
        <DialogContent>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <TextInput
              required
              className={clsx(globalClass.roundWhiteBg, globalClass.inputWidth)}
              field='numOfMonths'
              type='number'
              label='Number of Months'
              validateOnBlur
              validateOnChange
            />
          </Grid>
          <Button
            style={{ marginTop: '18px' }}
            className={globalClass.largeBtnContainer}
            type='submit'
            variant='contained'
            color='primary'
          >
            Submit
          </Button>
        </DialogContent>
      </Form>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAppointmentDateDialog;
