import React from "react";
import {
  Drawer,
  Box,
  Button,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import { AppointmentContext } from "../../context/appointment";
import NameIcon from "../../assets/name.svg";
import UhidIcon from "../../assets/uhid.svg";
import GenderIcon from "../../assets/gender.svg";
import DobIcon from "../../assets/dob.svg";
import PhoneIcon from "../../assets/phone.svg";
import DateIcon from "../../assets/date.svg";
import TimeIcon from "../../assets/clock.svg";

import { getFormattedDate } from "../../utils/app-util";
import isAuthorised from "../../utils/role-util";
import AppConstants from "../../utils/app-constants";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(4, 8),
  },
  drawer: {
    width: "300px",
    backgroundColor: theme.palette.bg,
    height: "100%",
  },
  text: {
    color: "#6D6D6D",
  },
  my: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

const SideTab = ({ data }) => {
  const appntmentCntxt = AppointmentContext.useContainer();
  let history = useHistory();
  const classes = useStyles();

  const onConsultationBtnClicked = (val) => {
    appntmentCntxt.toggleSideTab();
    history.push(Routes.PRESCRIPTION + `?slug=${val}`);
  };

  return (
    <Drawer
      anchor="right"
      open={appntmentCntxt.isSideTabOpen}
      onClose={appntmentCntxt.toggleSideTab}
    >
      {data.length > 0 && (
        <Box className={classes.drawer} m={4}>
          {data.map((item, index) => (
            <Box className={classes.containerBox} key={index}>
              <Box my="1rem" display="flex" justifyContent="flex-start">
                <Box mx="1rem">
                  <img alt="name" src={NameIcon} />
                </Box>
                <Typography className={classes.text} variant="h6">
                  {item.patient.name}
                </Typography>
              </Box>
              <Divider />
              {item.patient.uhid && (
                <>
                  <Box my="1rem" display="flex" justifyContent="flex-start">
                    <Box mx="1rem">
                      <img alt="name" src={UhidIcon} />
                    </Box>
                    <Typography className={classes.text} variant="h6">
                      {item.patient.uhid}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {item.patient.gender && (
                <>
                  <Box my="1rem" display="flex" justifyContent="flex-start">
                    <Box mx="1rem">
                      <img alt="name" src={GenderIcon} />
                    </Box>
                    <Typography className={classes.text} variant="h6">
                      {item.patient.gender}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {item.patient.dob && (
                <>
                  <Box my="1rem" display="flex" justifyContent="flex-start">
                    <Box mx="1rem">
                      <img alt="name" src={DobIcon} />
                    </Box>
                    <Typography className={classes.text} variant="h6">
                      {getFormattedDate(item.patient.dob)}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              <Box my="1rem" display="flex" justifyContent="flex-start">
                <Box mx="1rem">
                  <img alt="name" src={PhoneIcon} />
                </Box>
                <Typography className={classes.text} variant="h6">
                  {item.patient.mobileNumber}
                </Typography>
              </Box>
              <Divider />

              <Box my="1rem" display="flex" justifyContent="flex-start">
                <Box mx="1rem">
                  <img alt="name" src={DateIcon} />
                </Box>
                <Typography className={classes.text} variant="h6">
                  {getFormattedDate(item.date)}
                </Typography>
              </Box>
              <Divider />

              <Box my="1rem" display="flex" justifyContent="flex-start">
                <Box mx="1rem">
                  <img alt="name" src={TimeIcon} />
                </Box>
                <Typography className={classes.text} variant="h6">
                  {item.time}
                </Typography>
              </Box>
              <Divider />
              <Box my="1rem" display="flex" justifyContent="flex-start">
                <Box mx="1rem">
                  <img alt="name" src={NameIcon} />
                </Box>
                <Typography className={classes.text} variant="h6">
                  {"Dr. " + item.doctor.name}
                </Typography>
              </Box>

              {isAuthorised(AppConstants.ENTITY.CONSULTATION) && (
                <Box textAlign="center" my="2rem">
                  <Button
                    style={{ width: "90%", fontSize: "1rem" }}
                    onClick={() => onConsultationBtnClicked(item.slug)}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Start Consultation
                  </Button>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Drawer>
  );
};

export default SideTab;
