import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./treatmentSummary.css";
import Api2 from "../../utils/api2";
import moment from "moment";

const TreatmentSummery = ({ patientSlug }) => {

  const history = useHistory();
  const [summary, setSummary] = useState([]);
  const [page, setPage] = useState(0);
  const [profilePic, setprofilePic] = useState("");
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [waitResponse, setWaitResponse] = useState(false);


  const handleScroll = (e) => {
    if (!hasMore) {
      return;
    }

    if (!waitResponse) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
     

      // if (Math.floor(scrollTop) + clientHeight === scrollHeight) {
      if (Math.floor(scrollTop) + clientHeight + 2 >= scrollHeight) {
        setPage(page + 1);
        
      }
    }
  };

  useEffect(() => {
    Api2.get(
      `/get_treatment_summary?patientSlug=${patientSlug}&page=${page}&size=20`
    ).subscribe((resp) => {
      if (resp) {
        setSummary(resp?.data?.data?.activities);
        if (resp.data.data.profilePic) {
          setprofilePic(resp.data.data.profilePic);
          
        }else{
          setprofilePic('/profilePic.png');

        }
      }
    });
    setPage(0);
  }, [patientSlug]);

  useEffect(() => {
    if (!hasMore) {
      setLoader(false);
      return;
    }

    setLoader(true);

    setWaitResponse(true);
    Api2.get(
      `/get_treatment_summary?patientSlug=${patientSlug}&page=${page}&size=20`
    ).subscribe((resp) => {
      if (resp) {
        if (resp.data.data.activities.length === 0) {
          setHasMore(false);
          setLoader(false);

          return;
        }
        setSummary([...summary, ...resp.data.data.activities]);
        if (resp.data.data.profilePic) {
          setprofilePic(resp.data.data.profilePic);
          
        }else{
          setprofilePic('/profilePic.png');

        }
        setLoader(false);
        setWaitResponse(false);

      }
    });
  }, [page]);

  return (
    <>
      <div
        className="row"
        style={{ height: "400px", overflow: "auto", fontSize: "10px" }}
        onScroll={handleScroll}
      >
        <div className="col-md-12 mx-auto">
          <section class="py-1">
            <ul class="timeline-with-icons mt-1" style={{ listStyle: "none", paddingLeft: "10px" }}>
              {summary?.map((s) => {
                return (
                  <>
                    {s?.activity === "Patient was created!" && (
                      <li class="timeline-item mb-3">

                        <div className="d-flex">
                          <span class="timeline-icon me-1">
                            <img src={profilePic} alt="" className="imageClass" />
                          </span>

                          <div className="mt-1">
                            <p className="mb-0 mt-1">
                              {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                            </p>

                            <p className="mb-0">
                              {s?.fromSlug === s?.toSlug ? (
                                <div>
                                  <b>Registered</b> on proclinic by <b>self</b>
                                </div>
                              ) : (
                                <>
                                  <b>Registered</b> on proclinic by{" "}
                                  <b>{s?.fromName}</b>
                                </>
                              )}
                            </p>
                          </div>

                        </div>
                      </li>
                    )}

                    {s?.activity === "Booked appointment!" &&
                      s?.fromSlug === s?.toSlug && (
                        <li class="timeline-item mb-3">

                          <div className="d-flex">
                            <span class="timeline-icon me-1">
                              {/* <i class="fas fa-hand-holding-usd text-primary fa-sm fa-fw"></i> */}
                              <img
                                src={profilePic}
                                alt=""
                                className="imageClass"
                              />
                            </span>
                            <div>
                              <p className="mb-0 mt-1">
                                {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                              </p>

                              <p className="mb-0">
                                <b>Booked Appointment</b> for{" "}
                                <span className="fw-bold text-primary">
                                  {s?.doctorName}
                                </span>
                                <br /> by <b>self</b> for{" "}
                                <b>
                                  {moment(s?.appointmentDate).format(
                                    "YYYY-MM-DD, HH:mm:ss"
                                  )}
                                </b>{" "}
                                <br />
                                <span>{s?.paymentMode}</span>
                              </p>
                            </div>
                          </div>
                        </li>
                      )}

                    {s?.activity === "Booked appointment!" &&
                      s?.fromSlug !== s?.toSlug && (
                        <li class="timeline-item mb-3">

                          <div className="d-flex">
                            <span class="timeline-icon me-1">
                              {/* <i class="fas fa-hand-holding-usd text-primary fa-sm fa-fw"></i> */}
                              <img
                                src={profilePic}
                                alt=""
                                className="imageClass"
                              />
                            </span>
                            <div>
                              <p className="mb-0 mt-1">
                                {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                              </p>

                              <p className="mb-0">
                                <b>Booked Appointment</b> for{" "}
                                <span className="fw-bold text-primary">
                                  {s?.doctorName}
                                </span>
                                <br /> by <b>{s?.fromName}</b> for{" "}
                                <b>
                                  {moment(s?.appointmentDate).format(
                                    "YYYY-MM-DD, HH:mm:ss"
                                  )}
                                </b>{" "}
                                <br />
                                <span>{s?.paymentMode}</span>
                              </p>
                            </div>
                          </div>
                        </li>
                      )}

                    {s?.activity === "Message sent!" &&
                      s?.fromSlug === patientSlug && (
                        <li class="timeline-item mb-3">

                          <div className="d-flex">
                            <span class="timeline-icon me-1">
                              {/* <i class="fas fa-money-bill-wave text-primary fa-sm fa-fw"></i> */}
                              <img
                                src="./images/Frame 151.png"
                                alt=""
                                className="imageClass"
                              />
                            </span>

                            <div>
                              <p className="mb-0 mt-1">
                                {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                              </p>

                              <p className="mb-0">
                                Sent chat to{" "}
                                <span className="fw-bold text-primary">
                                  {s?.toName}
                                </span>
                              </p>
                              {/* <input
                                type="text"
                                className="shadow p-3 mb-2 bg-body rounded w-100 "
                                placeholder={s?.description}
                              /> */}
                              <b>{s?.description}</b>
                            </div>
                          </div>
                        </li>
                      )}

                    {s?.activity === "Message sent!" &&
                      s?.fromSlug !== patientSlug && (
                        <li class="timeline-item mb-3">


                          <div className="d-flex">
                            <span class="timeline-icon me-1">
                              <img
                                src="./images/Frame 151.png"
                                alt=""
                                className="imageClass"
                              />
                            </span>
                            <div>
                              <p className="mb-0 mt-1">
                                {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                              </p>

                              <p className="mb-0">
                                <b>Replied by</b>
                                <span className="fw-bold text-primary">
                                  {s?.fromName}
                                </span>
                              </p>
                              {/* <input
                                type="text"
                                className="shadow p-3 mb-2 bg-body rounded w-100 "
                                placeholder={s?.description}
                              /> */}
                              <b>{s?.description}</b>
                            </div>
                          </div>
                        </li>
                      )}

                    {s?.activity === "Updated appointment!" && (
                      <li class="timeline-item mb-3">

                        <div className="d-flex">
                          <span class="timeline-icon me-1">
                            <img
                              src="./profilePic.png"
                              alt=""
                              className="imageClass"
                            />
                          </span>
                          <div>
                            <p className="mb-0 mt-1">
                              {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                            </p>

                            <p className="mb-0">
                              Marked <b>{s?.description.replace("status:", "")}</b>{" "}
                              by{" "}
                              <span className="fw-bold text-primary">
                                {" "}
                                {s?.fromName}
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                    )}

                    {s?.activity === "Added prescription!" && (
                      <li class="timeline-item mb-3">

                        <div className="d-flex">
                          <span class="timeline-icon me-1">
                            <img
                              src="./images/Frame 151 (1).png"
                              alt=""
                              className="imageClass"
                            />
                          </span>
                          <div>
                            <p className="mb-0 mt-1">
                              {moment(s?.dateTime).format("YYYY-MM-DD, HH:mm:ss")}
                            </p>

                            <div>

                              <p className="mb-0">
                                {" "}
                                <b>Prescription generated</b> by{" "}
                                <span className="fw-bold text-primary ">
                                  {" "}
                                  {s?.fromName}
                                </span>
                              </p>
                              <div className=" p-3 mb-1 bg-body rounded" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                              {/* {s?.prescriptionHtml} */}
                              <div dangerouslySetInnerHTML={{__html: s?.prescriptionHtml}}/>
                                {/* <a
                                  
                                  href={s?.prescriptionHtml}
                                  style={{ textDecoration: "none" }}
                                >
                                  View Prescription
                                </a> */}

                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </section>

          <section class="py-3">
            <ul class="timeline-with-icons" style={{ listStyle: "none" }}>
              {loader && (
                <li class="timeline-item mb-5 text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </li>
              )}
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default TreatmentSummery;
