import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { default as React, useEffect, useRef, useState } from "react";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import Api from "../../utils/api";
import Axios from "axios";
import AppConstants, {
  LOCAL_STORAGE_CONSTANTS,
} from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import { NotificationManager } from "react-notifications";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Loader from "../../components/misc/loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
    fontFamily: "Comfortaa",
  },
  nameModal: {
    fontSize: "0.5rem",
    fontWeight: "700",
    opacity: "0.7",
    marginBottom: "10px",

  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  prescriptionmodal: {
    marginBottom: "0rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",

  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  picture: {
    maxWidth: "160px",
    width: "100%",
  },

  MuiTypographybody1: {
    fontsize: "0.6428571428571429rem",
    fontFamily: "Comfortaa",
    fontweight: "400",
    lineheight: "1.5",
  },

}));

const Specialists = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
    ? AuthService.getUser()
    : {};
  const [doctors, setDoctors] = useState([]);
  const [docData, setDocData] = useState(userObj);
  const [socialMediaInfo, setSocialMediaInfo] = useState(null);
  const formApi = useRef();
  const presFileRef = useRef(null);
  const [qrIndex, setQrIndex] = useState(null);
  const [paymentModes, setPaymentModes] = useState([]);
  const [profileImg, setProfileImg] = useState(null);
  const [initialValues, setInitialValues] = useState([]);
  const [paymentInitialValues, setPaymentInitialValues] = useState([]);
  const [docSlug, setDocSlug] = useState(null);
  const [speciality, setSpeciality] = useState(null);
  const [signImage, setSignImage] = useState(null);

  const [appointmentTypes, setAppointmentTypes] = useState([
    {
      label: "GENERAL",
      active: false,
      disclaimer: "",
    },
    {
      label: "MINOR_SURGERY",
      active: false,
      disclaimer: "",
    },
    {
      label: "DRESSING",
      active: false,
      disclaimer: "",
    },
    {
      label: "EMERGENCY",
      active: false,
      disclaimer: "",
    },
    {
      label: "AUDIO",
      active: false,
      disclaimer: "",
    },
  ]);



  useEffect(() => {
    if (userObj.id) {
      Axios.get(
        `${process.env.REACT_APP_API2_HOST}user-settings/${userObj.id}`
      ).then((resp) => {
        const response = resp.data.data;
        const { welcomeEmail } = response;
        formApi.current.setValue("welcomeEmail", welcomeEmail);
      });
    }
  }, [userObj.id]);

  const fetchDoctors = () => {
    if (isCurrentRole(AppConstants.ROLE.ADMIN)) {
      const filter = { roleName: { values: ["DOCTOR"], filterType: "set" } };
      DataService.fetchList("doctor/search", 0, 50, filter).subscribe(
        (resp) => {
          setDoctors(resp.content);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };
  useEffect(fetchDoctors, []);


  ////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [updatePicture, setUpdatePicture] = useState(null);
  const [UpdateImgData, setUpdateImgData] = useState(null);
  const [getUpdatePic, setGetUpdatePic] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [picture, setPicture] = useState(null);
  const socialMediaArr = ["Active", "Inactive"];
  

  const [addSpecilityData, setaddSpecilityData] = useState([
    {
      title: "",
      picture: "",
      status: "",
      slug: "",
    },
  ]);
  const [getUpdateData, setGetUpdateData] = useState({
    name: "",
    status: "",
    slug: "",
  });



  const handleUpdateChange = (event) => {
    setGetUpdateData({
      ...getUpdateData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const handleUpdatePic = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    const name = e.target.name;
    const value = e.target.files[0];
    if (e.target.files[0]) {
      setUpdatePicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setUpdateImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleOpen = (item) => {
    setOpen(true);
    setId(item._id);
    setGetUpdateData({ name: item.name, status: item.status, slug: item.slug });
    setGetUpdatePic(item.pic);

    Axios.get(`${process.env.REACT_APP_API2_HOST}getspecialitybyid`).then(
      (resp) => {
        if (resp) {
          setResp(resp.data);
          setLoading(true);
        }
      }
    );
  };
  const handleClose = () => setOpen(false);



  const [resp, setResp] = useState(null);



  ///

  const [logoStatus, setLogoStatus] = useState(false);
  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
  });

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    const name = e.target.name;
    const value = e.target.files[0];
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onChangeData = (event) => {
    setaddSpecilityData({
      ...addSpecilityData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveData = async () => {
    setLoadingStatus({
      logoLodingStatus: true,
    });
    if (
      picture === null ||
      addSpecilityData.title === "" ||
      addSpecilityData.status === "" ||
      addSpecilityData.slug === ""
    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      const formData = new FormData();
      formData.append("pic", picture);
      formData.append("name", addSpecilityData.title);
      formData.append("status", addSpecilityData.status);
      formData.append("slug", addSpecilityData.slug);

      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}addspeciality`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {
          if (response.data.status) {
            setOpen(false);
            setLogoStatus(false);
            setLoadingStatus({
              logoLodingStatus: false,
            });
            setAddModalOpen(false)
            NotificationManager.success(response.data.message);
          } else {
            setOpen(false);
            setLoadingStatus({
              logoLodingStatus: false,
            });
          
            NotificationManager.error(response.data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onUpdateSave = async (id) => {
    setLoadingStatus({
      logoLodingStatus: true,
    });
    if (
      getUpdateData.name === "" ||
      getUpdateData.status === "" ||
      getUpdateData.slug === ""
    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      setLoadingStatus({
        logoLodingStatus: false,
      });
    } else {
      const formData = new FormData();
      formData.append("pic", updatePicture);
      formData.append("name", getUpdateData.name);
      formData.append("status", getUpdateData.status);
      formData.append("slug", getUpdateData.slug);
      formData.append("id", id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}updatespeciality`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {
          if (response.data.status) {
            setOpen(false);
            setLogoStatus(false);
            setLoadingStatus({
              logoLodingStatus: false,
            });
            NotificationManager.success(response.data.message);
          } else {
            setOpen(false);
            NotificationManager.error(response.data.message);
          }
        }
      } catch (error) {
        setOpen(false);
        setLoadingStatus({
          logoLodingStatus: false,
        });
        console.log(error);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setResp(resp.data);
          setLoading(true);
        }
      }
    );
  }, [lodingStatus.logoLodingStatus]);

  return (
    <div>
      <Header title="Departments" />
      {resp === null ? (
        <Loader />
      ) : (
        <Form
          apiRef={formApi}
          // onSubmit={handleSubmit}
          initialValues={{
            availSummary: initialValues,
          }}
        >
          <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
            <Box className={classes.prescriptionSection}>
              <Paper
                className={clsx(classes.container, globalClass.roundWhiteBg)}
              >
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={handleAddModalOpen}
                  >
                    ADD
                  </Button>
                </Box>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    id="summaryRow"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Name</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Slug</strong>
                        </TableCell>
                        <TableCell>
                          <strong>status</strong>
                        </TableCell>

                        <TableCell>
                          <strong>Image</strong>
                        </TableCell>
                        <TableCell>
                          <strong></strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {resp?.map((item, i) => {
                        return (
                          <Scope scope={`availSummary[${i}]`} key={i}>
                            <TableRow key={i}>
                              <TableCell >
                                <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.name}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.slug}</Typography>
                              </TableCell>

                              <TableCell>
                                <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.status}</Typography>
                              </TableCell>

                              <TableCell>
                                <Box display="flex" flexDirection="column">
                                  <form method="post">
                                    <div class="col-12">
                                      <input
                                        type="file"
                                        placeholder="favicon"
                                        name="logo"
                                        class="form-control"
                                        id="logo"
                                        autocomplete="off"
                                        onChange={handleInputs}
                                        style={{ visibility: "hidden" }}
                                      />

                                      <div className="previewProfilePic">
                                        <img
                                          className={classes.picture}
                                          src={item.pic}
                                          alt="logo"
                                        />
                                      </div>
                                    </div>
                                    <br />
                                    &nbsp;
                                  </form>
                                </Box>
                              </TableCell>

                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  startIcon={<EditIcon />}
                                  onClick={() =>
                                    handleOpen(
                                      item
                                    )
                                  }
                                >
                                  Update
                                </Button>
                                &nbsp;

                              </TableCell>
                            </TableRow>
                          </Scope>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>


          </div>
        </Form>
      )}

      {AddModalOpen && (
        <Modal
          open={AddModalOpen}
          onClose={handleAddModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} className={classes.prescriptionTitle}>
                Add Department
              </Typography>

              <CloseIcon onClick={handleAddModalClose} style={{cursor: "pointer"}}/>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0,5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Title*
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="title"
                  field="title"
                  placeholder="title"
                  className={classes.style_2}
                  onChange={onChangeData}
                  value={addSpecilityData.title}
                />
              </Grid>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0,5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Slug*
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="slug"
                  placeholder="slug"
                  className={classes.style_2}
                  onChange={onChangeData}
                  value={addSpecilityData.slug}
                />
              </Grid>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Picture*
                </Typography>
              </Box>

              <Grid>
                <form method="post">
                  <div class="col-12">
                    <input
                      type="file"
                      placeholder="favicon"
                      name="status"
                      class="form-control"
                      id="logo"
                      autocomplete="off"
                      onChange={handleInputs}
                      style={{ visibility: "hidden" }}
                    />
                    <div className="previewProfilePic">
                      <img
                        className="playerProfilePic_home_tile"
                        src={imgData || "/uploadImage.png"}
                        alt="logo"
                        width="100"
                      />
                    </div>
                  </div>
                  <br />
                  {logoStatus ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                      onClick={() => handleInputs}
                    >
                      {lodingStatus.logoLodingStatus ? (
                        <Loader width="200px" height="20px" color="primary" />
                      ) : (
                        "SAVE"
                      )}
                    </Button>
                  ) : (
                    <label htmlFor="logo">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.button}
                      >
                        <EditIcon /> &nbsp; upload
                      </Button>
                    </label>
                  )}
                  &nbsp;
                </form>
              </Grid>
            </Box>
            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                  Status*
                </Typography>
              </Box>

              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="status"
                  value={addSpecilityData.disclaimer}
                  onChange={onChangeData}
                >
                  {socialMediaArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>
            {
              <Box mb={3} style={{ marginTop: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={() => handleSaveData("save")}
                >
                  {lodingStatus.logoLodingStatus ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              </Box>
            }
          </Box>
        </Modal>
      )}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>

            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                Update Department
              </Typography>
              <CloseIcon onClick={handleClose} style={{cursor: "pointer"}}/>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Title*
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="name"
                  field="name"
                  placeholder="title"
                  className={classes.style_2}
                  onChange={handleUpdateChange}
                  value={getUpdateData.name}
                />
              </Grid>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0,5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Slug*
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="slug"
                  placeholder="slug"
                  className={classes.style_2}
                  onChange={handleUpdateChange}
                  value={getUpdateData.slug}
                />
              </Grid>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                  Picture*
                </Typography>
              </Box>

              <Grid>
                <form method="post">
                  <div class="col-12">
                    <input
                      type="file"
                      placeholder="favicon"
                      class="form-control"
                      id="updateimage"
                      autocomplete="off"
                      onChange={handleUpdatePic}
                      style={{ visibility: "hidden" }}
                    />
                    <div className="previewProfilePic">
                      <img
                        className="playerProfilePic_home_tile"
                        src={UpdateImgData || getUpdatePic}
                        alt="logo"
                        width="100"
                      />
                    </div>
                  </div>
                  <br />
                  {logoStatus ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                      onClick={handleUpdatePic}
                    >
                      {lodingStatus.logoLodingStatus ? (
                        <Loader width="200px" height="20px" color="primary" />
                      ) : (
                        "SAVE"
                      )}
                    </Button>
                  ) : (
                    <label htmlFor="updateimage">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.button}
                      >
                        <EditIcon /> &nbsp; upload
                      </Button>
                    </label>
                  )}
                  &nbsp;
                </form>
              </Grid>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0.5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                  Status*
                </Typography>
              </Box>

              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="status"
                  value={getUpdateData.status}
                  onChange={handleUpdateChange}
                >
                  {socialMediaArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            {
              <Box mb={3} style={{ marginTop: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={() => onUpdateSave(id)}
                >
                  {lodingStatus.logoLodingStatus ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              </Box>
            }
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Specialists;
