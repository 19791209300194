import {
  Box,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextInput, SelectInput, CustomDateInput } from "sixsprints-react-ui";
// import MdDatePicker from "../../components/form/md-date-picker";
import { Form } from "informed";
import React, { useState, useEffect } from "react";
import Api from "../../utils/api";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  getDateTimeStamp,
} from "../../utils/app-util";
import DataService from "../../utils/services/data-service";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import AppConstants from "../../utils/app-constants";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import Bro from "brototype";
import { getDateDiff, getRoundToMinutes } from "../../utils/app-util";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: ".7rem",
    fontWeight: "bold",
  },
  value: {
    fontSize: ".7rem",
  },
}));

const LeaveLateDialog = ({ open = false, handleClose }) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [selectedDate, setSelectedDate] = useState(new Date().valueOf());
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedDocName, setSelectedDocName] = useState("");
  const [doctors, setDoctors] = useState([]);
  const userObj = AuthService.getUser();
  const docSlug = Bro(userObj).iCanHaz("slug");
  const docName = Bro(userObj).iCanHaz("name");

  const onDateSelected = (val) => {
    setSelectedDate(val);
  };
  const fetchDoctors = () => {
    const filter = { roleName: { values: ["DOCTOR"], filterType: "set" } };
    DataService.fetchList("doctor/search", 0, 50, filter).subscribe(
      (resp) => {
        setDoctors(resp.content);
      },
      () => {}
    );
  };

  useEffect(fetchDoctors, []);

  const onDoctorSelected = (e) => {
    let newDoc = doctors.filter((item) => item.slug === e.target.value);
    setSelectedDoc(newDoc[0]);
    setSelectedDocName(newDoc[0].name);
  };

  const handleSubmit = (val) => {
    if (isCurrentRole(AppConstants.ROLE.DOCTOR)) {
      val.doctorSlug = docSlug;
    }

    if (!val.doctorSlug) {
      SHOW_INFO_NOTIFICATION("Please Select Doctor");
      return;
    }

    if (!val.fromDate) {
      SHOW_INFO_NOTIFICATION("Please Select From Date");
      return;
    }
    if (!val.fromSlot) {
      SHOW_INFO_NOTIFICATION("Please Select From Time");
      return;
    }

    if (!val.toDate) {
      SHOW_INFO_NOTIFICATION("Please Select To Date");
      return;
    }

    if (!val.toSlot) {
      SHOW_INFO_NOTIFICATION("Please Select To Time");
      return;
    }

    if (getDateDiff(val.fromDate, val.toDate)) {
      let reqBody = {
        doctorSlug: val.doctorSlug,
        from: getDateTimeStamp(val.fromDate, val.fromSlot),
        to: getDateTimeStamp(val.toDate, val.toSlot),
      };
      Api.put(`appointment/doctor/unavailable`, reqBody).subscribe(
        (data) => {
          SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    } else {
      SHOW_INFO_NOTIFICATION("To Date cannot be less than From Date");
    }
    handleClose();
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.heading}>
        Mark Unavailable
      </DialogTitle>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          fromDate: selectedDate,
          toDate: selectedDate,
          fromSlot: getRoundToMinutes(),
          toSlot: "23:59",
        }}
      >
        <DialogContent>
          <Box className={classes.prescriptionSection}>
            <Box display="flex" flexDirection="column">
              {!isCurrentRole(AppConstants.ROLE.DOCTOR) && (
                <SelectInput
                  required={true}
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth
                  )}
                  options={doctors}
                  onChange={onDoctorSelected}
                  valueKey="slug"
                  labelKey="name"
                  field="doctorSlug"
                  placeholder="Select Doctor"
                  label="Doctor"
                  validateOnBlur
                  validateOnChange
                />
              )}
              <Box style={{ marginTop: "2%" }}>
                <Typography variant="h6">
                  {`Select the period for which Dr. ${
                    isCurrentRole(AppConstants.ROLE.DOCTOR)
                      ? docName
                      : selectedDocName
                  } is unavailable`}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <>
                  <CustomDateInput
                    disablePast
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    required
                    inputVariant="outlined"
                    field="fromDate"
                    onChange={onDateSelected}
                    label="From Date"
                    validateOnBlur
                    validateOnChange
                  />
                  <TextInput
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    field="fromSlot"
                    label="From Time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    validateOnBlur
                    validateOnChange
                  />
                </>
                <Box
                  style={{
                    marginRight: "20px",
                    marginTop: "5px",
                  }}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography variant="h3" style={{ opacity: "0.5" }}>
                    /
                  </Typography>
                </Box>
                <>
                  <CustomDateInput
                    disablePast
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    required
                    inputVariant="outlined"
                    field="toDate"
                    onChange={onDateSelected}
                    label="To Date"
                    validateOnBlur
                    validateOnChange
                  />
                  <TextInput
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    field="toSlot"
                    label="To Time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    validateOnBlur
                    validateOnChange
                  />
                </>
              </Box>
            </Box>
          </Box>
          <Button
            style={{ marginTop: "18px" }}
            className={globalClass.largeBtnContainer}
            type="submit"
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </DialogContent>
      </Form>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedDocName("");
            handleClose();
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveLateDialog;
