import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const CustomSelect = ({
  label,
  options = [],
  onChange,
  name,
  idKey = "id",
  valueKey = "value",
  labelKey = "label",
  defaultSelected = "",
  minWidth = 220,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  const handleOnChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onChange && onChange(value);
  };

  return (
    <div className="custom-select-wrapper">
      <FormControl sx={{ m: 1, minWidth: minWidth }}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={selectedValue}
          onChange={handleOnChange}
          name={name}
        >
          {options.length > 0 ? (
            options.map((option, index) => (
              <MenuItem key={option[idKey] ?? index} value={option[valueKey]}>
                {option[labelKey]}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No options available</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
