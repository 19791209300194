import {
  DialogContent,
  Divider,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Download from "../../assets/cloud-download-outline.svg";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import React, { useEffect, useState } from "react";
import {
  DEFAULT_ERROR_CALLBACK,
} from "../../utils/app-util";
import DataService from "../../utils/services/data-service";
import { getTransformArr, getFormattedDate } from "../../utils/app-util";
import Routes from "../../routes/routes";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: ".7rem",
    fontWeight: "bold",
  },
  value: {
    fontSize: ".7rem",
  },
  loaderStyle: {
    color: theme.palette.common.primary,
  },
}));

const PrespHistoryDialog = ({ open = false, handleClose, patientObj }) => {

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [patientAppointmentArr, setPatientAppointmentArr] = useState([]);


  const fetchPatientAppointments = () => {
    if (patientObj) {
      setIsLoading(true);
      DataService.getPatientAppointment(patientObj?.slug).subscribe(
        (resp) => {
          setPatientAppointmentArr(getTransformArr(resp?.data)); 
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };

  useEffect(fetchPatientAppointments, [patientObj]);

  // const [url, seturl] = useState(null)
  // useEffect(()=>{
  //    Axios.get(`${process.env.REACT_APP_API2_HOST}/getwebsite`).then((resp) => {
  //     if(resp){
  //       seturl(resp.data[0].website_url)
  //     }
  //   });
  // })


  const printPrescription = async (slug) => {
    window.open(`/#!` + Routes.APPOINTMENTPRINT + `?slug=${slug}`, '_blank')

    // await Axios.get(`${process.env.REACT_APP_API2_HOST}/getwebsite`).then((resp) => {
    //   if(resp){
    //     window.open(`${resp.data[0].website_url}/pres?id=${slug}`, '_blank')
    //   }
    // });
  };

  return (
    <div style={{ top: 0 }}>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.heading}>
          {patientObj.name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <CircularProgress
                disableShrink
                className={classes.loaderStyle}
                size={20}
              />
            </div>
          ) : patientAppointmentArr?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.heading}>Date</TableCell>
                    <TableCell align="center" className={classes.heading}>
                      View/Edit EMR
                    </TableCell>
                    <TableCell align="center" className={classes.heading}>
                      Print
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientAppointmentArr?.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.value}
                      >
                        {getFormattedDate(
                          row.appointmentDate,
                          "DD MMM YYYY HH:mm a"
                        )}
                      </TableCell>
                      <TableCell align="center" >
                        <a
                          href={`#!`+Routes.PRESCRIPTION+`?slug=${row.appointmentSlug}`}
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                        >
                          
                          <AssignmentOutlinedIcon
                            style={{ color: "black" }}
                            fontSize="large"
                          />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        {/* <a href={`${url}/pres?id=${row.appointmentSlug}` } */}
                          {/* // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"> */}
                          <img
                            className="mr-4"
                            src={Download}
                            width="20"
                            alt="eye"
                            onClick={() => printPrescription(row.appointmentSlug)}
                            style={{ cursor: 'pointer' }}
                          />
                        {/* </a> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Typography variant="h5">No Prescription Data Found</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrespHistoryDialog;
