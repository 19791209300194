import React ,{useEffect, useState}from "react";
import Axios from "axios";
import { Typography } from "@material-ui/core";

const Label = ({ statusList, paymentstatus, customStatus, paymentMode }) => {


  return (
    <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
      {statusList?.map((obj, index) => (
        <>
          <div
            key={index}
            style={{
              textAlign: "center",
              alignItems: "center",
              padding: "3px 6px",
              marginRight: 8,
              borderRadius: 5,
              maxWidth: 120,
              backgroundColor: obj.color.main,
            }}
          >
            <Typography
              style={{
                color: obj.color.contrastText,
                fontSize: 11,
                whiteSpace: "nowrap",
              }}
            >
              {obj.label}
            </Typography>
          </div>
        </>
      ))}

      { paymentMode && (
        <div
          style={{
            backgroundColor: "lightgray",
            textAlign: "center",
            padding: "3px 6px",
            marginRight: 8,
            borderRadius: 5,
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: 11,
              whiteSpace: "nowrap",
            }}
          >
            {paymentMode} 
          </Typography>
        </div>
      )}

      {paymentstatus  && (
        <div
          style={{
            backgroundColor: "lightgray",
            textAlign: "center",
            padding: "3px 6px",
            marginRight: 8,
            borderRadius: 5,
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: 11,
              whiteSpace: "nowrap",
            }}  
          >
            {paymentstatus === "PENDING_APPROVAL"
              ? "Pending"
              : `${paymentstatus}`}
          </Typography>
        </div>
      )}

      {customStatus && (
        <div
          style={{
            backgroundColor: "maroon",
            textAlign: "center",
            padding: "3px 6px",
            marginRight: 8,
            borderRadius: 5,
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: 11,
              whiteSpace: "nowrap",
            }}
          >
            {customStatus}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Label;
