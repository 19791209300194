import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField
  } from "@material-ui/core";
  import IconButton from "@material-ui/core/IconButton";
  import AddIcon from "@material-ui/icons/Add";
  import DeleteIcon from "@material-ui/icons/Delete";
  import PhotoCamera from "@material-ui/icons/PhotoCamera";
  import Bro from "brototype";
  import clsx from "clsx";
  import { Form, Scope } from "informed";
  import { useCallback } from "react";
  import { default as React, useEffect, useRef, useState } from "react";
  import { useRecoilState } from "recoil";
  import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
  import Header from "../../components/layout/header";
  import { CheckedInAudioState } from "../../states";
  import Api from "../../utils/api";
  import Axios from 'axios';
  import AppConstants, { LOCAL_STORAGE_CONSTANTS } from "../../utils/app-constants";
  import {
    DEFAULT_ERROR_CALLBACK,
    SHOW_INFO_NOTIFICATION,
    SHOW_SUCCESS_NOTIFICATION,
    transformArrToMap,
    validateAvailSummary,
    validatePaymentOptions,
  } from "../../utils/app-util";
  import useGlobalStyles from "../../utils/global-style";
  import { getDayFromStartAndEnd, getTimeFromStartAndEnd } from "../../utils/helper";
  import { isCurrentRole } from "../../utils/role-util";
  import AuthService from "../../utils/services/auth-service";
  import DataService from "../../utils/services/data-service";
  import { NotificationManager } from "react-notifications";
  
  const useStyles = makeStyles((theme) => ({
    appbar: {
      background: theme.palette.bg,
    },
  
    title: {
      flexGrow: 1,
      color: theme.palette.common.black,
      fontWeight: "bold",
      opacity: "0.9",
    },
  
    container: {
      marginTop: "1%",
      padding: theme.spacing(10),
    },
  
    prescriptionTitle: {
      fontSize: "0.8rem",
      fontWeight: "700",
      opacity: "0.7",
      lineHeight: "normal",
      marginBottom: "10px",
    },
    prescriptionSection: {
      marginBottom: "2rem",
    },
    input: {
      display: "none",
    },
    img: {
      width: theme.spacing(50),
      height: theme.spacing(50),
    },
    imgCaptureButton: {
      position: "absolute",
      bottom: -10,
      right: -10,
      backgroundColor: "white",
      borderRadius: "50%",
      boxShadow: "0px 5px 20px #00000000",
    },
    style_1: {
      width: "100%",
      marginLeft: "2%",
      marginRight: "4%",
      backgroundColor: "white",
      borderRadius: 14,
    },
    imageLoader: {
      position: "absolute",
      top: "40%",
      left: "35%",
    },
    signContainer: {
      minWidth: "30%",
      maxWidth: "35%",
    },
    signatureImage: {
      height: "100px",
      maxWidth: "80%",
      marginLeft: "15px",
    }
  }));
  
  const Template = () => {
    const classes = useStyles();
    const globalClass = useGlobalStyles();
    const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
      ? AuthService.getUser()
      : {};
    const [doctors, setDoctors] = useState([]);
    const [docData, setDocData] = useState(userObj);
    const [socialMediaInfo, setSocialMediaInfo] = useState(null);
    const formApi = useRef();
    const presFileRef = useRef(null);
    const [qrIndex, setQrIndex] = useState(null);
    const [paymentModes, setPaymentModes] = useState([]);
    const [profileImg, setProfileImg] = useState(null);
    const [initialValues, setInitialValues] = useState([]);
    const [paymentInitialValues, setPaymentInitialValues] = useState([]);
    const [docSlug, setDocSlug] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const [specialities, setSpecialities] = useState([]);
    const [speciality, setSpeciality] = useState(null);
    const [signImage, setSignImage] = useState(null);
    const weekDays = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];
    const [profileSettings, setProfileSettings] = useState(null)
  
  
  
    const [appointmentTypes, setAppointmentTypes] = useState([
    //   {
    //     label: "GENERAL",
    //     active: false,
    //     disclaimer: "",
    //   },
    //   {
    //     label: "MINOR_SURGERY",
    //     active: false,
    //     disclaimer: "",
    //   },
    //   {
    //     label: "DRESSING",
    //     active: false,
    //     disclaimer: "",
    //   },
    //   {
    //     label: "EMERGENCY",
    //     active: false,
    //     disclaimer: "",
    //   },
    //   {
    //     label: "AUDIO",
    //     active: false,
    //     disclaimer: "",
    //   },
    ]);
  
    const [socialMediaArr, setSocialMediaArr] = useState([
      {
        name: "FACEBOOK",
        link: "",
        id: 1,
      },
      {
        name: "INSTAGRAM",
        link: "",
        id: 2,
      },
      {
        name: "LINKEDIN",
        link: "",
        id: 3,
      },
      {
        name: "WEBSITE",
        link: "",
        id: 4,
      },
      {
        name: "TWITTER",
        link: "",
        id: 5,
      },
    ]);
  
    const paymentTypes = [
      {
        label: "GOOGLE PAY",
        paymentMode: "GOOGLEPAY",
        description: "GOOGLE PAY",
      },
  
      {
        label: "PAYTM",
        paymentMode: "PAYTM",
        description: "PAYTM",
      },
      {
        label: "BHIM UPI",
        paymentMode: "BHIM_UPI",
        description: "BHIM UPI",
      },
      {
        label: "PHONE PE",
        paymentMode: "PHONEPAY",
        description: "PHONE PE",
      },
    ];
    const paymentSelectArr = ["GOOGLE PAY", "PAYTM", "BHIM UPI", "PHONE PE"];
  
    const [checkinAudio, setCheckinAudio] = useRecoilState(CheckedInAudioState)
    const tenant = AuthService.getTenant()
    const settingsFormData = useRef(profileSettings)
    const appointmentTypesFormData = useRef(appointmentTypes)
    const profileImgFormData = useRef(profileImg)
    const signImageFormData = useRef(signImage)
    const socialMediaFormData = useRef(socialMediaArr)
  
    useEffect(() => {
      settingsFormData.current = profileSettings;
      appointmentTypesFormData.current = appointmentTypes
      profileImgFormData.current = profileImg
      signImageFormData.current = signImage
      socialMediaFormData.current = socialMediaArr
    }, [profileSettings, appointmentTypes, profileImg, signImage, socialMediaArr])
  
  
    useEffect(() => {
      Api.get("data/specialities").subscribe((resp) => {
        let arr = Bro(resp).iCanHaz("data.data");
        setSpecialities(arr);
        if (speciality && specialities) {
          let specialityVal = arr.filter((item) => item.label === speciality);
          if (specialityVal[0]) {
            formApi.current.setValue("speciality", specialityVal[0].value);
          }
        }
      });
    }, [speciality]);
  
    useEffect(() => {
      if (userObj.id) {
        Axios.get(`${process.env.REACT_APP_API2_HOST}user-settings/${userObj.id}`).then((resp) => {
          const response = resp.data.data
          const { welcomeEmail } = response
          formApi.current.setValue("welcomeEmail", welcomeEmail);
        })
      }
    }, [userObj.id])
  
    const fetchDoctors = () => {
      if (isCurrentRole(AppConstants.ROLE.ADMIN)) {
        const filter = { roleName: { values: ["DOCTOR"], filterType: "set" } };
        DataService.fetchList("doctor/search", 0, 50, filter).subscribe(
          (resp) => {
            setDoctors(resp.content);
          },
          (error) => {
            DEFAULT_ERROR_CALLBACK(error);
          }
        );
      }
    };
    useEffect(fetchDoctors, []);
  
    useEffect(() => {
      if (docData) {
        initDrFields(docData);
        setSocialMediaArr(docData.socialMedia ?? [])
      }
    }, [docData]);
  
    const onDoctorSelected = (e) => {
      let newDoc = doctors.filter((item) => item.slug === e.target.value);
      setDocData(newDoc[0]);
    };
  
    const initDrFields = (data) => {
      formApi.current.setValues(data);
      setProfileImg(data.profilePic);
      setSignImage(data.signature);
      if (data.appointmentType) {
        setAppointmentTypes(data.appointmentType);
      } else {
      }
      setSocialMediaInfo(data.socialMedia);
      if (isCurrentRole(AppConstants.ROLE.DOCTOR)) {
        setDocSlug(data.slug);
      }
  
      if (data.paymentOptions && data.paymentOptions.length > 0) {
        let transformArr = data.paymentOptions.map((item) => {
          delete item["paymentMode"];
          delete item["logo"];
          return item;
        });
        setPaymentInitialValues(transformArr);
        formApi.current.setInitialValue("paymentOptions", transformArr);
      } else {
        let initialData = [
          {
            payTo: "",
            qrCode: "",
            description: "",
          },
        ];
        setPaymentInitialValues(initialData);
        formApi.current.setInitialValue("paymentOptions", initialData);
      }
      setInitialValues([]);
      if (data.availSummary && data.availSummary.length > 0) {
        setInitialValues(data.availSummary);
        for (let i = 0; i < data.availSummary.length; i++) {
          formApi.current.setValue(
            `availSummary[${i}].appointmentType`,
            data.availSummary[i].type
          );
          formApi.current.setValue(
            `availSummary[${i}].fees`,
            data.availSummary[i].fees
          );
          formApi.current.setValue(
            `availSummary[${i}].appointmentSlotDurationInMillis`,
            data.availSummary[i].appointmentSlotDurationInMillis
          );
          formApi.current.setValue(
            `availSummary[${i}].fromDay`,
            data.availSummary[i].fromDay
          );
          formApi.current.setValue(
            `availSummary[${i}].toDay`,
            data.availSummary[i].toDay
          );
          formApi.current.setValue(
            `availSummary[${i}].fromSlot`,
            data.availSummary[i].fromSlot
          );
          formApi.current.setValue(
            `availSummary[${i}].toSlot`,
            data.availSummary[i].toSlot
          );
        }
      }
      formApi.current.setInitialValue("availSummary", data.availSummary);
      setSpeciality(data.speciality);
    };
  
    const handleTypeCheckedChange = (index) => {
      let updateArr = appointmentTypes.map((item, idx) => {
        if (idx === index) {
          item.active = !item.active;
        }
        return item;
      });
      setAppointmentTypes(updateArr);
      handleKeyUp()
    };
  
    const handleTextChange = (index, e) => {
      let tempArr = appointmentTypes;
      let updateArr = tempArr.map((item, idx) => {
        if (idx === index) {
          item.disclaimer = e;
        }
        return item;
      });
      setAppointmentTypes(updateArr);
    };
  
    const handleChange = (evt) => {
      if (!docSlug) {
        SHOW_INFO_NOTIFICATION("Please Select Doctor");
        return;
      }
      setImageLoading(true);
  
      let fileArray = Object.values(evt.target.files);
      let formData = new FormData();
      formData.append("file", fileArray[0]);
      Api.post(`file/upload/secure?dir=settings/${docSlug}`, formData).subscribe(
        (resp) => {
          const imgUrl = resp.data.data;
          setProfileImg(imgUrl);
          setImageLoading(false);
          handleKeyUp()
        },
        (error) => {
          setImageLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    };
  
    const handleSignChange = (evt) => {
      if (!docSlug) {
        SHOW_INFO_NOTIFICATION("Please Select Doctor");
        return;
      }
      setImageLoading(true);
  
      let fileArray = Object.values(evt.target.files);
      let formData = new FormData();
      formData.append("file", fileArray[0]);
      Api.post(`file/upload/secure?dir=settings/${docSlug}`, formData).subscribe(
        (resp) => {
          const imgUrl = resp.data.data;
          setSignImage(imgUrl);
          setImageLoading(false);
          handleKeyUp()
          SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.ADD_SUCCESS)
        },
        (error) => {
          setImageLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    };
  
    const handleQrChange = (evt) => {
      let fileArray = Object.values(evt.target.files);
      let formData = new FormData();
      formData.append("file", fileArray[0]);
      Api.post(`file/upload/secure?dir=settings/${docSlug}`, formData).subscribe(
        (resp) => {
          const imgUrl = resp.data.data;
          if (qrIndex !== null) {
            formApi.current.setValue(`paymentOptions[${qrIndex}].qrCode`, imgUrl);
            setQrIndex(null);
            SHOW_SUCCESS_NOTIFICATION(
              "QR code uploaded successfully.Please click on save button."
            );
          }
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    };
  
    const addRow = () => {
      setInitialValues((prev) => [
        ...prev,
        {
          appointmentType: "",
          fees: "",
          appointmentSlotDurationInMillis: "",
          fromDay: "",
          toDay: "",
          fromSlot: "",
          toSlot: "",
        },
      ]);
    };
  
    const onRemoveRow = (idx) => {
      const values = [...initialValues];
      values.splice(idx, 1);
      for (let i = 0; i < values.length; i++) {
        formApi.current.setValue(
          `availSummary[${i}].appointmentType`,
          values[i].appointmentType || ""
        );
        formApi.current.setValue(`availSummary[${i}].fees`, values[i].fees || "");
        formApi.current.setValue(
          `availSummary[${i}].appointmentSlotDurationInMillis`,
          values[i].appointmentSlotDurationInMillis || ""
        );
        formApi.current.setValue(
          `availSummary[${i}].fromDay`,
          values[i].fromDay || ""
        );
        formApi.current.setValue(
          `availSummary[${i}].toDay`,
          values[i].toDay || ""
        );
        formApi.current.setValue(
          `availSummary[${i}].fromSlot`,
          values[i].fromSlot || ""
        );
        formApi.current.setValue(
          `availSummary[${i}].toSlot`,
          values[i].toSlot || ""
        );
      }
      setInitialValues(values || []);
      handleKeyUp()
    };
  
    const addPaymentRow = () => {
      setPaymentInitialValues((prev) => [
        ...prev,
        { payTo: "", qrCode: "", description: "" },
      ]);
    };
  
    const onRemovePaymentRow = (idx) => {
      const values = [...paymentInitialValues];
      values.splice(idx, 1);
      for (let i = 0; i < values.length; i++) {
        formApi.current.setValue(`paymentOptions[${i}].payTo`, values[i].payTo);
        formApi.current.setValue(`paymentOptions[${i}].qrCode`, values[i].qrCode);
        formApi.current.setValue(
          `paymentOptions[${i}].description`,
          values[i].description
        );
      }
      setPaymentInitialValues(values || []);
      handleKeyUp()
    };
  
    const handleChangeSocialMedia = (name, value) => {
      setSocialMediaArr((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState.find((media) => {
          if (media.name === name) {
            media.link = value;
          }
        });
        return [...newState]
      });
    };
  
    const handleSubmit = (val) => {
      if (!docData) {
        SHOW_INFO_NOTIFICATION("Please Select Doctor");
        return;
      }
      if (!val.availSummary) {
        SHOW_INFO_NOTIFICATION("Availability Summary cannot be blank");
        return;
      }
  
      if (!validateAvailSummary(val.availSummary)) {
        return;
      }
  
      if (!val.paymentOptions) {
        SHOW_INFO_NOTIFICATION("Payment Options cannot be blank");
        return;
      }
  
      if (!validatePaymentOptions(val.paymentOptions)) {
        return;
      }
      let tempObj = docData;
      if (val.paymentOptions && val.paymentOptions.length > 0) {
        let paymentData = val.paymentOptions.map((item) => {
          let index = paymentTypes.filter(
            (data) => data.label === item.description
          );
          item.payTo = item.payTo ? item.payTo : null;
          item.qrCode = item.qrCode ? item.qrCode : null;
          item.description = item.description;
          item.paymentMode = index[0] ? index[0].paymentMode : null;
          return item;
        });
        tempObj.paymentOptions = paymentData;
      } else {
        tempObj.paymentOptions = [];
      }
      tempObj.name = val.name;
      tempObj.profilePic = profileImgFormData.current;
      tempObj.bio = val.bio;
      tempObj.shortTitle = val.shortTitle;
      tempObj.speciality = val.speciality;
      tempObj.fees = val.fees;
      tempObj.email = val.email;
      tempObj.contactNumber = val.contactNumber;
      tempObj.designation = val.designation;
      tempObj.socialMedia = val.socialMedia;
      tempObj.registrationNo = val.registrationNo;
      tempObj.appointmentType = appointmentTypesFormData.current;
      tempObj.signature = signImageFormData.current;
      tempObj.availabilitySummary = transformArrToMap(val.availSummary);
      tempObj.availSummary = val.availSummary ? val.availSummary : [];
      tempObj.socialMedia = socialMediaFormData.current;
  
  
      for (let i = 0; i < tempObj.paymentOptions.length; i++) {
        if (!tempObj.paymentOptions[i].paymentMode) {
          SHOW_INFO_NOTIFICATION("Please Update Payment Option");
          return;
        }
      }
  
  
      const slots = {}
      tempObj.availSummary.forEach(avail => {
        const { appointmentType, fromDay, toDay, fromSlot, toSlot, appointmentSlotDurationInMillis } = avail
        const days = getDayFromStartAndEnd(fromDay.toUpperCase(), toDay.toUpperCase())
        slots[appointmentType] = slots[appointmentType] ?? {}
        const times = getTimeFromStartAndEnd(fromSlot, toSlot, appointmentSlotDurationInMillis, 'minutes')
        days.forEach(day => {
          slots[appointmentType][day] = [...(slots[appointmentType][day] ?? []), ...times]
        })
      })
  
      Object.keys(slots).forEach(key => {
        const data = slots[key]
        Object.keys(data).forEach(day => {
          const times = [...new Set(data[day])]
          slots[key][day] = times
        })
      })
  
      const payload = {
        user: userObj.id,
        welcomeEmail: val.welcomeEmail,
        meetLink: "",
        uiFontFamily: "",
        pdfFontFamily: "",
      };
  
      handleSubmitExtraFields(payload);
  
      tempObj.slots = slots
      DataService.updateDoc("doctor/update", tempObj).subscribe(
        (resp) => {
          SHOW_SUCCESS_NOTIFICATION("Data Saved Successfully");
          AuthService.setUser(resp);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    };
  
    const handleCheckinAudio = (e) => {
      const value = e.target.checked
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.CHECKED_IN_AUDIO, value)
      setCheckinAudio(value)
    }
  
    const handleChangeSettingDetails = (event) => {
      var events = event.values;
      events.signature = signImage;
      setProfileSettings(events)
    }
  
    // this needs to be moved to a comman file in future
  
    const handleDebounce = (changeFunction, delay) => {
      let timer;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          settingsFormData.current && changeFunction(settingsFormData.current)
        }, delay);
      };
    };
  
    const handleKeyUp = useCallback(handleDebounce(handleSubmit, 10000), []);
  
  
    const handleSubmitExtraFields = useCallback((value) => {
      if (userObj.id) {
        Axios.post(`${process.env.REACT_APP_API2_HOST}user-settings`, value).catch(() => {
          NotificationManager.error(AppConstants.MSG.GENERIC_ERROR)
        })
      }
    }, [userObj])
  
    return (
      <div>
        <Header title="Email Templates" />
        <Form
          apiRef={formApi}
          onSubmit={handleSubmit}
          onChange={handleChangeSettingDetails}
          onKeyUp={handleKeyUp}
          initialValues={{
            availSummary: initialValues,
            paymentOptions: [
              {
                payTo: "",
                qrCode: "",
                description: "",
              },
            ],
          }}
        >
          <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
            
  
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Title
              </Typography>
            </Box>

            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput name="title" field="title" placeholder="Type here" />
            </Paper>
          </Box>  
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Content
              </Typography>
            </Box>

            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <TextAreaInput name="content" field="content" placeholder="Type here" />
            </Paper>
          </Box> 
            <Box className={classes.prescriptionSection}>
              <Box mt="1rem" display="flex" alignItems="center">
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Table
                </Typography>
              </Box>
              <Paper
                className={clsx(classes.container, globalClass.roundWhiteBg)}
              >
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong style={{ fontSize: "10px" }}>
                            Table
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontSize: "10px" }}>Active</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontSize: "10px" }}>Subject</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointmentTypes && appointmentTypes.length > 0 ? (
                        appointmentTypes.map((item, idx) => (
                          <Scope scope={`appointmentType[${idx}]`} key={idx}>
                            <TableRow key={idx}>
                              <TableCell style={{ fontSize: "10px" }}>
                                {item.label}
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={item.active}
                                  onChange={() => handleTypeCheckedChange(idx)}
                                  name="selectedPlan"
                                  color="primary"
                                  size="medium"
                                />
                              </TableCell>
                              <TableCell>
                                <TextAreaInput
                                  field={`disclaimer`}
                                  placeholder="Type disclaimer...."
                                  onChange={(e) => {
                                    handleTextChange(idx, e.target.value);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </Scope>
                        ))
                      ) : (
                        ''
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
              >
                SAVE
              </Button>
            </Box>
          </div>
        </Form>
      </div>
    );
  };
  
  export default Template;