import React from "react";
import { Typography, Link, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  color: {
    color: theme.palette.common.white,
    textDecoration: "none",
  },
  py: {
    padding: theme.spacing(1, 0),
  },
}));

const Precription = ({ title, url, color, ...rest }) => {
  const classes = useStyles();
  return (
    <Typography
      {...rest}
      variant="body2"
      className={clsx(classes.color, classes.py)}
      align="center"
    >
      {"Copyright ©   "}
      <Link className={classes.color} target="_blank" href={url}>
        {title}
      </Link>{" "}
      {new Date().getFullYear()}
      {"   "}
      {"All rights reserved"}
      {"."}
    </Typography>
  );
};

export default Precription;
