import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  TextField,
  Grid,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import Modal from "@mui/material/Modal";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import { CloseOutlined } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { SelectInput } from "sixsprints-react-ui";
import {
  addFormInput,
  deleteFormInput,
  deleteInputText,
  addFormSubimt,
} from "../../redux/action/action";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import InputText from "./inputComponents/inputText";
import InputNumber from "./inputComponents/inputNumber";

import InputPassword from "./inputComponents/password";
import InputCheckbox from "./inputComponents/checkBox";
import InputColor from "./inputComponents/inputColor";
import InputDate from "./inputComponents/InputDate";
import InputEmail from "./inputComponents/inputEmail";
import InputFile from "./inputComponents/inputFile";
import InputButton from "./inputComponents/inputButton";
import InputTel from "./inputComponents/inputTel";
import InputTime from "./inputComponents/inputTIme";
import InputSelect from "./inputComponents/inputSelect";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: theme.spacing(1),
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "25%",
    height: "auto",
    background: "white",
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
}));

const selectState = (state) => state.inputList.inputTextDAta;
const selectForm = (state) => state.inputFormData.FormInputData[0];

const CustomPrescriptionField = () => {
  const dispatch = useDispatch();
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => (setOpen(false), allData);

  const [allData, setAllData] = useState([]);
  const [inputData, setInputData] = useState({ selectType: "" });
  console.log(inputData);

  const AllFeilds = useSelector(selectState);
  const saveFeilds = useSelector(selectForm);
  console.log("select", AllFeilds);

  const handleChangeType = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const FieldRemove = (index) => {
    SHOW_INFO_NOTIFICATION("Removed successfully");
    dispatch(deleteInputText(index));
  };

  const [formInputData, setforminputData] = useState({});

  const handleChange = (name, value) => {
    console.log(value);
    setforminputData({ ...formInputData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    SHOW_SUCCESS_NOTIFICATION("Form Field Save successfully");
    dispatch(addFormInput(AllFeilds));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    SHOW_SUCCESS_NOTIFICATION("Form submitted successfully");
    dispatch(addFormSubimt(formInputData));
  };

  const components = [
    {
      type: "text",
      comp: <InputText inputData={inputData.selectType} />,
    },
    {
      type: "number",
      comp: <InputNumber inputData={inputData.selectType} />,
    },
    {
      type: "password",
      comp: <InputPassword inputData={inputData.selectType} />,
    },
    {
      type: "checkbox",
      comp: <InputCheckbox inputData={inputData.selectType} />,
    },
    {
      type: "color",
      comp: <InputColor inputData={inputData.selectType} />,
    },
    {
      type: "date",
      comp: <InputDate inputData={inputData.selectType} />,
    },
    {
      type: "email",
      comp: <InputEmail inputData={inputData.selectType} />,
    },
    {
      type: "file",
      comp: <InputFile inputData={inputData.selectType} />,
    },

    {
      type: "tel",
      comp: <InputTel inputData={inputData.selectType} />,
    },
    {
      type: "time",
      comp: <InputTime inputData={inputData.selectType} />,
    },
    {
      type: "select",
      comp: <InputSelect inputData={inputData.selectType} />,
    },
    {
      type: "submit",
      comp: <InputButton inputData={inputData.selectType} />,
    },
  ];

  const [selectedInputField, setselectedInputField] = useState([]);

  const selectInputFeild = (e) => {
    const value = e.target.value;
    setselectedInputField([...selectedInputField, value]);
    console.log(selectedInputField);
  };

  console.log(selectedInputField);

  return (
    <>
      <Form>
        {AllFeilds.length === 0 ? (
          <Box
            mb={3}
            style={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>No Field is added yet</Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
              onClick={handleOpen}
            >
              Add custom Feilds
            </Button>
          </Box>
        ) : null}

        <Box>
          {AllFeilds?.map((input, index) => {
            return (
              <>
                <Grid key={index}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <Box style={{ flex: 1 }}>
                      {input.type === "text" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {input.type === "number" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            min={input.min}
                            max={input.max}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "password" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            min={input.min}
                            max={input.max}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "checkbox" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <Checkbox
                            defaultChecked={input.defaultChecked}
                            name={input.label}
                            color="primary"
                            label={input.label}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "color" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "date" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {input.type === "email" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {input.type === "file" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            multiple={input.multiple === "true" ? true : false}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.file)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "tel" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            multiple={input.multiple === "true" ? true : false}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "time" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            label={input.label}
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            multiple={input.multiple === "true" ? true : false}
                            className={classes.style_1}
                            style={{ width: "100%", marginTop: "2%" }}
                            // onChange={(e) =>
                            //   handleChange(input.label, e.target.value)
                            // }
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "select" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span>
                          </Typography>

                          <SelectInput
                            className={globalClass.roundWhiteBg}
                            options={[]}
                            initialValue={"false"}
                            labelKey="label"
                            valuekey={[]}
                            name="selectType"
                            field="headerSpacing"
                            width="100%"
                            // label="Header Space Size"
                            validateOnBlur
                            validateOnChange
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {input.type === "submit" ? (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            {/* {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span> */}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            type={input.type}
                            placeholder={input.placeholder}
                            variant="outlined"
                            name={input.label}
                            style={{
                              width: "100%",
                              marginTop: "2%",
                              backgroundColor: "slategray",
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      style={{
                        flex: 0.1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {index === AllFeilds.length - 1 ? (
                        <>
                          <Box
                            onClick={handleOpen}
                            style={{ padding: " 1%", cursor: "pointer" }}
                          >
                            <AddCircleOutlineIcon />
                          </Box>
                        </>
                      ) : (
                        ""
                      )}

                      <Box
                        onClick={() => FieldRemove(index)}
                        style={{ padding: " 1%", cursor: "pointer" }}
                      >
                        <RemoveCircleOutlineIcon />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            );
          })}
        </Box>

        <Box style={{ width: "100%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
            onClick={handleFormSubmit}
          >
            Save Form
          </Button>

          <SelectInput
            className={globalClass.roundWhiteBg}
            options={saveFeilds}
            initialValue={"false"}
            labelKey="label"
            valuekey={[]}
            name="selectType"
            field="headerSpacing"
            width="100%"
            // label="Header Space Size"
            validateOnBlur
            validateOnChange
            onChange={selectInputFeild}
          />
        </Box>
      </Form>

      <Form >
        {selectedInputField?.map((input, index) => {
          console.log(input);
          return (
            <>
              <Grid key={index}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Box style={{ flex: 1 }}>
                    {input.type === "text" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {input.type === "number" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          min={input.min}
                          max={input.max}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "password" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          min={input.min}
                          max={input.max}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "checkbox" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <Checkbox
                          defaultChecked={input.defaultChecked}
                          name={input.label}
                          color="primary"
                          label={input.label}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "color" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "date" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {input.type === "email" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {input.type === "file" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <input
                          id="contained-diagram-file"
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          multiple={input.multiple === "true" ? true : false}
                          onChange={(e) => {
                            const reader = new FileReader();
                            reader.addEventListener(
                              "load",
                              () => {
                                console.log(reader.result);
                                handleChange(input.label, reader.result);
                              },
                              false
                            );
                            if (e.target.files) {
                              const data = reader.readAsDataURL(
                                e.target.files[0]
                              );
                            }
                          }}
                        />

                        {/* <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          multiple={input.multiple === "true" ? true : false}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={async (e) => {
                            const reader = new FileReader();
                            let data = "";
                            reader.addEventListener("load", () => {
                              data = reader.result;
                            });
                            const image = reader.readAsDataURL(
                              e.target.files[0]
                            );

                            console.log("file", e.target);
                            console.log("file", e.target.file);
                            await handleChange(input.label, data);
                          }}
                        /> */}
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "tel" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          multiple={input.multiple === "true" ? true : false}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "time" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          label={input.label}
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          multiple={input.multiple === "true" ? true : false}
                          className={classes.style_1}
                          style={{ width: "100%", marginTop: "2%" }}
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "select" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {input.label}{" "}
                          <span style={{ color: "red" }}>
                            {input.required === "true" ? "*" : ""}
                          </span>
                        </Typography>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="outlined"
                          onChange={(e) =>
                            handleChange(input.label, e.target.value)
                          }
                        >
                          {input.option.map((item) => {
                            return (
                              <MenuItem value={item.value}>
                                {item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </>
                    ) : (
                      ""
                    )}

                    {input.type === "submit" ? (
                      <>
                        <Typography
                          variant="h6"
                          className={classes.prescriptionTitle}
                        >
                          {/* {input.label}{" "}
                            <span style={{ color: "red" }}>
                              {input.required === "true" ? "*" : ""}
                            </span> */}
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          type={input.type}
                          placeholder={input.placeholder}
                          variant="outlined"
                          name={input.label}
                          style={{
                            width: "100%",
                            marginTop: "2%",
                            backgroundColor: "slategray",
                          }}
                          onClick={handleSubmit}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* <Box
                      style={{
                        flex: 0.1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {index === AllFeilds.length - 1 ? (
                        <>
                          <Box
                            onClick={handleOpen}
                            style={{ padding: " 1%", cursor: "pointer" }}
                          >
                            <AddCircleOutlineIcon />
                          </Box>
                        </>
                      ) : (
                        ""
                      )}

                      <Box
                        onClick={() => FieldRemove(index)}
                        style={{ padding: " 1%", cursor: "pointer" }}
                      >
                        <RemoveCircleOutlineIcon />
                      </Box>
                    </Box> */}
                </Box>
              </Grid>
            </>
          );
        })}
      </Form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Custom Feilds
            </Typography>
            <CloseOutlined onClick={handleClose} />
          </Box>

          <Box style={{ width: "100%" }}>
            {/* <SelectInput
              className={globalClass.roundWhiteBg}
              options={[
                "text",
                "number",
                "password",
                "checkbox",
                "color",
                "date",
                "email",
                "file",
                "radio",
                "select",
                "tel",
                "time",
                "submit",
              ]}
              initialValue={"false"}
              labelKey="label"
              valuekey="value"
              name="selectType"
              field="headerSpacing"
              width="100%"
              // label="Header Space Size"
              validateOnBlur
              validateOnChange
              onChange={handleChangeType}
            /> */}

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputData.selectType}
              label="Age"
              name="selectType"
              onChange={handleChangeType}
              className={globalClass.roundWhiteBg}
              variant="outlined"
            >
              {[
                "text",
                "number",
                "password",
                "checkbox",
                "color",
                "date",
                "email",
                "file",
                "radio",
                "select",
                "tel",
                "time",
                "submit",
              ].map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </Box>

          {components
            ?.filter(
              (row) =>
                inputData.selectType &&
                row?.type
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(inputData.selectType?.toString()?.toLowerCase())
            )
            ?.map((data) => {
              return <Box>{data.comp}</Box>;
            })}
        </Box>
      </Modal>
    </>
  );
};

export default CustomPrescriptionField;
