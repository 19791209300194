import React from "react";

import {
  Grid,
  makeStyles,
  Paper,
  Divider,
  Button,
  fade,
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Avatar,
} from "@material-ui/core";

const useStyle = makeStyles(function (theme) {
  return {
    roundBorder: {
      borderRadius: 14,
    },

    textBubbleLeftTop: {
      maxWidth: "70%",
      borderRadius: "1rem",
      borderTopLeftRadius: 0,
      padding: "0.5rem",
      color: theme.palette.common.black,
      font: "12px",
      backgroundColor: theme.palette.chatBg,
    },

    textBubble: {
      maxWidth: "70%",
      borderRadius: "1rem",
      padding: theme.spacing(3),
      backgroundColor: "#F6F7FC",
    },
    textBubbleRightBottom: {
      maxWidth: "70%",
      borderRadius: "1rem",
      borderBottomRightRadius: 0,
      padding: "0.5rem",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },

    bg: {
      backgroundColor: theme.palette.primary.main,
    },
    img: {
      objectFit: "contain",
      maxWidth: 150,
      maxHeight: 150,
    },
  };
});

const handleImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };


  const handlePdfClick = (val) => {
    let pdfWindow = window.open("")
pdfWindow.document.write(
    `<iframe width='100%' height='100%' src=${val}></iframe>`
)
  };

  const onDownloadClick = (val)=>{
    var a = document.createElement("a"); //Create <a>
    a.href = val //Image Base64 Goes here
    a.download = "chatFile" + new Date().toLocaleDateString() ; //File name Here
    a.click(); //Downloaded file
  }

const ChatBubble = ({
  handleClick,
  contenttype,
  messageType,
  actions,
  img,
  time,
  sameUser,
  user,
  left,
  text,
  hideActions,
  textVariant,
}) => {

  //   let _ref$textVariant = textVariant;
  //   let textVariant =
  //     _ref$textVariant === undefined ? "subtitle2" : _ref$textVariant;

  var classes = useStyle();

  var renderAvatar = function renderAvatar(user) {
    return (
      <Box style={{ visibility: sameUser ? "hidden" : "visible", mr: 2 }}>
        <Avatar>{user}</Avatar>
      </Box>
    );
  };

  var renderTextActions = function renderTextActions(actionArr, text) {
    return (
      <Box style={{ display: "flex", justifyContent: "center", margin: "1rem 0rem" }}>
        <Box className={classes.textBubble}>
          <Box style={{ textAlign: "center" }}>
            <Typography variant={textVariant}>{text}</Typography>
          </Box>

          {!hideActions && (
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                mt: "1rem",
              }}
            >
              {actionArr.map((item, index) => {
                return (
                  <Box key={index} style={{ marginRight: 2 }}>
                    <Button
                      onClick={handleClick.bind(this, item.actionUrl)}
                      disableElevation={true}
                      variant={index === 0 ? "contained" : "outlined"}
                      color="primary"
                      size="small"
                    >
                      {item.name}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          )}

          {hideActions && (
            <Box style={{ margin: "1rem", textAlign: "center" }}>
              <Typography variant={textVariant}>
                "Above system message sent to patient."
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  var renderDownloadIcon = function renderDownloadIcon(val) {
    return (
      <Box style={{ alignSelf: "center" }}>
        <a href={img}>
          <IconButton size="medium" onClick={()=>onDownloadClick(val)}>
            <img
              src="/downloadIcon.png"
              alt="download-icon"
              width="20px"
            />
          </IconButton>
        </a>
      </Box>
    );
  };

  var linkDecorator = function linkDecorator(href, text, key) {
    return (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
  };

  var renderTextBubble = function renderTextBubble() {
    return (
      <div>
        <Box
          style={{
            display: "flex",
            justifyContent: left ? "flex-start" : "flex-end",
          }}
        >
          {!left && img && renderDownloadIcon(img)}
          <Box
            className={
              left ? classes.textBubbleLeftTop : classes.textBubbleRightBottom
            }
          >
            {text && <Typography variant={textVariant}>{text}</Typography>}
            {(img &&( contenttype === "image/png"  || contenttype === "image/jpeg"  || contenttype === "image/jpg") ) ?  (
                <img
                  className={classes.img}
                  width="100%"
                  alt="chat-img"
                  src={img}
                  onClick={()=>handleImageClick(img)}
                  style={{cursor: "pointer"}}
                />
            )
            :
              null
            }

            {(img && contenttype === "application/pdf") ?  (
                <img
                  className={classes.img}
                  width="50%"
                  alt="chat-pdf"
                  src="/PDF_file_icon.svg.png"
                  onClick={()=>handlePdfClick(img)}
                  style={{cursor: "pointer"}}
                />
            )
            :
              null
            }
          </Box>
          {left && img && renderDownloadIcon(img)}
        </Box>
        <Box
          style={{
            marginLeft: "3.5rem",
            display: "flex",
            justifyContent: left ? "flex-start" : "flex-end",
          }}
        >
          <Typography variant="caption">{time}</Typography>
        </Box>
      </div>
    );
  };

  return (
    <Box style={{ margin: " 2rem , 1rem" }}>
      {messageType === "AUTOMATED"
        ? renderTextActions(actions, text)
        : renderTextBubble(img, time, sameUser, user, left, text)}
    </Box>
  );
};

export default ChatBubble;
