import React, { useState } from "react";
import { Chip, Tooltip, Menu, MenuItem } from "@mui/material";

const ChipStyledSelectDropdown = ({
  value,
  dropdownChips,
  defaultValue = "Select",
  onChangeHandler = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={defaultValue} arrow>
        <Chip
          label={value === "" ? defaultValue : value}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {dropdownChips.map((chip, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onChangeHandler(chip);
              handleClose();
            }}
          >
            {chip}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ChipStyledSelectDropdown;
