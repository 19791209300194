export const ADD_TEXT_INPUT = "ADD_TEXT_INPUT";
export const DELETE_TEXT_INPUT = "DELETE_TEXT_INPUT";
export const ADD_FORM_INPUT = "ADD_FORM_INPUT";
export const DELETE_FORM_INPUT = "DELETE_FORM_input";
export const ADD_FORM_SUBMIT = "ADD_FORM_SUBMIT";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const SWITCH_PROFILE = "SWITCH_PROFILE";
export const ADD_PRIMARY_PROFILE = "ADD_PRIMARY_PROFILE";
export const SET_SHARED_ACCESS_BY = "SET_SHARED_ACCESS_BY";
export const CHANGE_PRIMARY_USER_CURRENT_STATUS =
  "CHANGE_PRIMARY_USER_CURRENT_STATUS";

export const SET_TENANT = "SET_TENANT";