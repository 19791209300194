import {atom} from 'recoil'

export const AppointmentsState = atom({
    key: "appointments",
    default: []
})

export const AppointmentsStatusState = atom({
    key: "appointments-status",
    default: {}
})