import React, { useMemo } from "react";
import YourTurn from "../../assets/your-turn.svg";
import "./dashboard.scss";

const PatientTurn = ({ calledPatient }) => {
  console.log('called_Patient_2', calledPatient);
  const maskedMobile = useMemo(() => (calledPatient?.patientPhone?.split('')?.map((word, index) => (
    index >
      calledPatient?.patientPhone?.split('')?.length - 5
      ? word
      : '*'
  ))), [calledPatient]);

  const maskedUhid = useMemo(() => (calledPatient?.uhid?.split('')?.map((word, index) => (
    index >
      calledPatient?.uhid?.split('')?.length - 5
      ? word
      : '*'
  ))), [calledPatient]);

  return (
    <div className="main-container">
      <div className="logo">
        <img src={YourTurn} alt="Your turn" />
      </div>
      <h2>{calledPatient?.patientName ?? ""}</h2>
      <div className="patient-details">
        <div className="credentials">
          <div className="mobile">
            <span>Mobile Number</span>
            <h4>{maskedMobile ?? "*********NA"}</h4>
          </div>
          <div className="UID">
            <span>UID Number</span>
            <h4>{maskedUhid ?? "*********NA"}</h4>
          </div>
        </div>
      </div>
      <div className="thanks">
        <span className="thanks">Thank you for waiting</span>
      </div>
    </div>
  );
};

export default PatientTurn;
