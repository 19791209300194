import FilterOptions from "../../utils/filter-options";
import AuthService from "../../utils/services/auth-service";
import Axios from "axios";
import react, { useEffect } from "react";
import { useState } from "react"




class DoctorColumn {

  constructor(isEditMode) {

    this.allColumns = [
      {
        field: "_",
        colId: "_",
        hide: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
    ].concat(
      allFields().map((field) => {
        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          ...doctorFilters.chooseAptFilter(field.dataType),
          pinned: field.pinned ? "left" : "",
          cellRendererSelector: function (params) {
            return {
              component: "customRenderer",
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: "customEditor",
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
                context: params.context,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() {
   

    this.allColumns.splice(8, 0, {
      field: "icon",
      colId: "icon",
      headerName: "Logs",
      editable: false,
      sortable: false,

      cellRendererSelector: function (params) {

        return {
          component: "customRenderer",
          params: {
            headerName: "Logs",
            dataType: "LOGS",
            currentValue: params.value,
            context: params.context,
          },
        };
      },
    });
    this.allColumns.splice(9, 0, {
      field: "icon",
      colId: "icon",
      headerName: "feedbacks",
      editable: false,
      sortable: false,

      cellRendererSelector: function (params) {

        return {
          component: "customRenderer",
          params: {
            headerName: "feedbacks",
            dataType: "FEEDBACK",
            currentValue: params.value,
            context: params.context,
          },
        };
      },
    });

    return this.allColumns;
  }
}

let Alldata = [];

 (async () => {
  await Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then((resp)=>{
    
        Alldata = resp.data.map((data)=>(data)) 
  })  
})(); 

const allFields = () => {
  const configData = AuthService.getConfig().doctorField;
  console.log('test_configdata',configData)
  configData[4].allValues = Alldata;
  configData[4].dataType = "ENUM"

  


  return configData;
};

const doctorFilters = new FilterOptions({ collection: "doctor" });

export default DoctorColumn;
