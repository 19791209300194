import Api2 from "../utils/api2";
import { firstValueFrom } from "rxjs";

export const WebsiteAPI = {
  getAllSpeciality: async function () {
    try {
      const response = await firstValueFrom(Api2.get("/getspeciality"));
      return response.data;
    } catch (error) {
      console.error("Error fetching speciality:", error);
      throw error;
    }
  },
};
