import React, { useEffect, useRef } from "react";
//import Texteditor from "./texteditor";
import Header from "../../components/layout/header";
import Axios from "axios";
import JoditEditor from "jodit-react";
import { ToastContainer, Toast } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import EditIcon from "@material-ui/icons/Edit";

import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { useState } from "react";
import Api from "../../utils/api";
import { Form, Scope } from "informed";
import Loader from "../../components/misc/loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from "../../components/textEditor/CustomTools";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionTitle_1: {
    fontSize: "1.1rem",
    fontWeight: "800",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  
  prescriptionTitle1: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
    marginBottom: "2rem",
    backgroundColor: "white",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding:'1.8%',
    border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  logoContainer: {
    display: "flex",
  },
  addressgrid: {
    display: "flex",
    gap: "5%",
    marginBottom: "5px",
    gridTemplateColumns:
      "repeat(auto-fill, minmax(10rem, 1fr)) 100px repeat(3, 200px)",
    justifyContent: "spa",
  },
  select: {
    width: "100%",
    border: "1px solid #b4b0b0",
    padding: "13px",
    marginTop: "8px",
    minWidth: "80px",
    borderRadius: "10px",
  },
}));

const PrivacyPolicy = (props) => {
  const classes = useStyles();

  const { respo } = props;
  const [Loading, setLoading] = useState(false);

  const globalClass = useGlobalStyles();

  const editor = useRef(null);
  const [content, setContent] = useState({
    aboutus: "",
  });



  const contentFieldChanged = (data) => {
    setContent({ ...content, aboutus: data });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
   
    const req = {
      about: content.aboutus,
      id: content.id,
      type: "aboutus",
     
    };

    if (content.aboutus == "\n") {
      SHOW_ERROR_NOTIFICATION("All feild required");
      setLoading(false);
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/websiteupdate`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.toString() == "succss") {
          setLoading(false);
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
        } else {
          NotificationManager.success("Something went wrong Please try again");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

   
  };
 

  const [welcome, setWelcome] = useState('');

  const handleSetWelcome = html =>{
    setWelcome(html)
  }

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  }
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ]

  return (
    <div>
      <Header title="Policy" />
      <Form
        onSubmit={handleSubmit}
        //onChange={contentFieldChanged}
      >
        

        <Box className={classes.prescriptionSection}>
          <Box
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Privacy and Policy
            </Typography>
          </Box>
                    <Paper className={clsx(classes.container, globalClass.roundWhiteBg)}>
                      <CustomToolbar />
                      <ReactQuill
                        theme="snow"
                        value={welcome}
                        modules={modules}
                        formats={formats}
                        //onChange={handleSetWelcome}
                      />
                    </Paper>
             </Box>

        <Box mb={3} style={{ marginTop: "2%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
          >
            {Loading ? (
              <Loader width="200px" height="20px" color="white" />
            ) : (
              "Save"
            )}
          </Button>
          <ToastContainer />
        </Box>
      </Form>
    </div>
  );
};

export default PrivacyPolicy;
