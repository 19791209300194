import { useEffect, useRef } from "react";

function useSkipFirstRenderEffect(callbackFn, dependencyArray) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      callbackFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);
}

export default useSkipFirstRenderEffect;
