import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

const Loader = ({ height, color, size }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      height={height || "100vh"}
      alignItems="center"
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
};

export default Loader;
