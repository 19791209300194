import {
    Box,
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { default as React, useEffect, useRef, useState } from "react";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import Axios from "axios";
import AppConstants, {
    LOCAL_STORAGE_CONSTANTS,
} from "../../utils/app-constants";
import {
    DEFAULT_ERROR_CALLBACK,
    SHOW_INFO_NOTIFICATION,
    SHOW_SUCCESS_NOTIFICATION,
    SHOW_ERROR_NOTIFICATION,
    transformArrToMap,
    validateAvailSummary,
    validatePaymentOptions,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import { NotificationManager } from "react-notifications";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Loader from "../../components/misc/loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
    appbar: {
        background: theme.palette.bg,
    },

    title: {
        flexGrow: 1,
        color: theme.palette.common.black,
        fontWeight: "bold",
        opacity: "0.9",
    },

    container: {
        marginTop: "1%",
        padding: theme.spacing(10),
    },

    prescriptionTitle: {
        fontSize: "0.8rem",
        fontWeight: "700",
        opacity: "0.7",
        lineHeight: "normal",
        marginBottom: "10px",
        fontFamily: "Comfortaa",
    },
    nameModal: {
        fontSize: "0.5rem",
        fontWeight: "700",
        opacity: "0.7",
        marginBottom: "10px",

    },
    prescriptionSection: {
        marginBottom: "2rem",
    },
    prescriptionmodal: {
        marginBottom: "0rem",
    },
    input: {
        display: "none",
    },
    img: {
        width: theme.spacing(50),
        height: theme.spacing(50),
    },
    imgCaptureButton: {
        position: "absolute",
        bottom: -10,
        right: -10,
        backgroundColor: "white",
        borderRadius: "50%",
        boxShadow: "0px 5px 20px #00000000",
    },
    style_1: {
        width: "100%",
        marginLeft: "2%",
        marginRight: "4%",
        backgroundColor: "white",
        borderRadius: 14,
    },
    imageLoader: {
        position: "absolute",
        top: "40%",
        left: "35%",
    },
    signContainer: {
        minWidth: "30%",
        maxWidth: "35%",

    },
    signatureImage: {
        height: "100px",
        maxWidth: "80%",
        marginLeft: "15px",
    },
    modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 500,
        minWidth: 375,
        width: "100%",
        height: "auto",
        background: theme.palette.bg,
        boxShadow: 24,
        padding: "2%",
        borderRadius: "10px",
    },
    picture: {
        maxWidth: "160px",
        width: "100%",
    },

    MuiTypographybody1: {
        fontsize: "0.6428571428571429rem",
        fontFamily: "Comfortaa",
        fontweight: "400",
        lineheight: "1.5",
    },

}));

const IpdStatus = () => {
    const classes = useStyles();
    const globalClass = useGlobalStyles();
    const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
        ? AuthService.getUser()
        : {};
    const [doctors, setDoctors] = useState([]);
    const [docData, setDocData] = useState(userObj);
    const [socialMediaInfo, setSocialMediaInfo] = useState(null);
    const formApi = useRef();

    const [initialValues, setInitialValues] = useState([]);


    const [appointmentTypes, setAppointmentTypes] = useState([
        {
            label: "GENERAL",
            active: false,
            disclaimer: "",
        },
        {
            label: "MINOR_SURGERY",
            active: false,
            disclaimer: "",
        },
        {
            label: "DRESSING",
            active: false,
            disclaimer: "",
        },
        {
            label: "EMERGENCY",
            active: false,
            disclaimer: "",
        },
        {
            label: "AUDIO",
            active: false,
            disclaimer: "",
        },
    ]);



    useEffect(() => {
        if (userObj.id) {
            Axios.get(
                `${process.env.REACT_APP_API2_HOST}user-settings/${userObj.id}`
            ).then((resp) => {
                const response = resp.data.data;
                const { welcomeEmail } = response;
                formApi.current.setValue("welcomeEmail", welcomeEmail);
            });
        }
    }, [userObj.id]);


    const fetchDoctors = () => {
        if (isCurrentRole(AppConstants.ROLE.ADMIN)) {
            const filter = { roleName: { values: ["DOCTOR"], filterType: "set" } };
            DataService.fetchList("doctor/search", 0, 50, filter).subscribe(
                (resp) => {
                    setDoctors(resp.content);
                },
                (error) => {
                    DEFAULT_ERROR_CALLBACK(error);
                }
            );
        }
    };
    useEffect(fetchDoctors, []);


    ////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    //////////////////////////////////////////////////////

    const [id, setId] = useState();
    const [open, setOpen] = React.useState(false);
    const [AddModalOpen, setAddModalOpen] = useState(false);
    const [updatePicture, setUpdatePicture] = useState(null);
    const [UpdateImgData, setUpdateImgData] = useState(null);
    const [getUpdatePic, setGetUpdatePic] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);
    const socialMediaArr = ["Active", "Inactive"];

    const typeMediaArr = ["General", "ICU"]

    const [addSpecilityData, setaddSpecilityData] = useState([
        {
            roomno: "",

            status: ""
        },
    ]);

    const [getUpdateData, setGetUpdateData] = useState({
        roomNo: "",

        Status: ""
    });


    const onChangeData = (event) => {
        setaddSpecilityData({
            ...addSpecilityData,
            [event.target.name]: event.target.value,
        });
    };

    const handleUpdateChange = (event) => {
        setGetUpdateData({
            ...getUpdateData,
            [event.target.name]: event.target.value,
        });
    };

    const handleAddModalOpen = () => {
        setAddModalOpen(true);
        addSpecilityData.roomno = "";
        addSpecilityData.description = "";
        addSpecilityData.patientcapacity = "";

    };

    const handleAddModalClose = () => {
        setAddModalOpen(false);
    };

    const handleUpdatePic = (e) => {
        const name = e.target.name;
        const value = e.target.files[0];
        if (e.target.files[0]) {
            setUpdatePicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setUpdateImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleOpen = (item) => {
        console.log('test_item',item);
        setOpen(true);
        setId(item.id);


        setGetUpdateData(prevState => ({

            ...prevState,

            roomNo: item.name,

            Status: item.status


        }));


    };

    const handleClose = () => setOpen(false);



    const [resp, setResp] = useState(null);





    ///

    const [logoStatus, setLogoStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.files[0];
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };



    const handleSaveData = async () => {
        setIsLoading(true);
        if (

            addSpecilityData.roomno === ""

        ) {
            NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
        } else {
            const payloadData = {
                "name": addSpecilityData.roomno,
                "status": addSpecilityData.status
            }
            // const formData = new FormData();

            // formData.append("roomNumber", addSpecilityData.roomno);


            // formData.append("status", addSpecilityData.status);




            try {

                await Api.post("admit/status/add", payloadData).subscribe((response) => {
                    if (response) {
                        if (response.data.status) {
                            setOpen(false);
                            setLogoStatus(false);
                            setIsLoading(false);
                            setAddModalOpen(false)
                            NotificationManager.success(response.data.message);
                        } else {
                            setOpen(false);
                            setIsLoading(false);

                            NotificationManager.error(response.data.message);
                        }
                    }

                }

                )


            } catch (error) {
                console.log(error);
            }
        }
    };

    const onUpdateSave = async () => {
        console.log("test_working",id)
        setIsLoading(true);
       
        if (
            getUpdateData.roomNo === "" ||
            getUpdateData.Status === ""

        ) {
            NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
            setIsLoading(false);
        } else {
            const formData = new FormData();
            // formData.append("pic", updatePicture);
            formData.append("roomNumber", getUpdateData.roomNo);


            formData.append("status", getUpdateData.Status);
            formData.append("id", id);

            try {

                await Api.put(`admit/status/edit/${id}`, formData).subscribe((response) => {
                    if (response) {
                        if (response.data.status) {
                            setOpen(false);
                            setLogoStatus(false);
                            setIsLoading(false);
                            NotificationManager.success(response.data.message);
                        } else {
                            setOpen(false);
                            setIsLoading(false);
                            NotificationManager.error(response.data.message);
                        }
                    }
                })

            } catch (error) {
                setOpen(false);
                setIsLoading(false);
                console.log(error);
            }
        }
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("test_admitstatus1");
        Api.get('admit/status').subscribe((resp) => {
            if (resp) {
                console.log("test_admitstatus",resp);
                setResp(resp.data?.data);
                setLoading(true);
            }

        })
    }, [isLoading]);

    return (
        <div>
            <Header title="IPD Status" />
            {resp === null ? (
                <Loader />
            ) : (
                <Form
                    apiRef={formApi}
                    // onSubmit={handleSubmit}
                    initialValues={{
                        availSummary: initialValues,
                    }}
                >
                    <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
                        <Box className={classes.prescriptionSection}>
                            <Paper
                                className={clsx(classes.container, globalClass.roundWhiteBg)}
                            >
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        onClick={handleAddModalOpen}
                                    >
                                        ADD
                                    </Button>
                                </Box>
                                <TableContainer>
                                    <Table
                                        aria-label="simple table"
                                        id="summaryRow"
                                        className={classes.table}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Title</strong>
                                                </TableCell>

                                                <TableCell>
                                                    <strong>status</strong>
                                                </TableCell>


                                                <TableCell>
                                                    <strong></strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {resp?.map((item, i) => {
                                                return (
                                                    <Scope scope={`availSummary[${i}]`} key={i}>
                                                        <TableRow key={i}>
                                                            <TableCell >
                                                                <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.name}</Typography>
                                                            </TableCell>


                                                            <TableCell>
                                                                <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.status}</Typography>
                                                            </TableCell>



                                                            <TableCell>
                                                               {
                                                                item.edit==true?(
                                                                    <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    className={classes.button}
                                                                    startIcon={<EditIcon />}
                                                                    onClick={() =>
                                                                        handleOpen(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    Update
                                                                </Button>
                                                                ):("")
                                                               }
                                                                &nbsp;

                                                            </TableCell>
                                                        </TableRow>
                                                    </Scope>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>


                    </div>
                </Form>
            )}

            {AddModalOpen && (
                <Modal
                    open={AddModalOpen}
                    onClose={handleAddModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box
                            mt="1rem"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} className={classes.prescriptionTitle}>
                                Add IPD Status              </Typography>

                            <CloseIcon onClick={handleAddModalClose} style={{cursor: "pointer"}}/>
                        </Box>

                        <Box className={classes.prescriptionmodal}>
                            <Box
                                mt="0,5rem"


                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                                    Title
                                </Typography>
                            </Box>

                            <Grid>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="outlined-basic"
                                    label=""
                                    variant="outlined"
                                    name="roomno"
                                    type="text"
                                    field="roomno"
                                    placeholder="Title"
                                    className={classes.style_2}
                                    onChange={onChangeData}
                                    value={addSpecilityData.roomno}
                                />
                            </Grid>
                        </Box>




                        <Box className={classes.prescriptionmodal}>
                            {/* type  */}




                            <Box
                                mt="0.5rem"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                                    Status*
                                </Typography>
                            </Box>

                            <Grid>
                                <Select
                                    style={{ width: "100%" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    name="status"
                                    // value={addSpecilityData.disclaimer}
                                    value={addSpecilityData.status}
                                    onChange={onChangeData}
                                >
                                    {socialMediaArr.map((value) => (
                                        <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Box>
                        {
                            <Box mb={3} style={{ marginTop: "2%" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={globalClass.largeBtnContainer}
                                    onClick={() => handleSaveData("save")}
                                >
                                    {isLoading.logoisLoading ? (
                                        <Loader width="200px" height="20px" color="white" />
                                    ) : (
                                        "SAVE"
                                    )}
                                </Button>
                            </Box>
                        }
                    </Box>
                </Modal>
            )}

            {open && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>

                        <Box
                            mt="1rem"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography id="modal-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                                Update IPD Status                                       </Typography>
                            <CloseIcon onClick={handleClose} style={{cursor: "pointer"}}/>
                        </Box>

                        <Box className={classes.prescriptionmodal}>
                            <Box
                                mt="0.5rem"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h4" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                                    Title
                                </Typography>
                            </Box>

                            <Grid>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="outlined-basic"
                                    // InputProps={{
                                    //     readOnly: true,
                                    // }}
                                    label=""
                                    variant="outlined"
                                    name="roomNo"
                                    field="roomNo"
                                    placeholder="roomNo"
                                    value={getUpdateData && getUpdateData?.roomNo}
                                    className={classes.style_2}
                                    onChange={handleUpdateChange}
                                // value={getUpdateData.name}
                                />
                            </Grid>
                        </Box>





                        <Box className={classes.prescriptionmodal}>
                            {/* type dropdown */}





                            <Box
                                mt="0.5rem"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                                    Status*
                                </Typography>
                            </Box>

                            <Grid>
                                <Select
                                    style={{ width: "100%" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    name="Status"
                                    value={getUpdateData && getUpdateData?.Status}
                                    onChange={handleUpdateChange}
                                >
                                    {socialMediaArr.map((value) => (
                                        <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Box>

                        {
                            <Box mb={3} style={{ marginTop: "2%" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={globalClass.largeBtnContainer}
                                    onClick={() => onUpdateSave(id)}
                                >
                                    {isLoading.logoisLoading ? (
                                        <Loader width="200px" height="20px" color="white" />
                                    ) : (
                                        "UPDATE"
                                    )}
                                </Button>
                            </Box>
                        }
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default IpdStatus;

