import StatusBar from "./status-bar";
import CellEditor from "./cell-editor";
import CellRenderer from "./cell-renderer";
import AppConstants from "../../utils/app-constants";
import BooleanFilter from "./boolean-filter";
import DateFilter from "./date-filter";

const GRID_OPTIONS = {
  defaultColDef: {
    resizable: true,
    suppressMovable: true,
    // unSortIcon: true
  },
  frameworkComponents: {
    statusBar: StatusBar,
    customEditor: CellEditor,
    customRenderer: CellRenderer,
    booleanFilter: BooleanFilter,
    dateFilter: DateFilter
  },
  suppressContextMenu: true,
  autoHeight: true,
  rowSelection: "multiple",
  rowDeselection: true,
  rowModelType: "serverSide", 
  cacheBlockSize: AppConstants.PAGE_SIZE,
  paginationPageSize: AppConstants.PAGE_SIZE,
  getRowNodeId: data => data.id || data._id
};

export default GRID_OPTIONS;
