import React from 'react';
import { FieldArray, Field } from 'formik';
import { Box, IconButton, TextField } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';
import './DynamicFieldArray.scss';

const DynamicFieldArray = ({ name, values, handleChange, placeholder, maxLength }) => {
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <>
          {values.length === 0 ? (
            <Box display="flex" alignItems="center">
              <IconButton onClick={() => push('')}>
                <AddIcon />
              </IconButton>
            </Box>
          ) : (
            values.map((_, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Field
                  as={TextField}
                  name={`${name}[${index}]`}
                  value={values[index]}
                  onChange={handleChange}
                  variant="outlined"
                  className="text-field"
                  placeholder={placeholder}
                  fullWidth
                  inputProps={{ maxLength }} // Pass the maxLength prop
                />
                <IconButton onClick={() => remove(index)}>
                  <RemoveIcon />
                </IconButton>
                {index === values.length - 1 && (
                  <IconButton onClick={() => push('')}>
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
            ))
          )}
        </>
      )}
    </FieldArray>
  );
};

DynamicFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired, // Add maxLength to prop types
};

export default DynamicFieldArray;
