import React, { Component } from "react";

class BooleanFilter extends Component {
	state = {
		value: "all"
	};

	setModel = params => {
		if (!params) {
			this.setState({ value: "all" }, () => {
				this.props.filterChangedCallback();
			});
		}
	};

	getModel = () => {
		const { value } = this.state;
		if (value === "all") {
			return null;
		}
		return { filterType: "boolean", value: value === "true" };
	};

	change = evt => {
		this.setState({ value: evt.target.value }, () =>
			this.props.filterChangedCallback()
		);
	};

	isFilterActive() {
		const { value } = this.state;
		return value !== "all";
	}

	render() {
		const { value } = this.state;
		return (
			<select value={value} onChange={this.change}>
				<option value={"all"}>ALL</option>
				<option value={"true"}>TRUE</option>
				<option value={"false"}>FALSE</option>
			</select>
		);
	}
}

export default BooleanFilter;
