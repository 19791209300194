import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardActionArea,
  Avatar,
  makeStyles,
  Button,
  Hidden,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import useSocket from "./socket";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import SearchIcon from "@material-ui/icons/Search";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import { NotificationManager } from "react-notifications";
import Axios from "axios";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";



// const useStyles = makeStyles((theme) => ({
//   ellipsis: {
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//   },
//   duration: {
//     color: theme.palette.primary.main,
//   },
//   searchIcon: {

//   },
// }));

import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
    marginTop: "20px"
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  module: {
    width: "260px",
    height: "170px",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  duration: {
    color: theme.palette.primary.main,
  },
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#eeeeee",
    marginLeft: 0,
    lineHeight: '3',
    marginRight: '6px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
      '&:focus': {
        width: '22ch',
      },
    },
  },
}));

const ChatHeader = ({
  image,
  name,
  text,
  date,
  time,
  duration,
  itemStyle,
  timeStyle,
  onBackIconClicked,
  patientSlug,
  docSlug,
  setSearchChat,
  searchChat,
  handleOpensChat,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [apigetStatus, setApigetStatus] = useState(null);
  
  const socket = useSocket({});

  const [apiStstus, setApiStatus] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);



  const [state, setState] = React.useState(null);

  const handleChange = () => {
    setOpens(!opens)
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("doctorSlug", docSlug);
    formData.append("patientSlug", patientSlug);
    if (apigetStatus === false) {
      formData.append("type", "block");
    }
    if (apigetStatus === true) {
      formData.append("type", "unblock");
    }

    const response = await Axios({
      method: "post",
      url: `${process.env.REACT_APP_API2_HOST}block_user`,
      data: formData,
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      if (response.data.message.toString() === "Blocked Successfully!") {
        setOpens(false)
        setApiStatus(!apiStstus)
        NotificationManager.success(response.data.message);
       
      }

      if (response.data.message.toString() === "Un-blocked Successfully!") {
        setOpens(false)
        setApiStatus(!apiStstus)
        NotificationManager.success(response.data.message);
        
      } 
    }
    else {
    }
    

  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}is_blocked?doctorSlug=${docSlug}&patientSlug=${patientSlug}`).then((resp) => {
      setState(resp.data.data)
      setApigetStatus(resp.data.data)
    })
  }, [docSlug, patientSlug, apiStstus, open ]);

  const handleSearch = (e) => {
    setSearchChat(e.target.value)
  }

  return (
    <CardActionArea className={itemStyle}>
      <Box m={2} display="flex" alignItems="center">
        {onBackIconClicked && (
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onBackIconClicked}>
              <BackIcon />
            </IconButton>
          </Hidden>
        )}
        <Avatar alt="avatar-image" src={image}>
          {name?.charAt(0) ?? ""}
        </Avatar>

        <Box flexGrow="1" mx={1}>
          <Typography className={classes.ellipsis} variant="subtitle1" style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "2%" }}>
            {name}
            {apigetStatus ?
              <Tooltip title="Block"><BlockIcon style={{ color: "red" }} aria-label="Block"/></Tooltip> : null}
          </Typography>
          <Typography className={classes.ellipsis} variant="subtitle2">
            {text}
          </Typography>
        </Box>
        <Box textAlign="right">
          <Typography variant="body2">Remaining</Typography>
          <Typography variant="body2">{`${time}`}</Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}

          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}


          >



            <MenuItem>
              <Box>
                <Typography >
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      onChange={handleSearch}
                      value={searchChat}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />

                  </div>
                  <Button onClick={handleOpensChat} >
                    Submit
                  </Button>
                </Typography>
              </Box>
            </MenuItem>

            <MenuItem>
              <Box>
                <Typography >Blocked</Typography>
                <Box onClick={handleChange}>
                  <Switch
                    checked={apigetStatus}
                    defaultChecked={apigetStatus}
                    name="type"
                    label="Secondary"
                  />
                  {
                    opens &&
                    <Modal
                      open={opens}
                      onClose={handleCloses}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className={classes.modalStyle}>
                        <Typography variant="h5">
                          Are You Sure Want to Blocked
                        </Typography>
                        <Typography>Blocked Patients have no longer send you message</Typography>
                        <Box>
                          <Button onClick={handleCloses}>Cancel</Button>
                          <Button onClick={ handleSubmit}>
                            {apiStstus ? " UNBLOCK" : "BLOCK"}
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                  }


                </Box>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default ChatHeader;
