import {
  Box,
  CircularProgress,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: ".7rem",
    fontWeight: "bold",
  },
  value: {
    fontSize: ".7rem",
  },
  loaderStyle: {
    color: theme.palette.common.primary,
    marginTop: "15px",
  },
  container: {
    width: "200px",
    margin: theme.spacing(5),
  },
}));

const PrintInfoDialog = ({ open = false }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullwidth="true"
      maxWidth={"md"}
      open={open}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          className={classes.container}
        >
          <Typography variant="h6">Please wait</Typography>
          <CircularProgress
            disableShrink
            className={classes.loaderStyle}
            size={22}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PrintInfoDialog;
