import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { useCallback } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";


import Api from "../../utils/api";
import Axios from "axios";
import AppConstants, {
  LOCAL_STORAGE_CONSTANTS,
} from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import { NotificationManager } from "react-notifications";
import EditIcon from "@material-ui/icons/Edit";
import { async } from "rxjs/internal/scheduler/async";
import Loader from "../../components/misc/loader";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: theme.primary,
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: theme.primary,
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding:'1.8%',
    border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  logoContainer: {
    display: "flex",
  },
  addressgrid: {
    display: "flex",
    gap: "5%",
    marginBottom: "5px",
    gridTemplateColumns:
      "repeat(auto-fill, minmax(10rem, 1fr)) 100px repeat(3, 200px)",
    justifyContent: "spa",
  },
  select: {
    width: "100%",
    border: "1px solid #b4b0b0",
    padding: "13px",
    marginTop: "8px",
    minWidth: "80px",
    borderRadius: "10px",
  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: "15px !important",
    border: "1px solid #a4a0a0",
    marginTop: "1%"
  }
}));

const SocialMediaTab = (props) => {
  const [respo, setRespo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}/getwebsite`).then((resp) => {
      if(resp){
        setRespo(resp.data[0]);
        setLoading(true)
      }
    });
  }, []);
  
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
    ? AuthService.getUser()
    : {};

  const formApi = useRef();
  const [signImage, setSignImage] = useState(null);
  const [socialMediaArr, setSocialMediaArr] = useState([
    {
      name: "FACEBOOK",
      link: "",
      id: 1,
    },
    {
      name: "INSTAGRAM",
      link: "",
      id: 2,
    },
    {
      name: "LINKEDIN",
      link: "",
      id: 3,
    },
    {
      name: "WEBSITE",
      link: "",
      id: 4,
    },
    {
      name: "TWITTER",
      link: "",
      id: 5,
    },
  ]);

  const [data, setData] = useState({
    social_link:""
  });

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleClose = () => setOpen(false);


  const [url, setUrl] = useState("");

  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    setData({
      social_link:respo?.social_links,   
    });
    
    setInitialValues(respo?.social_links)
  }, [initialValues]);
  
  

  const SocialHandleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = [...initialValues];
    if(e.target.name=="name"){
      values[i].link=e.target.value;
    }
    else if(e.target.name=="url"){
      values[i].url=e.target.value;
    }
    setInitialValues(values);
  };

  const [socialDrop, setsocialDrop] = React.useState('');

  const handleChange = (event) => {
    setsocialDrop(event.target.value);
  };

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const addRow = async () => {
    let cin = initialValues.length;
    let ain = socialMediaArr.length;
    if(cin < ain ){

     await setInitialValues((prev) => [
        ...prev,
          {
            link: socialMediaArr[0].name || "",
            url: url,
          },
          
        ]);
    }
  
  };

  const onRemoveRow = (idx) => {
    const values = [...initialValues];
    values.splice(idx, 1);
    for (let i = 0; i < values.length; i++) {}
    setInitialValues(values || []);
    // handleKeyUp();
  };

  const handleSubmit = async (event) => { 

    const req = {
      social_link: initialValues
    };

    if (
      data.social_link == { link: "", url: "" }
    ) {
      SHOW_ERROR_NOTIFICATION("Feilds required");
    } else {
      try {

        setLoadingStatus({
          logoLodingStatus: false,
          faviconLoadingStatus:false,
          formLoadingStatus:true,
        })

        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}websiteupdate`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.toString() == "succss") {
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus:false,
            formLoadingStatus:false,
          })
        } else {
          NotificationManager.success("Something went wrong Please try again");
        }
      } catch (error) {
        setLoadingStatus({
          logoLodingStatus: false,
          faviconLoadingStatus:false,
          formLoadingStatus:false,
        })
      }
    }
  };

    const [lodingStatus , setLoadingStatus] = useState({
      logoLodingStatus: false,
      faviconLoadingStatus: false,
      formLoadingStatus:false,
    })


  return (
    <div>
      <Header title="Social Media Links" />
      <Form
        apiRef={formApi}
        onSubmit={handleSubmit}
        // onChange={handleChangeSettingDetails}
        // onKeyUp={handleKeyUp}
        initialValues={{
          availSummary: initialValues,
          paymentOptions: [
            {
              payTo: "",
              qrCode: "",
              description: "",
            },
          ],
        }}
      >
        <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Social Links
              </Typography>
            </Box>
            <Paper
              className={clsx(classes.container, globalClass.roundWhiteBg)}
            >
              <Box className={classes.prescriptionSection}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={handleAddModalOpen}
                >
                  ADD
                </Button>
              </Box>

              <TableContainer>
                <Table
                  aria-label="simple table"
                  id="summaryRow"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Social Plateform</strong>
                      </TableCell>
                      <TableCell>
                        <strong>link</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>


                    {initialValues?.map((item, i) => {

                      if(initialValues != "undefined"){
                      }

                      return (
                        <Scope scope={`availSummary[${i}]`} key={i}>
                          <TableRow key={i}>
                            <TableCell>
                              <Box display="flex" flexDirection="column">
                               
                                <Select
                                  labelId="demo-simple-select-label"
                                  id= {`demo-simple-select-${i}` }
                                  name="name"
                                  value={item.link}
                                  onChange={(e) => SocialHandleChange(e,i)}
                                >
                                  {
                                    socialMediaArr.map((value)=>{
                                      let selected = "false"
                                      if(item?.link == value.name){
                                        selected = "true";
                                      }
                                      return(
                                        <MenuItem  value={value.name} selected={selected}>{value.name} </MenuItem>
                                      )
                                      })
                                  }
                                </Select>
                                
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box display="flex" flexDirection="column">
                                <TextInput
                                  field="Name"
                                  placeholder="URL"
                                  containerClassName="Name"
                                  label="Name"
                                  type="text"
                                  value={item.url}
                                  validateOnBlur
                                  validateOnChange
                                  name="url"
                                  onChange={(e) => SocialHandleChange(e, i)}
                                  className={classes.style_1}
                                />
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => onRemoveRow(i)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Scope>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <Box mb={3} style={{ marginTop: "2%" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
            >
               {lodingStatus.formLoadingStatus ?   <Loader width="20px" height="20px"  color="primary"/> : "SAVE"} 
            </Button>
          </Box>
        </div>
      </Form>

      {AddModalOpen && (
        <Modal
          open={AddModalOpen}
          onClose={handleAddModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Social Links
              </Typography>

              <CloseIcon onClick={handleAddModalClose} style={{cursor: "pointer"}}/>
            </Box>

            <Box className={classes.prescriptionmodal}>
              <Box
                mt="0,5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle} style={{marginTop: "2%"}}>
                  Links
                </Typography>
              </Box>

              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="title"
                  field="title"
                  placeholder="links"
                  className={classes.style_2}
                  onChange={onChangeData}
                />
              </Grid>

              <Typography variant="h6" className={classes.prescriptionTitle} style={{ marginTop: '2%'}}>
                  Social Platform
              </Typography>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={socialDrop}
                onChange={handleChange}
                label="Mail Encryption"
                className={classes.dropdown}
              >
                <MenuItem value={10}>FACEBOOK</MenuItem>
                <MenuItem value={20}>INSTAGRAM</MenuItem>
                <MenuItem value={30}>LINKEDIN</MenuItem>
                <MenuItem value={40}>WEBSITE</MenuItem>
                <MenuItem value={50}>TWITTER</MenuItem>

              </Select>

            </Box>

            {
              <Box mb={3} style={{ marginTop: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                >
                  {lodingStatus.logoLodingStatus ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              </Box>
            }
          </Box>
        </Modal>
      )}

    </div>
  );
  
};

export default SocialMediaTab;
