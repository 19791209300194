import React from "react";
import { CardActionArea, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  tab: {
    width: "20%",
    maxWidth: "200px",
    textAlign: "center",
    borderRadius: theme.spacing(6, 6, 0, 0),
    boxShadow: "5px #00000008 0px 0px",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(7.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(10),
    flexWrap: "wrap",
  },
  active: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const Tab = ({ index, title, onTabClicked, currentIndex }) => {
  const classes = useStyles();
  return (
    <CardActionArea
      onClick={() => onTabClicked(index)}
      className={clsx(classes.tab, index === currentIndex && classes.active)}
    >
      <Typography variant="h6">{title}</Typography>
    </CardActionArea>
  );
};

export default Tab;
