import React, { useState } from "react";
import GenericGrid from "../../components/grid/generic-grid";
import DoctorColumn from "./doctor-column";
import useGlobalStyles from "../../utils/global-style";
import Api from "../../utils/api";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Box,
  TablePagination,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import { format } from "date-fns";
import { CircularProgress, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  searchBar: {
    // width: '22%',
    // padding: "8px 6px",
    // borderRadius: 12,
    // marginTop: 12,
    marginBottom: 12,
    border: "1px solid gray",
    outline: "none",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "20px",
    marginTop: "20px",
  },
}));

const Doctor = () => {
  const classes = useStyles();
  const [searchlogs, setSearchlogs] = useState(null);

  const [counts, setCounts] = useState(0);
  const [countsf, setCountsf] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [pagef, setPagef] = React.useState(0);
  const [rowsPerPagef, setRowsPerPagef] = React.useState(25);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangePagef = (newPage) => {
    setPagef(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCounts((prev) => prev + 0);
    setPage(0);
  };

  const handleChangeRowsPerPagef = (event) => {
    setRowsPerPagef(+event.target.value);
    setCountsf((prev) => prev + 0);
    setPagef(0);
  };

  const globalClasses = useGlobalStyles();
  const [open, setOpen] = React.useState(false);
  const [openf, setOpenf] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenf = () => setOpenf(true);
  const handleClose = () => setOpen(false);
  const handleClosef = () => setOpenf(false);
  const [allData, setAllData] = React.useState(null);
  const [allDataf, setAllDataf] = React.useState(null);

  const formatTime = (timeString) => {
    const dat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timeString);
    const vad = format(new Date(dat), "dd-MM-yyyy");
    if (!timeString) {
      return "N/A";
    }
    // return timeString.lastIndexOf(':') === 5
    //   ? timeString.substring(0, 5)
    //   : timeString;

    return vad;
  };

  const onLogButtonClicked = (val) => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_all_logs?slug=${val.slug}&page=${page}&size=${rowsPerPage}`
    ).then((resp) => {
      if (resp) {
        setAllData(resp?.data.data);
        setCounts(resp?.data.data.length);
      }
    });
    handleOpen();
  };
  const onfeedbackButtonClicked = (val) => {
    Api.post("admit/feedback/search", {
      page: pagef,
      size: rowsPerPagef,

      filterModel: {
        staffSlug: {
          type: "equals",
          value: val.slug,
        },
      },

      sortModel: [],
    }).subscribe((resp) => {
      let slugdatas = resp.data.data.content;

      setAllDataf(slugdatas);
      setCountsf(slugdatas.length);
    });
    handleOpenf();
  };

  const handleSearch = (e) => {
    setSearchlogs(e.target.value);
  };
  //?.filter((row) =>
  // !searchlogs?.length || row?.message?.toString()
  //?.toLowerCase()
  //?.includes(searchlogs?.toString()?.toLowerCase())
  //)
  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        baseUrl="doctor"
        hideOnCopy={true}
        onLogButtonClicked={onLogButtonClicked}
        onfeedbackButtonClicked={onfeedbackButtonClicked}
        csvName="doctor"
        hideExport={false}
        addText="Create New Staff"
        hideOnUpload={true}
        LogButton={true}
        columnDefGenerator={DoctorColumn}
        title="Staff"
      />
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ Height: "71vh" }}
        >
          <Box className={classes.modalStyle}>
            <CloseIcon onClick={handleClose} style={{ float: "right", cursor: "pointer"}} />
            <Typography variant="h5" className={classes.prescriptionTitle}>
              Staff Logs
            </Typography>

            <Box
              style={{
                border: "1px solid gray ",
                position: "relative",
                overflow: "scroll",
                height: "77vh",
              }}
            >
              {allData ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead className={classes.striky}>
                      <TableRow className={classes.striky}>
                        <TableCell component="th" className={classes.textStyle}>
                          DateTime
                        </TableCell>
                        <TableCell component="th" className={classes.textStyle}>
                          Message
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Typography align="center">
                                No data available
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ) : (
                        allData
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((item) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography>
                                      {formatTime(item.dateTime)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography> {item.message}</Typography>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                      )}
                    </TableBody>

                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      count={allData?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </Box>
          </Box>
        </Modal>
      }

      {
        <Modal
          open={openf}
          onClose={handleClosef}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ witdh: "80%", Height: "71vh" }}
        >
          <Box className={classes.modalStyle}>
            <CloseIcon onClick={handleClosef} style={{ float: "right", cursor: "pointer" }} />
            <Typography variant="h5" className={classes.prescriptionTitle}>
              Staff Feedbacks
            </Typography>

            <Box
              style={{
                border: "1px solid gray ",
                position: "relative",
                overflow: "scroll",
                height: "77vh",
              }}
            >
              {allDataf ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead className={classes.striky}>
                      <TableRow className={classes.striky}>
                        <TableCell component="th" className={classes.textStyle}>
                          DateTime
                        </TableCell>
                        <TableCell component="th" className={classes.textStyle}>
                          Patient
                        </TableCell>
                        <TableCell component="th" className={classes.textStyle}>
                          Rating
                        </TableCell>
                        <TableCell component="th" className={classes.textStyle}>
                          Message
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allDataf?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Typography align="center">
                                No data available
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ) : (
                        allDataf
                          ?.slice(
                            pagef * rowsPerPagef,
                            pagef * rowsPerPagef + rowsPerPagef
                          )
                          ?.map((item) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography>
                                      {formatTime(item?.createdAt)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography>
                                      {item?.patient?.name} <br />
                                      {item?.patient?.uhid}{" "}
                                      {item?.patient?.mobileNumber}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography> {item?.rating} / 5</Typography>
                                  </TableCell>
                                  <TableCell
                                    className={classes.cellTextStyle}
                                    style={{ padding: "2%" }}
                                  >
                                    <Typography> {item?.remark}</Typography>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                      )}
                    </TableBody>

                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      count={allDataf?.length}
                      rowsPerPage={rowsPerPagef}
                      page={page}
                      onChangePage={handleChangePagef}
                      onChangeRowsPerPage={handleChangeRowsPerPagef}
                    />
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </Box>
          </Box>
        </Modal>
      }
    </div>
  );
};

export default Doctor;
