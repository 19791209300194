import React from 'react'
import QRCode from "qrcode.react";
import { useLocation } from 'react-router-dom';

const QRstring = () => {

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const string = searchParams.get("checkin");



  return (
    <div style={{ height: "auto", margin: " auto", width: "100%", padding:"20px", position:"absolute" , left:"0px" ,top:"0px"}}>
<center>
      <QRCode
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={string}
        size={800}
        includeMargin={true}
      />
      <div class="mt-2">
        <button
          class="btn btn-primary"
          onClick={window.print}
          id="printPageButton"
        >
          Print
        </button>

      </div>
      </center>
    </div>
  )
}

export default QRstring