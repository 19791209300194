import {
  Box,
  Button, makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { TextInput } from "sixsprints-react-ui";
import Loader from "../../components/misc/loader";
import { NotificationManager } from "react-notifications";

// import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
import clsx from "clsx";
import { Scope } from "informed";
import { default as React, useEffect, useState } from "react";
// import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import Axios from "axios";
import AppConstants from "../../utils/app-constants";
import {
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
}));


const CustomTab = (props) => {

  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const user = JSON.parse(localStorage.getItem("USER"))
  const [addLoadingStatus, setAddLoadingStatus] = useState(false)


  const [data, setData] = useState({
    menuName: '',
    link: '',
  });

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getprofile?id=${user.id}`).then((resp) => {
      if (resp) {
        if (resp.data.data.custommenu) {
          setInitialValues(resp.data.data.custommenu)
        }
        else {
          setInitialValues([])
        }
      }
    });
  }, [addLoadingStatus]);

  const SocialHandleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = [...initialValues];
    if (e.target.name === "name") {
      values[i].link = e.target.value;
    }
    else if (e.target.name === "url") {
      values[i].url = e.target.value;
    }
    setInitialValues(values);
  };

  const addRow = async () => {

    await setInitialValues((prev) => [
      ...prev,
      {
        link: "",
        url: "",
      },

    ]);
  }

  const onRemoveRow = (idx) => {
    const values = [...initialValues];
    values.splice(idx, 1);
    for (let i = 0; i < values.length; i++) { }
    setInitialValues(values || []);
  };

  const handleSave = async (event) => {

    setAddLoadingStatus(true)
    event.preventDefault();
    const req = {
      custommenu: initialValues,
      id: user.id,
      role: user.roleName
    };


    if ( initialValues.map((item)=> item.link )  === "") {
      
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      setAddLoadingStatus(false)
      return;
    }
     else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}updatecustomemnu`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.message.toString() === "Updated Successfully!") {
          NotificationManager.success(response.data.message);
          setAddLoadingStatus(false)
        } else {
          NotificationManager.error(response.data.message);
          setAddLoadingStatus(false)
        }
      }
      catch (error) {
        console.log(error);
      }
    }
  };


  return (
    <div>
      <Header title="Custom Menu" />
      <Box className={classes.prescriptionSection}>
        <Box
          mt="2rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.prescriptionTitle}>
            Custom Menu
          </Typography>
        </Box>
        <Paper
          className={clsx(classes.container, globalClass.roundWhiteBg)}
        >
          <Box className={classes.prescriptionSection}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={addRow}
            >
              ADD
            </Button>
          </Box>

          <TableContainer>
            <Table
              aria-label="simple table"
              id="summaryRow"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>MENU NAME</strong>
                  </TableCell>
                  <TableCell>
                    <strong>LINK</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialValues?.map((item, i) => {
                  return (
                    <Scope scope={`availSummary[${i}]`} key={i}>
                      <TableRow key={i}>
                        <TableCell>
                          <Box display="flex" flexDirection="column">
                            <TextInput
                              field="Name"
                              placeholder="Menu Name"
                              containerClassName="Name"
                              label="Menu Name"
                              type="text"
                              value={item.link}
                              validateOnBlur
                              validateOnChange
                              name="name"
                              onChange={(e) => SocialHandleChange(e, i)}
                              className={classes.style_1}
                            />
                          </Box>

                        </TableCell>

                        <TableCell>
                          <Box display="flex" flexDirection="column">
                            <TextInput
                              field="link"
                              placeholder="URL"
                              containerClassName="link"
                              label="link"
                              type="text"
                              value={item.url}
                              validateOnBlur
                              validateOnChange
                              name="url"
                              onChange={(e) => SocialHandleChange(e, i)}
                              className={classes.style_1}
                            />
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                            onClick={() => onRemoveRow(i)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    </Scope>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box mb={3} style={{ marginTop: "2%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
            onClick={handleSave}
          >
            {addLoadingStatus ? <Loader width="200px" height="20px" color="white" /> : "SAVE"}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CustomTab;
