import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  makeStyles,
  Typography,
} from "@material-ui/core";

import AppConstants from "../../utils/app-constants";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { AppointmentsStatusState, AppointmentsState } from "../../states";
import { format } from "date-fns";
import Header from "../../components/layout/header";
import Axios from "axios";
import { Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  queue: {
    width: "40px",
    textAlign: "center",
  },
  container: {
    margin: "20px auto",
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "10%",
  },

  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "4%",
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: "0.7rem",
  },
  calledCellStyle: {
    backgroundColor: "#faebd7 ",
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
}));

const OtpList = ({
  pageSize,
  rows,
  count,
  className,

  onDateTimeClicked,
  onViewPrescriptionClicked,
  onAddDiscountClicked,
  onPrintReceiptClicked,
  onViewEMRClicked,
  onPrintLetterHeadClicked,
  handleOpenPrescription,
  onNotifyClicked,
  summary,
}) => {
  const moreItems = [
    {
      label: "View EMR",
      onClick: onViewEMRClicked,
      isVisible: isCurrentRole(AppConstants.ROLE.DOCTOR),
    },
    {
      label: "View Prescription",
      onClick: onViewPrescriptionClicked,
      isVisible: true,
    },
    { label: "Add Discount", onClick: onAddDiscountClicked, isVisible: true },
    { label: "Print Receipt", onClick: onPrintReceiptClicked, isVisible: true },
    {
      label: "Print LetterHead Only",
      onClick: onPrintLetterHeadClicked,
      isVisible: true,
    },
    { label: "Notify", onClick: onNotifyClicked, isVisible: true },
  ];
  const [status, setStatus] = useState({});
  const appointmentStatus = useRecoilValue(AppointmentsStatusState);
  const user = AuthService.getUser();
  const appointmentList = useRecoilValue(AppointmentsState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(null);
  const [allData, setAllDoctors] = useState(null);

  const onDateSelected = (val) => {
    const dat = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(val);
    const vad = format(new Date(dat), "yyyy-mm-dd");
    setSelectedDate(vad);
  };
  useEffect(() => {
    setSelectedDate(format(new Date(), "yyyy-mm-dd"));
    Axios.get(
      `${process.env.REACT_APP_API_HOST}otp/get_my_patient_otp?doctorSlug=${user.slug}`
    ).then((resp) => {
      if (resp) {
        setAllDoctors(resp?.data.data);
      }
    });
  }, [selectedDate, rowsPerPage]);

  return (
    <>
      <Header title="Otp" />
      <TableContainer
        component={Paper}
        className={className ? className : classes.container}
      >
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" className={classes.textStyle}>
                Mobile Number
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Otp
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Date Modified
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Typography align="center">No data available</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ) : (
              allData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography>{item.authId}</Typography>
                        </TableCell>

                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography> {item.otp}</Typography>
                        </TableCell>

                        <TableCell
                          className={classes.cellTextStyle}
                          style={{ padding: "2%" }}
                        >
                          <Typography>
                            {" "}
                            {(item.dateModified = selectedDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
            )}
          </TableBody>

          <TablePagination
            rowsPerPageOptions={[1, 10, 25, 50, 100]}
            count={allData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
      {allData?.item}
    </>
  );
};

export default OtpList;
