import React, { useState, useEffect, createContext } from "react";
import "kg-grid";
import AllRoutes from "./routes/all-routes";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./App.scss";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { SidebarContext } from "./context/sidebar";
import Api2 from "./utils/api2";
import AuthService from "./utils/services/auth-service";
import Loader from "./components/misc/loader";
import { AppointmentContext } from "./context/appointment";
import { ErrorBoundary } from 'react-error-boundary'
import { FallBackComponent } from "./components/misc/FallBackComponent";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const initTheme = (theme) => {
  theme["bg"] = "#F6F7FC";
  theme["chatBg"] = "#E6E6E6";
  theme["textSecondary"] = "#FFFFFF";
  return createMuiTheme({
    spacing: 2,
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 10,
          textTransform: "capitalize",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 10,
        },
      },
      MuiButtonGroup: {
        root: {
          borderRadius: 10,
        },
      },
    },
    palette: theme,
    typography: {
      fontFamily: "Comfortaa",
      fontSize: 9,
    },
  });
};

const UserContext = createContext();

const App = () => {
  const [tenant, setTenant] = useState(null);

  const [ctheme, setCtheme] = useState({});

  useEffect(() => {
    Api2.get("tenant").subscribe(
      (resp) => {
        document.title = resp?.data?.data?.name;
        const favicon = document.getElementById("favicon");
        favicon.href = resp?.data?.data?.logo;
        setTenant(resp?.data?.data);
        AuthService.setTenant(resp?.data?.data);
        const type = "primary";
        var back1 = {};
        var back2 = {};
        var button1 = {};

        if (resp?.data?.data?.backend_current_theme === type) {
          back1 = {
            color: resp?.data?.data?.backend_theme_light_2,
            backgroundColor: resp?.data?.data?.backend_theme_light_1,
          };
          back2 = {
            color: resp?.data?.data?.backend_theme_light_4,
            backgroundColor: resp?.data?.data?.backend_theme_light_3,
          };
          button1 = {
            color: resp?.data?.data?.backend_theme_light_6,
            backgroundColor: resp?.data?.data?.backend_theme_light_5,
            border: "none",
          };
        } else {
          back1 = {
            color: resp?.data?.data?.backend_theme_dark_2,
            backgroundColor: resp?.data?.data?.backend_theme_dark_1,
          };
          back2 = {
            color: resp?.data?.data?.backend_theme_dark_4,
            backgroundColor: resp?.data?.data?.backend_theme_dark_3,
          };
          button1 = {
            color: resp?.data?.data?.backend_theme_dark_6,
            backgroundColor: resp?.data?.data?.backend_theme_dark_5,
            border: "none",
          };
        }

        var obj = {
          secondary: {
            light: back2?.color,
            dark: back2?.backgroundColor,
            contrastText: back1?.color,
            main: back1?.backgroundColor,

            HighlightButtonText: button1?.backgroundColor,
            HighlightButtonBackground: button1?.color,
          },
          primary: {
            light: back2?.color,
            dark: back2?.backgroundColor,
            contrastText: back1?.color,
            main: back1?.backgroundColor,

            HighlightButtonText: button1?.backgroundColor,
            HighlightButtonBackground: button1?.color,
          },
        };
        setCtheme(obj);
      },
      (error) => {}
    );
  }, []);

  return (
    <>
      <QueryClientProvider client={new QueryClient()}>
        <ErrorBoundary FallbackComponent={FallBackComponent}>
          {tenant ? (
            <MuiThemeProvider theme={initTheme(ctheme)}>
              <SidebarContext.Provider>
                <AppointmentContext.Provider>
                  <AllRoutes />
                </AppointmentContext.Provider>
              </SidebarContext.Provider>
            </MuiThemeProvider>
          ) : (
            <Loader />
          )}
        </ErrorBoundary>
      </QueryClientProvider>
    </>
  );
};
export default App;
