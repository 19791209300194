import React, { useState, useCallback } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Switch,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { SearchInput, ButtonGroup } from "sixsprints-react-ui";
import { SidebarContext } from "../../context/sidebar";
import { useHistory } from "react-router-dom";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AppConstants from "../../utils/app-constants";
import ThemedButton from "../layout/styled-btn";
import FileUpload from "../form/fileupload";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.common.white,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(6, 6, 0, 0),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  roundButton: {
    borderRadius: 25,
  },
  title: {
    display: "none",
    padding: "10px 9px 0",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    fontWeight: "bold",
    opacity: "0.9",
  },
  input: {
    display: "none",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  deleteBtn: {
    color: "#fff",
    backgroundColor: "rgb(220, 0, 78)",

    "&:hover": {
      backgroundColor: "rgb(154, 0, 54)",
    },
  },
}));

const GridAction = ({
  title,
  addText = "Add",
  hideAddNew,
  onToggleEditMode,
  onReset,
  onDelete,
  onExport,
  onCopy,
  baseUrl,
  onUpload,
  onGlobalSearch,
  onGroupBtnClick,
  isListView,
  bulkOperationOnRows = [],
  customActions = [],
  log,
  LogButton

}) => {
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const sidebarContext = SidebarContext.useContainer();
  const handleAddNew = () => {
    history.push(`${baseUrl}/new`);
  };

  const toggleChecked = () => {
    setChecked(!checked);
    onToggleEditMode(!checked);
  };

  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const handleUpload = useCallback((data) => {
    if (onUpload) {
      onUpload(data);
    }
  }, [onUpload]);

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appbar} color="default" position="static">
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={sidebarContext.handleSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          {onGlobalSearch && (
            <SearchInput
              field="srch"
              containerClassName={globalClass.search}
              className={globalClass.inputInput}
              onSearchSubmit={onGlobalSearch}  
            />
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Grid container justify="center" spacing={5}>
              {onToggleEditMode && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={toggleChecked}
                        color="primary"
                      />
                    }
                    label="Edit"
                  />
                </Grid>
              )}

              {customActions.map((act, idx) => {
                return (
                  <Grid item key={idx}>
                    <ThemedButton
                      onClick={act.action}
                      color={act.danger ? "secondary" : "primary"}
                      text={act.label}
                    />
                  </Grid>
                );
              })}

              {bulkOperationOnRows.map((act, idx) => {
                return (
                  <Grid item key={idx}>
                    <ThemedButton
                      onClick={act.action}
                      color={act.danger ? "secondary" : "primary"}
                      text={act.label}
                    />
                  </Grid>
                );
              })}

              {onCopy && (
                <Grid item>
                  <ThemedButton
                    onClick={onCopy}
                    color="primary"
                    text={"Copy"}
                  />
                </Grid>
              )}

              {/* {onReset && (
                <Grid item>
                  <ThemedButton
                    onClick={onReset}
                    color="primary"
                    text={"  Reset Filters"}
                  />
                </Grid>
              )} */}

              {onExport &&
                (isCurrentRole(AppConstants.ROLE.ADMIN) ||
                  isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                  isCurrentRole(AppConstants.ROLE.DOCTORADMIN)) && (
                  <Grid item>
                    <ThemedButton
                      onClick={onExport}
                      color="primary"
                      text={"Export"}
                    />
                  </Grid>
                )}

              {onUpload && (
                <Grid item>
                  <FileUpload
                    key={`file-upload-${Date.now()}`}
                    btnText="Import"
                    url={`${baseUrl}/import`}
                    onUpload={handleUpload}
                    
                  />
                </Grid>
              )}

              {!hideAddNew && (
                <Grid item>
                  <ThemedButton
                    onClick={handleAddNew}
                    color="primary"
                    text={addText}
                  />
                </Grid>
              )}

              {onDelete &&
                (isCurrentRole(AppConstants.ROLE.ADMIN) ||
                  isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                  isCurrentRole(AppConstants.ROLE.DOCTORADMIN)) && (
                  <Grid item>
                    <ThemedButton
                      onClick={onDelete}
                      color="primary"
                      text={"Delete"}
                    />
                  </Grid>
                )}
             

            </Grid>
          </div>
          {onGroupBtnClick && (
            <ButtonGroup isList={isListView} handleClick={onGroupBtnClick} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default React.memo(GridAction);
