import {
  Avatar,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import VideoPlayer from "../../components/common/VideoPlayer";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export const WaitingScreenSideInfoPanel = ({
  tenantLogoUrl,
  youtubeVideoUrlList,
  updatesList,
  handleDoctorsListOnClick,
  doctorsList,
  handleOnCloseSettingsMenu,
  settingsMenuOpen,
  anchorEl,
  checkedState,
  handleLogOut,
}) => {
  return (
    <div className="sideinfo-panel">
      <div className="sideinfo-panel__content">
        {/* Tenant avatar/logo */}
        <div className="sideinfo-panel__logo-container">
          <Avatar src={tenantLogoUrl} className="sideinfo-panel__logo" />
        </div>

        {/* Info section */}
        <div className="sideinfo-panel__info-section">
          <WSInfoSection updatesList={updatesList} />
        </div>

        {/* Youtube video card */}
        <div className="sideinfo-panel__video-card">
          <VideoPlayer videos={youtubeVideoUrlList} />
        </div>
      </div>

      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={settingsMenuOpen}
        onClose={handleOnCloseSettingsMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {doctorsList?.map((option, index) => (
          <div
            key={option.slug}
            onClick={() => handleDoctorsListOnClick(option?.slug, index)}
          >
            <MenuItem>
              <input
                type="checkbox"
                id={`custom-checkbox-${index}`}
                value={option?.slug}
                checked={checkedState.includes(option?.slug)}
                style={{ fontFamily: "Comfortaa", marginRight: "4px" }}
              />
              {option.name}
            </MenuItem>
          </div>
        ))}

        <ListItem onClick={handleLogOut} button>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ fontFamily: "Comfortaa", fontSize: "14px" }}
            primary="Log Out"
          />
        </ListItem>
      </Menu>
    </div>
  );
};

const WSInfoSection = ({ updatesList }) => {
  // Local states
  const [currentTime, setCurrentTime] = useState(dayjs());

  // Side effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="info-section">
      {/* Time info */}
      <div className="info-section__time">
        <Typography
          sx={{ fontSize: "3vw", fontFamily: "Comfortaa" }}
          textAlign="center"
        >
          <b>{currentTime.format("HH:mm")}</b>
        </Typography>
        <Typography
          textAlign="center"
          sx={{ fontFamily: "Comfortaa", fontSize: "14px" }}
        >
          {currentTime.format("DD MMM, YYYY")} | {currentTime.format("dddd")}
        </Typography>
      </div>

      {/* Updates info */}
      <div className="info-section__updates-wrapper">
        {updatesList.length > 0 && (
          <div className="info-section__updates">
            <Typography sx={{ fontFamily: "Comfortaa", fontSize: "12px" }} textAlign="left">
              <b>Updates</b>
            </Typography>
            {updatesList.map((update, index) => (
              <Typography
                style={{
                  textAlign: "left",
                  fontFamily: "Comfortaa",
                  fontSize: "9px",
                }}
                key={index}
              >
                {update}
              </Typography>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
