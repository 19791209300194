import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import rootReducer from "./reducer/rootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AppConstants from "../utils/app-constants";

let initialState = {};

const middleware = [thunk];

const store = legacy_createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// maintain switch-profile state when opening some page in a new tab
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state.switchProfile);
    localStorage.setItem(AppConstants.SWITCH_PROFILE_REDUX_STATE, serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
};

// Save state to localStorage whenever it changes
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
