import React from "react";

import { Box, Button } from "@material-ui/core";
import {  TextInput } from "sixsprints-react-ui";
import { makeStyles } from "@material-ui/styles";
import { Form } from "informed";

import useGlobalStyles from "../../utils/global-style";

const useStyles = makeStyles({
  form: {
    display: 'flex',
    paddingLeft: 24,
  },
  staffDetailsForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "24px",
  },
  dropdownFields: {
    display: "flex",
    position: "relative",
  },
  submitBtn: {
    marginLeft: "24px"
  },
  btn: {
    padding: "12px 24px"
  },
  iconButton: {
    position: "absolute",
    right: "5px",
    top: "25%",
  },
  fields: {
    width: "180px",
  },
});

const ReferalDoctorForm = ({ handleSubmit }) => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();

  return (
    <Form onSubmit={handleSubmit} className={classes.form}>
      <Box className={classes.staffDetailsForm}>
        <Box mb='0.5rem'>
          <TextInput
            className={`${globalClass.roundWhiteBg} ${classes.fields}`}
            field='name'
            type='text'
            label='Doctor Name'
            validateOnBlur
            validateOnChange
          />
        </Box>
        <Box mb='0.5rem'>
          <TextInput
            className={`${globalClass.roundWhiteBg} ${classes.fields}`}
            field='email'
            type='text'
            label='Email Id'
            validateOnBlur
            validateOnChange
          />
        </Box>
        <Box mb='0.5rem'>
          <TextInput
            className={`${globalClass.roundWhiteBg} ${classes.fields}`}
            field='mobileNumber'
            type='text'
            label='Mobile Number'
            validateOnBlur
            validateOnChange
          />
        </Box>
      </Box>
      <Box mx='.4rem' my={12} className={classes.submitBtn}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.btn}
        >
          Save
        </Button>
      </Box>
    </Form>
  );
};

export default ReferalDoctorForm;
