import React, { useState, useEffect } from 'react';
import Api from '../../utils/api';
import {
  Button,
  makeStyles, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Loader from '../../components/misc/loader';
import Typography from "@mui/material/Typography";
import Header from '../../components/layout/header';
import { Scope } from "informed";
import moment from 'moment';

import { SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util';
const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
    fontFamily: "Comfortaa",
  },
  nameModal: {
    fontSize: "0.5rem",
    fontWeight: "700",
    opacity: "0.7",
    marginBottom: "10px",

  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  prescriptionmodal: {
    marginBottom: "0rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",

  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  picture: {
    maxWidth: "160px",
    width: "100%",
  },

  MuiTypographybody1: {
    fontsize: "0.6428571428571429rem",
    fontFamily: "Comfortaa",
    fontweight: "400",
    lineheight: "1.5",
  },

}));


const Ipdrequest = () => {
  const classes = useStyles();
  const [isloading, setIsloading] = useState(false)
  const [staffRequestSearchData, setStaffRequestSearchData] = useState([])
  const [refresh, setRefresh] = useState(false);



  const playBeepSound = () => {

    console.log('Beep sound played');
    
  };

  const ipdrequest = async() => {

    setIsloading(true)
    setTimeout(() => {
      setRefresh(!refresh);
    }, 15000);
   await Api.post('admit/staff-request/search', {
      "size": 20,
      "page": 0,
      "filterModel": {
        "status": {
          "type": "equals",
          "value": "Active"
        }
      },
      "sortModel": []
    }).subscribe((resp) => {
      console.log('test_resp_ipd', resp)

      setStaffRequestSearchData(resp.data.data.content)

      setIsloading(false)
    })

  }

  useEffect(() => {
    ipdrequest()

  }, [refresh])


  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 15000);
  }, [refresh])


  //code for beep sound when new object added to array 

  // useEffect(() => {
  //   const previousIds = staffRequestSearchData.map(obj => obj.id);

  //   return () => {
  //     // Clean up the previousIds when the component unmounts
  //     // to avoid memory leaks
  //     previousIds.length = 0;
  //   };
  // }, [staffRequestSearchData]);

  // useEffect(() => {
  //   const previousIds = staffRequestSearchData.map(obj => obj.id);
  //   console.log('test_previousdata', previousIds)
  //   // Check if new object with new id added to the array
  //   const isNewObjectAdded = staffRequestSearchData.some(obj => !previousIds.includes(obj.id));
  //   console.log('test_boolean',isNewObjectAdded)

  //   if (isNewObjectAdded) {
  //     playBeepSound();
  //   }
  // }, [staffRequestSearchData]);






  const onstaffrequestUpdate = (id) => {

    Api.put('admit/staff-request/status',
      {
        "id": id,
        "status": "Completed"
      }).subscribe((resp) => {
        console.log(resp)
        if (resp.data?.status == true) {
          SHOW_SUCCESS_NOTIFICATION("Successfully Update")
          ipdrequest()
        }
        else {
          SHOW_ERROR_NOTIFICATION("Not Updated")
        }

      })
  }






  return (
    <div>
      <Header title="IPD Request" />
      {
        isloading ? (<Loader />) : (
          <TableContainer>
            <Table
              aria-label="simple table"
              id="summaryRow"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Patient Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Uhid/MobNo</strong>
                  </TableCell>

                  <TableCell>
                    <strong>RoomNo</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Calling At</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Type</strong>
                  </TableCell>


                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {staffRequestSearchData.map((item, i) => {
                  return (
                    <Scope scope={`availSummary[${i}]`} key={i}>
                      <TableRow key={i}>
                        <TableCell >
                          <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>
                            {item?.admit?.patient?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>        {item?.admit?.patient?.uhid} / {item?.admit?.patient?.mobileNumber}
                          </Typography>
                        </TableCell>

                        <TableCell >
                          <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>        {item?.admit?.roomNumber}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{moment(item?.createdAt).format('DD-MM-YYYY') + " " + " " + moment(item?.createdAt).format("HH:mm:ss")}</Typography>
                        </TableCell>

                        <TableCell>
                          <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.requestType}</Typography>
                        </TableCell>



                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => onstaffrequestUpdate(item?.id)}
                          // startIcon={<EditIcon />}
                          // onClick={() =>
                          //   handleOpen(
                          //     item
                          //   )
                          // }
                          >
                            Done
                          </Button>
                          &nbsp;

                        </TableCell>
                      </TableRow>
                    </Scope>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    </div>
  )
}

export default Ipdrequest
