import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Api from "../../utils/api";
import moment from "moment";
import {
  TablePagination,
  Typography,
  Box,
  Grid,
  TextField,
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useGlobalStyles from "../../utils/global-style";

const Compare = () => {
  const globalClass = useGlobalStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [receiptDate, setReceiptDate] = useState("");
  const [timeStampforDatepicker, setTimeStampforDatepicker] = useState(null);
  const [receiptDate_1, setReceiptDate_1] = useState("");
  const [timeStampforDatepicker_1, setTimeStampforDatepicker_1] =
    useState(null);

  const [totalInput, setTotalInput] = useState(null);
  const [totalOutput, setTotalOutput] = useState(null);

  useEffect(() => {
    // Api.get(`admit/inputoutput/total/${id}${timeStampforDatepicker ? `?createdAt=${timeStampforDatepicker}` : ''}`).subscribe((resp) => {
    Api.get(
      `admit/inputoutput/total/${id}${
        timeStampforDatepicker && timeStampforDatepicker_1
          ? `?createdAt=${timeStampforDatepicker_1}-${timeStampforDatepicker}`
          : ""
      }`
    ).subscribe((resp) => {
      setTotalInput(resp.data.data?.totalInput);
      setTotalOutput(resp.data.data?.totalOutput);
    });
  }, [timeStampforDatepicker]);

  const [patientStatus, setPatientStatus] = useState();
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState();
  const [plan, setPlan] = useState();
  const [roomNumber, setRoomNumber] = useState();
  const [doctor, setDoctor] = useState();
  const [patientName, setPatientName] = useState();
  const [doctorName, setDoctorName] = useState();
  const [patientUhid, setPatientUhid] = useState();

  useEffect(() => {
    Api.get(`admit/id/${id}`).subscribe((resp) => {
      let admitdata = resp.data.data;
      setDoctorName(admitdata?.doctor?.name);
      setPatientName(admitdata?.patient?.name);
      setPatientUhid(admitdata?.patient?.uhid);
      setRoomNumber(admitdata?.roomNumber);
      setProvisionalDiagnosis(admitdata?.prov);
      setPlan(admitdata?.plan);
      setPatientStatus(admitdata?.status);
      setDoctor(admitdata?.doctor);
    });
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [AdmitHistoryData, setAdmitHistoryData] = useState([]);
  const [content, setContent] = useState({});
  useEffect(() => {
    setIsLoading(true);
    Api.post("admit/history/search", {
      size: rowsPerPage,
      page: page,
      filterModel: {
        admitId: {
          type: "equals",
          // "value": "6478601cb15649193601d60b"
          value: id,
        },
        inputOutput: {
          type: "custom",
          value: "exists",
        },
        // "createdAt": {
        //     "type": "equals",
        //     // "value": 1686913936000

        //     "value": timeStampforDatepicker
        // },
        vitals: {
          type: "custom",
          value: "exists",
        },
        createdAt:
          timeStampforDatepicker_1 && timeStampforDatepicker
            ? {
                type: "custom",
                // "value": "1686900179000-1687277207000"
                value: `${timeStampforDatepicker_1}-${timeStampforDatepicker}`,
              }
            : null,
      },
      sortModel: [],
    }).subscribe((resp) => {
      setContent(resp?.data?.data);
      setAdmitHistoryData(resp?.data?.data?.content);
      setIsLoading(false);
    });
  }, [timeStampforDatepicker, page, rowsPerPage]);

  const onReceiptDateSelected = (dateInput) => {
    setTimeStampforDatepicker(dateInput ? dateInput.valueOf() : null);
    setReceiptDate(dateInput);
  };
  const onReceiptDateSelected_1 = (dateInput) => {
    setTimeStampforDatepicker_1(dateInput ? dateInput.valueOf() : null);
    setReceiptDate_1(dateInput);
  };

  const clearDate = () => {
    setTimeStampforDatepicker(null);
    setTimeStampforDatepicker_1(null);
    setReceiptDate("");
    setReceiptDate_1("");
    setPage(0);
  };

  return (
    <>
      <Box flexGrow="1" justifyContent="space-between" mb="1.5rem">
        <div className="row" style={{ padding: "15px" }}>
          <div className="col-md-6">
            <div>Input Output/Vitals</div>
          </div>
          <div className="col-md-6 text-end">
            <Grid item>
              <Typography
                onClick={clearDate}
                variant="h5"
                style={{
                  textAlign: "right",
                  marginBottom: "5px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                Clear
              </Typography>
              {/* new datepicker */}
              <DatePicker
                style={{ float: "right" }}
                label="Date(DD/MM/YYYY)"
                value={receiptDate_1 || null}
                name="surgeryDateTime"
                onChange={(newValue) => {
                  // const timestamp = newValue ? newValue.valueOf() : null;
                  onReceiptDateSelected_1(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                inputFormat="DD/MM/YYYY"
                className={globalClass.roundWhiteBg}
                format="DD/MM/YYYY"
              />

              <DatePicker
                style={{ float: "right" }}
                label="Date(DD/MM/YYYY)"
                value={receiptDate || null}
                name="surgeryDateTime"
                onChange={(newValue) => {
                  // const timestamp = newValue ? newValue.valueOf() : null;
                  onReceiptDateSelected(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                inputFormat="DD/MM/YYYY"
                className={globalClass.roundWhiteBg}
                format="DD/MM/YYYY"
              />
            </Grid>
          </div>
        </div>

        <Grid
          container
          style={{ marginRight: "10px", float: "right", display: "block" }}
        ></Grid>
      </Box>
      <div className="second_part justify-content-between  align-items-center ps-2">
        <div className="patient_name  align-items-center ps-2 row">
          <p className="col-md-2">
            {" "}
            <b>Patient Name </b> <br />
            <small style={{ fontWeight: "500" }}>&nbsp;{patientName}</small>
          </p>
          <p className="col-md-1">
            {" "}
            <b>Room</b> <br />
            <small style={{ fontWeight: "500" }}>&nbsp;{roomNumber}</small>
          </p>
          <p className="col-md-2">
            {" "}
            <b>Doctor</b> <br />
            <small style={{ fontWeight: "500" }}>&nbsp;{doctorName}</small>
          </p>
          <p className="col-md-2">
            {" "}
            <b>Number/UHID</b> <br />
            <small style={{ fontWeight: "500" }}>&nbsp;{patientUhid}</small>
          </p>
          <p className="col-md-2">
            {" "}
            <b>Diagnosis</b> <br />
            <small style={{ fontWeight: "500" }}>
              &nbsp;{provisionalDiagnosis}
            </small>
          </p>
          <p className="col-md-1">
            {" "}
            <b>Plan</b> <br />
            <small style={{ fontWeight: "500" }}>&nbsp;{plan}</small>
          </p>
          <p className="col-md-1">
            {" "}
            <b>Status</b> <br />
            <small style={{ fontWeight: "500", paddingRight: "15px" }}>
              &nbsp;{patientStatus}
            </small>
          </p>
        </div>
        <div></div>
      </div>

      {isloading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ maxWidth: 1100 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Date And Time</TableCell>
                <TableCell align="right">Pr</TableCell>
                <TableCell align="right">BP&nbsp;</TableCell>
                <TableCell align="right">SPO2&nbsp;</TableCell>
                <TableCell align="right">Temp&nbsp;</TableCell>
                <TableCell align="right">RR&nbsp;</TableCell>
                <TableCell align="right">Pain score</TableCell>
                <TableCell align="right">RBS&nbsp;</TableCell>
                <TableCell align="right">Input&nbsp;</TableCell>
                <TableCell align="right">Input Type&nbsp;</TableCell>
                <TableCell align="right">output&nbsp;</TableCell>
                <TableCell align="right">Output type&nbsp;</TableCell>
                <TableCell align="right">User Name&nbsp;</TableCell>
              </TableRow>
            </TableHead>

            {content?.content?.map((row, index) => (
              <TableBody>
                <TableCell
                  style={{ borderRight: "1px solid grey", paddingRight: "0px" }}
                >
                  {moment(row?.createdAt).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.pulseRate}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.bloodPressure}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.oxygenSaturation}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.temperature}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.respirationRate}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.ps}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.vitals?.remarks}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.inputOutput?.inputOutputType == "input"
                    ? row?.inputOutput?.volume + row?.inputOutput?.unit
                    : ""}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.inputOutput?.inputOutputType == "input"
                    ? row?.inputOutput?.valueType
                    : ""}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.inputOutput?.inputOutputType == "output"
                    ? row?.inputOutput?.volume + row?.inputOutput?.unit
                    : ""}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.inputOutput?.inputOutputType == "output"
                    ? row?.inputOutput?.valueType
                    : ""}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid grey" }}>
                  {row?.createdByName}
                </TableCell>
              </TableBody>
            ))}

            {/* <TablePagination
                                rowsPerPageOptions={[5]}
                                count={AdmitHistoryData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                // onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            /> */}
            <TablePagination
              rowsPerPageOptions={[1, 10, 25, 50, 100, 500]}
              count={content?.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
            />
          </Table>
          {
            <>
              Total Input {receiptDate ? "for Date" : ""} : {totalInput} <br />
              Total Output {receiptDate ? "for Date" : ""}:{totalOutput}
            </>
          }
        </TableContainer>
      )}
    </>
  );
};

export default Compare;