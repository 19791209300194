import React, { useRef } from "react";
import { CircularProgress, Box } from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { useState } from "react";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import Api from "../../utils/api";
import ThemedButton from "../layout/styled-btn";
import Api2 from "../../utils/api2";

const FileUpload = ({ btnText, url, onUpload }) => {
  const fileRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const globalClass = useGlobalStyles();

  const handleChange = (evt) => {
    setLoading(true);
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    formData.append("file", fileArray[0]);

    if (url === "emr-template/import") {
      Api2.post("import_emr", formData).subscribe(
        (resp) => {
          onUpload(resp.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }

    else if (url === "patient/import") {
      Api2.post("import_patients", formData).subscribe(
        (resp) => {
          onUpload(resp.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }

    else {
      Api.post(url, formData).subscribe(
        (resp) => {
          onUpload(resp.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          DEFAULT_ERROR_CALLBACK(error);
        }
      );

    }

  };

  return (
    <Box>
      <input
        ref={fileRef}
        className={globalClass.fileInput}
        id="contained-diagram-file"
        type="file"
        onChange={(e) => handleChange(e)}
      />
      <label htmlFor="contained-diagram-file">
        <ThemedButton component="span" color="primary" text={"Import"}>
          {loading ? (
            <CircularProgress
              disableShrink
              className={globalClass.loaderColor}
              size={20}
            />
          ) : (
            { btnText }
          )}
        </ThemedButton>
      </label>
    </Box>
  );
};
export default FileUpload;
