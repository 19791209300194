import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AuthService from "../../utils/services/auth-service";


const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: (props) => (props.dark ? props.dark : props.light),
    },
    
    backgroundColor: (props) => props.light,
    borderRadius: 10,
    textTransform: "capitalize",
    fontSize: 11,
    color: (props) => props.contrastText,
  },
});

const ThemedButton = ({ color = "primary", onClick, text, component }) => {
  const tenant = AuthService.getTenant();
  const tempColor = tenant?.theme[color];
  const classes = useStyles(tempColor);
  return (
    <Button component={component} onClick={onClick} className={classes.root}>
      {text}
    </Button>
  );
};

export default ThemedButton;
