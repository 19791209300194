import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Button,
  Paper,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import { TextAreaInput } from "sixsprints-react-ui";
import ChatBubble from "../chat/chatBubble";
import isAuthorised from "../../utils/role-util";
import SendIcon from "@material-ui/icons/SendOutlined";
import DataService from "../../utils/services/data-service";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AttachIcon from "@material-ui/icons/AttachFile";
import clsx from "clsx";
import useGlobalStyles from "../../utils/global-style";
import useSocket from "../chat/socket";
import ChatHeader from "../chat/chat-header";
import { Form, TextArea } from "informed";
import Api from "../../utils/api";
import AuthService from "../../utils/services/auth-service";
import { useRecoilState } from "recoil";
import { TempChatUsersState, ChatsState } from "../../states";
import AppConstants from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
} from "../../utils/app-util";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Routes from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  chatHeader: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    margin: theme.spacing(0, 1),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.chatBg,
  },
  chatBox: {
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderBottomLeftRadius: "14px",
    borderBottomRightRadius: "14px",
    padding: theme.spacing(6),
    height: "66vh",
    width: "100%",
    overflowY: "auto",
  },
  containerInput: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(0, 2),
    height: "6vh",
  },
  attachIcon: {
    height: "6vh",
  },

  adjustPadding: {
    padding: theme.spacing(0, 0, 0, 8),
  },

  textInput: {
    width: "100%",
    fontSize: "0.85rem",
  },

  loadMoreBtn: {
    position: "absolute",
    width: "50%",
    left: "25%",
    right: "25%",
    color: "white",
    display: "flex",
    alignSelf: "center",
  },
  startChat: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

const PatientChat = ({ appointment, patientSlug, docSlug }) => {
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const socket = useSocket();
  const formApi = useRef();
  const messagesEndRef = useRef();
  const user = AuthService.getUser();
  const [tempChatUsers, setTempChatUsers] = useRecoilState(TempChatUsersState);
  const [, setChatUsers] = useRecoilState(ChatsState);
  const [activePatient, setActivePatient] = useState(null);
  let history = useHistory();

  let chatBoxContainer = document.getElementById("chatBox");
  const [question, setQuestions] = useState(0);

  const [showChatBoxLoader, setShowCHatBoxLoader] = useState(false);
  const [chatPackages, setChatPackages] = useState([]);
  const [newChatUser, setNewChatUser] = useState(null);

  useEffect(() => {
    if (isAuthorised(AppConstants.ENTITY.CHAT)) {
      setShowCHatBoxLoader(true);
      Api.get("/chat-session/my?size=100").subscribe((resp) => {
        const chatList = ((resp.data || {}).data || {}).content || [];
        setChatUsers(chatList);
        setTempChatUsers(chatList);
        setShowCHatBoxLoader(false);
      });
    }
    const payload = {
      filterModel: {},
      page: 0,
      size: 200,
      sortModel: [],
    };
    Api.post("chat-package/search", payload).subscribe((resp) => {
      setChatPackages(resp.data.data.content);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef?.current?.scrollHeight;
      const height = messagesEndRef?.current?.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [socket.messageHistory, messagesEndRef]);

  useEffect(() => {
    if (appointment && tempChatUsers) {
      const patientId = appointment?.patient?.id;
      const currentChatUser = tempChatUsers.find((user) => {
        return user?.patient?.id === patientId;
      });
      setActivePatient(currentChatUser ?? null);
      setQuestions(currentChatUser?.totalQuestions);

      if (socket.isConnected && activePatient?.slug !== currentChatUser?.slug) {
        socket.unsubscribe(activePatient?.slug);
      }

      if (
        !socket.isConnected ||
        (socket.isConnected && activePatient?.slug !== currentChatUser?.slug)
      ) {
        socket.setMessageHistory([]);
        socket.setCurrentSessionSlug(currentChatUser && currentChatUser.slug);
        socket.startSession(currentChatUser && currentChatUser.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, tempChatUsers]);

  useEffect(() => {
    if (newChatUser) {
      if (socket.isConnected) {
        socket.unsubscribe(newChatUser.chatSessionSlug);
      } else {
        socket.setMessageHistory([]);
        socket.setCurrentSessionSlug(newChatUser.chatSessionSlug);
        socket.startSession(newChatUser.chatSessionSlug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatUser]);

  useEffect(() => {
    if (chatBoxContainer) {
      chatBoxContainer.addEventListener(
        "scroll",
        function (e) {
          socket.isConnected &&
          socket.isMsgLoaded &&
          chatBoxContainer.scrollTop < 500
            ? setVisibility(true)
            : setVisibility(false);
        },
        false
      );
    }
  });
  const pickImage = (evt) => {
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    formData.append("file", fileArray[0]);
    DataService.uploadImage("prescriptions", "slug", formData).subscribe(
      (resp) => {
        socket.sendMessage({ imgUrl: resp });
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleSubmit = (val) => {
    if (!val || !val.msg || !val.msg.trim()) {
      return;
    }
    if (!(activePatient?.totalQuestions === 0)) {
      socket.sendMessage(val);
    } else {
      SHOW_INFO_NOTIFICATION(
        "Patient's chat subscription is expired. Can't send messages now. "
      );
    }
    formApi.current.reset();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        handleSubmit(formApi.current.getState().values);
      }
    }
  };

  const handleChatAction = (val) => {
    history.push(val);
  };

  const uploadImage = (evt) => {
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    formData.append("files", fileArray[0]);
    DataService.uploadImage(formData).subscribe((resp) => {
      socket.sendMessage({ imgUrl: resp?.imageId });
    });
  };

  const handleMoreMessages = () => {
    if (socket.currentPage <= socket.totalPages) {
      socket.loadMoreMessages(activePatient?.slug);
      socket.setIsScrollDown(false);
    }
  };

  const startChatWithPatient = useCallback(() => {
    const freeChatPackage = chatPackages?.find(
      (chatPackage) => chatPackage.amount === 0
    );

    if (freeChatPackage) {
      const payload = {
        patientSlug: appointment?.patientSlug,
        chatPackageSlug: freeChatPackage.slug,
      };
      Api.post(
        `/order/initiate/chat-package/doctor?patientSlug=${appointment.patientSlug}&chatPackageSlug=${freeChatPackage.slug}`,
        payload
      ).subscribe(
        (resp) => {
          const response = resp.data.data;
          setNewChatUser(response);
        },
        (err) => {}
      );
    } else {
      NotificationManager.error(" Please Create a Free chat Package...! ");
    }
  }, [appointment, chatPackages]);


  return (
    <>
      {!activePatient && !newChatUser && showChatBoxLoader ? (
        <Grid item xs={4} md={3}>
          <Box
            className={clsx(
              classes["prescription-qr"],
              globalClass.roundWhiteBg
            )}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 150,
            }}
          >
            <Loader height="100%" />
          </Box>
        </Grid>
      ) : !showChatBoxLoader && !activePatient && !newChatUser ? (
        <Grid >
          <Box
            className={clsx(
              classes["prescription-qr"],
              globalClass.roundWhiteBg
            )}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 150,
            }}
          >
            <Typography variant="h5" className={classes.prescriptionTitle}>
              <Button
                onClick={startChatWithPatient}
                className={classes.startChat}
              >
                Start Chat
              </Button>
            </Typography>
          </Box>
        </Grid>
      ) : (
        <Grid  >
          <Box
            display={{ xs: "none", sm: "block" }}
            className={clsx(
              classes["prescription-qr"],
              globalClass.roundWhiteBg
            )}
            style={{ position: "relative" }}
          >
            <ChatHeader
              itemStyle={classes.chatHeader}
              name={appointment?.patient?.name ?? ""}
              date="Remaining"
              time={`${question ?? 10} Question(s)`}
              patientSlug={patientSlug}
              docSlug={docSlug}
            />
            {visibility && socket.currentPage < socket.totalPages && (
              <Button
                variant="contained"
                color="primary"
                className={`${classes.button} ${classes.loadMoreBtn}`}
                startIcon={<ArrowUpwardIcon />}
                onClick={handleMoreMessages}
              >
                Load More Messages
              </Button>
            )}
            <Paper
              style={{ height: 200, width: "auto" }}
              id="chatBox"
              ref={messagesEndRef}
              className={clsx(
                classes.chatBox,
                globalClass.whiteBg,
                globalClass.srollBar
              )}
            >
              {socket.isMsgLoaded ? (
                socket.messageHistory.map((item, index) => {
                  return (
                    <>
                      <ChatBubble
                        left={user.slug !== item.fromUserSlug}
                        user={activePatient && activePatient?.patient?.name}
                        key={index}
                        handleClick={handleChatAction}
                        img={
                          item?.attachment?.content
                            ? item?.attachment?.content
                            : item?.attachment?.contenttype &&
                              ` data:${item?.attachment?.contenttype};base64,${item?.attachment?.data?.data}`
                        }
                        contenttype={item?.attachment?.contenttype}
                        messageType={item.type}
                        actions={item.actions}
                        text={item?.text} 
                        time={item.dateCreated}
                        hideActions
                        textVariant={"body1"}
                        
                      />
                    </>
                  );
                })
              ) : (
                <Loader height="100%" />
              )}
            </Paper>

            <Form
              style={{ width: "100%" }}
              apiRef={formApi}
              onSubmit={handleSubmit}
              onKeyPress={handleKeyPress}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  display="flex"
                  flexGrow="1"
                  justifyContent="center"
                  alignItems="center"
                  className={clsx(
                    classes.containerInput,
                    globalClass.roundWhiteBg
                  )}
                >
                  <TextArea
                  style={{ borderRadius: "20px" }}
                    field="msg"
                    placeholder="Type here"
                    className={clsx(classes.adjustPadding, classes.textInput)}
                    onFocus={() => {
                      socket.setIsScrollDown(true);
                    }}
                  />

                  <IconButton type="submit" color="primary">
                    <SendIcon fontSize="large" />
                  </IconButton>
                </Box>

                <Box
                  mx={2}
                  display="flex"
                  className={clsx(classes.attachIcon, globalClass.roundWhiteBg)}
                  alignItems="center"
                >
                  <label htmlFor="image-input">
                    <IconButton color="primary" component="span">
                      <AttachIcon fontSize="large" />
                    </IconButton>
                  </label>
                  <input
                    accept="image/*"
                    className={globalClass.fileInput}
                    type="file"
                    id="image-input"
                    onChange={uploadImage}
                    multiple
                  />
                </Box>
              </Box>
            </Form>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default PatientChat;
