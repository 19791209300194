import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";

import Loader from "../../components/misc/loader";
import { Form, Scope } from "informed";
import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";

import { default as React, useEffect, useRef, useState } from "react";

import Header from "../../components/layout/header";
import Axios from "axios";

import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const OtherTab = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const formApi = useRef();
  const [initialValues, setInitialValues] = useState([]);

  const [addLoadingStatus, setAddLoadingStatus] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [futureBooking, setFutureBooking] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [checkinAudio, setCheckinAudio] = useState(false);
  const [resp, setResp] = useState(null);
  const [status, setStatus] = useState(false);
  const [terms, setterms] = useState("");

  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}getprofile?id=${user.id}`
    ).then((resp) => {
      if (resp) {
        setResp(resp.data.data);
      }
    });
  }, [status]);

  useEffect(() => {
    setCheckinAudio(resp?.checkinAudio);
    setShowRecord(resp?.showRecord);
    setFutureBooking(resp?.futureBooking);
    setFeedback(resp?.feedback);
    setterms(resp?.terms);
    setVideoLink(resp?.videoLink);
  }, [resp, addLoadingStatus]);

  const user = JSON.parse(localStorage.getItem("USER"));

  const handleSave = async (event) => {
    setAddLoadingStatus(true);
    event.preventDefault();

    const req = {
      checkinAudio: checkinAudio,
      futureBooking: +futureBooking,
      feedback: feedback,
      showRecord: showRecord,
      id: user.id,
      terms: terms,
      videoLink: videoLink,
    };
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}/update_profile_other`,
        data: req,
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.message.toString() === "Updated Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false);
        setStatus(true);
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SendTodayLink = async() => {
    const formData = new FormData();
    formData.append("slug", user.slug);
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}/send_virtual_link_email`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });
        if(response.statusCode === 200){
          NotificationManager.success(response.data.message);
        }
        else{
          NotificationManager.error(response.data.message);
        }

    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div>
      <Header title="Meeting Link" />
      <Form
        apiRef={formApi}
        initialValues={{
          availSummary: initialValues,
          paymentOptions: [
            {
              payTo: "",
              qrCode: "",
              description: "",
            },
          ],
        }}
      >
        <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
          <Box mt="2rem" alignItems="center">
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Meeting Link
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  field="title"
                  placeholder="meeting link"
                  name="feedback"
                  value={videoLink}
                  className={classes.style_1}
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </Grid>

              <Box mb={3} style={{ padding: "2%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ padding: "2%" }}
                  onClick={SendTodayLink}
                >
                  Send Meeting Link for Today Appointment
                </Button>
              </Box>
            </Grid>
          </Box>

          <Box mb={3} style={{ marginTop: "2%" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={globalClass.largeBtnContainer}
              onClick={handleSave}
            >
              {addLoadingStatus ? (
                <Loader width="200px" height="20px" color="white" />
              ) : (
                "SAVE"
              )}
            </Button>
          </Box>
        </div>
      </Form>
    </div>
  );
};

export default OtherTab;
