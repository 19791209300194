import Api from "../utils/api";
import axios from "axios";
import { firstValueFrom } from "rxjs";

export const UploadAPI = {
  uploadFileToBlobAndGetFileURLs: async (files, appointmentSlug) => {
    // Step 1: Request pre-signed URLs from the backend
    console.log("files uploard", files);
    const fileInfos = files.map((file) => ({
      fileName: file.name,
      fileType: file.type,
    }));
    const response = await firstValueFrom(
      Api.post(
        `file/generate-upload-urls?appointmentSlug=${appointmentSlug}`,
        fileInfos
      )
    );

    const uploadResults = await Promise.all(
      response.data.map(async (fileData, index) => {
        const { uploadUrl, fileUrl } = fileData;
        const file = files[index];

        // Step 2: Upload the file to Azure Blob Storage using the pre-signed URL
        await axios.put(uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
            "x-ms-blob-type": "BlockBlob",
            "x-ms-version": "2017-11-09",
          },
        });

        return fileUrl;
      })
    );

    return uploadResults;
  },

  getPresignedUrlForFile: async (filePath) => {
    try {
      const response = await firstValueFrom(
        Api.get(`file/generate-file-sas-token`, {
          params: { filePath }
        })
      );
      return response.data.sasUrl;
    } catch (error) {
      console.error("Error getting pre-signed URL:", error);
      throw error;
    }
  },
};
