import React, { useState, useRef, useEffect, useCallback } from "react";
import Header from "../../components/layout/header";
import {
  Box,
  Button,
  Grid,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  Tooltip,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import {
  useQuery,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Api from "../../utils/api";
import { TextInput, CustomDateInput } from "../../components/sixsprint-react-ui";
import { Form } from "informed";
import Resizer from "react-image-file-resizer";
import clsx from "clsx";
import AppConstants from "../../utils/app-constants";
import Loader from "../../components/misc/loader";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import AuthService from "../../utils/services/auth-service";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import Api2 from "../../utils/api2";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  btnBoxes: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    gap: "18px",
    marginTop: "8px",
  },
});

const Report = () => {
  const globalClass = useGlobalStyles();
  const [uimages, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileRef = useRef(null);
  const formApi = useRef();
  const classes = useStyles();
  const [submitBtnAction, setSubmitBtnAction] = useState("");
  const [slug, setSlug] = useState({});

  const [patientDetails, setPatientDetails] = useState({});

  const [file, setFile] = useState([]);
  const [base64, setBase64] = useState([]);

  let query = useQuery();

  useEffect(() => {
    query.forEach((slug, key) => {
      setSlug((prevState) => {
        return { ...prevState, [key]: slug };
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function uploadSingleFile(e) {
    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only PDF, JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      const excludedTypes = [
        "image/svg+xml",
        "text/plain",
        "image/png",
        "video/*",
        "application/msword",
      ];

      // if (fileType !== 'image/png') {
      if (!excludedTypes.includes(fileType)) {
        setImages(e.target.files);

        // let ImagesArray = Object.entries(e.target.files).map((e) => {
        //   return URL.createObjectURL(e[1]);
        // });

        let Imageshash = Object.entries(e.target.files).map((e) => {
          var hash1 = { image: URL.createObjectURL(e[1]), type: e[1].type };

          return hash1;
        });

        setFile([...file, ...Imageshash]);
      } else {
        SHOW_ERROR_NOTIFICATION(`${fileType} files are not allowed`);

        e.target.value = null;
      }
    }
  }

  function deleteFile(e) {
    const fileListAsArray = Array.from(uimages);
    const d = fileListAsArray?.filter((item, index) => index !== e);
    setImages(d);

    const s = file.filter((item, index) => index !== e);
    setFile(s);
    SHOW_SUCCESS_NOTIFICATION("Deleted successfully");
  }

  useEffect(() => {
    if (slug.patientSlug) {
      const payload = {
        page: 0,
        size: 20,
        filterModel: {
          _: {
            filterType: "text",
            type: "",
            filter: slug.patientSlug,
          },
        },
        sortModel: [],
      };
      Api.post("patient/search", payload).subscribe((resp) => {
        const response = resp.data.data;
        setPatientDetails(response.content[0] ?? {});
      });
    }
  }, [slug]);

  const resizeFile = useCallback(
    (file) =>
      new Promise((resolve) => {
        try {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "file",
            250,
            250
          );
        } catch (err) {
          SHOW_ERROR_NOTIFICATION("Please select an image");
        }
      }),
    []
  );

  const handleSendCredForEmail = useCallback(() => {
    const { email, name } = patientDetails;
    if (email && name) {
      const payload = {
        toEmail: email,
        patientName: name,
        type: "report",
      };
      Axios.post(`${process.env.REACT_APP_API2_HOST}send-emails`, payload);
    } else {
      NotificationManager.error(AppConstants.MSG.EMAIL_FAILED);
    }
  }, [patientDetails]);

  const docslug = JSON.parse(localStorage.getItem("USER"));

  const handleSubmit = async (val) => {
    // setIsLoading(true)
    const formData = new FormData();
    if (uimages?.length > 0) {
      for (let i = 0; i < uimages?.length; i++) {
        formData.append("files", uimages[i]);
      }
    } else {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FILE_MSG);
      return;
    }
    formData.append("patientSlug", slug.patientSlug);
    formData.append("appointmentSlug", slug.appointmentSlug);
    formData.append("reportDate", val.reportDate);
    formData.append("reportName", val.reportName);
    formData.append("value", "");
    formData.append("action", submitBtnAction);
    if (docslug.slug?.length > 0) {
      formData.append("withMessage", true);
      formData.append("doctorSlug", docslug.slug);
    }

    const token = AuthService.getToken();
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API_HOST}report/add`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response) {
        SHOW_SUCCESS_NOTIFICATION(response.data.message);
        setImages([]);
        setFile([]);
        // setIsLoading(false)
        // handleSendCredForEmail();
        formApi.current.reset();
        window.location.reload();
      }
    } catch (error) { }
  };

  // const handleSubmit = useCallback(
  //   (val) => {
  //     if (images.length) {
  //       val.images = images;
  //     } else {
  //       if (!val.value) {
  //         SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FILE_MSG);
  //         return;
  //       }
  //     }
  //     val.patientSlug = slug?.patientSlug ?? "";
  //     val.appointmentSlug = slug?.appointmentSlug ?? "";
  //     val.action = submitBtnAction;

  //     DataService.createData(val, `report`).subscribe(
  //       () => {
  //         SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.ADD_SUCCESS);
  //         setImages([]);
  //         handleSendCredForEmail();
  //         formApi.current.reset();
  //       },
  //       (error) => {
  //         DEFAULT_ERROR_CALLBACK(error);
  //       }
  //     );
  //   },
  //   [handleSendCredForEmail, images, slug, submitBtnAction]
  // );

  return (
    <div>
      <Header showBackBtn title="Report" menu={true} />

      <Box mx="1.6rem">
        <Paper className={clsx(globalClass.roundWhiteBg)}>
          <Box p="1rem" className={globalClass.prescriptionSection}>
            <input
              ref={fileRef}
              // accept="image/*,.pdf"
              // accept="image/*,.pdf, .jpg, .jpeg"
              accept="image/*,.pdf,.jpg,.jpeg"
              className={globalClass.input}
              id="contained-diagram-file"
              type="file"
              multiple
              onChange={uploadSingleFile}
            />
            <Box width="40%">
              <label htmlFor="contained-diagram-file">
                <Button
                  fullWidth
                  my={8}
                  style={{ height: "3rem" }}
                  variant="outlined"
                  color="primary"
                  component="span"
                >
                  {isLoading ? <Loader /> : "Select file to  Upload"}
                </Button>
              </label>
            </Box>

            <Box my={8} m={1} className={globalClass.prescriptionSection}>
              <Grid container spacing={3}>
                {file &&
                  file?.map((item, index) => {
                    return (
                      <Tooltip key={index} title="View Report">
                        <Grid item xs={12} sm={4}>
                          <Box
                            mb={2}
                            p={2}
                            textAlign="center"
                            style={{ backgroundColor: "#F6F7FC" }}
                          >
                            <Card className={classes.root}>
                              <a
                                href={item.image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <CardActionArea>
                                  {item.type === "application/pdf" ||
                                  "application/jpg" ||
                                  "application/jpeg" ? (
                                    <div
                                      style={{
                                        width: 200,
                                        height: 200,
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      {/* <PictureAsPdfRoundedIcon
                                                                          style={{
                                                                            width: 120,
                                                                            height: 120,
                                                                            color: "red",
                                                                          }}
                                                                        /> */}
                                      <iframe src={item.image} />
                                    </div>
                                  ) : (
                                    <CardMedia
                                      component="img"
                                      alt="Contemplative Reptile"
                                      height="300"
                                      image={item.image}
                                      title="Contemplative Reptile"
                                    />
                                  )}
                                </CardActionArea>
                              </a>

                              <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() => deleteFile(index)}
                              >
                                Delete
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  window.open(`${item.image}`, "_blank")
                                }
                                style={{ margin: "3px 3px" }}
                              >
                                View
                              </Button>
                            </Card>
                          </Box>
                        </Grid>
                      </Tooltip>
                    );
                  })}
              </Grid>
            </Box>
            <Box
              width="40%"
              my={8}
              m={1}
              className={globalClass.prescriptionSection}
            >
              <Form apiRef={formApi} onSubmit={handleSubmit}>
                <TextInput
                  fullWidth
                  required
                  field="reportName"
                  label="Report Name"
                  validateOnBlur
                  validateOnChange
                />
                {/* <TextInput
                  fullWidth
                  field="value"
                  label="Value"
                  multiline
                  validateOnBlur
                  validateOnChange
                /> */}
                <CustomDateInput
                  fullWidth
                  disableFuture
                  disableToolbar
                  required
                  inputVariant="outlined"
                  field="reportDate"
                  label="Report Date (DD/MM/YYYY)"
                  initialValue={new Date().valueOf()}
                  validateOnBlur
                  validateOnChange
                />
                <Box className={classes.btnBoxes}>
                  <Box
                    my={4}
                    mb={6.25}
                    display={{ xs: "flex" }}
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ padding: "13px 28px", fontWeight: "bold" }}
                      onClick={() => setSubmitBtnAction("SAVE")}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box
                    my={4}
                    mb={6.25}
                    display={{ xs: "flex" }}
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ padding: "13px 28px", fontWeight: "bold" }}
                      onClick={() => setSubmitBtnAction("REVIEW")}
                    >
                      Save and Preview
                    </Button>
                  </Box>
                  <Box
                    my={4}
                    mb={6.25}
                    display={{ xs: "flex" }}
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setSubmitBtnAction("CHAT")}
                      style={{ padding: "13px 28px", fontWeight: "bold" }}
                    >
                      Save and Share to Chat
                    </Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default Report;
