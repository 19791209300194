
import React, { useState } from 'react'
import {
    Box,
    Typography

} from "@material-ui/core";
import Tab from './tab'

const VisitRequest = () => {

    const [tabIndex, setTabIndex] = useState(0);

    const onTabClicked = (index) => {
        setTabIndex(index)
    }






    return (
        <>
            <Box style={{ padding: '50px' }}>
                <Typography variant="h4">Visit Request</Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '20px' }}
            >

                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>

                    <Tab
                        key={0}
                        index={0}
                        currentIndex={tabIndex}
                        title={`Visit Requests : ${78}`}
                        onTabClicked={onTabClicked}
                    />
                    <Tab
                        key={1}
                        index={1}
                        currentIndex={tabIndex}
                        title={`Not Visited : ${90}`}
                        onTabClicked={onTabClicked}
                    />
                </div>
            </Box >



        </>
    )
}

export default VisitRequest
