import FilterOptions from '../../utils/filter-options';
import AuthService from '../../utils/services/auth-service';

class UpdateAppointmentColumn {
  constructor(isEditMode) {
    this.allColumns = [
      {
        field: '_',
        colId: '_',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep',
        },
      },
    ].concat(
      allFields().map((field) => {
        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          ...appointmentFilters.chooseAptFilter(field.dataType),
          pinned: field.pinned ? 'left' : '',
          cellRendererSelector: function (params) {
            return {
              component: 'customRenderer',
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                data: params.data,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: 'customEditor',
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() {
    return this.allColumns;
  }
}

const allFields = () => {
  const config = AuthService.getConfig();
  const value = AuthService.fetchConfig();
  value.subscribe((resp) => {  });
  return config?.['appointmentField'];
};

const appointmentFilters = new FilterOptions({ collection: 'appointment' });

export default UpdateAppointmentColumn;
