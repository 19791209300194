import { makeStyles, fade } from "@material-ui/core";

const useGlobalStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(8),
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  listStyle: {
    width: '100%',
    maxWidth: 230,
    backgroundColor: theme.palette.background.paper,
  },
  pointer: {
    cursor: "pointer",
  },
  timeField: {
    width: "200px",
  },

  input: {
    display: "none",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },

  addSpacing: {
    padding: theme.spacing(10),
  },
  prescriptionUserDetailsInput: {
    marginRight: "12px",
    maxWidth: "180px"
    
  }, 
  inputWidthLg: {
    width: "200px",
    height: "45px",
  },
  inputWidth: {
    width: "150px",
  },
  textWidth: {
    width: "300px",
  },
  loaderColor: {
    color: "white",
  },
  whiteBox: {
    // margin: theme.spacing(1),
    padding: "0 1rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },

  roundWhiteContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    // height: "2.8em",
    marginBottom: "5%",
  },
  prevPrescription: {
    display: 'inline-block',
    padding: '0px',
    maxWidth: '170px',
    maxHeight: '40px',
    borderRadius: 10
  },
  roundWhiteBg: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    outline: "none",
  },
  fileInput: {
    display: "none",
  },
  loaderStyle: {
    color: "white",
  },
  greyFont: {
    color: "#6D6D6D",
  },
  srollBar: {
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
    },
  },

  boxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(20),
  },

  formContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(2),
  },

  inputContainer: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(10),
  },

  largeBtnContainer: {
    padding: theme.spacing(6),
    width: "15%",
  },

  mediumBtnContainer: {
    padding: theme.spacing(6),
    width: "10%",
  },

  smallBtnContainer: {
    padding: theme.spacing(6),
    width: "7%",
  },

  formElement: {
    border: "none",
    outline: "none",
    width: "100%",
    paddingLeft: theme.spacing(5),
    fontFamily: "Comfortaa",
    fontSize: 10,
  },

  search: {
    border: "hidden",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    marginRight: theme.spacing(2),
    padding: `10px ${theme.spacing(4)}px`,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  inputInput: {
    fontSize: 10,
    border: "none",
    "&:focus": {
      outline: "none",
    },
    backgroundColor: "transparent",
    padding: theme.spacing(1, 1, 1, 2),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },

  inputWidth172:{
    width:"200px"
  },
  inputWidth173:{
    width:"190px"
  },

  labelContainer: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    opacity: 1,
    letterSpacing: 0.6,
    marginTop: theme.spacing(10),
    fontSize: 16,
  },

  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4em",
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

export default useGlobalStyles;
