import React, { useState, useRef, useEffect } from "react";
import { Scope } from "informed";
import {
  makeStyles,
  TableContainer,
  Paper,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Tooltip,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useGlobalStyles from "../../utils/global-style";
import { TextAreaInput, TextInput } from "sixsprints-react-ui";
import Api from "../../utils/api";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import Bro from "brototype";

const useStyles = makeStyles((theme) => ({
  fSize: {
    fontSize: "10px",
  },
}));

let searchSubscription = null;
const Medication = ({
  onRemoveMedicationClicked,
  data,
  medList,
  setMedicationData,
  onMedicationSelected,
  copyMedicationsInterVal
}) => {
  const [rowCount, setRowCount] = useState(0);
  const [searchList, setSearchList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const globalClass = useGlobalStyles();
  const classes = useStyles();

  const wrapperRef = useRef(null);

  const medicineIntervals = [
    { id: 1, value: "Days" },
    { id: 2, value: "Weeks" },
    { id: 3, value: "Months" },
  ];

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchList([]);
    }
  };

  useEffect(() => {
    if (data?.length) {
      setRowCount(data.length > 0 ? data.length : 1);
    }
  }, [data, data?.length]);

  const onAddRow = () => {
    setRowCount((prev) => prev + 1);
    setMedicationData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState ? prevState : []));
      return [
        ...newState,
        {
          name: "",
          dose: "",
          frequency: "1-0-1",
          num: 1,
          interval: "Days",
          remarks: "",
        },
      ];
    });
  };

  const onRemoveRow = (val) => {
    onRemoveMedicationClicked(val);
    if (rowCount > 0) {
      setRowCount((prev) => prev - 1);
    }
  };

  const onMedSelected = (e, val, index) => {
    console.log("test_value", val);
    console.log("test_index", index);
    onMedicationSelected(val, index);
    setSearchList([]);
  };

  const handleOnChange = (e, index) => {
    setCurrentIndex(index);
    let val = e.target.value;
    if (val === "") {
      setSearchList([]);
      return;
    }
    setLoading(true);
    const dto = {
      page: 0,
      size: 20,
      filterModel: {
        _: { filterType: "text", type: "", filter: val, fields: ["name"] },
      },
      sortModel: [],
    };

    if (searchSubscription) {
      searchSubscription.unsubscribe();
    }

    searchSubscription = Api.post(`medication/search`, dto).subscribe(
      (resp) => {
        const { content } = Bro(resp).iCanHaz("data.data") || {};
        setSearchList(content);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        DEFAULT_ERROR_CALLBACK(err);
      }
    );
  };

  const medicineFormChange = (keyName, value, index) => {
    setMedicationData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[index] = newState[index] ?? {}
      newState[index][keyName] = value;
      return [...newState];
    });
  };

  const handleChangeName = (e, index) => {
    medicineFormChange("name", e.target.value, index);
    handleOnChange(e, index);
  };

  const selectMedicineInterval = (selectedInterval, index) => {
    medicineFormChange("interval", selectedInterval, index)
  }

  const changeInterval = (value) => {
    copyMedicationsInterVal(value);
  };

  const createTableRow = () => {
    return data?.length ? (data?.map((row, index) => (
      <Scope key={index} scope={`medications[${index}]`}>
        <TableRow>
          <TableCell style={{ display: "none" }}>
            <TextInput field="id" />
          </TableCell>
          <TableCell>{index + 1}</TableCell>
          <TableCell component="th">
            <TextAreaInput
              onChange={(e) => handleChangeName(e, index)}
              placeholder="Name"
              value={row?.name}
              field="name"
            />
            {index === currentIndex && searchList.length > 0 && (
              <Paper
                ref={wrapperRef}
                className={globalClass.listStyle}
                elevation={2}
                style={{ position: "absolute", zIndex: 999 }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                    }}
                  >
                    <CircularProgress disableShrink size={14} />
                  </div>
                ) : (
                  <List dense>
                    {searchList.map((value, listIndex) => (
                      <ListItem
                        key={listIndex}
                        button
                        onClick={(e) => onMedSelected(e, value, index)}
                      >
                        <ListItemText
                          disableTypography
                          className={classes.fSize}
                          primary={`${value.name} (${value.dose})`}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Paper>
            )}
          </TableCell>
          <TableCell style={{ display: 'flex' }}>
            <TextInput
              style={{ marginRight: ".5rem", marginTop: 8, marginBottom: 8 }}
              label="Dose"
              field="dose"
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                medicineFormChange("dose", e.target.value, index)
              }
              value={row?.dose}
            />
            <TextInput
              style={{ width: "120px", marginTop: 8, marginBottom: 8 }}
              label="Frequency"
              type="text"
              field="frequency"
              onChange={(e) =>
                medicineFormChange("frequency", e.target.value, index)
              }
              initialValue="1-0-1"
              value={row?.frequency}
            />
          </TableCell>
          <TableCell>
            <Box display="flex">
              <TextField
                style={{ width: 100, marginRight: '1rem' }}
                id="outlined-basic"
                label="Interval"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={row?.num}
                type='number'
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  medicineFormChange("num", e.target.value, index)
                }
              />
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {medicineIntervals?.map((interval) => (
                  <Box
                    className="mb"
                    display="inline-block"
                    mr={2}
                    mt={3}
                    mb={3}
                    key={interval.id}
                  >
                    <Button
                      onClick={() => selectMedicineInterval(interval.value, index)}
                      key={interval.id}
                      color={(row?.interval === interval.value) ? "primary" : "default"}
                      variant={(row?.interval === interval.value) ? "contained" : "outlined"}
                    >
                      {interval.value}
                    </Button>
                  </Box>
                ))
                }
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 8,
                }}
              >
                <Tooltip title="Copy Interval">
                  <IconButton color="primary" onClick={() => changeInterval(row)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <TextAreaInput
              placeholder="Remarks"
              field="remarks"
              onChange={(e) =>
                medicineFormChange("remarks", e.target.value, index)
              }
              value={row?.remarks}
            />
          </TableCell>
          {
            <TableCell>
              {index === rowCount - 1 && (
                <IconButton color="primary" onClick={onAddRow}>
                  <AddIcon />
                </IconButton>
              )}

              <IconButton
                color="secondary"
                onClick={() => {
                  onRemoveRow(index);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </TableCell>
          }
        </TableRow>
      </Scope>

    ))) :
      (<TableRow>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m="1rem"
          >
            <Typography variant="h6">No Medicines Added Yet</Typography>
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddRow}
            disableElevation
          >
            Click here to add one
            <Box m=".5rem">
              <AddIcon />
            </Box>
          </Button>
        </TableCell>
      </TableRow>)
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Rx</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Dose</strong>
            </TableCell>
            <TableCell>
              <strong>Interval</strong>
            </TableCell>
            <TableCell>
              <strong>Remarks</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {createTableRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Medication;
