import React, { Component } from "react";
import Bro from "brototype";

class StatusBar extends Component {
  paginationChanged = (_evt, params) => {
    this.setState({ totalElements: Bro(params).iCanHaz("totalElements") });
  };

  render() {
    const { totalElements = "Loading ..." } = this.state;
    return (
      <div className="ag-name-value">
        <span>{`Total : ${totalElements}`}</span>
      </div>
    );
  }
}

export default StatusBar;
