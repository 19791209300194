import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import { Form } from "informed";
import { default as React, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { TextInput } from "../../components/sixsprint-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import AppConstants, {
  LOCAL_STORAGE_CONSTANTS,
} from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  transformArrToMap,
  validateAvailSummary,
  validatePaymentOptions,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import { NotificationManager } from "react-notifications";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "90%",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  modalStyleRemove: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const Appointment_type = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const formApi = useRef();
  const [addModelOpen, setaddModelOpen] = React.useState(false);
  const addhandleOpen = () => (setaddModelOpen(true), setChecked([]));
  const addhandleClose = () => setaddModelOpen(false);
  const [uniqueData, setUniqueData] = useState(null);


  const [updateModelOpen, setupdateModelOpen] = useState(false);
  const updatehandleOpen = (item) => (
    setupdateModelOpen(true), setUniqueData(item)
  );
  const updatehandleClose = () => setupdateModelOpen(false);

  const [checked, setChecked] = useState([]);

  const [appointmentTypeStatus, setappointmentTypeStatus] = useState("false");

  const [checkedUpdate, setCheckedUpdate] = useState([]);


  const paymentCheckHandle = (event, item) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, item._id];
    } else {
      updatedList.splice(checked?.indexOf(item._id), 1);
    }
    setChecked(updatedList);
  };

  const paymentCheckHandleUpdate = (event, item) => {
    var updatedList = [...checkedUpdate];
    if (event.target.checked) {
      updatedList = [...checkedUpdate, item._id];
    } else {
      updatedList.splice(checkedUpdate?.indexOf(item._id), 1);
    }
    setCheckedUpdate(updatedList);
  };

  const handleappointmentTypeStatus = (event) => {
    const { name, value } = event.target;
    setappointmentTypeStatus(event.target.value);
  };

  const weekDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const [profileSettings, setProfileSettings] = useState(null);

  const [apoinmentData, setAppoinmentData] = useState({
    AppoinmentType: "",
    fee: "",
    slot: "",
    FromDay: "",
    toDay: "",
    fromTime: "",
    toTime: "",
    disclaimer: "",
    paymentIds: [],
  });

  const [getStatus, setGetStatus] = useState(true);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [uniqueSlotID, slotId] = useState("");
  const handleOpenRemove = (id) => (setOpenRemove(true), slotId(id));
  const handleCloseRemove = () => setOpenRemove(false);
  const [removeStatus, setremoveStatus] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [AllAppoinmentData, setAllAppointmentData] = useState([]);
  const [appoinment, setappoinment] = useState(null);
  const [AllData, setAllData] = useState(null);

  const [addlodingStatus, setLoadingStatus] = useState(false);

  const handleChangeData = (e) => {
    setAppoinmentData({ ...apoinmentData, [e.target.name]: e.target.value });
  };

  const userId = JSON.parse(localStorage.getItem("USER"));

  const handleSubmit = async () => {
    if (
      apoinmentData.AppoinmentType === "" ||
      apoinmentData.fee === "" ||
      apoinmentData.slot === "" ||
      apoinmentData.FromDay === "" ||
      apoinmentData.toTime === "" ||
      apoinmentData.toTime === "" ||
      apoinmentData.disclaimer === "" ||
      checked === []
    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      setLoadingStatus(true);
      const formData = new FormData();
      formData.append("appointmentType", apoinmentData.AppoinmentType);
      formData.append("fee", apoinmentData.fee);
      formData.append("minutes", apoinmentData.slot);
      formData.append("fromDay", apoinmentData.FromDay);
      formData.append("toDay", apoinmentData.toDay);
      formData.append("fromTime", apoinmentData.fromTime);
      formData.append("toTime", apoinmentData.toTime);
      formData.append("disclaimer", apoinmentData.disclaimer);
      formData.append("id", userId.id);
      formData.append("paymentIds", checked);
      formData.append("appointmentTypeStatus", "true");

      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}add_appointment_doctor`,
          data: formData,
          headers: { "Content-Type": " application/json" },
        });
        if (response) {
          if (response.data.message.toString() === "Updated Successfully!") {
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
            setLoadingStatus(false);
            setAddStatus(!addStatus);
            setaddModelOpen(false);
          } else {
            NotificationManager.error("Something went wrong Please try again");
          }
        }
      } catch (error) {}
    }
  };

  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateloadingStatus, setupdateloadingStatus] = useState(false);

  const [paymentOptionsEnabled, setPaymentOptionsEnabled] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_payment_types?status=Active`).then(
      (resp) => {
        if (resp) {
          setPaymentOptionsEnabled(resp.data);
        }
      }
    );
  }, [])

  const handleUpdate = async () => {
    setupdateloadingStatus(true);
    const formData = new FormData();
    formData.append("appointmentType", apoinmentData.AppoinmentType);
    formData.append("fee", apoinmentData.fee);
    formData.append("minutes", apoinmentData.slot);
    formData.append("fromDay", apoinmentData.FromDay);
    formData.append("toDay", apoinmentData.toDay);
    formData.append("fromTime", apoinmentData.fromTime);
    formData.append("toTime", apoinmentData.toTime);
    formData.append("disclaimer", apoinmentData.disclaimer);
    formData.append("id", userId.id);
    formData.append("slotId", uniqueData._id);
    formData.append("paymentIds", checkedUpdate);
    formData.append("confirmOrder", true);
    formData.append("appointmentTypeStatus", appointmentTypeStatus);
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}update_appointment_doctor`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      });
      if (response) {
        if (response.data.message.toString() === "Updated Successfully!") {
          NotificationManager.success(response.data.message);
          setupdateloadingStatus(false);
          setupdateModelOpen(false);
          setUpdateStatus(!updateStatus);
        } else {
          NotificationManager.error(response.data.message);
          setupdateModelOpen(false);
          setupdateloadingStatus(false);
        }
      } else {
        NotificationManager.error(response.data.message);
        setupdateModelOpen(false);
        setupdateloadingStatus(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setAppoinmentData({
      AppoinmentType: uniqueData?.appointmentType || "",
      fee: uniqueData?.fee || "",
      slot: uniqueData?.minutes || "",
      FromDay: uniqueData?.fromDay || "",
      toDay: uniqueData?.toDay || "",
      fromTime: uniqueData?.fromTime || "",
      toTime: uniqueData?.toTime || "",
      disclaimer: uniqueData?.disclaimer || "",
    });
    setappointmentTypeStatus(uniqueData?.appointmentTypeStatus);
    setCheckedUpdate(uniqueData?.paymentIds);
  }, [updateModelOpen]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getappointmenttype`).then(
      (resp) => {
        if (resp) {
          setappoinment(resp.data);
          setGetStatus(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_appointment_time_doctor?id=${userId.id}`
    ).then((resp) => {
      if (resp) {
        if (resp.data === null) {
          setAllAppointmentData([]);
        } else {
          setAllAppointmentData(resp?.data);
        }
        // setLoading(true)
      }
    });
  }, [removeStatus, updateStatus, addStatus]);

  const [removeloadingStatus, setRemoveLoadingStatus] = useState(false);

  const handleRemove = async () => {
    setRemoveLoadingStatus(true);
    const formData = new FormData();
    formData.append("slotId", uniqueSlotID);
    formData.append("id", userId.id);
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}remove_appointment_time_doctor`,
        data: formData,
        headers: { "Content-Type": " application/json" },
      });
      if (response) {
        if (response.data.message === "Deleted Successfully!") {
          NotificationManager.success(response.data.message);
          setRemoveLoadingStatus(false);
          setOpenRemove(false);
          setremoveStatus(!removeStatus);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_doctor_payment_options?id=${userId.id}`
    ).then((resp) => {
      if (resp) {
        setAllData(resp.data.paymentOptions);
      }
    });
  }, []);

  return (
    <div>
      {getStatus ? (
        <Loader />
      ) : (
        <>
          <Header title="My Availability" />
          <Form apiRef={formApi} onSubmit={handleSubmit}>
            <div
              style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}
            >
              <Box className={classes.prescriptionSection}>
                <Paper
                  className={clsx(classes.container, globalClass.roundWhiteBg)}
                >
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={addhandleOpen}
                    >
                      ADD
                    </Button>
                  </Box>
                  <TableContainer>
                    <Table
                      aria-label="simple table"
                      id="summaryRow"
                      className={classes.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Appointment Type</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Fees</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Appointment Slots(in minutes)</strong>
                          </TableCell>
                          <TableCell>
                            <strong> From Days and time </strong>
                          </TableCell>
                          <TableCell>
                            <strong>To Day and time</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Disclaimer</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Status</strong>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AllAppoinmentData &&
                          AllAppoinmentData?.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell>
                                <Typography>
                                  {item &&
                                    appoinment?.map((appItem) => {
                                      if (appItem._id == item.appointmentType) {
                                        return appItem.title;
                                      }
                                    })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{item.fee}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{item.minutes}</Typography>
                              </TableCell>
                              <TableCell>
                                <Box display="flex" flexDirection="column">
                                  <Typography>{item.fromDay}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                  <Typography>{item.fromTime}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box display="flex" flexDirection="column">
                                  <Typography>{item.toDay}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                  <Typography>{item.toTime}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography>{item.disclaimer}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.appointmentTypeStatus}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  gap: "2px",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  startIcon={<EditIcon />}
                                  onClick={() => updatehandleOpen(item)}
                                >
                                  Update
                                </Button>
                                &nbsp;
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  startIcon={<DeleteIcon />}
                                  onClick={() => handleOpenRemove(item._id)}
                                >
                                  Remove
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </div>
          </Form>
        </>
      )}

      {addModelOpen && (
        <Modal
          open={addModelOpen}
          onClose={addhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add Availability
              </Typography>
              <Close onClick={addhandleClose} style={{ cursor: "pointer" }} />
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appoinment Type
                </Typography>
              </Box>
              <Grid>
                <Select
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth,
                    classes.style_1
                  )}
                  field={`appointmentType`}
                  placeholder="Select Appointment Type"
                  label="AppointmentType"
                  containerClassName="mr"
                  name="AppoinmentType"
                  onChange={handleChangeData}
                >
                  {appoinment?.map((apptype) => (
                    <MenuItem value={apptype._id} selected={""}>
                      {apptype.title}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Fees
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Fees"
                  containerClassName="mr"
                  type="number"
                  className={classes.style_1}
                  name="fee"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appoinment Slot (in Minutes)
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  field="appointmentSlotDurationInMillis"
                  placeholder="Slots"
                  containerClassName="mr"
                  label="Slots"
                  type="number"
                  className={classes.style_1}
                  name="slot"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Days
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      From
                    </Typography>
                  </Box>
                  <Select
                    style={{ width: "100%" }}
                    required={true}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    options={weekDays}
                    placeholder="Select Day"
                    label="From"
                    name="FromDay"
                    onChange={handleChangeData}
                  >
                    {weekDays.map((weekDays) => (
                      <MenuItem value={weekDays} selected={""}>
                        {weekDays}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      To
                    </Typography>
                  </Box>
                  <Select
                    style={{ width: "100%" }}
                    required={true}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    options={weekDays}
                    placeholder="Select Day"
                    label="To"
                    name="toDay"
                    onChange={handleChangeData}
                  >
                    {weekDays.map((weekDays) => (
                      <MenuItem value={weekDays} selected={""}>
                        {weekDays}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Time
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      From
                    </Typography>
                  </Box>
                  <TextField
                    style={{ width: "100%" }}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    name="fromTime"
                    onChange={handleChangeData}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      To
                    </Typography>
                  </Box>
                  <TextField
                    style={{ width: "100%" }}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    field={`toSlot`}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    name="toTime"
                    onChange={handleChangeData}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Payment Type
                </Typography>
              </Box>

              <Grid>
                <Box
                  style={{
                    display: "grid",
                    gridGap: "10px",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {AllData?.map((item) => {
                    if (!item.status) return <></>;
                    return (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            paymentCheckHandle(e, item);
                          }}
                        />
                        <Typography>
                          {item?.paymentModeName === "UPI"
                            ? `${item?.paymentModeName} - ${
                                item?.description ?? ""
                              }`
                            : item?.paymentModeName}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Disclaimer
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  field="disclaimer"
                  placeholder="Disclaimer"
                  containerClassName="mr"
                  type="text"
                  className={classes.style_1}
                  name="disclaimer"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleSubmit}
              >
                {addlodingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "save"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {updateModelOpen && (
        <Modal
          open={updateModelOpen}
          onClose={updatehandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Update Availability
              </Typography>
              <Close
                onClick={updatehandleClose}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appoinment Type
                </Typography>
              </Box>
              <Grid>
                <Select
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth,
                    classes.style_1
                  )}
                  field={`appointmentType`}
                  placeholder="Select Appointment Type"
                  label="AppointmentType"
                  containerClassName="mr"
                  name="AppoinmentType"
                  onChange={handleChangeData}
                  value={apoinmentData.AppoinmentType}
                >
                  {appoinment?.map((apptype) => (
                    <MenuItem value={apptype._id} selected={""}>
                      {apptype.title}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Fees
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Fees"
                  containerClassName="mr"
                  type="number"
                  value={apoinmentData.fee}
                  className={classes.style_1}
                  name="fee"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appoinment Slot (in Minutes)
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  field="appointmentSlotDurationInMillis"
                  placeholder="Slots"
                  containerClassName="mr"
                  label="Slots"
                  type="number"
                  value={apoinmentData.slot}
                  className={classes.style_1}
                  name="slot"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Days
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      From
                    </Typography>
                  </Box>
                  <Select
                    style={{ width: "100%" }}
                    required={true}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    value={apoinmentData.FromDay}
                    placeholder="Select Day"
                    label="From"
                    name="FromDay"
                    onChange={handleChangeData}
                  >
                    {weekDays.map((weekDays) => (
                      <MenuItem value={weekDays} selected={""}>
                        {weekDays}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      to
                    </Typography>
                  </Box>
                  <Select
                    style={{ width: "100%" }}
                    required={true}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    value={apoinmentData.toDay}
                    placeholder="Select Day"
                    label="To"
                    name="toDay"
                    onChange={handleChangeData}
                  >
                    {weekDays.map((weekDays) => (
                      <MenuItem value={weekDays} selected={""}>
                        {weekDays}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Time
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      From
                    </Typography>
                  </Box>

                  <TextInput
                    style={{ width: "100%" }}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    value={apoinmentData.fromTime}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    name="fromTime"
                    onChange={handleChangeData}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box mt="0.5rem">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      To
                    </Typography>
                  </Box>
                  <TextInput
                    style={{ width: "100%" }}
                    className={clsx(
                      globalClass.roundWhiteBg,
                      globalClass.inputWidth
                    )}
                    containerClassName="mr"
                    field={`toSlot`}
                    value={apoinmentData.toTime}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    name="toTime"
                    onChange={handleChangeData}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Payment Type
                </Typography>
              </Box>
              <Grid>
                <Box
                  style={{
                    display: "grid",
                    gridGap: "10px",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {AllData?.map((item, index) => {
                    let savedPaymentModesId = uniqueData?.paymentIds;
                    let paymentTypeChecked = savedPaymentModesId.includes(
                      item?._id
                    );
                    if (!item.status) return <></>;
                    return (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={paymentTypeChecked}
                          onChange={(e) => paymentCheckHandleUpdate(e, item)}
                        />
                        <Typography>
                          {" "}
                          {item?.paymentModeName === "UPI"
                            ? `${item?.paymentModeName} - ${
                                item?.description ?? ""
                              }`
                            : item?.paymentModeName}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Disclaimer
                </Typography>
              </Box>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  field="disclaimer"
                  placeholder="Disclaimer"
                  containerClassName="mr"
                  type="text"
                  value={apoinmentData.disclaimer}
                  className={classes.style_1}
                  name="disclaimer"
                  onChange={handleChangeData}
                />
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Status
                </Typography>
              </Box>
              <Grid>
                <Box style={{ display: "flex", gridGap: "21px" }}>
                  <Box>
                    <RadioGroup
                      // defaultValue="elective"
                      // name="controlled-radio-buttons-group"
                      // name="surgeryType"
                      value={appointmentTypeStatus}
                      onChange={handleappointmentTypeStatus}
                      sx={{ my: 1 }}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="InActive"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
            </Box>

            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleUpdate}
              >
                {updateloadingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {openRemove && (
        <Modal
          open={openRemove}
          onClose={handleCloseRemove}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyleRemove}>
            <Box textAlign="center">
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Are you sure to remove.
              </Typography>
            </Box>

            <Grid container style={{ marginTop: "10%" }}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleCloseRemove}
                  style={{ marginTop: "2%", width: "95%" }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={handleRemove}
                  style={{ marginTop: "2%", width: "95%" }}
                >
                  {removeloadingStatus ? (
                    <Loader width="20px" height="20px" color="white" />
                  ) : (
                    "Remove"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Appointment_type;
