import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Checkbox,
  CircularProgress,
  TextField,
  Dialog,
} from "@material-ui/core";
import { useState } from "react";
import { Form, Scope } from "informed";
import useGlobalStyles from "../../../utils/global-style";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import VitalsDialog from "./vital-dialog";


import {
  TextInput,
  TextAreaInput,
  SelectInput,
  CustomDateInput,
} from "sixsprints-react-ui";
import { useCallback } from "react";
import clsx from "clsx";
import { useRef } from "react";
import { useEffect } from "react";
import Api2 from "../../../utils/api2";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    paddingTop: "1rem",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  input: {
    display: "none",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "90%",
    margin: "auto",
  },
  containerBox: {
    width: "100%",
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(5, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  reportTitle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReports: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reportContainer: {
    display: "flex",
  },
  closeTitle: {
    fontSze: "12px",
  },
  reportHeading: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  diagramContainer: {
    display: "flex",
  },
  diagramClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  closeTitle: {
    fontSze: "12px",
  },
  diagramImage: {
    height: "90%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },

  "prescription-details": {
    padding: "40px 30px",
    marginBottom: "2.5rem",

    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "20px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "20px" },
      },
    },

    "&__title": {
      color: "#1A1A1A",
      font: "bold 16px/18px Comfortaa",
      marginBottom: "8px",
    },

    "&__sub-title": {
      color: "#6D6D6D",
      font: "lighter 16px/18px Comfortaa",
      paddingBottom: "12px",
      borderBottom: "1px solid #E6E6E6",
    },
  },

  "prescription-qr": {
    textAlign: "center",
    padding: "20px 30px 30px",
    marginBottom: "2.5rem",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },

    "&__img": {
      maxWidth: "100%",
      margin: "-15px",
    },
  },
  vitals: {
    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "30px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "30px" },
      },
    },
  },
  otherEmailField: {
    width: "400px",
  },
  vitalSign: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "230px",
    },

    "& h6": {
      width: "40px",
    },

    "& .MuiPaper-root": {
      flex: 1,
    },
  },
  restrictionControlBox: {
    display: "flex",
    alignItems: "center",
  },
  clrBtnBox: {
    padding: 20,
  },

  imageDiv: {
    maxWidth: "100px",
    width: "100%",
    height: "100px",
    maxHeight: "100%",
    border: "1px solid black",
    borderRadius: "50%",
  },
}));

const Vitals = ({setSelectedVitals , selectedVitals}) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [prescription, setPrescription] = useState(null);
  const formApi = useRef();



  const [vitalEditMode, setVitalEditMode] = useState(false);
  const [vitalType, setVitalType] = useState(null);
  const [vitals, setVitals] = useState([]);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [templateMode, setTemplateMode] = useState(false);


  const selectedVitalsRef = useRef(selectedVitals);

  const config = JSON.parse(localStorage.getItem("CONFIG"))


  const onToggleVitalEditMode = (type) => {
    setVitalEditMode(!vitalEditMode);
    setVitalType(type);
  };

  // const onToggleTemplateMode = () => {
  //   setTemplateInfo(formApi.current.getValues());
  //   setTemplateMode(!templateMode);
  // };


  const getVital = useCallback((currentVital) => {
    const finalVitalObj = {};
    currentVital.map((vital) => {
      let newObj = {};
      newObj[vital.label] = vital.val;
      return Object.assign(finalVitalObj, newObj);
    });
    return { ...finalVitalObj };
  }, []);

  const handleChangeVital = useCallback((value, index, setVital) => {
    setVital((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[index].val = value;
      return [...newState];
    });
  }, []);

  
  
  useEffect(() => {
    setSelectedVitals(() => {
      const newVitals = vitals.filter(({ checked }) => checked);
      return [...newVitals];
    });
  }, [ vitals]);
  
  useEffect(() => {
    selectedVitalsRef.current = selectedVitals;
  }, [selectedVitals]);
  



  return (
    <Box className={classes.prescriptionSection}>
      <Box mt="2rem" display="flex" justifyContent="space-between">
        <Typography variant="h6" className={classes.prescriptionTitle}>
          Vitals
        </Typography>

        {
          selectedVitals.length > 0 &&
          <FormControlLabel
            control={
              <Switch
                checked={vitalEditMode}
                onChange={() => {
                  onToggleVitalEditMode("vitals");
                }}
                color="primary"
              />
            }
            label="Edit"
          />
        
          }
      </Box>

      
      { 
        selectedVitals.length > 0 ? (
          <Scope scope="vitals">
            <Grid container>
              {selectedVitals.map((vital, index) => (
                <Grid
                  item
                  sm={4}
                  key={index}
                  className={`${classes.vitals}__column`}
                >
                  <Box className={classes.vitalSign}>
                    <TextInput
                      className={globalClass.roundWhiteBg}
                      label={vital.label}
                      field={vital.label}
                      value={vital.val}
                      onChange={(e) =>
                        handleChangeVital(
                          e.target.value,
                          index,
                          setSelectedVitals
                        )
                      }
                      validateOnBlur
                      validateOnChange
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Scope>
        ): (
        <Paper className={clsx(classes.container, globalClass.roundWhiteBg)}>
          <Box mr={1} display="inline-block">
            <Typography className={globalClass.greyFont} variant="body1">
              No Vitals Available
            </Typography>
          </Box>

          <IconButton
            color="primary"
            aria-label="add vital"
            size="small"
            onClick={() => onToggleVitalEditMode("vitals")}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Paper>
      )}

      <VitalsDialog
        handleClose={() => {
          onToggleVitalEditMode(vitalType);
        }}
        open={vitalEditMode}
        type={vitalType}
        selectedVitals={vitalType === "vitals" ? vitals : ""}
        updateVitalsCallback={vitalType === "vitals" ? setVitals : ""}
      />
    </Box>
  );
};

export default Vitals;
