import React, { useState } from "react";
import {
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TableCell,
} from "@material-ui/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SendIcon from "@material-ui/icons/Send";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { getFormattedDate } from "../../../utils/app-util";
import { useRecoilValue } from "recoil";

import AuthService from "../../../utils/services/auth-service";
import OverFlowMenu from "../../../components/layout/menu";
import AppConstants from "../../../utils/app-constants";
import isAuthorised, { isCurrentRole } from "../../../utils/role-util";
import { AppointmentsStatusState } from "../../../states";
import Label from "../../../components/layout/label";
import Routes from "../../../routes/routes";

import "./appointments-card.scss";

export const AppointmentsCards = ({
  row,
  index,
  onReviewBtnClicked,
  handleChangeStatus,
  onPrintLetterHeadClicked,
  onCallNextBtnClicked,
  onViewPrescriptionClicked,
  onQueueUpdateClicked,
  onAddDiscountClicked,
  onPrintReceiptClicked,
  handleOpenPrescription,
  onViewEMRClicked,
  onNotifyClicked,
}) => {
  let appointmentType = row.type;
  if (row.type === "" || row.type === undefined) {
    if (row.appointmentTypeId === "639086abb834da06eb4a8034") {
      appointmentType = "FIRST_VISIT";
    } else if (row.appointmentTypeId === "639086b9b834da06eb4a8036") {
      appointmentType = "FOLLOW_UP";
    } else if (row.appointmentTypeId === "639086cdb834da06eb4a803d") {
      appointmentType = "AUDIO";
    } else if (row.appointmentTypeId === "639086dab834da06eb4a803f") {
      appointmentType = "VIDEO";
    } else if (row.appointmentTypeId === "6392fcf77e2a9b2e89f61110") {
      appointmentType = "GENERAL";
    } else if (row.appointmentTypeId === "6392fd117e2a9b2e89f61111") {
      appointmentType = "MINOR_SURGERY";
    } else if (row.appointmentTypeId === "6392fd287e2a9b2e89f61112") {
      appointmentType = "DRESSING";
    } else if (row.appointmentTypeId === "6392fd3c7e2a9b2e89f61113") {
      appointmentType = "ACTIVE";
    } else if (row.appointmentTypeId === "6392fd797e2a9b2e89f61114") {
      appointmentType = "REPORT_REVIEW";
    }
  }
  const [status, setStatus] = useState({});
  const [number, setNumber] = useState();

  const appointmentStatus = useRecoilValue(AppointmentsStatusState);

  const formatTime = (timeString) => {
    if (!timeString) {
      return "N/A";
    }
    return timeString.lastIndexOf(":") === 5
      ? timeString.substring(0, 5)
      : timeString;
  };

  const config = AuthService.getConfig();

  const moreItems = [
    {
      label: "View EMR",
      onClick: onViewEMRClicked,
      isVisible: isCurrentRole(AppConstants.ROLE.DOCTOR),
    },
    {
      label: "View Prescription",
      onClick: onViewPrescriptionClicked,
      isVisible: true,
    },
    { label: "Add Discount", onClick: onAddDiscountClicked, isVisible: true },
    { label: "Print Receipt", onClick: onPrintReceiptClicked, isVisible: true },
    // {
    //   label: 'Print LetterHead Only',
    //   onClick: onPrintLetterHeadClicked,
    //   isVisible: true,
    // },
    { label: "Notify", onClick: onNotifyClicked, isVisible: true },
  ];

  const checkChangedToConfirm = (row) => {
    return (
      appointmentStatus[row.slug] && appointmentStatus[row.slug] === "CONFIRMED"
    );
  };

  const getLabel = (row) => {
    if (
      appointmentStatus[row.slug] &&
      appointmentStatus[row.slug] === "CONFIRMED"
    ) {
      return row.labels?.filter(({ label }) => label !== "Called");
    }
    return row.labels;
  };

  const handleChangeQueue = (e) => {
    const queue = e.target.value;
    setNumber(queue);
    onQueueUpdateClicked(e, row);
  };

  return (
    <div
      className={`appointments-card ${
        row.called ? (checkChangedToConfirm(row) ? "" : "called-card") : ""
      }`}
    >
      <div className="appointments-card__header">
        <div className="card-queue">
          <input
            className="queue-field"
            // value={(row.queue === 0 ? "NA" : row.queue)}
            value={number ?? (row.queue === 0 ? "NA" : row.queue)}
            onChange={handleChangeQueue}
          />
        </div>
        <div className="card-right">
          <div className="card-action">
            <FormControl variant="outlined" className="action-select">
              <InputLabel htmlFor="filled-age-native-simple">Action</InputLabel>
              <Select
                id="demo-simple-select-outlined"
                style={{ width: 180 }}
                required
                value={status[row.slug] ?? row.status}
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    [row.slug]: e.target.value,
                  }));
                  handleChangeStatus(e, row.slug, index);
                }}
                label="Age"
              >
                <MenuItem value={"CONFIRMED"}>CONFIRMED</MenuItem>
                {config.appointmentStatus?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="card-menu">
            <OverFlowMenu options={moreItems} appointmentObj={row} />
          </div>
        </div>
      </div>
      <div className="appointments-card__body">
        <div className="data">
          <div className="card-item">
            <div className="label">Slot (Date & Time)</div>
            <div className="text" style={{ display: "flex" }}>
              {getFormattedDate(row.date, "DD MMM")}, {formatTime(row.time)}
            </div>
          </div>
          <div className="card-item">
            <div className="label">Patient Name</div>
            <div className="text" style={{ display: "flex" }}>
              {row?.patient?.name ?? ""}
            </div>
          </div>
        </div>
        <div className="data">
          <div className="card-item">
            <div className="label">Consulting Doctor</div>
            <div className="text" style={{ display: "flex" }}>
              {row?.doctor?.name ?? ""}
            </div>
          </div>
          <div className="card-item">
            <div className="label">Appointment Type</div>
            <div className="text" style={{ display: "flex" }}>
              {appointmentType ?? ""}
            </div>
          </div>
        </div>
        <div className="data">
          <div className="card-item">
            <div className="label">Mobile Number</div>
            <div className="text" style={{ display: "flex" }}>
              {row?.patient?.mobileNumber ?? ""}
            </div>
          </div>
          <div className="card-item">
            <div className="label">UID Number</div>
            <div className="text" style={{ display: "flex" }}>
              {row?.patient?.uhid ?? ""}
            </div>
          </div>
        </div>
        <div className="data-1">
          <TableCell style={{ border: "none" }}>
            <Label statusList={getLabel(row)} />
          </TableCell>
        </div>
      </div>
      <div className="appointments-card__footer">
        <div className="icon-group">
          <Tooltip title="Report" aria-label="report">
            <IconButton
              className="icon"
              onClick={() => onReviewBtnClicked(row.patientSlug)}
            >
              <PostAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Consult" aria-label="consult">
            <IconButton
              className="icon"
              disabled={row.status === "NO_SHOW" || row.status === "CANCELLED"}
              onClick={() => handleOpenPrescription(row.slug)}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </div>
        {isAuthorised(AppConstants.ENTITY.CALL_NEXT) &&
          isAuthorised(AppConstants.ENTITY.CONSULTATION) && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                row.status === "NO_SHOW" ||
                row.status === "CANCELLED" ||
                (row.called && !checkChangedToConfirm(row))
              }
              className="call-btn"
              endIcon={<SendIcon />}
              onClick={() => onCallNextBtnClicked(row.slug)}
            >
              Call Next
            </Button>
          )}
      </div>
    </div>
  );
};
