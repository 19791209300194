import Bro from "brototype";
import { Form } from "informed";
import React, { Component } from "react";
import Api from "../../utils/api";
import { TextInput } from "sixsprints-react-ui";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import clsx from "clsx";
import Axios from "axios";

const TYPES = {
  TEXT: "text",
  NUMBER: "number",
  IMAGE: "IMAGE"
};

const OPTIONS = {
  BOOLEAN: [
    { label: "SELECT", value: "_" },
    { label: "TRUE", value: true },
    { label: "FALSE", value: false }
  ]
};

const DATE_FORMAT = "dd/MM/yyyy";

class CustomEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploaded: false,
      val: props.currentValue === undefined ? "" : props.currentValue,
      autoCompleteLoading: true,
      allSpecialityData: [],
    };
    this.ref = null;
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      if (this.ref) {
        this.ref.select();
      }
    }, 1);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  

   
  handleClick = data => {
    const { context, headerName } = this.props;
    context.onConfirmBtnClicked(data, headerName);
  };

  onUpload = resp => {
    SHOW_SUCCESS_NOTIFICATION("Successfully uploaded");
    this.setState({ val: resp.data.data, isUploaded: true });
  };

  handleDateChange = date => {
    if (moment(date).isValid()) {
      const timestamp = moment(date).valueOf();
      this.setState({ val: timestamp });
    }
  };

  handleChange = event => {
    if (event.target.value !== "_") {
      let val =
        Bro(event).iCanHaz("target.value") ||
        Bro(event).iCanHaz("value") ||
        event;
      this.setState({ val });
    }
  };



  getValue = () => {
    let { field, system, customValueParser, ...rest } = this.props;
    const { val } = this.state;
    if (customValueParser) {
      rest.newValue = val || "";
      customValueParser(rest, field, system);
    }
    return val;
  };

  getOptions = () => {
    const { options } = this.state;
    if (options) {
      return;
    }
    let { collectionName, data } = this.props;

    let url = `${collectionName}/search-all`;
    let obj;
    if (collectionName === "city") {
      url = `city/search-all`;
      obj = {
        filterModel: {
          countryName: {
            filterType: "exact",
            filter: data.countryName
          }
        }
      };
    } else {
      obj = {};
    }

    url = encodeURI(url);
    Api.post(url, obj).subscribe(resp => {
      this.setState({ autoCompleteLoading: false, options: resp.data.data });
    });
  };

  getComponent = type => {

    const { val } = this.state;
    if (type === "ROLE" || type === "BOOLEAN") {
      return (
        <select value={val} onChange={this.handleChange}>
          {(OPTIONS[type] || []).map((op, idx) => {
            return (
              <option key={idx} value={op.value}>
                {op.label}
              </option>
            );
          })}
        </select>
      );
    }

    if (type === "DATE") {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Select Date"
            format={DATE_FORMAT}
            value={val || new Date()}
            onChange={this.handleDateChange}
          />
        </MuiPickersUtilsProvider>
      );
      // <InputMask
      //   onChange={e => {
      //     let value = e.target.value;
      //     if (!moment(value, DATE_FORMAT).isValid()) {
      //       return;
      //     }
      //     value = moment(e.target.value, DATE_FORMAT)
      //       .toDate()
      //       .getTime();
      //     this.handleChange(value);
      //   }}
      //   mask="99-99-9999"
      //   defaultValue={getFormattedDate(val, DATE_FORMAT)}
      // />
    }

    // if (type === "IMAGE") {
    //   return (
    //     <div className="text-center">
    //       <img
    //         className="mr-4"
    //         src={val || AppConstants.ICON.IMAGE}
    //         width="50"
    //         alt="logo"
    //       />

    //       {!isUploaded && (
    //         <FileUpload
    //           className="btn upload-btn"
    //           onUpload={this.onUpload}
    //           url="cloud/upload/"
    //           text="upload.key"
    //         />
    //       )}
    //     </div>
    //   );
    // }

    if (type === "Button") {
      return (
        <button onClick={this.handleClick.bind(this, val)} component="Button">
          View
        </button>
      );
    }

    if (type === "AUTO_COMPLETE" || type === "SELECT") {
      const { options, autoCompleteLoading } = this.state;
      const { columnName } = this.props;
      if (autoCompleteLoading) {
        return "";
      }

      return (
        <select value={val} onChange={this.handleChange}>
          {(options || []).map((op, idx) => {
            return (
              <option key={idx} value={op[columnName]}>
                {op[columnName]}
              </option>
            );
          })}
        </select>
      );
    }

    if (type === "ENUM") {
      const { allValues } = this.props;
      return (
        <select value={val} onChange={this.handleChange}>
          <option value="_">SELECT</option>
          {(allValues || []).map((op, idx) => {
            return (
              <option key={idx} value={op._id ? op._id : op}>
                {op.name ? op.name : op}
              </option>
            );
          })}
        </select>
      );
    }


    return (
      <Form initialValues={{ val }}>
        <TextInput
          field="val"
          type={"text"}
          onChange={this.handleChange}
          className={clsx("full-w", "m-0")}
          size="small"
          // hideLabel
        />
      </Form>
    );
  };

  render() {
    const { dataType = "TEXT" } = this.props;
    const type = TYPES[dataType] || dataType;
    if (dataType === "SELECT") {
      this.getOptions();
    }
    return this.getComponent(type);
  }
}

export default CustomEditor;
