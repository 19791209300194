import { NotificationManager } from "react-notifications";
import Bro from "brototype";
import AppConstants from "./app-constants";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const SCROLL_TO_TOP = () => {
  window.scrollTo(0, 0);
};

export const DEFAULT_ERROR_CALLBACK = (error) => {
  NotificationManager.error(
    Bro(error).iCanHaz("response.data.message") ||
    AppConstants.MSG.GENERIC_ERROR,
    "",
    8000
  );
};
export const getFormattedDate = (date, format) => {
  return moment(date).format(format || "DD MMM YYYY");
};

export const getFormattedTime = (time, format) => {
  return moment(time, "HH:mm").format(format || "hh:mm a");
};

export const getIncrementedTime = (time, value) => {
  return moment(time, "HH:mm").add(value, "milliseconds").format("hh:mm a");
};

export const getStringFromArray = (array) => {
  return array.join("||");
};

export const SHOW_ERROR_NOTIFICATION = (error) => {
  NotificationManager.error(error, "", 8000);
};

export const SHOW_INFO_NOTIFICATION = (msg, time = 8000) => {
  NotificationManager.info(msg, "", time);
};

export const SHOW_SUCCESS_NOTIFICATION = (msg) => {
  NotificationManager.success(msg, "", 8000);
};

export const convertToSlug = (text) => {
  return text
    ?.toLowerCase()
    ?.replace(/ /g, "-")
    ?.replace(/[^\w-]+/g, "");

};

export const getDateDiff = (from, to) => {
  return to - from >= 0;
};

export const checkvalidTime = (date, from) => {
  let curDate = moment().format("YYYY/MM/DD");
  let fromDate = moment(date).format("YYYY/MM/DD");
  let isToday = curDate === fromDate;
  const [currentHrs] = moment().format("HH:mm").split(":");
  const [fromHrs] = from.split(":");

  if (isToday) {
    if (parseInt(fromHrs) >= parseInt(currentHrs)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getTimeDiff = (from, to) => {
  let returnStatus = false;
  const [fromHrs, fromMin] = from.split(":");
  const [toHrs, toMin] = to.split(":");
  if (parseInt(fromHrs) > parseInt(toHrs)) {
    returnStatus = false;
  } else {
    if (parseInt(toHrs) > parseInt(fromHrs)) {
      returnStatus = true;
    } else {
      if (parseInt(toMin) > parseInt(fromMin)) {
        returnStatus = true;
      } else {
        returnStatus = false;
      }
    }
  }
  return returnStatus;
};

export const getRoundToMinutes = () => {
  const start = moment();
  const remainder = 10 - (start.minute() % 10);

  const time = moment(start).add(remainder, "minutes").format("HH:mm");
  return time;
};

export const getTransformArr = (arr) => {
  let transformArr = arr?.map((data) => {
    return {
      appointmentDate: data.dateTime,
      appointmentLink: data.appointmentPdf,
      appointmentSlug: data.slug,
    };
  });
  return transformArr;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
    );
};

export const validatePhone = (phone) => {
  return String(phone).match(
    /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
  );
};

export const validatePassword = (password) => {
  return String(password).match(/^[A-Za-z0-9]{4,15}$/);
}

export const generateSpecialityFilter = (speciality) => {
  const currentSpeciality = convertToSlug(speciality);
  const isIncludes = currentSpeciality?.includes("--");
  const otherSpeciality = isIncludes && currentSpeciality.replace("--", "-");
  return isIncludes
    ? [currentSpeciality, otherSpeciality]
    : [currentSpeciality];
};

export const getDateTimeStamp = (date, time) => {
  let dateValue = moment(date).format("YYYY-MM-DD");
  let newValue = moment(dateValue + " " + time);
  return moment(newValue).valueOf();
};

export const convertMilisecondToMinute = (value) => {
  let minVal = value / (60 * 1000);
  return minVal;
};

export const convertMinuteToMilliseconds = (value) => {
  let milVal = value * (60 * 1000);
  return milVal;
};

export const transformMapToArr = (mapVal) => {
  let arr = [];
  let keys = Object.keys(mapVal);
  let values = Object.values(mapVal);
  for (let index = 0; index < keys.length; index++) {
    let obj = {};
    let [fromDay, toDay] = keys[index].split("-");
    let [fromSlot, toSlot] = values[index].split("-");
    obj.fromSlot = fromSlot.trim();
    obj.toSlot = toSlot.trim();
    obj.fromDay = fromDay.trim();
    obj.toDay = toDay ? toDay.trim() : "";
    arr.push(obj);
  }
  return arr;
};

const getAvailSummary = (arrObj, type) => {
  let availSummary = {};
  for (let i = 0; i < arrObj.length; i++) {
    let key = arrObj[i].toDay
      ? `${arrObj[i].fromDay} - ${arrObj[i].toDay}`
      : `${arrObj[i].fromDay}`;
    if (arrObj[i].appointmentType === type) {
      availSummary[key] = `${arrObj[i].fromSlot} - ${arrObj[i].toSlot}`;
    }
  }
  return availSummary;
};

export const transformArrToMap = (arrObj) => {
  let result = arrObj.reduce(function (map, obj) {
    let keyType = obj.appointmentType;
    // let key = obj.toDay ? `${obj.fromDay} - ${obj.toDay}` : `${obj.fromDay}`;
    map[keyType] = {
      availabilitySummary: getAvailSummary(arrObj, obj.appointmentType),
      fees: obj.fees,
      appointmentSlotDurationInMillis: convertMinuteToMilliseconds(
        obj.appointmentSlotDurationInMillis
      ),
    };
    return map;
  }, {});
  return result;
};

export const validateAvailSummary = (row) => {
  let count = 1;
  let set = new Set();
  for (let i = 0; i < row.length; i++) {
    set.add(`${row[i].fromDay} - ${row[i].toDay}`);
  }

  for (let i = 0; i < row.length; i++) {
    if (!row[i].fromDay) {
      SHOW_INFO_NOTIFICATION("From cannot be blank in availability summary");
      count = 0;
      return;
    }


    if (!row[i].fromSlot) {
      count = 0;
      SHOW_INFO_NOTIFICATION(
        "FromSlot cannot be blank in availability summary"
      );
      return;
    }

    if (!row[i].toSlot) {
      count = 0;
      SHOW_INFO_NOTIFICATION("ToSlot cannot be blank in availability summary");
      return;
    }

    if (row[i].fromSlot === row[i].toSlot) {
      count = 0;
      SHOW_INFO_NOTIFICATION(
        "FromSlot and ToSlot cannot be same in availability summary"
      );
      return;
    }
  }
  return count ? true : false;
};

export const validatePaymentOptions = (row) => {
  let count = 1;

  let set = new Set();
  for (let i = 0; i < row.length; i++) {
    set.add(`${row[i].payTo} - ${row[i].description}`);
  }

  for (let i = 0; i < row.length; i++) {
    if (!row[i].payTo) {
      SHOW_INFO_NOTIFICATION("PayTo cannot be blank in Payment Options");
      count = 0;
      return;
    }

    if (!row[i].description) {
      SHOW_INFO_NOTIFICATION("Type cannot be blank in Payment Options");
      count = 0;
      return;
    }

    if (set.size !== row.length) {
      count = 0;
      SHOW_INFO_NOTIFICATION(
        "Duplicate payTo and payment type are not allowed in payment Options"
      );
      return;
    }
  }
  return count ? true : false;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
