import Api from "../utils/api";
import Api2 from "../utils/api2";
import { firstValueFrom } from "rxjs";

export const DoctorAPI = {
  getAllDoctors: async function () {
    try {
      const response = await firstValueFrom(Api2.get("/getdoctor?active=true"));
      return response.data;
    } catch (error) {
      console.error("Error fetching doctors:", error);
      // TODO: check if needed to throw the error
      throw error;
    }
  },

  getSharedAccessInfoOfADoctor: async function () {
    try {
      const response = await firstValueFrom(Api.get("share-access"));
      return response.data;
    } catch (error) {
      console.error("Error fetching shared-access info of a doctor:", error);
      throw error;
    }
  },

  updateDoctorsCurrentStatus: async function (updatedStatus) {
    try {
      const response = await firstValueFrom(Api.put("doctor/current-status", updatedStatus))
      return response.data;
    } catch (error) {
      console.error("Error updating doctors current status: ", error);
    }
  },
};
