const Routes = {
  LOGIN: "/login",
  FORGETPASSWORD: "/forgetpassword",
  SEARCHFUNCTION: "/app/search",
  SCANNER: "/qr-scanner",
  QR_STRING: "/app/qr-string",
  MAIN: "/app",
  DASHBOARD: "/app/dashboard",
  ACTIVITYREGISTRY:"/app/activityregistry",
  ADMITEDPROFILE:"/app/admittedprofile",
  BILLING:"/app/billing",
  NEWREPORT:"/app/newReport",
  COMPARE:"/app/compare",
  VIEWFOLDER:"/app/viewfolder",
  VISITREQUEST:"/app/visitRequest",
  FILELIST:"/app/filelist",
  APPOINTMENT: "/app/appointment",
  APPOINTMENTPRINT: "/app/appointment/print",
  APPOINTMENTPRINTHEAD: "/app/appointment/printletter",
  RECEIPTPRINT: "/app/appointment/receiptprint",
  NEW_APPOINTMENT: "/app/appointment/new",
  UPDATE_APPOINTMENT: "/app/update_appointment/update",
  PATIENT: "/app/patient",
  NEW_PATIENT: "/app/patient/new",
  PRESCRIPTION: "/app/patient/prescription",
  REPORT: "/app/patient/report",
  DOCTOR: "/app/staff",
  NEW_DOCTOR: "/app/doctor/new",
  REFERAL_DOCTOR: "/app/referral-doctors",
  CREATE_REFERAL_DOCTOR: "/app/referral-doctors/new",
  EMR: "/app/emr-template",
  MEDICATION: "/app/medication",
  NEW_MEDICATION: "/app/medication/new",
  NEW_EMR: "/app/emr-template/new",
  UPDATE_EMR: "/app/emr-template/template",
  PAYMENT: "/app/payment",
  SPECIALIST: "/app/specialist",
  CHAT: "/app/chat",
  CHAT_SESSION: "/app/chat-session",
  CHAT_PACKAGE: "/app/chat-package",
  CHATIMAGE: "/app/chat-image",
  NEW_CHAT_PACKAGE: "/app/chat-package/new",
  PROFILE: "/app/profile",
  OTP: "/app/otp",
  SETTINGS: "/app/settings",
  IPDREQUEST:"/app/Ipdrequest",
  Appointment_type: "/app/appointment_type",
  Customerstatus: "/app/customer-status",
  Specialists: "/app/specialists",
  Quelists: "/app/que_system",
  Payment_option: "/app/payment_option",
  Social_media: "/app/social-media",
  Template: "/app/templates",
  Website: "/app/website",
  Aboutus: "/app/aboutus",
  Banner: "/app/banner",
  Department: "/app/department",
  Precription: "/app/precription",
  Patients: "",


  //new routes
  ActivePatient: "/app/active-patient",
  TREATMENTSUMMERY: "/app/treatmentsummary"

};

export default Routes;
