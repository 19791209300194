import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import AuthService from "../../utils/services/auth-service";
import { DEFAULT_ERROR_CALLBACK, SHOW_ERROR_NOTIFICATION, } from "../../utils/app-util";
import AppConstants from "../../utils/app-constants";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import Loader from "../../components/misc/loader";
import { useSetRecoilState } from "recoil";
import { NotificationManager } from "react-notifications";
import { LoginState } from '../../states'

import "./login-page.scss"
import "../../../node_modules/react-phone-input-2/lib/style.css"
import Otpforgetpassword from "./otp-forgetpassword"

const Forgetpassword = () => {
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inValidPhone, setInValidPhone] = useState(false);
  const [inValidPassword, setInValidPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [inValidEmail, setInvalidEmail] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [pslug, setpslug] = useState();





  const setLoginCreds = useSetRecoilState(LoginState);

  const history = useHistory();

  // regex for the email id
  useEffect(() => {
    if (email) {
      const isValidEmail = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      isValidEmail ? setInvalidEmail(false) : setInvalidEmail(true)
    }
  }, [email])

  const LoginThroughPassword = () => {
    setIsLoading(true);
    const payload = {
      mobileNumber: mobileNumber,
      password: password,
      countryCode: countryCode,
    };
    AuthService.login(payload).subscribe(
      () => {
        history.replace(Routes.MAIN);
        setIsLoading(false);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setIsLoading(false);
      }
    );

  };
  const onQrScanBtnClicked = () => {
    history.push(Routes.FORGETPASSWORD);
  };

  const sendOtpToUser = () => {
    const payload = {
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
      password: "",

    };
    AuthService.sendOtp(payload).subscribe(
      (resp) => {



        NotificationManager.success('OTP Sent Successfully', "", 5000);
        setpslug(resp.data.data.slug);
        setTimeout(() => {

          // setOpenOtpPage(true);
        }, 2000);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const sendOtpToUserForget = () => {
    setIsLoading(true);
    const payload = {
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
      password: "",
      type: "forgotPassword",
    };
    AuthService.sendOtp(payload).subscribe(
      (resp) => {

        setpslug(resp?.data?.slug)
        NotificationManager.success('OTP Sent Successfully', "", 5000);
        setIsLoading(false);
        setTimeout(() => {
          setOpenOtpPage(true);
        }, 2000);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setIsLoading(false);
      }
    );
  };
  // const handleGoBack = () => {
  //   setOpenOtpPage(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginCreds({
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
    })
    if (!otpLogin) {
      if (mobileNumber && password) {
        !isLoading && LoginThroughPassword();
      } else {
        !mobileNumber && setInValidPhone(true)
        !password && setInValidPassword(true)
      }
    }
    // if (mobileNumber || (email && !inValidEmail)) {
    if (mobileNumber && (email && !inValidEmail)) {
      sendOtpToUserForget();
    } else {
      !mobileNumber && setInValidPhone(true);
      !email && setInvalidEmail(true);
    }
  };

  const handleChangePhoneNumber = (value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setInValidPhone(false);
  };

  const handleChangePassword = (event) => {
    setInValidPassword(false);
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const switchLoginForm = () => {
    setOtpLogin(!otpLogin);
  };

  const [status, setStatus] = useState()
  const [pass, setpass] = useState();

  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });


  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };


  const validation = async (event) => {
    event.preventDefault();

    let inputError = {
      password: "",
      confirmPassword: "",
    };

    if (formInput.confirmPassword !== formInput.password) {
      setFormError({
        ...inputError,
        confirmPassword: "Password and confirm password should be same",
      });
      return;
    }

    if (!formInput.password) {
      setFormError({
        ...inputError,
        password: "Password should not be empty",
      });
      return
    }
    setFormError(inputError);


    setIsLoading(true);

    const formData = new FormData();
    formData.append("slug", pslug);
    formData.append("newPassword", formInput.password);

    if (pass === "") {
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/change_user_password`,
          data: formData,
          headers: { "Content-Type": "application/json" },
        });
        if (response) {
          if (response.data.message.toString() === "Updated Successfully!") {
            NotificationManager.success(response.data.message);
            setIsLoading(false);
            history.replace(Routes.MAIN)
          } else {
            NotificationManager.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          NotificationManager.error(response.data.message);
        }

      } catch (error) {
        console.log(error);
      }
    }
  }


  return (
    <>
      <div className='login-page'>
        <div className='login-page__poster'></div>
        <div className='login-page__login'>

          <div className='login-page__login-box'>
            <img className="login-page__logo" src="https://storage.googleapis.com/eclinic_assets/tenants/jyotihospital/logo.png" alt="" />
          </div>
          <div className='login-page__login-box'>
            <div className='login-page__headline'>Verify Your Number</div>
            <div className='login-page__text-content'>
              Continue with your mobile number as you proceed to forget password
            </div>
          </div>

          {!openOtpPage ? (
            <form
              className='login-page__login-box login-page__login-form'
              onSubmit={handleSubmit}
              setOpenOtpPage={setOpenOtpPage}
            >
              <div className='login-page__form-row'>
                <label className='login-page__label' htmlFor='phone-input'>
                  Mobile Number (Required)
                </label>
                <PhoneInput
                  country={"in"}
                  enableSearch
                  inputClass='login-page__phone-input'
                  id='phone-input'
                  countryCodeEditable={false}
                  placeholder='Enter Mobile'
                  onChange={handleChangePhoneNumber}

                />
                {inValidPhone && (
                  <span className='login-page__validation-error'>
                    Phone Number is required
                  </span>
                )}
              </div>

              <div className='login-page__form-row'>
                <label className='login-page__label' htmlFor='email-input'>
                  {/* Email Id (Optional) */}
                  Email Id
                </label>
                <input
                  id='email-field'
                  type='email'
                  // required
                  className='login-page__password-input'
                  placeholder='Enter Email'
                  onChange={handleChangeEmail}
                  value={email}
                />

              </div>
              <button
                className='login-page__login-btn login-page__form-row'
                type='submit'
              >
                {isLoading ? (
                  <Loader color={"#fff"} size={"35px"} />
                ) : otpLogin ? (
                  "Send Otp"
                ) : (
                  "Send Otp"
                )}
              </button>

            </form>
          ) :
            verifyStatus ?

              (
                <form
                  className='login-page__login-box login-page__login-form'
                  onSubmit={validation}

                >
                  <div className='login-page__form-row'>
                    <label className='login-page__label' htmlFor='phone-input'>
                      New Password
                    </label>
                    <input
                      id=''
                      type='password'
                      name="password"
                      className='login-page__password-input'
                      placeholder='Enter Password'
                      value={formInput.password}
                      onChange={(e) => handleUserInput(e.target.name, e.target.value)}

                    />

                    <span className='login-page__validation-error'>
                      {formError.password}
                    </span>

                  </div>
                  <div className='login-page__form-row'>
                    <label className='login-page__label' htmlFor='password-field'>
                      Confirm Password
                    </label>
                    <input
                      id=''
                      type='password'
                      value={formInput.confirmPassword}
                      name="confirmPassword"
                      className='login-page__password-input'
                      placeholder='Enter confirm password'
                      onChange={(e) => handleUserInput(e.target.name, e.target.value)}

                    />
                    <span className='login-page__validation-error'>
                      {formError.confirmPassword}
                    </span>
                    {status === "password must be matched" && (
                      <span className='login-page__validation-error'>
                        password must be matched
                      </span>
                    )}


                  </div>


                  <button
                    className='login-page__login-btn login-page__form-row'
                    type='submit'
                  >
                    {isLoading ? <Loader width="200px" height="20px" color="white" /> : "Submit"}
                  </button>
                </form>
              )
              :
              <Otpforgetpassword setOpenOtpPage={setOpenOtpPage} mobileNumber={mobileNumber} countryCode={countryCode} setVerifyStatus={setVerifyStatus} />
          }

        </div>
      </div>
    </>
  )
}

export default Forgetpassword