import React, { useEffect, useState } from "react";
import GenericGrid from "../../components/grid/generic-grid";
import PatientColumn from "./patient-column";
import useGlobalStyles from "../../utils/global-style";
import PrespHistoryDialog from "./prescription-history-dialog";
import { useHistory } from "react-router-dom";
import Api from "../../utils/api";
import Routes from "../../routes/routes";
import Axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Box, TablePagination,
  makeStyles,
  CircularProgress,
  Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    width: "80%",
    height: "auto",
    //overflow: 'scroll',
  },
  searchBar: {
    marginBottom: 12,
    border: "1px solid gray",
    outline: "none",
  },
  container: {
    width: "78%%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
    height: "80vh",
    position: "absolute",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "20px",
    marginTop: "15px",
  },
  striky: {
    position: "-webkit-sticky",
    position: "sticky",
    zIndex: 5,
  },
}));

const Patient = () => {
  const classes = useStyles();
  const history = useHistory("");
  const globalClasses = useGlobalStyles();
  const [toggle, setToggle] = useState(false);
  const [patientObj, setPatientObj] = useState({});
  const [allData, setAllData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // for switched profile
  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);
  const primaryProfileInfo = useSelector(state => state?.switchProfile?.primaryProfileInfo);
  const switchedProfileInfo = useSelector(state => state.switchProfile?.switchedProfileInfo);

  const onToggleMode = () => {
    setToggle(!toggle);
  };

  const [counts, setCounts] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCounts((prev) => prev + 0);
    setPage(0);
  };

  const onEyeBtnClicked = (val) => {
    setPatientObj(val);
    onToggleMode();
  };

  const onChatBtnClick = (val) => {
    Api.get(`chat-session/patient/session?patientSlug=${val.slug}`).subscribe(
      (resp) => {
        if (resp) {
          history.push({
            pathname: Routes.CHAT,
            state: resp,
          });
        } else {
        }
      }
    );
  };

  const onLogButtonClicked = (val) => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_all_logs?slug=${val.slug}&page=${page}&size=${rowsPerPage}`
    ).then((resp) => {
      if (resp) {
        setAllData(resp?.data.data);
      }
    });

    handleOpen();
  };

  const onMailButtonClicked = (val) => {
    // const res=Axios.post(`${process.env.REACT_APP_API2_HOST}send_custom_msg?slug=${val?.slug}`)
    // setPatientObj(val);
  };

  const user = JSON.parse(localStorage.getItem("USER"));
  const [defaultFilter, setDefaultFilter] = useState({});

  useEffect(() => {
    if (user.roleName === "DOCTOR_ADMIN" || user.roleName === "DOCTOR") {
      const currentDoctorSlug = isPrimaryProfile
        ? primaryProfileInfo?.slug
        : switchedProfileInfo?.doctorSlug;
      setDefaultFilter({
        custom: {
          filterType: "exact",
          filter: `doctorPatients::slug::${currentDoctorSlug}`,
        },
      })
      
    } else {
      setDefaultFilter({})
    }
  }, [isPrimaryProfile]);

  const formatTime = (timeString) => {
    const dat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timeString);
    const vad = format(new Date(dat), "dd-MM-yyyy");
    if (!timeString) {
      return "N/A";
    }

    return vad;
  };

  return (
    <>
      <div className={globalClasses.addSpacing}>
        <GenericGrid
          baseUrl="patient"
          hideExport={false}
          onEyeBtnClicked={onEyeBtnClicked}
          onChatBtnClick={onChatBtnClick}
          onLogButtonClicked={onLogButtonClicked}
          onMailButtonClicked={onMailButtonClicked}
          hideOnUpload={false}
          hideOnCopy={true}
          LogButton={true}
          csvName="patient"
          hideDelete={false}
          columnDefGenerator={PatientColumn}
          title="Patient"
          defaultFilter={defaultFilter}
          key={isPrimaryProfile
            ? primaryProfileInfo?.slug
            : switchedProfileInfo?.doctorSlug}
        />
        {
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ Height: "71vh" }}
          >
            <Box className={classes.modalStyle}>
              <CloseIcon onClick={handleClose} style={{ float: "right", cursor: "pointer" }} />
              <Typography variant="h5" className={classes.prescriptionTitle}>
                Patients Logs
              </Typography>
              <Box
                style={{
                  border: "1px solid gray ",
                  position: "relative",
                  overflow: "scroll",
                  height: "66vh",
                }}
              >
                {allData !== null ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                      <TableHead className={classes.striky}>
                        <TableRow className={classes.striky}>
                          <TableCell
                            component="th"
                            className={classes.textStyle}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            component="th"
                            className={classes.textStyle}
                          >
                            Message
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allData?.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Stack sx={{ width: "100%" }} spacing={2}>
                                <Typography align="center">No data available</Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : (
                          allData
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((item) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      className={classes.cellTextStyle}
                                      style={{ padding: "2%" }}
                                    >
                                      <Typography>
                                        {formatTime(item.dateTime)}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      className={classes.cellTextStyle}
                                      style={{ padding: "2%" }}
                                    >
                                      <Typography> {item.message}</Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })
                        )}
                      </TableBody>

                      <TablePagination
                        rowsPerPageOptions={[1, 10, 25, 50, 100]}
                        count={allData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </Box>
          </Modal>
        }

        {/* Report modal */}
      </div>

      <PrespHistoryDialog
        handleClose={onToggleMode}
        open={toggle}
        patientObj={patientObj}
      />
    </>
  );
};

export default Patient;
