import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";

const VideoPlayer = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    if (currentVideoIndex >= videos?.length) {
      setCurrentVideoIndex(0); // Reset to the first video if we reach the end
    }
  }, [currentVideoIndex, videos?.length]);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {videos.length > 0 && (
        <ReactPlayer
          url={videos[currentVideoIndex]}
          playing
          controls
          onEnded={handleVideoEnd}
          width="100%"
          height="100%"
        />
      )}
    </Box>
  );
};

export default VideoPlayer;
