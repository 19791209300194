import React, { useState, useEffect, createContext } from 'react'

import { useLocation } from "react-router-dom"
import Api from '../../utils/api';

import {
    TablePagination, Tab, Typography, Box, makeStyles, Grid, TextField, IconButton,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tooltip
} from "@material-ui/core"
import Loader from '../../components/misc/loader';
import moment from 'moment';
import { tr } from 'date-fns/locale';
import { AddCircleOutline } from "@material-ui/icons";
import Routes from '../../routes/routes';
import VisibilitySharp from '@material-ui/icons/VisibilitySharp';
import medicine from "../../assets/medicine.png"



const useStyles = makeStyles((theme) => ({
    tab: {
        fontSize: "0.9rem",
        textTransform: "none"
    },
    icon: {
        margin: theme.spacing(0, 4),
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        opacity: '0.8',
        padding: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            opacity: '0.6',
        },
        '&:disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26)',
        },





    },
    icon_1: {
        margin: theme.spacing(0, 4),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        opacity: '0.8',
        padding: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.white,
            opacity: '0.6',
        },
        '&:disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26)',
        },





    },
}));












const FileList = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id');


    const [patientStatus, setPatientStatus] = useState()
    const [provisionalDiagnosis, setProvisionalDiagnosis] = useState()
    const [plan, setPlan] = useState()
    const [roomNumber, setRoomNumber] = useState()
    const [doctor, setDoctor] = useState()
    const [patientName, setPatientName] = useState()
    const [doctorName, setDoctorName] = useState()
    const [patientUhid, setPatientUhid] = useState()

    const classes = useStyles();






    useEffect(() => {
        Api.get(`admit/id/${id}`).subscribe((resp) => {

            let admitdata = resp.data.data
            setDoctorName(admitdata?.doctor?.name)
            setPatientName(admitdata?.patient?.name)
            setPatientUhid(admitdata?.patient?.uhid)
            setRoomNumber(admitdata?.roomNumber)
            setProvisionalDiagnosis(admitdata?.prov)
            setPlan(admitdata?.plan)
            setPatientStatus(admitdata?.status)
            setDoctor(admitdata?.doctor)
        })
    }, [])



    // new content
    const [admitHistory, setAdmitHistory] = useState([])
    const [page, setPage] = useState(0);
    const [profilePic, setprofilePic] = useState("");
    const [loader, setLoader] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [waitResponse, setWaitResponse] = useState(false);

    const handleScroll = (e) => {

        if (!hasMore) {

            return;
        }

        if (!waitResponse) {
            const { scrollTop, clientHeight, scrollHeight } = e.target;

            console.log("test_scroll", Math.floor(scrollTop), clientHeight, scrollHeight)
            if (Math.floor(scrollTop) + clientHeight + 2 >= scrollHeight) {


                setPage(page + 1);
            }
        }
    };

    useEffect(() => {
       
        Api.post('admit/history/search', {
            "size": 20,
            "page": 0,
            "filterModel": {
                "admitId": {
                    "type": "equals",
                    "value": id
                }
            },
            "sortModel": [
                {
                    "colId": "createdAt",
                    "sort": "DESC"
                }
            ]
        }).subscribe((resp) => {
            if (resp) {
                setAdmitHistory(resp?.data?.data?.content)
               

               
            }

        })
        setPage(0);
    }, [id])


    useEffect(() => {
        if (!hasMore) {
            setLoader(false);
            return;
        }

        setLoader(true);

        setWaitResponse(true);
        Api.post('admit/history/search', {
            "size": 20,
            "page": 0,
            "filterModel": {
                "admitId": {
                    "type": "equals",
                    "value": id
                }
            },
            "sortModel": [
                {
                    "colId": "createdAt",
                    "sort": "DESC"
                }
            ]
        }).subscribe((resp) => {
            if (resp) {
                if (resp?.data?.data?.content === 0) {
                    setHasMore(false);
                    setLoader(false);

                    return;
                }

                console.log('test_response', resp?.data?.data?.content)
                setAdmitHistory([...admitHistory, ...resp?.data?.data?.content])
                setLoader(false);
                setWaitResponse(false);
            }

        })

    }, [page])

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        border: "0px",
    };
    const thStyle = {
        border: "0px",
        borderBottom: '1px solid gray',
        padding: '15px 10px',
        textAlign: 'left',

    };
    const tdStyle = {

        padding: '8px',
        textAlign: 'left',
        border: "0px",
    };
    const [rotate, setRotate] = useState(false);
    const [addtoggle, setaddtoggle] = useState(false);
    const addAppointmentItemstoggle = () => {
        setaddtoggle(!addtoggle)
        setRotate(!rotate);
        window.open(`#!` + Routes.PRESCRIPTION + `?id=${id}`, '_blank');
    }







    return (
        <>
            <Box
                flexGrow="1"
                display="flex"
                justifyContent="space-between"
                mb="1.5rem"
            >
                <div style={{ fontSize: '17px', padding: "22px 57px", fontFamily: "Comfortaa", fontWeight: "bold" }}>File-List</div>

            </Box>
            <div className="second_part justify-content-between  align-items-center ps-2">
                <div className="patient_name  align-items-center ps-2 row">

                    <p className='col-md-2'>
                        {" "}
                        <b>Patient Name </b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{patientName}
                        </small>
                    </p>
                    <p className='col-md-1'>
                        {" "}
                        <b>Room</b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{roomNumber}
                        </small>
                    </p>
                    <p className='col-md-2'>
                        {" "}
                        <b>Doctor</b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{doctorName}
                        </small>
                    </p>
                    <p className='col-md-2'>
                        {" "}
                        <b>Number/UHID</b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{patientUhid}
                        </small>
                    </p>
                    <p className='col-md-2'>
                        {" "}
                        <b>Diagnosis</b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{provisionalDiagnosis}
                        </small>
                    </p>
                    <p className='col-md-1'>
                        {" "}
                        <b>Plan</b> <br />
                        <small style={{ fontWeight: "500" }}>
                            &nbsp;{plan}
                        </small>
                    </p>
                    <p className='col-md-1'>
                        {" "}
                        <b>Status</b> <br />
                        <small style={{ fontWeight: "500", paddingRight: "15px" }}>
                            &nbsp;{patientStatus}
                        </small>
                    </p>
                </div>


            </div>


            {/* new section */}

            <div>
                {/* <h5 style={{ "fontWeight": "bold", "textAlign": "center" }}>Patients Summary</h5> */}
                <div style={{ height: "700px", overflow: "auto", fontSize: "10px", backgroundColor: "white", padding: "5px" }} onScroll={handleScroll}>

                    <section>
                        <table style={tableStyle}>

                            {

                                admitHistory.length !== 0 ? (
                                    admitHistory?.map((item, index) => {


                                        return (
                                            <>
                                                {
                                                    item.historyType == "billing" && (
                                                        <tr style={thStyle}>
                                                            <td style={tdStyle}><b>{moment(item.createdAt).format("DD-MM-YYYY")}  , {moment(item.createdAt).format(" HH:mm:ss")}</b></td>
                                                            <td style={tdStyle}>

                                                                <TableContainer
                                                                    // component={Paper}
                                                                // style={{ minWidth: '1500px' }}
                                                                >

                                                                    <Typography style={{ margin: "10px" }} variant="body1" component="p">

                                                                        <span style={{ fontWeight: "bold" }}>Activity :</span> <span>{item?.billing?.activityName}</span><span>{item?.billing?.remarks !== '' ? (<>
                                                                            
                                                                            ({item?.billing?.remarks})
                                                                        </>) : ("")}</span>

                                                                    </Typography>
                                                                    <Table aria-label="simple table" size="small">

                                                                        {
                                                                            loader ? (<Loader />) : (<>


                                                                                <TableHead>
                                                                                    <TableRow>

                                                                                        {/* <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>Diagnosis</span></TableCell> */}

                                                                                        <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>cost</span></TableCell>
                                                                                        <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>Quantitiy</span></TableCell>

                                                                                        <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>Discount</span></TableCell>
                                                                                        <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>SubTotal </span></TableCell>

                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody >

                                                                                    <TableRow >

                                                                                        
                                                                                        {/* <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >{row.DateandTime}</TableCell> */}

                                                                                        <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >{item?.billing?.price.toFixed(2)}</TableCell>
                                                                                        <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >{item?.billing?.number}</TableCell>

                                                                                        <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >
                                                                                            {item?.billing?.discountValue > 0 ? (<>
                                                                                                {item?.billing?.discountType == 'percentage' ? (<>
                                                                                                    {item?.billing?.discount} ({item?.billing?.discountValue}%)

                                                                                                </>) : (<>
                                                                                                    {item?.billing?.discountValue.toFixed(2)}
                                                                                                </>)}
                                                                                            </>) : ("0")}


                                                                                        </TableCell>
                                                                                        <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >(
                                                                                            {item?.billing?.type == 'credit' ? (<>
                                                                                                +
                                                                                            </>) : (<>
                                                                                                -
                                                                                            </>)}
                                                                                            ) {item?.billing?.totalAmount.toFixed(2)}
                                                                                        </TableCell>


                                                                                    </TableRow>

                                                                                </TableBody>
                                                                            </>)
                                                                        }
                                                                    </Table>
                                                                </TableContainer>





                                                            </td>
                                                            <td style={tdStyle}>by <b>{item.createdByName}</b></td>
                                                        </tr>

                                                    )
                                                }

                                                {
                                                    item.historyType == "prescription" && (
                                                        <tr style={thStyle}>
                                                            <td style={tdStyle}><b>{moment(item.createdAt).format("DD-MM-YYYY")}  , {moment(item.createdAt).format(" HH:mm:ss")}</b></td>
                                                            <td style={tdStyle}>
                                                                <Typography variant='h6'>{item?.prescription?.templateTypeName}</Typography>
                                                                <div dangerouslySetInnerHTML={{ __html: item?.prescriptionHtml }} />
                                                                </td>
                                                            <td style={tdStyle}>by <b>{item.createdByName}</b></td>
                                                        </tr>
                                                    )
                                                }

                                                {
                                                    item.historyType == "indicators" && (
                                                        <tr style={thStyle}>
                                                            <td style={tdStyle}><b>
                                                                {`${moment(item.createdAt).format("DD-MM-YYYY, HH:mm:ss")}`}
                                                            </b>
                                                            </td>
                                                            <td style={tdStyle}>
                                                                {item.bathingDone === "done" && (
                                                                    <span>{`Bathing done`}<br></br></span>
                                                                )}
                                                                {item.beddingDone === "done" && (
                                                                    <span>{`Bedding done`}<br></br></span>
                                                                )}
                                                                {item.oralCareDone === "done" && (
                                                                    <span>{`Oral care done`}<br></br></span>
                                                                )}
                                                                {item.passedFlatus === "done" && (
                                                                    <span>{`Passed flatus done`}<br></br></span>
                                                                )}
                                                                {item.passedMotion === "done" && (
                                                                    <span>{`Passed motion done`}<br></br></span>
                                                                )}
                                                            </td>
                                                            <td style={tdStyle}>
                                                                by <b>
                                                                    {`${item.createdByName}`}
                                                                </b>
                                                            </td>
                                                        </tr>



                                                    )
                                                }
                                                {
                                                    item.historyType == "vitals" && (
                                                        <tr style={thStyle}>
                                                            <td style={tdStyle}>
                                                                <b>
                                                                    <span>{moment(item.createdAt).format("DD-MM-YYYY, HH:mm:ss")}</span></b>
                                                            </td>
                                                            <td style={tdStyle}>
                                                                <span>Vitals recorded</span><br />
                                                                <span>{
                                                                    item.vitals !== null && item.vitals !== "" && (
                                                                        ` ${item.vitals.bloodPressure + " " + item.vitals.oxygenSaturation + " " + item.vitals.ps + " " + item.vitals.pulseRate + " " + item.vitals.respirationRate + " " + item.vitals.temperature}`
                                                                    )
                                                                }</span>
                                                            </td>
                                                            <td style={tdStyle}>
                                                                by  <b>
                                                                    <span>{item.createdByName}</span>
                                                                </b>

                                                            </td>
                                                        </tr>

                                                    )
                                                }

                                                {
                                                    item.historyType == "inputOutput" && (


                                                        <tr style={thStyle}>
                                                            <td style={tdStyle}>  <b>     <span>{moment(item.createdAt).format("DD-MM-YYYY, HH:mm:ss")}</span></b>
                                                            </td>
                                                            <td style={tdStyle}>
                                                                {
                                                                    item.inputOutput.inputOutputType == "output" && (
                                                                        <>
                                                                            <span><b>Output</b></span><br />
                                                                            <span>{`${item.inputOutput.volume + " " + item.inputOutput.unit}`}</span><br />
                                                                            <span>{`Type:${item.inputOutput.valueType} `}</span>
                                                                        </>


                                                                    )
                                                                }
                                                                {
                                                                    item.inputOutput.inputOutputType == "input" && (
                                                                        <>
                                                                            <span><b>Input</b></span><br />
                                                                            <span>{`${item.inputOutput.volume + " " + item.inputOutput.unit}`}</span><br />
                                                                            <span>{`Type:${item.inputOutput.valueType} `}</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </td>
                                                            <td style={tdStyle}>by  <b>{item.createdByName}</b></td>
                                                        </tr>


                                                    )
                                                }
                                                <div style={{ margin: "2px", bottom: " 40px", position: "fixed", right: "10px" }}>
                                                    {/* <AddCircleIcon onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease", }} /> */}
                                                    <AddCircleOutline onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease" }} />
                                                </div>


                                            </>



                                        )







                                    })


                                ) : (
                                    <>
                                        <li style={{ "marginBottom": "20px", "display": "flex" }}>No Data </li>
                                    </>
                                )

                            }
                        </table>
                    </section>
                    <section className="py-3">
                        <ul className="timeline-with-icons" style={{ listStyle: "none" }}>
                            {loader && (
                                <li className="timeline-item mb-5 text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </section>


                </div>
            </div>
        </>
    )
}

export default FileList
