import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Box } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";

import Header from "../../components/layout/header";
import ReferalDoctorForm from "./referal-doctor-form";
import Api from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import { validateEmail, validatePhone } from "../../utils/app-util";
import Axios from 'axios'
import AuthService from "../../utils/services/auth-service";

const useStyles = makeStyles({
  sectionContainer: {
    minHeight: "100vh",
  },
});

const NewReferalDoctor = () => {
  const history = useHistory();
  const classes = useStyles();
  const tenant = AuthService.getTenant()

  const handleSubmit = useCallback(
    (data) => {
      const { name, email, mobileNumber } = data;
      const payload = {
        name,
        email,
        mobileNumber,
        tenant: tenant.id
      }

      if (name && email && mobileNumber) {
        const isValidMobileNumber = validatePhone(mobileNumber);
        const isValidEmailId = validateEmail(email);
        if (!isValidEmailId) {
          NotificationManager.error(AppConstants.MSG.INVALID_EMAIL);
        } else if (!isValidMobileNumber) {
          NotificationManager.error(AppConstants.MSG.INVALID_MOBILE_NUMBER);
        } else {
          Axios.post(`${process.env.REACT_APP_API2_HOST}referral-doctors`, payload).then(()=>{
            NotificationManager.success(AppConstants.MSG.ADD_SUCCESS);
            setTimeout(() => {
              history.goBack();
            }, 2000);
          })
          .catch(()=>{
            NotificationManager.error(AppConstants.MSG.GENERIC_ERROR)
          })
        }
      } else {
        NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      }
    },
    [history, tenant]
  );

  return (
    <div className={classes.sectionContainer}>
      <Header showBackBtn title='New Referal Doctor' />
      <Box m={10}>
        <ReferalDoctorForm handleSubmit={handleSubmit}/>
      </Box>
    </div>
  );
};

export default NewReferalDoctor;
