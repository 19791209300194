import React, { useEffect, useState } from "react";
import PhoneNumberPage from "./components/phone-number-page";
import OtpPage from "./components/otp-page";

import "react-phone-input-2/lib/style.css";
import "./login-page.scss";
import Api2 from "../../utils/api2";
import Axios from "axios";


const LoginPage = () => {
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const [imgDatabackend, setImgDatabackend] = useState();

  useEffect(() => {
    Api2.get("get_bg_images?type=backEnd").subscribe(
      (resp) => {
        setBackgroundImage(resp?.data?.data?.loginBgBackEnd)
      }
    )
  }, [])
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setImgDatabackend(resp1?.data[0]?.loginBgBackEnd)
        // setImgData(resp1?.data[0]?.logo)
      }
    });
  }, []);

  // window.addEventListener('beforeunload', function() {
  //   // Get an array of all cookies
  //   window.localStorage.clear()
  //   var cookies = document.cookie.split(';');
  //   // Loop through the array and delete each cookie
  //   for (var i = 0; i < cookies.length; i++) {
  //     var cookie = cookies[i];
  //     var eqPos = cookie.indexOf('=');
  //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  //   }
  // });


  return (
    <div className='login-page' >
      {/* <div className='login-page__poster' style={{backgroundImage :`url(${backgroundImage})`}}></div> */}
      <div className='login-page__poster' style={{ backgroundImage: `url(${imgDatabackend})` }}></div>
      <div className='login-page__login'>
        {!openOtpPage ? (
          <PhoneNumberPage setOpenOtpPage={setOpenOtpPage} />
        ) : (
          <OtpPage setOpenOtpPage={setOpenOtpPage} />
        )}
      </div>
    </div>
  );
};
export default LoginPage;
