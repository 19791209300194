import { Box, Button, Grid, Paper } from "@material-ui/core";
import { Form } from "informed";
import React, { useState, useCallback, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import {
  SelectInput,
  TextAreaInput,
  TextInput,
  CustomDateInput,
} from "../../components/sixsprint-react-ui";
import useGlobalStyles from "../../utils/global-style";
import { makeStyles } from "@material-ui/styles";
import Loader from "../../components/misc/loader";
import { useEffect } from "react";

const useStyles = makeStyles({
  sectionContainer: {
    minHeight: "100vh",
  },
  staffDetailsForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "24px",
  },
  dropdownFields: {
    display: "flex",
    position: "relative",
  },
  iconButton: {
    position: "absolute",
    right: "5px",
    top: "25%",
  },
  fields: {
    width: "175px",
  },
});

const PatientForm = ({
  formApi,
  setName=()=>{},
  setEmail=()=> {},
  setDob=()=>{},
  setSex=()=>{},
  setAddress=()=>{},
  handleSubmit,
  setCountryCode,
  setMobileNumber,
  setIsInvalidPhone,
  addLoadingStatus,
  copyMobileNumber,
}) => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const gender = ["MALE", "FEMALE", "OTHERS"];

  useEffect(() => {
    if (copyMobileNumber) {
      setMobileNumber(copyMobileNumber);
    }
  }, [copyMobileNumber]);

  const handleChangePhoneNumber = useCallback((value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setIsInvalidPhone(false);
  }, []);

  const handleName = useCallback((value, data) => {
    setName(data);
  });

  const handleEmail = useCallback((value, data) => {
    setEmail(data);
  });
  const handleDob = useCallback((value, data) => {
    setDob(data);
  });
  const handleSex = useCallback((value, data) => {
    setSex(data);
  });
  const handleAddress = useCallback((value, data) => {
    setAddress(data);
  });

  return (
    <Form apiRef={formApi} onSubmit={handleSubmit}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center" m={2}>
          <Box mb="0.5rem">
            <TextInput
              className={globalClass.roundWhiteBg}
              containerClassName={globalClass.inputContainer}
              // required
              field="name"
              type="text"
              label="Patient Name"
              validateOnBlur
              validateOnChange
              onChange={handleName}
            />
          </Box>

          <Box mb="0.5rem">
            <TextInput
              className={globalClass.roundWhiteBg}
              containerClassName={globalClass.inputContainer}
              field="email"
              type="text"
              label="Patient Email"
              validateOnBlur
              validateOnChange
              onChange={handleEmail}
            />
          </Box>

          <Box>
            <PhoneInput
              country={"in"}
              className={`${globalClass.roundWhiteBg} ${classes.fields}`}
              enableSearch
              inputClass="login-page__phone-input"
              label="Staff Number"
              field="mobileNumber"
              value={"+91" + copyMobileNumber}
              countryCodeEditable={false}
              placeholder="Enter Mobile"
              onChange={handleChangePhoneNumber}
              validateOnBlur
              validateOnChange
            />
          </Box>

          <CustomDateInput
            style={{ marginLeft: "8px" }}
            containerClassName={"mr"}
            className={globalClass.roundWhiteBg}
            openTo="year"
            inputVariant="outlined"
            field="dob"
            label=" DOB"
            onChange={handleDob}
          />

          <Box mb="0.5rem">
            <SelectInput
              containerClassName={globalClass.inputContainer}
              className={globalClass.roundWhiteBg}
              options={gender}
              field="gender"
              placeholder="Gender"
              label="Sex"
              onChange={handleSex}
            />
          </Box>
        </Box>
      </Grid>
      <Box my={5}>
        <Paper
          style={{ padding: "1rem", width: "50%" }}
          className={globalClass.roundWhiteBg}
        >
          <TextAreaInput
            field="address"
            placeholder="Address...."
            onChange={handleAddress}
          />
        </Paper>
      </Box>

      <Box mx=".4rem" my={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={globalClass.mediumBtnContainer}
        >
          {addLoadingStatus ? (
            <Loader width="200px" height="20px" color="white" />
          ) : (
            "SAVE"
          )}
        </Button>
      </Box>
    </Form>
  );
};

export default PatientForm;
