
import React, {
  useEffect,
  useState,

} from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  makeStyles, Button,
  Typography,
  FormControlLabel,


  CircularProgress,
  TextField,
  Dialog
} from "@material-ui/core";
import Autocomplete from "../../components/misc/autocomplete";
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import {
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION
} from "../../utils/app-util";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { ThemeProvider, createTheme } from "@mui/material";



import useGlobalStyles from "../../utils/global-style";
import { TextInput } from "../../components/sixsprint-react-ui";
import Api from "../../utils/api";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import dayjs from "dayjs";
import 'dayjs/locale/en';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Radio, RadioGroup, FormLabel } from '@mui/material';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import moment from "moment";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const myTheme = createTheme({
  // style radio button as button element
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#293264",
          margin: "5px 15px 0 0",
          padding: "2px 8px",
          width: "max-content",
          borderStyle: "none",
          border: "1px solid !important",
          borderRadius: "13px!important",
          "&.Mui-selected": {
            backgroundColor: "#D6DBF5",
            borderStyle: "none!important"
          },
          "&:hover": {
            backgroundColor: "#D6DBF5"
          }
        },
        grouped: {
          backgroundColor: "#D6DBF5",
          borderStyle: "none!important",
          "&:hover": {
            backgroundColor: "#D6DBF5"
          }
        }


      }
    }
  }
});


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const useStyles = makeStyles((theme) => ({


  root: {
    '& .MuiRadio-root': {
      borderRadius: 0,  // Adjust this value to control the roundness of the corners
      padding: '4px',
      '&.Mui-checked': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Change the background color when the button is selected
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    paddingTop: "1rem",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  input: {
    display: "none",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },
  newtextField: {
    border: 'none',
    '& .PrivateNotchedOutline-root-878': {
      borderWidth: "0px",
    },


  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "90%",
    margin: "auto",
  },
  containerBox: {
    width: "100%",
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(5, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  reportTitle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReports: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reportContainer: {
    display: "flex",
  },
  closeTitle: {
    fontSze: "12px",
  },
  reportHeading: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  diagramContainer: {
    display: "flex",
  },
  diagramClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  closeTitle: {
    fontSze: "12px",
  },
  diagramImage: {
    height: "90%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },

  "prescription-details": {
    padding: "40px 30px",
    marginBottom: "2.5rem",

    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "20px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "20px" },
      },
    },

    "&__title": {
      color: "#1A1A1A",
      font: "bold 16px/18px Comfortaa",
      marginBottom: "8px",
    },

    "&__sub-title": {
      color: "#6D6D6D",
      font: "lighter 16px/18px Comfortaa",
      paddingBottom: "12px",
      borderBottom: "1px solid #E6E6E6",
    },
  },

  "prescription-qr": {
    textAlign: "center",
    padding: "20px 30px 30px",
    marginBottom: "2.5rem",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },

    "&__img": {
      maxWidth: "100%",
      margin: "-15px",
    },
  },
  vitals: {
    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "30px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "30px" },
      },
    },
  },
  otherEmailField: {
    width: "400px",
  },
  vitalSign: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "230px",
    },

    "& h6": {
      width: "40px",
    },

    "& .MuiPaper-root": {
      flex: 1,
    },
  },
  restrictionControlBox: {
    display: "flex",
    alignItems: "center",
  },
  clrBtnBox: {
    padding: 20,
  },

  imageDiv: {
    maxWidth: "100px",
    width: "100%",
    height: "100px",
    maxHeight: "100%",
    border: "1px solid black",
    borderRadius: "50%",
  },
}));




const AdmitPatient = ({ appointment, doctorSlug, open, setOpen, Prov, Hopi, Chiefcomplaints, Examination, medications }) => {
  dayjs.locale('en');
  const [receiptDate, setReceiptDate] = useState("");
  const [defaultTime, setDefaultTime] = useState("");
  const [expectedDischarge, setexpectedDischarge] = useState(null)
  const [surgeryDateTime, setSurgeryDateTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [slugdata, setSlugdata] = useState([])
  const [doctorName, setDoctorName] = useState([])
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <MenuIcon fontSize="small" />;

  const history = useHistory()

  // surgerydateandtime 2023-05-30 07:30:00

  //let Newmedication = medications;



  useEffect(() => {
    Api.post('doctor/search', {
      page: 0,
      size: 300,

      filterModel: {
        roleName: {
          filterType: "set",
          values: ["DOCTOR", "DOCTOR_ADMIN"]
        },
      },

      sortModel: [],
    }).subscribe((resp) => {


      let slugdatas = resp.data.data.content;

      setDoctorName(slugdatas)


    })
  }, [])
  useEffect(() => {
    Api.get('/admit/get_all_rooms?status=Active').subscribe((resp) => {
      if (resp) {
        // setResp(resp.data);
        setSlugdata(resp?.data?.data)

        // setLoading(true);
      }

    })
  }, []);
  const hasEmptyRoomNumber = slugdata.some(item => item.roomNumber === null || item.roomNumber.trim() === "");




  const handleSlug = (event, value) => {
    setPersonName(value);
    // Create an array to store the selected slugs
    const selectedSlugs = [];

    // Iterate through the selected names
    value.forEach((name) => {
      // Find the corresponding object in slugdata array
      const selectedOption = doctorName.find((option) => option.name === name);

      // If the object exists, push the slug to the selectedSlugs array
      if (selectedOption) {
        selectedSlugs.push(selectedOption.slug);
      }
    });

    // Set the selected slugs in the addPatientData.otherDoctors field
    addPatientData.otherDoctors = selectedSlugs;
  };









  const globalClass = useGlobalStyles();
  const classes = useStyles();




  const onSelectPrintingTIme = (timeString) => {
    setDefaultTime(timeString);
    if (receiptDate) {
      let surgdate = moment(receiptDate).format("YYYY-MM-DD") + " " + timeString.format("HH:mm:ss");


      setSurgeryDateTime(surgdate);
    }
  };
  const onReceiptDateSelected = (dateInput) => {
    setReceiptDate(dateInput);
    //  setReceiptDate(moment(dateInput).format("DD/MM/YYYY"));
    if (defaultTime) {

      let surgdate = format(dateInput?.$d, "yyyy-MM-dd") + " " + moment(defaultTime).format("HH:mm:ss");

      setSurgeryDateTime(surgdate);
    }
  };
  const onexpectedDischarge = (expecteddate) => {

    setPatientData(prevData => ({
      ...prevData,
      expectedDischarge: expecteddate
    }));



  }
  const handleClosee = () => {
    setReceiptDate(null);
    setDefaultTime(null);
    setPatientData({});
    setexpectedDischarge(null)
    setPersonName([])
    setOpen(false)

  };




  const [addPatientData, setPatientData] = useState({
    prov: "",
    chiefComplaints: "",
    hopi: "",
    examination: "",
    allowedFoods: "",
    plan: "",
    pacStatus: "",
    roomNumber: "",
    surgeryType: "",
    anesthesia: "",
    bloodRequirement: "",
    specialRequirements: "",
    expectedDischarge: "",

    otherDoctors: [],
    surgeryDateTime: "",
    patientSlug: "",
    appointmentSlug: "",
    doctorSlug: "",
    medications: ""

  })


  useEffect(() => {

    setPatientData(prevState => ({
      ...prevState,
      prov: Prov,
      hopi: Hopi,
      chiefComplaints: Chiefcomplaints,
      examination: Examination
    }))
    // addPatientData.prov = Prov;
    // addPatientData.hopi = Hopi;
    // addPatientData.chiefComplaints = Chiefcomplaints;
    // addPatientData.examination = Examination
  }, [Prov, Hopi, Chiefcomplaints, Examination])

  const handleadmitpatient = (event) => {
    const { name, value } = event.target;

    setPatientData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const showErrorAndResetLoading = (errorMessage) => {
    SHOW_ERROR_NOTIFICATION(errorMessage);
    setLoading(false);
  };
  const onsaveData = async () => {
    
    if (!addPatientData.plan) {
      showErrorAndResetLoading("Please enter plan");
      return;
    }
    if (!addPatientData.surgeryType) {
      showErrorAndResetLoading("Please choose surgery Type");
      return;
    }
    if (!addPatientData.anesthesia) {
      showErrorAndResetLoading("Please enter anesthesia");
      return;
    }
    if (!addPatientData.bloodRequirement) {
      showErrorAndResetLoading("Please enter bloodRequirement");
      return;
    }
    if (!addPatientData.specialRequirements) {
      showErrorAndResetLoading("Please enter specialRequirements");
      return;
    }
    if (!addPatientData.pacStatus) {
      showErrorAndResetLoading("Please choose pacstatus");
      return;
    }
    if (!addPatientData.roomNumber) {
      showErrorAndResetLoading("Please choose roomNumber");
      return;
    }
    // if (!addPatientData.allowedFoods) {
    //   SHOW_ERROR_NOTIFICATION("Please choose allowed food");
    //   return;
    // }
    if (!addPatientData.expectedDischarge) {
      showErrorAndResetLoading("Please enter expected Discharge ");
      return;
    }


    if (!surgeryDateTime) {
      showErrorAndResetLoading("Please enter date and time");
      return;
    }
    setLoading(true)

    addPatientData.surgeryDateTime = surgeryDateTime;
    // addPatientData.expectedDischarge = addPatientData.expectedDischarge;
    addPatientData.doctorSlug = doctorSlug;
    addPatientData.patientSlug = appointment?.patientSlug;
    addPatientData.appointmentSlug = appointment?.slug;
    addPatientData.medications = medications;

    // addPatientData.otherDoctors = personName;


    await Api.post("/admit/add", addPatientData).subscribe((response) => {
      if (response.data.status == true) {
        SHOW_SUCCESS_NOTIFICATION(response.data.message);
      }
      if (response.data.status == false) {
        SHOW_ERROR_NOTIFICATION(response.data.message);
      }


      setLoading(false);
      handleClosee();
      // history.push(Routes.APPOINTMENT);

    })


  }


  return (
    <div>


      <Dialog

        maxWidth="900px"
        open={open}
        onClose={handleClosee}


      >
        <DialogContent style={{ display: "grid", gridTemplateColumns: "400px 600px ", margin: "20px", borderRadius: "20px" }} >
          {/* a */}
          <div style={{ backgroundColor: "#EEF6FF", margin: "15px 25px", padding: "20px 10px", borderRadius: "20px" }}>
            <div style={{ padding: "10px" }}>
              <label style={{ fontSize: "14px", margin: "10px" }}>Provisional Diagonis</label> <br />
              <input required value={addPatientData.prov} onChange={handleadmitpatient} name="prov" style={{ backgroundColor: "white", border: "none", padding: "20px 60px", padding: "10px 20px", borderRadius: "6px", width: "100%" }} type="text" placeholder="Provisional Diagonis" />
            </div>
            <div style={{ padding: "10px" }}>
              <label style={{ fontSize: "14px", margin: "10px" }}>chief complaints</label> <br />
              <input required value={addPatientData.chiefComplaints} onChange={handleadmitpatient} name="chiefComplaints" style={{ backgroundColor: "white", border: "none", padding: "20px 60px", padding: "10px 20px", borderRadius: "6px", width: "100%" }} type="text" placeholder="chief complaints" />
            </div>
            <div style={{ padding: "10px" }}>
              <label style={{ fontSize: "14px", margin: "10px" }}>HOPI</label> <br />
              <input required value={addPatientData.hopi} onChange={handleadmitpatient} name="hopi" style={{ backgroundColor: "white", border: "none", padding: "20px 60px", padding: "10px 20px", borderRadius: "6px", width: "100%" }} type="text" placeholder="HOPI" />
            </div>

            <div style={{ padding: "10px" }}>
              <label style={{ fontSize: "14px", margin: "10px" }}>Examination</label> <br />
              <input required value={addPatientData.examination} onChange={handleadmitpatient} name="examination" style={{ backgroundColor: "white", border: "none", padding: "20px 60px", padding: "10px 20px", borderRadius: "6px", width: "100%" }} type="text" placeholder="Examination" />
            </div>
            <div style={{ padding: "10px" }}>
              <label style={{ fontSize: "14px", margin: "10px" }}>Allowed Foods</label> <br />
              <input required value={addPatientData.allowedFoods} onChange={handleadmitpatient} name="allowedFoods" style={{ backgroundColor: "white", border: "none", padding: "20px 60px", padding: "10px 20px", borderRadius: "6px", width: "100%" }} type="text" placeholder="Allowed foods" />
            </div>


          </div>

          {/* b */}
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <label>Plan</label>
              </Grid>
              <Grid item xs={8} >
                <TextInput
                  className={globalClass.roundWhiteBg}
                  style={{ width: "100%" }}
                  value={addPatientData.plan}
                  onChange={handleadmitpatient}
                  containerClassName={globalClass.inputContainer}
                  required
                  // field="name"
                  name="plan"

                  type="text"
                  // label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>

              <Grid item xs={4}>
                <FormLabel>Surgery Type:</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup
                  // defaultValue="elective"
                  // name="controlled-radio-buttons-group"
                  name="surgeryType"
                  value={addPatientData.surgeryType}
                  onChange={handleadmitpatient}
                  sx={{ my: 1 }}
                  row
                >
                  <FormControlLabel value="elective" control={<Radio />} label="Elective" />
                  <FormControlLabel value="emergency" control={<Radio />} label="Emergency" />
                </RadioGroup>
              </Grid>






              <Grid item xs={4}>
                <label>Anesthesia</label>
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  className={globalClass.roundWhiteBg}
                  style={{ width: "100%" }}
                  value={addPatientData.anesthesia}
                  containerClassName={globalClass.inputContainer}
                  onChange={handleadmitpatient}

                  required
                  // field="name"
                  name="anesthesia"
                  type="text"
                  // label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>


              {/* 4 */}
              <Grid item xs={4}>
                <label>Blood Requirement</label>
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  style={{ width: "100%" }}
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  value={addPatientData.bloodRequirement}
                  onChange={handleadmitpatient}
                  required
                  // field="name"
                  name="bloodRequirement"
                  type="text"
                  // label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>




              {/* 5 */}
              <Grid item xs={4}>
                <label>Special Requirements</label>
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  onChange={handleadmitpatient}
                  value={addPatientData.specialRequirements}
                  style={{ width: "100%" }}

                  required
                  // field="name"
                  name="specialRequirements"
                  type="text"
                  // label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>

              {/* allowedfoods */}

              {/* <Grid item xs={4}>
                <label>Allowed Foods</label>
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  onChange={handleadmitpatient}
                  value={addPatientData.allowedFoods}
                  style={{ width: "100%" }}

                  required
                  // field="name"
                  name="allowedFoods"
                  type="text"
                  // label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />
              </Grid> */}







              <br />
              <br />
              <br />




              <Grid item xs={4}>
                <label>Surgery Date&Time</label>
              </Grid>
              <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Surgery Date (DD/MM/YYYY)"
                        value={receiptDate || null}
                        name="surgeryDateTime"
                        // format="dd-MM-yyyy"
                        onChange={(newValue) => {
                          // const timestamp = newValue ? newValue.valueOf() : null;
                          onReceiptDateSelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        inputFormat="DD/MM/YYYY"
                        className={globalClass.roundWhiteBg}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        label="Surgery Time"
                        value={defaultTime || null}
                        name="surgeryDateTime"
                        onChange={(newValue) => {
                          onSelectPrintingTIme(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                      />
                    </Grid>

                  </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <br />
              {/* expectedDischarge */}
              <Grid item xs={4}>
                <label>Expected Discharge</label>
              </Grid>
              <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Expected Discharge (DD/MM/YYYY)"
                        // value={expectedDischarge || null}
                        value={addPatientData.expectedDischarge || null}
                        name="expectedDischarge"
                        onChange={(newValue) => {
                          // const timestamp = newValue ? newValue.valueOf() : null;
                          onexpectedDischarge(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        inputFormat="DD/MM/YYYY"
                        className={globalClass.roundWhiteBg}
                      />
                    </Grid>
                  </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <br />

              {/* otherDoctors */}
              {/* <Grid container spacing={4}> */}
              <Grid item md={3}>
                <label>Other Doctors</label>
              </Grid>

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={doctorName.map((option) => option.name)}
                getOptionLabel={(option) => option}
                className={globalClass.roundWhiteBg}
                onChange={handleSlug}

                value={personName}
                renderInput={(params) => {
                  return <TextField
                    {...params}
                    label={"Select Doctor"}
                    variant="outlined"
                  />;
                }}
                style={{ width: 611 }}
              />
              <Grid item xs={4}>
                <FormLabel>PAC  Status</FormLabel>
              </Grid>
              <Grid>

              </Grid>
              <Grid item xs={8}>
                <RadioGroup
                  // defaultValue="fit"
                  name="pacStatus"
                  value={addPatientData.pacStatus}
                  onChange={handleadmitpatient}
                  sx={{ my: 1 }}
                  row
                >
                  <FormControlLabel value="fit" control={<Radio />} label="fit" />
                  <FormControlLabel value="unfit" control={<Radio />} label="Unfit" />
                  <FormControlLabel value="pending" control={<Radio />} label="Pending" />

                </RadioGroup>
              </Grid>

              <Grid item xs={4}>
                <FormLabel>Room No.</FormLabel>
              </Grid>

              <Grid item xs={8}>
                <Grid item xs={12}>

                  <ThemeProvider theme={myTheme}>
                    <ToggleButtonGroup
                      name="roomNumber"
                      value={addPatientData.roomNumber}
                      exclusive
                      onChange={handleadmitpatient}
                      aria-label="text alignment"
                      style={{
                        "display": "flex",
                        "flexWrap": "wrap"
                      }}
                    >
                      {
                        !hasEmptyRoomNumber ? (
                          slugdata?.map((item, index) => (
                            <ToggleButton
                              name="roomNumber"
                              key={index}
                              value={item.roomNumber}
                              aria-label="centered"
                              style={{ width: "56px" }}
                            >
                              {item.roomNumber}
                            </ToggleButton>
                          ))
                        ) : (
                          <>
                            <Typography variant="h5">No Rooms available</Typography>
                          </>
                        )
                      }
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosee}>Cancel</Button>



          <Box
            ml={4}
            display={{ xs: "none", sm: "inline-block" }}
            className={globalClass.largeBtnContainer}
          >
            {
              loading ? (<Button variant="contained" color="primary" disabled>
                <CircularProgress size={20} />
                Loading
              </Button>) : (
                <Button
                  className={classes.printBtnContainer}
                  variant="contained"
                  color="primary"
                  onClick={onsaveData}

                >
                  Admit Patient
                </Button>
              )
            }
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AdmitPatient
